import { useLocation, useNavigate } from 'react-router'

import { httpPostLogout } from '@src/api/httpAPI/httpAuthAPI'
import { localStorageAPI, sessionStorageAPI } from '@src/api/storageAPI'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { topNotiCountsState } from '@src/stores/topNotiCountsState'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { userState } from '@src/stores/userStore'
import parseNumComma from '@src/util/parseNumComma'
import { topBlinkState } from '@src/stores/topBlinkState'
import { httpGetTopNotiCounts } from '@src/api/httpAPI/httpNotiCountApi'
import { getTopBlink } from '@src/util/getTopBlink'
import { forceRefreshState } from '@src/stores/forceRefreshState'

import i18next from '@src/lang/i18n'
import { Button } from '@mui/material'
import useLang from '@src/hooks/useLang'
import { useTranslation } from 'react-i18next'
import { displayLangState } from '@src/stores/displayLangState'
import { langState } from '../../stores/langState'

const PersonIcon = ({ svgClass }: { svgClass: string }) => {
  return (
    <svg
      className={svgClass}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.768 20c.34 0 .63-.12.87-.362.241-.24.362-.53.362-.87v-1.896c0-.365-.205-.662-.616-.89l-.616-.342-5.454-2.6c.43-.339.795-.746 1.095-1.222.3-.476.54-.97.723-1.486a8.51 8.51 0 0 0 .391-1.515 8.52 8.52 0 0 0 .117-1.31V4.38c0-.521-.166-1.042-.498-1.564a5.702 5.702 0 0 0-1.29-1.407 6.92 6.92 0 0 0-1.8-1.017A5.602 5.602 0 0 0 10.01 0c-.691 0-1.375.13-2.053.391-.678.26-1.28.6-1.808 1.017a5.535 5.535 0 0 0-1.281 1.407c-.326.522-.489 1.043-.489 1.564v3.128c0 .34.04.753.118 1.242.078.489.211.99.4 1.505.19.515.434 1.02.733 1.515.3.496.672.92 1.115 1.271l-5.494 2.6-.625.362c-.417.241-.626.531-.626.87v1.896c0 .34.12.63.362.87.24.241.537.362.89.362h17.516zM1.251 18.768v-1.27c0-.261.069-.44.206-.538a1.37 1.37 0 0 1 .322-.186l5.494-2.6c.195-.091.355-.225.479-.4.124-.177.205-.369.244-.578a1.241 1.241 0 0 0-.47-1.133c-.65-.522-1.13-1.258-1.436-2.21-.306-.951-.46-1.733-.46-2.346V4.38c0-.326.128-.671.382-1.036s.59-.704 1.007-1.017a5.86 5.86 0 0 1 1.407-.772 4.35 4.35 0 0 1 1.584-.303c.521 0 1.046.101 1.574.303.527.202.997.456 1.407.763.41.306.746.642 1.007 1.007.26.364.391.716.391 1.055v3.128c0 .6-.14 1.379-.42 2.337-.28.958-.76 1.697-1.437 2.219a1.241 1.241 0 0 0-.47 1.134 1.267 1.267 0 0 0 .723.997l5.455 2.58c.014 0 .101.043.264.127.163.085.245.297.245.636v1.212l-17.498.02z"
        // fill="#B9B9B9"
        fillRule="nonzero"
      />
    </svg>
  )
}

const ArrowRightIcon = ({ svgClass }: { svgClass: string }) => {
  return (
    <svg
      className={svgClass}
      width="8"
      height="16"
      viewBox="0 0 8 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-test"
        d="M.486 15.5a.51.51 0 0 1-.353-.148.514.514 0 0 1-.133-.355c0-.139.044-.257.133-.356l6.659-6.693-6.556-6.59a.486.486 0 0 1-.148-.355c0-.138.05-.256.148-.355A.481.481 0 0 1 .589.5C.727.5.845.55.943.648l6.91 6.945A.486.486 0 0 1 8 7.948c0 .138-.05.257-.147.356L.855 15.352a.482.482 0 0 1-.17.111.547.547 0 0 1-.199.037z"
        // fill="#B9B9B9"
        fillRule="nonzero"
      />
    </svg>
  )
}

const MainIcon = ({ svgClass }: { svgClass: string }) => {
  return (
    <svg
      className={svgClass}
      width="21"
      height="17"
      viewBox="0 0 21 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.93 4.275V2.138L10.5 7.457l-8.43-5.32v2.138l8.43 5.268 8.43-5.268zm0-4.275c.572 0 1.06.212 1.464.636.404.424.606.925.606 1.502v12.724c0 .577-.202 1.078-.606 1.502A1.948 1.948 0 0 1 18.93 17H2.07c-.572 0-1.06-.212-1.464-.636A2.105 2.105 0 0 1 0 14.862V2.138C0 1.56.202 1.06.606.636A1.948 1.948 0 0 1 2.07 0h16.86z"
        // fill="#A8A8A8"
        fillRule="nonzero"
      />
    </svg>
  )
}

const Topbar = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const user = useRecoilValue(userState)
  // const topNotiCounts = useRecoilValue(topNotiCountsState)
  const [isMyPage, setIsMyPage] = useState(false)
  const [isMessageBox, setIsMessageBox] = useState(false)
  const [topBlink, setTopBlink] = useRecoilState(topBlinkState)
  const [topNotiCounts, setTopNotiCounts] = useRecoilState(topNotiCountsState)
  const [forceRefresh, setForceRefresh] = useRecoilState(forceRefreshState)

  const [displayLang, setDisplayLang] = useRecoilState(displayLangState)
  const setLangStore = useSetRecoilState(langState)

  const trans = useTranslation()

  const selectLang = (lang: string) => {
    localStorageAPI.setItem('lang', lang)
    i18next.changeLanguage(lang)
    setLangStore(lang)
    setDisplayLang(localStorageAPI.getItem('lang'))
  }

  const toggleLang = () => {
    switch (localStorageAPI.getItem('lang')) {
      case 'en':
        selectLang('ko')
        break
      case 'ko':
        selectLang('en')
        break
      default:
        selectLang('ko')
    }
  }

  const setLang = useLang()

  useEffect(() => {
    setLang()
    setDisplayLang(localStorageAPI.getItem('lang'))
  }, [])

  const handleReceiveMessage = async (e: any) => {
    // console.log('call receiveMessage')
    getViewChageNotificationCount()
  }

  const getViewChageNotificationCount = useCallback(async () => {
    const notiCounts = await httpGetTopNotiCounts()
    const newBlink = getTopBlink(topNotiCounts, notiCounts, topBlink)
    setTopBlink(newBlink)
    setTopNotiCounts(notiCounts)
  }, [topNotiCounts, topBlink])

  const getNotificationCount = useCallback(async () => {
    const notiCounts = await httpGetTopNotiCounts()
    setTopNotiCounts(notiCounts)
  }, [])

  // useEffect(() => {
  //   console.log('topBlink', topBlink)
  // }, [topBlink])

  useEffect(() => {
    window.addEventListener('visibilitychange', handleReceiveMessage)
    return () => removeEventListener('visibilitychange', handleReceiveMessage)
  }, [topNotiCounts, topBlink])

  useEffect(() => {
    const token = sessionStorageAPI.getItem('token')
    if (token) {
      getNotificationCount()
    }
  }, [location.pathname])

  const totals = useMemo(() => {
    const result = {
      borrowIssueCount:
        topNotiCounts.borrow.competitive.issueCount +
        topNotiCounts.borrow.nextDay.issueCount +
        topNotiCounts.borrow.today.issueCount,
      borrowTotalValue:
        topNotiCounts.borrow.competitive.totalValue +
        topNotiCounts.borrow.nextDay.totalValue +
        topNotiCounts.borrow.today.totalValue,
      lendIssueCount:
        topNotiCounts.lend.competitive.issueCount +
        topNotiCounts.lend.nextDay.issueCount +
        topNotiCounts.lend.today.issueCount,
      lendTotalValue:
        topNotiCounts.lend.competitive.totalValue +
        topNotiCounts.lend.nextDay.totalValue +
        topNotiCounts.lend.today.totalValue,
    }
    return result
  }, [topNotiCounts])

  useLayoutEffect(() => {
    const orderType = location.pathname.split('/')[1]
    const second = location.pathname.split('/')[2]
    // if (orderType == 'borrow') setOrderType('BORROW')
    // if (orderType == 'lend') setOrderType('LEND')
    if (orderType === 'mypage') {
      setIsMyPage(true)
    } else {
      setIsMyPage(false)
    }
    if (
      orderType === 'message' &&
      (second === 'list' || second === 'detail' || second === 'send')
    ) {
      setIsMessageBox(true)
    } else {
      setIsMessageBox(false)
    }
  }, [location])

  return (
    <div className="appbar">
      <div className="top-appbar">
        <div className="ul-wrap">
          <div className={`user-info is-mypage-${isMyPage}`} onClick={() => navigate('/mypage')}>
            <div className="top-icon-wrap">
              <PersonIcon svgClass={isMyPage ? 'sidebar-top-svg-selected' : 'sidebar-top-svg'} />
            </div>
            <span className={isMyPage ? 'name sidebar-top-text-selected' : 'name sidebar-top-text'}>
              {user?.name || ''}
            </span>
            {/*<ArrowRightIcon*/}
            {/*    svgClass={isMyPage ? 'sidebar-top-svg-selected' : 'sidebar-top-svg'}*/}
            {/*/>*/}
          </div>
          {/* <div
            className={`user-account-${location.pathname.split('/')[1] === 'user-account'}`}
            onClick={() => navigate('/user-account')}
          >
            <span>내계좌</span>
          </div>
          <div
            className={`user-group-${location.pathname.split('/')[1] === 'user-group'}`}
            onClick={() => navigate('/user-group')}
          >
            <span>내그룹</span>
          </div> */}
          <div
            className={`message is-message-${isMessageBox}`}
            onClick={() => {
              if (location.pathname === '/message/list') {
                setForceRefresh(!forceRefresh)
                return
              }
              navigate('/message/list')
            }}
          >
            <div className="top-icon-wrap">
              <MainIcon svgClass={isMessageBox ? 'sidebar-top-svg-selected' : 'sidebar-top-svg'} />
            </div>
            <span
              className={isMessageBox ? 'text sidebar-top-text-selected' : 'text sidebar-top-text'}
            >
              {trans.t('message')}
            </span>
            {/*<span*/}
            {/*    className={*/}
            {/*      isMessageBox ? 'count sidebar-top-text-selected' : 'count sidebar-top-text'*/}
            {/*    }*/}
            {/*>*/}
            {/*    {topNotiCounts.messageCount}*/}
            {/*  </span>*/}
          </div>
        </div>
        {/* lang: {displayLang}
        <Button onClick={() => i18next.changeLanguage('en')}>change en</Button> */}
        <div className="language-wrap">
          <div
            className={displayLang === 'ko' ? 'language-selected' : 'language'}
            onClick={() => selectLang('ko')}
          >
            KOR
            {/* <Button onClick={() => i18next.changeLanguage('en')}>change en</Button> */}
          </div>
          <div
            className={displayLang === 'en' ? 'language-selected' : 'language'}
            onClick={() => selectLang('en')}
          >
            ENG
          </div>
        </div>
        <div
          className="logout"
          onClick={async () => {
            await httpPostLogout()
            sessionStorageAPI.removeItem('token')
            navigate('/user/login')
          }}
          style={{ float: 'right' }}
        >
          {trans.t('logout')}
        </div>
      </div>
      <div className="bot-appbar">
        <div className="ul-wrap">
          <ul>
            <div className="ul-title-wrap">
              <div className="ul-title ul-title-lend">{trans.t('notiBar.lend')}</div>
            </div>
            <li className={topBlink.lend.requestCount ? 'blink-text-blue' : undefined}>
              <div className="menu-name">{trans.t('notiBar.orderResponse')}</div>
              <div className="menu-count">{topNotiCounts.lend.requestCount}</div>
            </li>
            <li className={topBlink.lend.matchedCount ? 'blink-text-blue' : undefined}>
              <div className="menu-name">{trans.t('notiBar.orderConfirmed')}</div>
              <div className="menu-count">{topNotiCounts.lend.matchedCount}</div>
            </li>
            <li className={topBlink.lend.cancelRequestCount ? 'blink-text-blue' : undefined}>
              <div className="menu-name">{trans.t('notiBar.orderCancelResponse')}</div>
              <div className="menu-count">{topNotiCounts.lend.cancelRequestCount}</div>
            </li>
          </ul>
          <ul>
            <div className="ul-title-wrap">
              <div className="ul-title ul-title-borrow">{trans.t('notiBar.borrow')}</div>
            </div>
            <li className={topBlink.borrow.requestCount ? 'blink-text-red' : undefined}>
              <div className="menu-name">{trans.t('notiBar.orderResponse')}</div>
              <div className="menu-count">{topNotiCounts.borrow.requestCount}</div>
            </li>
            <li className={topBlink.borrow.matchedCount ? 'blink-text-red' : undefined}>
              <div className="menu-name">{trans.t('notiBar.orderConfirmed')}</div>
              <div className="menu-count">{topNotiCounts.borrow.matchedCount}</div>
            </li>
            <li className={topBlink.borrow.cancelRequestCount ? 'blink-text-red' : undefined}>
              <div className="menu-name">{trans.t('notiBar.orderCancelResponse')}</div>
              <div className="menu-count">{topNotiCounts.borrow.cancelRequestCount}</div>
            </li>
          </ul>
          <ul>
            <div className="ul-title-wrap">
              <div className="ul-title ul-title-message">{trans.t('message')}</div>
            </div>
            {/*<li className={topBlink.recallCount && 'alerts-border'}>*/}
            <li className={topNotiCounts.recallCount > 0 ? 'blink-text-green' : undefined}>
              <div className="menu-name">{trans.t('notiBar.recall')}</div>
              <div className="menu-count">{topNotiCounts.recallCount}</div>
            </li>
            <li className={topNotiCounts.urgentCount > 0 ? 'blink-text-green' : undefined}>
              <div className="menu-name">{trans.t('notiBar.urgent')}</div>
              <div className="menu-count">{topNotiCounts.urgentCount}</div>
            </li>
          </ul>
          <div className="break"></div>
          <ul>
            <div className="ul-title-wrap">
              <div className="ul-title">{trans.t('notiBar.lendPool')}</div>
            </div>
            {/* <li className={'blink-bg'}> */}
            <li>
              <div className="menu-name">{trans.t('notiBar.issueCount')}</div>
              <div className="menu-count">{parseNumComma(totals.lendIssueCount)}</div>
            </li>
            <li>
              <div className="menu-name">{trans.t('notiBar.issueAmount')}</div>
              <div className="menu-count">{parseNumComma(totals.lendTotalValue)}</div>
            </li>
          </ul>
          <ul>
            <div className="ul-title-wrap">
              <div className="ul-title">{trans.t('notiBar.borrowPool')}</div>
            </div>
            <li>
              <div className="menu-name">{trans.t('notiBar.issueCount')}</div>
              <div className="menu-count">{parseNumComma(totals.borrowIssueCount)}</div>
            </li>
            <li>
              <div className="menu-name">{trans.t('notiBar.issueAmount')}</div>
              <div className="menu-count">{parseNumComma(totals.borrowTotalValue)}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Topbar
