import Button from '@src/components/Button'
import PopupWrapper from '@src/components/PopupWrapper'
import { Dispatch, FunctionComponent } from 'react'
import { useNavigate } from 'react-router'

import { Box, Button as MuiButton, Modal } from '@mui/material'
import { MESSAGE, RESULT } from '@src/constants/NotiText'

interface JoinPopupProps {
  isPopupOpen: boolean
  setIsPopupOpen: Dispatch<React.SetStateAction<boolean>>
}

const forgotModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '560px',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const JoinPopup = ({ isPopupOpen, setIsPopupOpen }: JoinPopupProps) => {
  const navigate = useNavigate()
  return (
    <Modal
      open={isPopupOpen}
      onClose={() => setIsPopupOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...forgotModalStyle,
          width: '500px',
          height: '250px',
          paddingTop: '50px',
          paddingBottom: '50px',
        }}
      >
        <div id="parent-modal-title">제출 완료되었습니다.</div>
        <p id="parent-modal-description">회원가입 승인 진행중입니다.</p>
        {/* <hr className="en-kr" /> */}
        {/* <div id="parent-modal-title">Your submission was successful.</div>
        <p id="parent-modal-description">{MESSAGE.applyToRegister}</p> */}
        <br></br>
        <br></br>
        <div id="help-link" style={{ top: '72%' }} onClick={() => navigate('/user/login')}>
          <a>확인</a>
        </div>
        {/*<MuiButton*/}
        {/*  sx={{ margin: 'auto' }}*/}
        {/*  variant="outlined"*/}
        {/*  onClick={() => {*/}
        {/*    setIsRegisterModal(false)*/}
        {/*  }}*/}
        {/*>*/}
        {/*  OK*/}
        {/*</MuiButton>*/}
      </Box>
    </Modal>

    // <PopupWrapper close={() => {}}>
    //   <div className="join-popup">
    //     <h3>회원가입 완료</h3>
    //     <p>회원가입이 완료되었습니다</p>
    //     <Button onClick={() => navigate('/user/login')}>로그인</Button>
    //   </div>
    // </PopupWrapper>
  )
}

export default JoinPopup
