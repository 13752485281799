import { colors, InputAdornment, TextField } from '@mui/material'

import { useCallback, useMemo, useRef, useState } from 'react'

import { Search as SearchIcon, Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material'
import { useEffect } from 'react'
import { MyGroup } from '@src/pages/MyPage/MyPage'
import { httpDelMyGroup, httpPutMyGroupName } from '@src/api/httpAPI/httpMyPageAPI'
import { alignProperty } from '@mui/material/styles/cssUtils'
import { HttpErrMsgDefault } from '@src/api/httpAPI/errMsg'
import { delConfirm } from '@src/api/httpAPI/confirmMsg'
import { REQUEST, VALUECHECK } from '@src/constants/NotiText'
import { useRecoilState } from 'recoil'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { useTranslation } from 'react-i18next'

interface MyGroupCompnay {
  accountId: number
  companyCode: string
  companyName: string
  companyPropertyCode: string
  companySLBCode: string
}

interface MyGroupDetail {
  id: number
  name: string
  accountList: MyGroupCompnay[]
}

interface MyGroupTextFeildProps {
  selectedMyGroupId: number
  setSelectedMyGroupId: React.Dispatch<React.SetStateAction<number>>
  index: number
  fetchMyGroupList: (id?: number) => Promise<void>
  group: MyGroup
  getCurrentGroup: (id: number) => Promise<void>
  editingMyGroupIndex: number
  setEditingMyGroupIndex: React.Dispatch<React.SetStateAction<number>>
  myGroupList: any
  setCurrentGroup: React.Dispatch<React.SetStateAction<MyGroupDetail>>
}

const MyGroupTextFeild = ({
  selectedMyGroupId,
  setSelectedMyGroupId,
  index,
  group,
  fetchMyGroupList,
  getCurrentGroup,
  editingMyGroupIndex,
  setEditingMyGroupIndex,
  setCurrentGroup,
}: MyGroupTextFeildProps) => {
  const c = useMemo(() => document.createElement('canvas'), [])
  const ctx = useMemo(() => c.getContext('2d'), [])
  const [inputValue, setInputValue] = useState(group.name)
  const [fixBtnMargin, setFixBtnMargin] = useState('10px')
  const [isDisabled, setIsDisabled] = useState(true)

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)

  const prevInputValue = useMemo(() => (' ' + group.name).slice(1), [isDisabled])

  const ref = useRef<HTMLInputElement>()

  const trans = useTranslation()

  useEffect(() => {
    const textWidth = ctx.measureText(inputValue).width
    if (inputValue === '') {
      setFixBtnMargin('140px')
    } else {
      const marginNum = 130 - textWidth * 1.58
      setFixBtnMargin(marginNum.toString() + 'px')
    }
  }, [inputValue])

  useEffect(() => {
    if (editingMyGroupIndex === index) {
      setIsDisabled(false)
      ref.current.focus()
      return
    }
    setIsDisabled(true)
  }, [editingMyGroupIndex])

  useEffect(() => {
    !isDisabled && ref.current.focus()
  }, [isDisabled])

  let flag = false

  const handleDleBtnCallback = async (param) => {
    try {
      await httpDelMyGroup(param.id)
      await fetchMyGroupList(param.id)
      setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('deleted') })
    } catch (error) {
      setErrorModal({ ...errorModal, isOpen: true, innerString: trans.t('modal.request.invalid') })
      console.error(error)
      // alert(HttpErrMsgDefault)
    }
  }

  const handleDleBtn = useCallback(async (id: number) => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: trans.t('modal.request.removeMyGroup'),
      confirmFunction: handleDleBtnCallback,
      closeFunction: () => {
        return
      },
      confirmFunctionParam: { id: id },
      // if (window.confirm(delConfirm)) {
      //   try {
      //     await httpDelMyGroup(id)
      //     await fetchMyGroupList(id)
      //   } catch (e) {
      //     console.error(e)
      //     alert(HttpErrMsgDefault)
      //   }
      // }
    })
  }, [])

  const handleClick = useCallback(async (id: number) => {
    try {
      await getCurrentGroup(id)
      await fetchMyGroupList(id)
      flag = false
    } catch (e) {
      console.error(e)
      alert(HttpErrMsgDefault)
    }
  }, [])

  //   input: {
  //     "-webkit-text-fill-color": `${textFieldColor} !important`,
  //     color: `${textFieldColor} !important`,
  // },

  const fieldsetStyleDefault = {
    fieldset: { borderRadius: '50px', border: 'solid 1px rgba(151, 139, 244, 0.15) !important' },
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        borderColor: 'rgba(151, 139, 244, 0.11) !important',
        backgroundColor: 'rgba(151, 139, 244, 0.11) !important',
      },
    },
    input: {
      WebkitTextFillColor: `black !important`,
      color: `black !important`,
    },
  }

  const fieldsetStyleSelected = {
    // backgroundColor: 'blue !important',
    fieldset: {
      borderRadius: '50px',
      border: 'solid 1px rgba(151, 139, 244, 0.23) !important',
      backgroundColor: 'rgba(151, 139, 244, 0.23) !important',
    },
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        borderColor: 'blue',
      },
    },
    input: {
      WebkitTextFillColor: `black !important`,
      color: `black !important`,
    },
  }

  const fieldsetStyle = fieldsetStyleDefault

  // const isDisabled = index !== editingMyGroupIndex

  return (
    <li
      className={
        group.id === selectedMyGroupId ? 'mypage-group-li-selected' : 'mypage-group-li-not-selected'
      }
      onPointerDown={() => {
        if (flag === false) {
          flag = true
          setSelectedMyGroupId(group.id)
          handleClick(group.id)
        }
      }}
    >
      <input
        disabled={isDisabled}
        onBlur={async (e) => {
          if (inputValue.length < 1) {
            alert(trans.t('modal.valueCheck.leastOneCharacter'))
            e.target.value = prevInputValue
            setInputValue(prevInputValue)
            setEditingMyGroupIndex(-1)
            return
          }
          if (inputValue == prevInputValue) {
            setEditingMyGroupIndex(-1)
            return
          }
          await httpPutMyGroupName(group.id, { name: inputValue })
          setEditingMyGroupIndex(-1)
          fetchMyGroupList()
          setCurrentGroup({
            id: -1,
            name: '-1',
            accountList: [],
          })
        }}
        onKeyDown={async (e: any) => {
          if (e.key !== 'Enter') {
            return
          }
          if (inputValue.length < 1) {
            alert(trans.t('modal.valueCheck.leastOneCharacter'))
            e.target.value = prevInputValue
            setInputValue(prevInputValue)
            return
          }
          if (inputValue == prevInputValue) {
            setEditingMyGroupIndex(-1)
            return
          }
          await httpPutMyGroupName(group.id, { name: inputValue })
          setEditingMyGroupIndex(-1)
          fetchMyGroupList()
          setCurrentGroup({
            id: -1,
            name: '-1',
            accountList: [],
          })
        }}
        ref={ref}
        defaultValue={group.name}
        id="outlined-basic"
        // label=""
        placeholder=""
        // variant="outlined"
        // size="small"
        onChange={(e) => {
          setInputValue(e.target.value)
        }}
        className={
          group.id === selectedMyGroupId
            ? 'mypage-group-input-selected'
            : 'mypage-group-input-not-selected'
        }
        // sx={group.id === selectedMyGroupId ? fieldsetStyleSelected : fieldsetStyleDefault}
        // inputProps={{
        //   maxLength: 8,
        // }}
        // InputProps={{
        //   endAdornment: (
        //     <>
        //       <InputAdornment position="end" sx={{ marginRight: fixBtnMargin }}>
        //         <EditIcon
        //           onClick={(e) => {
        //             e.stopPropagation()
        //             setEditingMyGroupIndex(index)
        //             ref.current.querySelector('input').focus()
        //           }}
        //           sx={{ cursor: 'pointer', color: 'grey', padding: '0px', height: '16px' }}
        //         ></EditIcon>
        //       </InputAdornment>
        //       <InputAdornment position="end">
        //         <CloseIcon
        //           onClick={(e) => {
        //             e.stopPropagation()
        //             handleDleBtn(group.id)
        //           }}
        //           sx={{ cursor: 'pointer', color: 'crimson' }}
        //         ></CloseIcon>
        //       </InputAdornment>
        //     </>
        //   ),
        // }}
      />
      <EditIcon
        onClick={(e) => {
          e.stopPropagation()
          setEditingMyGroupIndex(index)
          ref.current.focus()
        }}
        sx={{ cursor: 'pointer', color: 'grey', padding: '0px', height: '16px' }}
      ></EditIcon>
      <CloseIcon
        onClick={(e) => {
          e.stopPropagation()
          handleDleBtn(group.id)
        }}
        sx={{ cursor: 'pointer', color: 'crimson' }}
      ></CloseIcon>
    </li>
  )
}

export default MyGroupTextFeild
