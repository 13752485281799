import httpClient from './httpClient'

export const httpGetOrderHistory = async (params: {
  type: OrderType | ''
  status: StatusType | ''
  query: string
  before: string
  after: string
  page: number
  size: number
  dateType: 'ORDER_DATE' | 'SETTLEMENT_DATE'
}) => {
  const { data } = await httpClient.get<OrderHistoryType[]>('/api/v1/order-history', { params })

  return data
}

export const httpDeleteOrderHistory = async (
  params: { orderId: string; type: OrderHistoryType['transactionType'] }[]
) => {
  const { data } = await httpClient.delete<ResponseDeleteOrderHistoryType[]>(
    '/api/v1/order-history',
    {
      data: params,
    }
  )

  return data
}

export const httpGetOrderHistoryReturn = async (params: {
  type: OrderType | ''
  status: StatusType | ''
  query: string
  before: string
  after: string
}) => {
  const { data } = await httpClient.get<OrderHistoryReturnType[]>('/api/v1/order-history/returns', {
    params,
  })

  return data
}
