import { Check } from '@mui/icons-material'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { httpGetMyGroup } from '@src/api/httpAPI/httpMyPageAPI'
import { SelectedGroup, SelectedPool } from '@src/pages/LendPoolRegister/LendPoolRegister'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

interface GroupAutocomplateProps {
  options: any[]
  selectedGroupId: string
  selectedPoolList: SelectedPool[]
  setSelectedPoolList: React.Dispatch<React.SetStateAction<SelectedPool[]>>
  assetCompanyCodeList: (string | number)[]
}

const GroupAutocomplateCompanyCode = ({
  options,
  selectedGroupId,
  selectedPoolList,
  setSelectedPoolList,
  assetCompanyCodeList,
}: GroupAutocomplateProps) => {
  const [autoCompleteInputText, setAutoCompleteInputText] = useState(null)
  const [selectedComboboxOption, setSelectedComboboxOption] = useState(null)
  const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>([])

  const trans = useTranslation()

  useEffect(() => {
    setAutoCompleteInputText(null)
  }, [])

  const hanldeAssetGroupInputChange = useCallback(
    async (e: any, value, reason) => {
      if (!e) {
        return
      }
      if (e._reactName === 'onChange') {
        return
      }
      if (e._reactName === 'onBlur') {
        return
      }
      const selected = options.filter((option) => option.label === value)[0]
      const isDup = (selectedPoolList: SelectedPool[], selected) => {
        let bool = false
        selectedPoolList.forEach((pool) => {
          if (pool.companyCode === selected.companyCode) {
            bool = true
            return
          }
        })
        return bool
      }
      if (isDup(selectedPoolList, selected)) {
        return
      }
      if (selected.companyCode === '-1') {
        setSelectedPoolList([
          ...selectedPoolList.filter((pool) => pool.companyCode === undefined),
          { companyName: selected.label, companyCode: selected.companyCode },
        ])
      } else {
        setSelectedPoolList([
          ...selectedPoolList.filter((pool) => pool.companyCode !== '-1'),
          { companyName: selected.label, companyCode: selected.companyCode },
        ])
      }
    },
    [selectedPoolList]
  )

  const handleOnClick = useCallback(
    (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>,
      option: any,
      isAllOption: boolean,
      checked: boolean,
      selected: any
    ) => {
      e.preventDefault()
      if (isAllOption) {
        if (!checked) {
          setSelectedPoolList([
            ...selectedPoolList.filter((pool) => pool.companyCode === undefined),
            { companyName: option.label, companyCode: option.companyCode },
          ])
          return
        }
      }
      if (!checked) {
        setSelectedPoolList([
          ...selectedPoolList.filter((pool) => pool.companyCode !== '-1'),
          { companyName: option.label, companyCode: option.companyCode },
        ])
        return
      }
      setSelectedPoolList(selectedPoolList.filter((elem) => elem.companyCode !== selected))
    },
    [selectedPoolList]
  )

  return (
    <Autocomplete
      // autoSelect
      // autoHighlight
      // open={true}
      value={autoCompleteInputText}
      className="autocomplete-wrap"
      // sx={{
      //   fontSize: '13px !important',
      //   width: '230px',
      //   button: {
      //     backgroundColor: '#ffffff !important;',
      //     height: '24px !important;',
      //     width: '24px !important;',
      //     padding: '0px !important;',
      //     marginTop: '2px !important',
      //   },
      // }}
      onBlur={() => setAutoCompleteInputText(null)}
      isOptionEqualToValue={(option, value) => option.label == value}
      onInputChange={hanldeAssetGroupInputChange}
      forcePopupIcon={true}
      size="small"
      options={options}
      renderOption={(props, option) => {
        const isAllOption = option.companyCode === '-1'
        const selected = option.accountId || option.companyCode
        const checked = assetCompanyCodeList.includes(selected)
        if (option.companyCode === '-1') {
          return (
            <li
              {...props}
              key={option.label}
              className={'li-group'}
              onClick={(e) => {
                handleOnClick(e, option, isAllOption, checked, selected)
              }}
            >
              {checked && (
                <input
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleOnClick(e, option, isAllOption, checked, selected)
                  }}
                  readOnly
                  type="checkbox"
                  checked={true}
                ></input>
              )}
              {!checked && (
                <input
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleOnClick(e, option, isAllOption, checked, selected)
                  }}
                  readOnly
                  type="checkbox"
                  checked={false}
                ></input>
              )}
              {trans.t('all')}
            </li>
          )
        } else {
          return (
            <li
              {...props}
              key={option.label}
              className={'li-group-list'}
              onClick={(e) => {
                handleOnClick(e, option, isAllOption, checked, selected)
              }}
            >
              {checked && (
                <input
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleOnClick(e, option, isAllOption, checked, selected)
                  }}
                  readOnly
                  type="checkbox"
                  checked={true}
                ></input>
              )}
              {!checked && (
                <input
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleOnClick(e, option, isAllOption, checked, selected)
                  }}
                  readOnly
                  type="checkbox"
                  checked={false}
                ></input>
              )}
              {option.label}
            </li>
          )
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            // onChange={(e) => setAutoCompleteInputText(e.target.value)}
            // onClick={() => {
            //   setSelectedGroup(selectedGroupId as SelectedGroup)
            // }}
            {...params}
            placeholder={trans.t('search')}
          />
        )
      }}
    ></Autocomplete>
  )
}

export default GroupAutocomplateCompanyCode
