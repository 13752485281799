import { httpGetPoolHistory } from '@src/api/httpAPI/httpPoolAPI'
import Checkbox from '@src/components/Checkbox'
import { OrderTypeText, OrderTypeTextEn, OrderTypeTextKo } from '@src/constants/OrderTypeText'
import { PoolStatusText, PoolStatusTextEn, PoolStatusTextKo } from '@src/constants/PoolStatusText'
import useInfiniteScroll from '@src/hooks/useInfiniteScroll'
import { formatNumber } from '@src/util/formatNumber'
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'

import { styled } from '@mui/system'

import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TableBodyTd from '@src/components/Table/getTableBodyTds'
import getTableBodyTds from '@src/components/Table/getTableBodyTds'
import { useRecoilState } from 'recoil'
import { forceFetchState } from '@src/stores/forceFetchState'
import { contverTrueFalseToYorN } from '@src/util/contverTrueFalseToYorN'
import { CLASSES } from '@src/constants/Global'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface Props {
  setSelectedPoolIds: React.Dispatch<React.SetStateAction<number[]>>
  poolHistory: PoolHistoryType[]
  poolStatus: PoolStatusType | 'ALL'
  setPoolStatus: React.Dispatch<React.SetStateAction<PoolStatusType | 'ALL'>>
  settlementType: SettlementType
  query: string
  setPoolHistory: React.Dispatch<React.SetStateAction<PoolHistoryType[]>>
  pageType: PageType
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  constants: any
  isSwap: any
  setIsSwap: React.Dispatch<any>
  tab: string
}

const isInnerStrAlignRight = (text: string) => {
  return text.includes(',') ? true : text.includes('.') ? true : text === '0' ? true : false
}

const AlignedTableCell = (innerStr: string) => {
  if (isInnerStrAlignRight(innerStr)) {
    return styled('tr')({
      align: 'right !important',
    })
  }
}

// const Table = styled('table')({
//   backgroundColor: 'red',
//   '& > thead': {
//     backgroundColor: 'green !important',
//   },
//   '& .tr': {
//     backgroundColor: 'yellow',
//   },
// })

type PoolType = 'ALL' | 'SELECT' | 'ASSET' | 'SECURITY'

const popupBtnStyle = [
  {
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '2px',
    backgroundColor: 'inherit',
    boxShadow: 'none !important',
    lineHeight: 'normal',
  },
  {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    '& >span': {
      marginTop: '20px !important',
    },
  },
]

const LendPoolRegisterListTable: FunctionComponent<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = [props.selectedIndex, props.setSelectedIndex]

  const [isLast, setIsLast] = useState(true)
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const [page, setPage] = [props.page, props.setPage]

  const [modalOpen, setModalOpen] = useState(false)
  const [modalPositionBase, setModalPositionBase] = useState({
    top: 0,
    left: 0,
  })
  const [innerModal, setInnerModal] = useState('')

  const transKeyPrefix = props.pageType === 'LEND' ? 'lendListTable' : 'borrowListTable'
  const trans = useTranslation('translation', { keyPrefix: transKeyPrefix })

  const Modal = ({ modalOpen }) => {
    const [position, setPositon] = useState({
      top: 0,
      left: 0,
    })
    const [visibility, setVisibility] = useState<'hidden' | 'visible'>('hidden')

    const ref = useRef<HTMLDivElement>()
    useEffect(() => {
      const newPosition = {
        top: 530 - ref.current.offsetHeight + modalPositionBase.top,
        left: 1545 - ref.current.offsetWidth,
      }
      setPositon(newPosition)
      setVisibility(modalOpen === true ? 'visible' : 'hidden')
    }, [modalOpen])
    return (
      <div
        ref={ref}
        className="pool-register-list-modal"
        style={{
          ...position,
          visibility: visibility,
        }}
      >
        {innerModal}
      </div>
    )
  }

  // const [isFirstLoading, setIsfirstLoading] = useState(true)

  const [forceFetch, setForceFetch] = useRecoilState(forceFetchState)

  // useEffect(() => {
  //   if (page !== 0 && !isLoading) {
  //     setPage(0)
  //   }
  // }, [])
  const resetPoolHistory = useCallback(
    async (page?: number, query?: string) => {
      try {
        const poolHistory = await httpGetPoolHistory({
          orderType: props.pageType,
          settlementType: props.settlementType,
          query: props.query,
          poolStatus: props.poolStatus == 'ALL' ? '' : props.poolStatus,
          page: 0,
          size: 20,
        })

        props.setPoolHistory(poolHistory)
        setPage(0)
        setIsLast(poolHistory.length < 1)
        if (poolHistory.length) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      } catch (err) {
        //
      }
      setIsLoading(false)
    },
    [props.query, props.poolStatus, props.settlementType]
  )

  const getPoolHistory = useCallback(
    async (page?: number, query?: string) => {
      try {
        const poolHistory = await httpGetPoolHistory({
          orderType: props.pageType,
          settlementType: props.settlementType,
          query: props.query,
          poolStatus: props.poolStatus == 'ALL' ? '' : props.poolStatus,
          page,
          isSwap: props.isSwap,
          size: 20,
        })

        if (page == 0) {
          props.setPoolHistory(poolHistory)
        } else {
          props.setPoolHistory((prevPoolHistory) => [...prevPoolHistory, ...poolHistory])
        }

        setIsLast(poolHistory.length < 1)
        if (page == 0 && !poolHistory.length) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      } catch (err) {
        //
      }
      setIsLoading(false)
    },
    [props.query, props.poolStatus, props.settlementType, page, props.isSwap]
  )

  // useEffect(() => {
  //   return () => onInfiniteScrollDisconnect()
  // }, [])

  useEffect(() => {
    setSelectedIndex([])
    props.setSelectedPoolIds([])
    getPoolHistory(page, props.query)
  }, [page, forceFetch])

  // useEffect(() => {
  //   setPage(0)
  //   setForceFetch(!forceFetch)
  // }, [props.poolStatus, props.query, props.isSwap])

  useEffect(() => {
    props.setSelectedPoolIds(
      props.poolHistory.filter((_, index) => selectedIndex.includes(index)).map(({ id }) => id)
    )
  }, [selectedIndex])

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  const MenuItemStatus = styled(MenuItem)({
    fontSize: '13px',
  })

  useEffect(() => {
    onInfiniteScrollInit(document.querySelector('.table-bottom'))
  }, [])

  useEffect(() => {
    if (isLoading || isLast) onInfiniteScrollDisconnect()
    else onInfiniteScrollUpdate()
  }, [isLoading, isLast])

  const handleMenuItemStatusClick = useCallback(
    (value: string, popupStateClose: () => void) => {
      if (value === props.poolStatus) {
        popupStateClose()
        return
      }
      props.setPoolStatus(value as PoolStatusType | 'ALL')
      setPage(0)
      setForceFetch(!forceFetch)
      popupStateClose()
    },
    [forceFetch, props.poolStatus]
  )

  const handleMenuItemIsSwaplick = useCallback(
    (value: string, popupStateClose: () => void) => {
      props.setIsSwap(value)
      setPage(0)
      setForceFetch(!forceFetch)
      popupStateClose()
    },
    [forceFetch]
  )

  const getSelectedListCellTextKo = (poolType: PoolType, selectedList: any[]) => {
    let text = ''
    const listNum = selectedList.length
    const isMany = listNum > 1
    const etcNum = listNum - 1
    if (isMany) {
      text = `외 ${etcNum}건`
    }
    if (poolType === 'ALL') {
      return '모든 사용자에게 공개'
    }
    const selectedText = selectedList[0]?.name ? ', ' + selectedList[0]?.name : ''
    // if (selectedList.length !== 0) {
    if (poolType === 'ASSET') {
      return '운용사 전체 공개' + selectedText + ' ' + text
    }
    if (poolType === 'SECURITY') {
      return '증권사 전체 공개' + selectedText + ' ' + text
    } else {
      return selectedList[0]?.name + ' ' + text
    }
    // }
  }

  const getSelectedListCellTextEn = (poolType: PoolType, selectedList: any[]) => {
    let text = ''
    const listNum = selectedList.length
    const isMany = listNum > 1
    const etcNum = listNum - 1
    if (isMany) {
      text = `meritz securities +${etcNum} entities`
    }
    if (poolType === 'ALL') {
      return 'All'
    }
    const selectedText = selectedList[0]?.englishName ? ', ' + selectedList[0]?.englishName : ''
    // if (selectedList.length !== 0) {
    if (poolType === 'ASSET') {
      return 'Asset Managers(All)' + selectedText + ' ' + text
    }
    if (poolType === 'SECURITY') {
      return 'Brokers(All)' + selectedText + ' ' + text
    } else {
      return selectedList[0]?.englishName + ' ' + text
    }
    // }
  }

  const getIsCancelable = (orderPoolStatus: PoolStatusType) => {
    if (orderPoolStatus === 'PARTIALLY_MATCHED' || orderPoolStatus === 'RECEIVED') {
      return true
    }
    return false
  }

  return (
    <div
      className={
        'fixed-table tlist' +
        ' ' +
        props.constants.pageType.toUpperCase() +
        ' ' +
        'tab-' +
        props.tab
      }
    >
      <Modal modalOpen={modalOpen} />
      <table>
        <thead>
          <th>
            <Checkbox
              readOnly
              checked={
                props.poolHistory.length !== 0 && selectedIndex.length == props.poolHistory.length
              }
              onClick={() => {
                if (selectedIndex.length == props.poolHistory.length) {
                  setSelectedIndex([])
                } else {
                  setSelectedIndex(props.poolHistory.map((_, index) => index))
                }
              }}
            />
          </th>
          <th>{trans.t('createdDate')}</th>
          <th>{trans.t('orderType')}</th>
          <th>{trans.t('settlementType')}</th>
          <th className={'lend-th-change'}>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" sx={popupBtnStyle} {...bindTrigger(popupState)}>
                    {trans.t('poolStatus')}
                    <KeyboardArrowDownIcon sx={{ width: '15px' }}></KeyboardArrowDownIcon>
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItemStatus
                      onClick={() => handleMenuItemStatusClick('ALL', popupState.close)}
                    >
                      {trans.t('innerPoolStatus.all')}
                    </MenuItemStatus>
                    <MenuItemStatus
                      onClick={() => handleMenuItemStatusClick('RECEIVED', popupState.close)}
                    >
                      {trans.t('innerPoolStatus.received')}
                    </MenuItemStatus>
                    <MenuItemStatus
                      onClick={() => handleMenuItemStatusClick('MATCHED', popupState.close)}
                    >
                      {trans.t('innerPoolStatus.matched')}
                    </MenuItemStatus>
                    <MenuItemStatus
                      onClick={() =>
                        handleMenuItemStatusClick('PARTIALLY_MATCHED', popupState.close)
                      }
                    >
                      {trans.t('innerPoolStatus.partiallyMatched')}
                    </MenuItemStatus>
                    <MenuItemStatus
                      onClick={() => handleMenuItemStatusClick('CANCELED', popupState.close)}
                    >
                      {trans.t('innerPoolStatus.canceled')}
                    </MenuItemStatus>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </th>
          <th>{trans.t('issueCode')}</th>
          <th>{trans.t('issueName')}</th>
          <th>{props.pageType === 'LEND' ? trans.t('volume') : trans.t('borrowRequestedQty')}</th>
          <th>{props.pageType === 'LEND' ? trans.t('rate') : trans.t('borrowBidRate')}</th>
          <th>
            {props.pageType === 'LEND'
              ? trans.t('lendOrderedVolume')
              : trans.t('borrowOrderedVolume')}
          </th>
          <th>{trans.t('matchedVolume')}</th>
          <th>{props.pageType === 'LEND' ? trans.t('remainVolume') : trans.t('unfilledQty')}</th>
          {props.constants.pageType === 'borrow' && (
            <th className={'lend-th-change'}>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button variant="contained" sx={popupBtnStyle} {...bindTrigger(popupState)}>
                      {trans.t('swap')}
                      <KeyboardArrowDownIcon sx={{ width: '15px' }}></KeyboardArrowDownIcon>
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItemStatus
                        onClick={() => handleMenuItemIsSwaplick('', popupState.close)}
                      >
                        {trans.t('all')}
                      </MenuItemStatus>
                      <MenuItemStatus
                        onClick={() => handleMenuItemIsSwaplick('true', popupState.close)}
                      >
                        <span style={{ margin: 'auto' }}>Y</span>
                      </MenuItemStatus>
                      <MenuItemStatus
                        onClick={() => handleMenuItemIsSwaplick('false', popupState.close)}
                      >
                        <span style={{ margin: 'auto' }}>N</span>
                      </MenuItemStatus>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </th>
          )}
          <th>{trans.t('disclosedTarget')}</th>
          <th></th>
        </thead>
        <tbody>
          {props.poolHistory.map((pool, index) => (
            <tr
              key={index}
              onClick={() => {
                if (!getIsCancelable(pool.poolStatus)) {
                  return
                }
                const checked = selectedIndex.includes(index)
                if (!checked) {
                  setSelectedIndex((selectedIndex) => [...selectedIndex, index])
                } else {
                  setSelectedIndex((selectedIndex) =>
                    selectedIndex.filter((_index) => _index !== index)
                  )
                }
              }}
            >
              <td key={index}>
                {getIsCancelable(pool.poolStatus) && (
                  <Checkbox readOnly checked={selectedIndex.includes(index)} onClick={() => {}} />
                )}
              </td>
              <td>{pool.createdDate.substring(0, 8)}</td>
              <td>
                {trans.i18n.language === 'ko'
                  ? OrderTypeTextKo[pool.orderType]
                  : OrderTypeTextEn[pool.orderType]}
              </td>
              <td>{pool.settlementType == 'TODAY' ? 'T' : 'T1'}</td>
              <td className={CLASSES.tableCellFilterable}>
                {trans.i18n.language === 'ko'
                  ? PoolStatusTextKo[pool.poolStatus]
                  : PoolStatusTextEn[pool.poolStatus]}
              </td>
              <td>{pool.stock.issueCode}</td>
              <td>
                {localStorageAPI.getItem('lang') === 'en'
                  ? pool.stock.issueEnglishName
                  : pool.stock.issueName}
              </td>
              <td className="right">{formatNumber(pool.volume)}</td>
              <td className="right">{pool.rate.toFixed(2)}</td>
              <td className="right">{formatNumber(pool.orderedVolume)}</td>
              <td className="right">{formatNumber(pool.matchedVolume)}</td>
              <td className="right">{formatNumber(pool.remainVolume)}</td>
              {props.constants.pageType === 'borrow' && (
                <td className={CLASSES.tableCellFilterable}>
                  {contverTrueFalseToYorN(pool.isSwap)}
                </td>
              )}
              <td
                onMouseEnter={(e) => {
                  const tartgetTd = e.target as HTMLElement
                  if (pool.selectedList.length === 0) {
                    return
                  }
                  setModalOpen(true)
                  setModalPositionBase({
                    top: tartgetTd.offsetTop,
                    left: tartgetTd.offsetLeft,
                  })
                  const getSelectedList = () => {
                    let nameList = []
                    if (localStorageAPI.getItem('lang') === 'en') {
                      pool.selectedList.forEach((name) => {
                        nameList.push(name.englishName)
                      })
                    } else {
                      pool.selectedList.forEach((name) => {
                        nameList.push(name.name)
                      })
                    }
                    return nameList as string[]
                  }
                  setInnerModal(getSelectedList().join(', '))
                }}
                onMouseLeave={(e) => {
                  setModalOpen(false)
                }}
              >
                {trans.i18n.language === 'ko'
                  ? getSelectedListCellTextKo(pool.poolType, pool.selectedList)
                  : getSelectedListCellTextEn(pool.poolType, pool.selectedList)}
              </td>
              <td></td>
              {/* <td></td>
              {props.constants.pageType === 'borrow'
                ? getTableBodyTds([
                    [selectedIndex.includes(index), getIsCancelable(pool.poolStatus)],
                    pool.createdDate.substring(0, 8),
                    OrderTypeText[pool.orderType],
                    pool.settlementType == 'TODAY' ? 'T' : 'T1',
                    PoolStatusText[pool.poolStatus],
                    pool.stock.issueCode,
                    pool.stock.issueName,
                    formatNumber(pool.volume),
                    pool.rate.toFixed(2),
                    formatNumber(pool.orderedVolume),
                    formatNumber(pool.matchedVolume),
                    formatNumber(pool.remainVolume),
                    contverTrueFalseToYorN(pool.isSwap),
                    getSelectedListCellText(pool.poolType, pool.selectedList),
                    '',
                  ])
                : getTableBodyTds([
                    [selectedIndex.includes(index), getIsCancelable(pool.poolStatus)],
                    pool.createdDate.substring(0, 8),
                    OrderTypeText[pool.orderType],
                    pool.settlementType == 'TODAY' ? 'T' : 'T1',
                    PoolStatusText[pool.poolStatus],
                    pool.stock.issueCode,
                    pool.stock.issueName,
                    formatNumber(pool.volume),
                    pool.rate.toFixed(2),
                    formatNumber(pool.orderedVolume),
                    formatNumber(pool.matchedVolume),
                    formatNumber(pool.remainVolume),
                    getSelectedListCellText(pool.poolType, pool.selectedList),
                    '',
                  ])} */}
            </tr>
          ))}
          <div className="table-bottom"></div>
        </tbody>
      </table>
    </div>
  )
}

export default LendPoolRegisterListTable
