const getSeparetedByCompany = (borrowPool: PoolType[]) => {
  const CompanyNames: string[] = []
  const sepratedByCompany: PoolSepratedByName = {}
  borrowPool.forEach((borrow: any) => {
    if (!CompanyNames.includes(borrow.account.companyName)) {
      CompanyNames.push(borrow.account.companyName)
      sepratedByCompany[borrow.account.companyName] = [JSON.parse(JSON.stringify(borrow))]
    } else {
      sepratedByCompany[borrow.account.companyName].push(JSON.parse(JSON.stringify(borrow)))
    }
  })
  return sepratedByCompany
}

const getInfoByCompany = (sepratedByCompany: PoolSepratedByName) => {
  const InfoByCompany: InfoByCompany = {}
  const keys = Object.keys(sepratedByCompany)
  const values: Array<PoolType[]> = Object.values(sepratedByCompany)
  for (let i = 0; i < keys.length; i++) {
    InfoByCompany[keys[i]] = {
      companyCode: values[i][0].account.companyCode,
      companyName: values[i][0].account.companyName,
      companyEnglishName: values[i][0].account.companyEnglishName,
      settlementType: values[i][0].settlementType,
      volume: 0,
      remainVolume: 0,
      interestTotal: 0,
      rate: 0,
      idList: [],
    }
    values[i].forEach((value) => {
      InfoByCompany[keys[i]].volume += value.volume
      InfoByCompany[keys[i]].remainVolume += value.remainVolume
      InfoByCompany[keys[i]].interestTotal += value.remainVolume * value.rate
      InfoByCompany[keys[i]].idList.push(value.id)
    })
  }
  for (let i = 0; i < keys.length; i++) {
    InfoByCompany[keys[i]].rate =
      InfoByCompany[keys[i]].interestTotal / InfoByCompany[keys[i]].volume
  }
  return InfoByCompany
}

const getOutput = (separetedByCompany: PoolSepratedByName, infoByCompany: InfoByCompany) => {
  const output: Array<CompanyInfo | PoolType> = []
  const len = Object.keys(infoByCompany).length
  for (let i = 0; i < len; i++) {
    output.push(Object.values(infoByCompany)[i])
    const _separetedByCompany: any = Object.values(separetedByCompany)[i]
    for (let j = 0; j < _separetedByCompany.length; j++) {
      const outPutLine = JSON.parse(JSON.stringify(Object.values(separetedByCompany)[i][j]))
      output.push(outPutLine)
    }
  }
  return output
}

export const getGroupByCompanyOutput = (borrowPool: PoolType[]) => {
  const sepratedByCompany = getSeparetedByCompany(borrowPool)
  const infoByCompany = getInfoByCompany(sepratedByCompany)
  const output = getOutput(sepratedByCompany, infoByCompany)
  return output
}
