import { httpGetOrderHistoryReturn } from '@src/api/httpAPI/httpOrderHistoryAPI'
import Button from '@src/components/Button'
import LogoutButton from '@src/components/Button/LogoutButton'
import Checkbox from '@src/components/Checkbox'
import ExcelButton from '@src/components/ExcelButton/ExcelButton'
import SearchIssue from '@src/components/SearchIssue/SearchIssue'
import SelectOption from '@src/components/SelectOption'
import TextField from '@src/components/TextField'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import { TransactionTypeText } from '@src/constants/TransactionTypeText'
import { formatNumber } from '@src/util/formatNumber'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type StatusType =
  | 'ALL'
  | 'RECEIVED'
  | 'MATCHED'
  | 'TRANSFERRED'
  | 'CANCELED'
  | 'REJECTED'
  | 'REQUESTED'

const SearchReturn = () => {
  const [orderType, setOrderType] = useState<OrderType | ''>('')
  const [status, setStatus] = useState<StatusType>('ALL')
  const [dateType, setDateType] = useState<'REQUEST' | 'PAYMENT'>('REQUEST')
  const [after, setAfter] = useState<string>(DateTime.local().toFormat('yyyyMMdd'))
  const [before, setBefore] = useState<string>(DateTime.local().toFormat('yyyyMMdd'))
  const [orderHistoryReturn, setOrderHistoryReturn] = useState<OrderHistoryReturnType[]>([])
  const [selectedLedgerId, setSelectedLedgerId] = useState<LedgerType['ledgerId'][]>([])

  const trans = useTranslation()

  const getOrderHistoryReturn = useCallback(
    async (query?: string) => {
      const orderHistoryReturn = await httpGetOrderHistoryReturn({
        type: orderType,
        status: '',
        after,
        before,
        query: query || '',
      })

      setOrderHistoryReturn(orderHistoryReturn)
    },
    [orderType, after, before]
  )

  useEffect(() => {
    getOrderHistoryReturn()
  }, [])

  return (
    <section className={`search-return ${orderType}`}>
      <header>상환내역 조회</header>
      <article>
        <div className="options">
          <div className="select-options">
            <SelectOption
              label="대차구분"
              value={orderType}
              onChange={(e) => {
                setOrderType(e.target.value as OrderType)
              }}
              options={[
                {
                  label: '전체',
                  value: 'ALL',
                },
                {
                  label: '차입',
                  value: 'BORROW',
                },
                {
                  label: '대여',
                  value: 'LEND',
                },
              ]}
            />
            <SelectOption
              label="상태"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value as StatusType)
              }}
              options={[
                {
                  label: '전체',
                  value: 'ALL',
                },
                {
                  label: 'REQUESTED',
                  value: '신청',
                },
                {
                  label: '확정',
                  value: 'MATCHED',
                },
                {
                  label: '인도',
                  value: '인도',
                },
                {
                  label: '취소',
                  value: '취소',
                },
              ]}
            />
          </div>
          <div className="select-options">
            <span>신청일</span>
            {/* <SelectOption
              value={dateType}
              onChange={(e) => {
                setDateType(e.target.value as 'REQUEST' | 'PAYMENT')
              }}
              options={[
                {
                  label: '신청일자',
                  value: '신청일자',
                },
              ]}
            /> */}

            <TextField value={after} onChange={(e) => setAfter(e.target.value)} />
            <span>~</span>
            <TextField value={before} onChange={(e) => setBefore(e.target.value)} />
            <Button color="green" onClick={() => getOrderHistoryReturn()}>
              조회
            </Button>
          </div>
        </div>
        <SearchIssue
          searchIcon
          handleSearch={(query) => {
            getOrderHistoryReturn(query)
          }}
        />
        <div className="fixed-table">
          <table>
            <thead>
              {/* <th>전체선택</th> */}
              <th>{trans.t('lendListTable.orderType')}</th>
              <th>상태</th>
              <th>신청일</th>
              <th>확정일</th>
              <th>거래유형</th>
              <th>{trans.t('matchedId')}</th>
              <th>예탁원 확정번호</th>
              <th>{trans.t('partnerAsset')}</th>
              <th>{trans.t('issueCode')}</th>
              <th>{trans.t('issueName')}</th>
              <th>{trans.t('table.volume')}</th>
              <th>{trans.t('table.rate')}</th>
              <th>상환시간</th>
              <th></th>
            </thead>
            <tbody>
              {orderHistoryReturn.map((orderHistory, index) => (
                <tr key={index}>
                  {/* <td>
                    <Checkbox
                      checked={selectedLedgerId.includes(orderHistory.ledger.ledgerId)}
                      onClick={() => {}}
                    />
                  </td> */}
                  <td>{orderHistory.ledger.orderStatus}</td>
                  <td>{OrderTypeText[orderHistory.ledger.orderType]}</td>
                  <td>{orderHistory.ledger.orderDateTime?.substring(0, 8)}</td>
                  <td>{orderHistory.ledger.matchedDateTime?.substring(0, 8)}</td>
                  <td>{TransactionTypeText[orderHistory.ledger.transactionType]}</td>
                  <td className="right">{orderHistory.ledger.ledgerId}</td>
                  <td className="right">{orderHistory.ledger.matchKSDId}</td>
                  <td className="left">
                    {orderHistory.ledger.partnerAccount &&
                      `${orderHistory.ledger.partnerAccount.companyName}(${orderHistory.ledger.partnerAccount.companyCode})`}
                  </td>
                  <td>{orderHistory.ledger.stock.issueCode}</td>
                  <td className="left">{orderHistory.ledger.stock.issueName}</td>
                  <td className="right">{formatNumber(orderHistory.ledger.volume)}</td>
                  <td className="right">{orderHistory.ledger.rate.toFixed(2)}</td>
                  <td>{orderHistory.returnDateTime.substring(0, 8)}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <footer>
          <ExcelButton
            data={orderHistoryReturn.map((orderHistory) => ({
              ...orderHistory,
              issueCode: `=""${orderHistory.ledger.stock.issueCode}""`,
              orderType: OrderTypeText[orderType],
              orderDateTime: orderHistory.ledger.orderDateTime?.substring(0, 8),
              matchedDateTime: orderHistory.ledger.matchedDateTime?.substring(0, 8),
              returnDateTime: orderHistory.returnDateTime?.substring(0, 8),
            }))}
            headers={headers}
            fileName={`상환내역 ${DateTime.local().toFormat('yyyyMMdd')}`}
          >
            엑셀다운로드
          </ExcelButton>
          <ul>
            <li>종목수 : {orderHistoryReturn.length}</li>
            <li>수량합계 : {orderHistoryReturn.reduce((acc, cur) => acc + cur.returnVolume, 0)}</li>
          </ul>
        </footer>
      </article>
    </section>
  )
}

const headers = [
  { label: '대차구분', key: 'orderType' },
  { label: '상태', key: 'ledger.orderStatus' },
  { label: '신청일', key: 'orderDateTime' },
  { label: '결제일', key: 'matchedDateTime' },
  { label: '거래유형', key: 'ledger.transactionType' },
  { label: '확정번호', key: 'ledger.matchKsdId' },
  { label: '예탁원 확정번호', key: 'ledger.ledgerId' },
  { label: '상대기관', key: 'ledger.partnerAccount.companyName' },
  { label: '종목코드', key: 'issueCode' },
  { label: '종목명', key: 'ledger.stock.issueName' },
  { label: '수량(주)', key: 'ledger.volume' },
  { label: '요율(%)', key: 'ledger.rate' },
  { label: '상환시간', key: 'returnDateTime' },
]

export default SearchReturn
