export const csvHeader = ['No', '상태', '상대기관', '거래구분', '유형', '수/발신', '발송일시']

export const csvKeySender = [
  'id',
  'ISREAD',
  'to.companyName',
  '!orderType',
  '!messageType',
  'ISSENDER',
  '0dateTime',
]

export const csvKeyNotSender = [
  'id',
  'ISREAD',
  'from.companyName',
  '!orderType',
  '!messageType',
  'ISSENDER',
  '0dateTime',
]

export const csvDict = {
  LEND: '대여',
  BORROW: '차입',
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  RECEIVED: '접수',
  MATCHED: '확정',
  CANCELED: '취소',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  CONFIRMATION: '컨퍼메이션',
  UPDATE: '변경',
  NEW: '신규',
  URGENT: '긴급',
  ETC: '기타',
  NONE: '없음',
}
