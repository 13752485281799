import { httpGetOrderBookList } from '@src/api/httpAPI/httpOrderBookAPI'
import { useCallback, useEffect, useState } from 'react'
import useInfiniteScroll from './useInfiniteScroll'

export default function useOrderBook<T>() {
  const [isLoading, setIsLoading] = useState(false)
  const [orderBookList, setOrderBookList] = useState<(OrderBookType & T)[]>([])
  const [isLast, setIsLast] = useState(true)
  const [page, setPage] = useState(0)
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null)

  const getOrderBookList = useCallback(
    async (page: number, query: string, orderType: OrderType) => {
      setIsLoading(true)
      try {
        const orderBookList = (await httpGetOrderBookList({
          type: orderType,
          page,
          size: 20,
          query,
        })) as (OrderBookType & T)[]

        if (page == 0) {
          setOrderBookList(orderBookList)
        } else {
          setOrderBookList((prevOderBookList) => [...prevOderBookList, ...orderBookList])
        }

        setIsLast(orderBookList.length < 1)
        if (page == 0 && !orderBookList.length) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      } catch (err) {
        //
      }
      setIsLoading(false)
    },
    []
  )

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  useEffect(() => {
    onInfiniteScrollInit(document.querySelector('.table-bottom'))
  }, [])

  useEffect(() => {
    if (isLoading || isLast) onInfiniteScrollDisconnect()
    else onInfiniteScrollUpdate()
  }, [isLoading, isLast])

  return {
    orderBookList,
    setOrderBookList,
    page,
    setPage,
    getOrderBookList,
    isEmpty,
  }
}
