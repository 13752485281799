import httpClient from './httpClient'

export const httpGetMessageList = async (params: {
  partnerAccountId: number | ''
  orderType: OrderType | 'RECALL' | ''
  messageType: MessageStatusType | ''
  isSender: boolean | ''
  isRead: boolean | ''
  page?: number
  size?: number
}) => {
  const { data } = await httpClient.get<MessageDetailType[]>('/api/v1/messages', {
    params,
  })

  return data
}

export const httpPostMessage = async (params: {
  ledgerIdList: number[]
  message: string
  messageType: MessageStatusType
  orderType: OrderType | ''
  originalMessageId: number | null
  partnerId: number
}) => {
  await httpClient.post('/api/v1/messages', {
    ...params,
  })
}

export const httpGetMessageDtail = async (messageId: number) => {
  const { data } = await httpClient.get<MessageDetailType>(`/api/v1/messages/${messageId}`)

  return data
}

export const httpGetNotification = async () => {
  const { data } = await httpClient.get<NotificationCountType>('/api/v1/messages/count')

  return data
}
