import SelectOption from '@src/components/SelectOption'
import { SpanCursorPointer } from '@src/components/Span/SpanCursorPointer'
import TextField from '@src/components/TextField'
import { isKeyTabOrEnter } from '@src/util/isKeyTapOrEnter'
import { FunctionComponent, useEffect } from 'react'
import { forceFetchState } from '@src/stores/forceFetchState'
import { useRecoilState } from 'recoil'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'

interface Props {
  reset: () => void
  query: string
  setQuery: (query: string) => void
  poolStatus: PoolStatusType | 'ALL'
  setPoolStatus: React.Dispatch<React.SetStateAction<PoolStatusType | 'ALL'>>
  tab: string
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const LendPoolRegisterListLiveOption: FunctionComponent<Props> = (props) => {
  // useEffect(() => {
  //   if (props.tab === 'LIVE') {
  //     props.reset()
  //     props.setQuery('')
  //   }
  // }, [props.tab])

  const [forceFetch, setForceFetch] = useRecoilState(forceFetchState)
  const trans = useTranslation()

  return (
    <div className="option">
      <div className="first-line">
        <h3>{trans.t('searchInList')}</h3>
        <SpanCursorPointer onClick={() => props.reset()}>
          <RefreshIcon></RefreshIcon>
          <p>&nbsp;{trans.t('refresh')}</p>
        </SpanCursorPointer>
      </div>
      <div className="second-line">
        <TextField
          label={trans.t('issue')}
          value={props.query}
          onChange={(e) => props.setQuery(e.target.value)}
          onKeyDown={(e: any) => {
            if (isKeyTabOrEnter(e)) {
              props.setPage(0)
              setForceFetch(!forceFetch)
            }
          }}
        />
        {/* <SelectOption
          label="상태"
          value={props.poolStatus}
          onChange={(e) => {
            props.setPoolStatus(e.target.value as PoolStatusType | 'ALL')
          }}
          options={[
            {
              label: '전체',
              value: 'ALL',
            },
            {
              label: '신청',
              value: 'RECEIVED',
            },
            {
              label: '일부확정',
              value: 'PARTIALLY_MATCHED',
            },
            {
              label: '전부확정',
              value: 'MATCHED',
            },
            {
              label: '취소됨',
              value: 'CANCELED',
            },
          ]}
        /> */}
      </div>
    </div>
  )
}

export default LendPoolRegisterListLiveOption
