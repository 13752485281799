import { toastModalState } from '@src/stores/toastModalState'
import parseNumComma from '@src/util/parseNumComma'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

let timerList = []

interface WaitingToastModalProps {
  isWaiting: boolean
}

export const WaitingToastModal = ({ isWaiting }: WaitingToastModalProps) => {
  // const [toastModal, setToastModal] = useRecoilState(toastModalState)

  // useEffect(() => {
  //   timerList.map((_timer) => {
  //     clearTimeout(_timer)
  //   })
  //   if (toastModal.isOpen === true) {
  //     const timer = setTimeout(() => {
  //       setToastModal({ ...toastModal, isOpen: false })
  //       timerList = timerList.filter((_timer) => _timer !== timer)
  //     }, toastModal.openTime)
  //     timerList.push(timer)
  //   }
  // }, [toastModal])
  return (
    <div className={isWaiting ? 'notice-wrap' : 'notice-wrap-none'}>
      <div className={'notice-over-amount'}>{'전송중입니다'}</div>
    </div>
  )
}
