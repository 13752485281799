import Button from '@src/components/Button'
import PopupWrapper from '@src/components/PopupWrapper'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router'

interface Props {
  title: string
  result?: {
    orderType?: OrderType | string
    volume?: number
    rate?: number
    stock?: {
      issueName: string
      issueCode: string
    }
  }[]
  close: () => void
}

const ResultPopup: FunctionComponent<Props> = (props) => {
  return (
    <PopupWrapper close={() => window.location.reload()}>
      <div className={`result-popup ${props.result?.length > 1 ? 'multiple' : 'single'}`}>
        <h3>{props.title}</h3>
        {props.result?.length ? (
          <ul>
            {props.result.map(({ orderType, stock, volume, rate }, index) => (
              <li key={index}>
                <p>{OrderTypeText[orderType]}</p>
                <p>{stock?.issueName}</p>
                <p>{volume}주</p>
                <p>{rate}%</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>{props.title}</p>
        )}
        <Button
          color="blue"
          onClick={() => {
            // props.close()
            window.location.reload()
          }}
        >
          확인
        </Button>
      </div>
    </PopupWrapper>
  )
}

export default ResultPopup
