import React from 'react'

import { Button, Menu } from '@mui/material'
import { styled } from '@mui/system'
import MenuItem from '@mui/material/MenuItem'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { useTranslation } from 'react-i18next'

interface TradeTypePopupStateProps {
  text: string
  queryKey: string[]
  value: string
  handlePopupTwoValueMenuClick?: (
    popupMenuValue: string[],
    queryKey: string[],
    popupClose: () => void
  ) => void
  parentClose: () => void
}

const MenuItemStatus = styled(MenuItem)({
  paddingLeft: '20px',
  paddingRight: '20px',
  fontSize: '13px',
})

const popupBtnStyle = [
  {
    padding: '6px',
    paddingLeft: '14px',
    backgroundColor: 'inherit',
    boxShadow: 'none !important',
    lineHeight: 'normal',
    color: 'black',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
    fontSize: '13px !important',
  },
  {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& >span': {
      marginTop: '20px !important',
    },
  },
]

const NestedPopupInside = ({
  text,
  value,
  queryKey,
  handlePopupTwoValueMenuClick,
  parentClose,
}: TradeTypePopupStateProps) => {
  const trans = useTranslation()
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="contained" sx={popupBtnStyle} {...bindTrigger(popupState)}>
            {text}
            <KeyboardArrowRightIcon
              sx={{
                width: '13px',
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
              }}
            ></KeyboardArrowRightIcon>
          </Button>
          <Menu sx={{ position: 'absolute', top: '-40px', left: '63px' }} {...bindMenu(popupState)}>
            <MenuItemStatus
              onClick={() => {
                handlePopupTwoValueMenuClick([value, ''], queryKey, popupState.close)
                parentClose()
              }}
            >
              {trans.t('all')}
            </MenuItemStatus>
            <MenuItemStatus
              onClick={() => {
                handlePopupTwoValueMenuClick([value, 'RECEIVED'], queryKey, popupState.close)
                parentClose()
              }}
            >
              {trans.t('requested')}
            </MenuItemStatus>
            <MenuItemStatus
              onClick={() => {
                handlePopupTwoValueMenuClick([value, 'ACCEPTED'], queryKey, popupState.close)
                parentClose()
              }}
            >
              {trans.t('accepted')}
            </MenuItemStatus>
            <MenuItemStatus
              onClick={() => {
                handlePopupTwoValueMenuClick([value, 'REJECTED'], queryKey, popupState.close)
                parentClose()
              }}
            >
              {trans.t('rejected')}
            </MenuItemStatus>
            <MenuItemStatus
              onClick={() => {
                handlePopupTwoValueMenuClick([value, 'CANCEL'], queryKey, popupState.close)
                parentClose()
              }}
            >
              {trans.t('canceled')}
            </MenuItemStatus>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}

export default NestedPopupInside
