import Checkbox from '@src/components/Checkbox'
import { OrderTypeText, OrderTypeTextEn } from '@src/constants/OrderTypeText'
import { PoolStatusText } from '@src/constants/PoolStatusText'
import { formatNumber } from '@src/util/formatNumber'
import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface Props {
  setSelectedOrderIds: React.Dispatch<React.SetStateAction<number[]>>
  orderList: OrderDataType[]
  tab: string
}

const LendPoolMatchedLiveTable: FunctionComponent<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const trans = useTranslation('translation', { keyPrefix: 'lendListTable' })

  useEffect(() => {
    props.setSelectedOrderIds(
      props.orderList
        .filter((_, index) => selectedIndex.includes(index))
        .map(({ orderId }) => orderId)
    )
  }, [selectedIndex])

  return (
    <div className={'fixed-table live' + ' ' + 'tab-' + props.tab.toUpperCase()}>
      <table>
        <thead>
          <th>{trans.t('createdDate')}</th>
          <th>{trans.t('orderType')}</th>
          <th>{trans.t('issueCode')}</th>
          <th>{trans.t('issueName')}</th>
          <th>{trans.t('normalVolume')}</th>
          <th>{trans.t('matchedVolume')}</th>
          <th>{trans.t('remainVolume')}</th>
          <th></th>
        </thead>
        <tbody>
          {props.orderList.map((order, index) => (
            <tr
              key={index}
              onClick={() => {
                const checked = selectedIndex.includes(index)
                if (!checked) {
                  setSelectedIndex((selectedIndex) => [...selectedIndex, index])
                } else {
                  setSelectedIndex((selectedIndex) =>
                    selectedIndex.filter((_index) => _index !== index)
                  )
                }
              }}
            >
              <td>{order.createdDate}</td>
              <td>
                {localStorageAPI.getItem('lang') === 'en'
                  ? OrderTypeTextEn[order.orderType]
                  : OrderTypeText[order.orderType]}
              </td>
              <td>{order.stock.issueCode}</td>
              <td>
                {localStorageAPI.getItem('lang') === 'en'
                  ? order.stock.issueEnglishName
                  : order.stock.issueName}
              </td>
              <td>{formatNumber(order.volume)}</td>
              <td>{formatNumber(order.matchedVolume)}</td>
              <td>{formatNumber(order.remainVolume)}</td>
              <td></td>
            </tr>
          ))}
          <div className="table-bottom"></div>
        </tbody>
      </table>
    </div>
  )
}

export default LendPoolMatchedLiveTable
