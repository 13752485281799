import SelectOption from '@src/components/SelectOption'
import { SpanCursorPointer } from '@src/components/Span/SpanCursorPointer'
import TextField from '@src/components/TextField'
import { isKeyTabOrEnter } from '@src/util/isKeyTapOrEnter'
import { FunctionComponent, useEffect, useState } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'

interface QueryOptionBoxProps {
  reset: () => void
  query: string
  setQuery: (query: string) => void
  tab: string
}

const QueryOptionBox: FunctionComponent<QueryOptionBoxProps> = ({
  reset,
  query,
  setQuery,
  tab,
}: QueryOptionBoxProps) => {
  const [issueName, setIssueName] = useState(query)
  const trans = useTranslation()

  useEffect(() => {
    reset()
    setIssueName('')
  }, [tab])

  return (
    <div className="option">
      <div className="first-line">
        <h3>{trans.t('searchInList')}</h3>
        <SpanCursorPointer onClick={() => reset()}>
          <RefreshIcon></RefreshIcon>
          <p>&nbsp;{trans.t('refresh')}</p>
        </SpanCursorPointer>
      </div>
      <div className="second-line">
        <TextField
          label={trans.t('issue')}
          value={issueName}
          onChange={(e) => setIssueName(e.target.value)}
          onKeyDown={(e: any) => {
            isKeyTabOrEnter(e) && setQuery(issueName)
          }}
        />
      </div>
    </div>
  )
}

export default QueryOptionBox
