import { FunctionComponent, useState } from 'react'
import Button from '../Button'
import TextField from '../TextField'

interface Props {
  handleSearch: (query: string) => void
  placeholder?: string
  searchIcon?: boolean
}

const SearchIssue: FunctionComponent<Props> = (props) => {
  const [query, setQeury] = useState<string>('')

  return (
    <div className="search-issue">
      <TextField
        // style={{
        //   position: 'relative',
        // }}
        placeholder={props.placeholder || '종목 검색'}
        value={query}
        onChange={(e) => setQeury(e.target.value)}
        onKeyPress={(e) => {
          if (e.code == 'Enter') {
            props.handleSearch(query)
          }
        }}
      >
        {props.searchIcon && (
          <img
            src={require('@src/assets/search.png')}
            onClick={() => props.handleSearch(query)}
            // style={{
            //   cursor: 'pointer',
            //   position: 'absolute',
            //   right: '5px',
            //   top: '3px',
            // }}
          />
        )}
      </TextField>

      {!props.searchIcon && (
        <Button
          color="blue"
          onClick={() => props.handleSearch(query)}
          style={{
            borderRadius: '25px',
          }}
        >
          조회
        </Button>
      )}
      <div className="search-subtext">
        <span>&lt;입력예시&gt;삼성전자,카카오,SK하이닉스</span>
      </div>
    </div>
  )
}

export default SearchIssue
