import {
  CSSProperties,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  issueCode?: string
  style?: CSSProperties
  inputStyle?: CSSProperties
  labelStyle?: CSSProperties
  position?: 'left' | 'right' | 'top' | 'bottom'
  id?: string
  name?: string
  label?: string
}
const TextFieldWithIcon: FunctionComponent<Props> = ({
  issueCode,
  style,
  inputStyle,
  labelStyle,
  position,
  id,
  label,
  children,
  name,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>()
  const Label = () => {
    const [toolTip, setToolTip] = useState(false)
    if (!label) return null
    return (
      <label htmlFor={id}>
        {toolTip === true && (
          <div
            style={{
              position: 'absolute',
              zIndex: 30,
              background: '#ffffff',
              border: 'solid 1px #000000',
              width: '320px',
              top:'20px',
              left:'150px',
              padding:'10px',
              lineHeight:'22px',
              textAlign:'left',
            }}
          >
            Account number should be 12 digits. If your account number is less than 12 digits,
            please add ‘0’ at the front'
          </div>
        )}
        {label}{' '}
        <div
          onMouseEnter={() => {
            console.log('enter')
            console.log('toolTip', toolTip)
            setToolTip(true)
          }}
          onMouseLeave={() => {
            console.log('out')
            console.log('toolTip', toolTip)
            setToolTip(false)
          }}
        >
          <QuestionMarkIcon sx={{ fontSize: '16px', color: '#128ec7' }}></QuestionMarkIcon>
        </div>
      </label>
    )
  }

  // useEffect(() => {
  //   if (!issueCode) {
  //     return
  //   }
  //   if (issueCode.length === 6) {
  //     ref.current.focus()
  //   }
  // }, [issueCode, ref])

  return (
    <div
      className={`text-field ${position}`}
      style={style}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        e.persist()
      }}
    >
      {(position == 'left' || position == 'top') && <Label />}
      <input
        // ref={ref}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          e.persist()
        }}
        onFocus={(e) => e.target.select()}
        id={id}
        style={inputStyle}
        {...props}
      ></input>
      {children}
      {(position == 'right' || position == 'bottom') && <Label />}
    </div>
  )
}

export default TextFieldWithIcon

TextFieldWithIcon.defaultProps = {
  position: 'left',
  type: 'text',
}
