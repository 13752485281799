import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from '@mui/material'
import MyGroupTextFeild from '@src/components/TextField/MyGroupTextFiled'
import AddIcon from '@mui/icons-material/Add'
import { Search as SearchIcon, Close as CloseIcon, ChildCare } from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { delConfirm } from '@src/api/httpAPI/confirmMsg'

import { useCallback, useEffect, useRef, useState } from 'react'

import {
  httpDelMyGroupCompany,
  httpGetMyGroup,
  httpGetMyGroupList,
  httpPostAddCompanyToGroup,
  httpPostAddMyGroup,
} from '@src/api/httpAPI/httpMyPageAPI'
import { REQUEST, VALUECHECK } from '@src/constants/NotiText'
import httpClient from '@src/api/httpAPI/httpClient'
import { AutocompleteInputChangeEvent } from './AutocompleteInputChangeEvent'
import TradeButton from '@src/components/Button/TradeButton'
import { useTranslation } from 'react-i18next'

interface MyGroup {
  id: number
  name: string
}

interface MyGroupDetail {
  id: number
  name: string
  accountList: MyGroupCompnay[]
}
interface MyGroupCompnay {
  accountId: number
  companyCode: string
  companyName: string
  companyPropertyCode: string
  companySLBCode: string
}

const MyPage4 = () => {
  const [myGroupList, setMyGroupList] = useState<MyGroup[]>([])
  const [currentGroup, setCurrentGroup] = useState<MyGroupDetail>(null)
  const [selectedMyGroupId, setSelectedMyGroupId] = useState<number>(-1)
  const [editingMyGroupIndex, setEditingMyGroupIndex] = useState(-1)
  const [companyList, setCompanyList] = useState([])
  const [companyFilter, setCompanyFilter] = useState('')

  const [currentInputAccountId, setCurrentInputAccountId] = useState<number>(-1)

  const [autocompeteInputValue, setAutocompleteInputValue] = useState('')
  const [autocompeteValue, setAutocompleteValue] = useState({
    accountId: -1,
    companyName: '',
    label: '',
  })

  const trans = useTranslation()

  const ref = useRef<HTMLInputElement>(null)

  const fetchCompanies = async () => {
    const companies = await httpClient.get('/api/v1/accounts?query=')
    const result = companies.data.map(
      ({ accountId, companyName, companyCode, companyPropertyCode, companySLBCode }) => ({
        accountId: accountId,
        label: companyCode + '-' + companyPropertyCode + '-' + companySLBCode,
        companyName: companyName,
      })
    )
    setCompanyList(result)
  }

  const fetchMyGroupList = useCallback(async (id?: number) => {
    const data = await httpGetMyGroupList()
    // ref.current.value = ''
    // console.log('ref.current.value',ref.current.value)
    setMyGroupList(data)
    if (data.length === 0) {
      setSelectedMyGroupId(-1)
      return
    }
    const dataIds = data.map((data: any) => data.id)
    if (!dataIds.includes(id)) {
      setSelectedMyGroupId(-1)
      return
    }
  }, [])

  useEffect(() => {
    fetchMyGroupList()
    fetchCompanies()
  }, [])

  const handleGroupAddBtn = useCallback(async () => {
    try {
      await httpPostAddMyGroup()
      await fetchMyGroupList()
      setCurrentGroup({
        id: -1,
        name: '-1',
        accountList: [],
      })
    } catch (e) {
      alert(trans.t('modal.request.serverError'))
      console.error(e)
    }
  }, [])

  const getCurrentGroup = useCallback(async (id: number) => {
    const myGroup = await httpGetMyGroup(id)
    setCurrentGroup(myGroup)
  }, [])

  const handleAutocompleteKeyDown = useCallback(
    async (e: any, selectedMyGroupId: number) => {
      if (e.key !== 'Enter' || e.key !== 'Tab') {
        return
      }
      if (selectedMyGroupId === -1) {
        alert(trans.t('modal.valueCheck.noGroup'))
        return
      }
      if (currentInputAccountId === -1) {
        alert(trans.t('modal.valueCheck.invalidAccountNumber'))
        return
      }
      await httpPostAddCompanyToGroup(selectedMyGroupId, { accountId: currentInputAccountId })
      await getCurrentGroup(selectedMyGroupId)
      setAutocompleteInputValue('')
      setCompanyFilter('')
    },
    [currentInputAccountId]
  )

  let autoCompleteCloseFlag = false

  const autoCompleteCloseFlagCheck = () => {
    if (autoCompleteCloseFlag) {
      return true
    } else {
      autoCompleteCloseFlag = true
      return false
    }
  }

  const handleAutocompleteInputClose = useCallback(
    async (
      e: AutocompleteInputChangeEvent,
      reason: AutocompleteInputChangeReason,
      selectedMyGroupId: number
    ) => {
      // console.log('value',value)
      // if(value === '') {
      //   return
      // }
      if (!e) {
        return
      }
      if (e._reactName === undefined) {
        return
      }
      // if (e._reactName === 'onChange') {
      //   // setAutocompleteInputValue(value)
      //   return
      // }
      if (selectedMyGroupId === -1) {
        alert(trans.t('modal.valueCheck.noGroup'))
        return
      }
      if (e._reactName === 'keyDown') {
        try {
          await httpPostAddCompanyToGroup(selectedMyGroupId, { accountId: currentInputAccountId })
          await getCurrentGroup(selectedMyGroupId)
          return
        } catch (e) {
          alert(trans.t('modal.request.updateFail'))
          return
        }
      }
      // ref.current.value = ''
      // if (reason !== 'reset') {
      //   return
      // }
      // if(value === '') {
      //   return
      // }

      // // const accountId = currentInputAccountId
      // if (isNaN(currentInputAccountId)) {
      //   alert('계좌번호가 올바르지 않습니다.')
      //   return
      // }
      // setCurrentInputAccountId(currentInputAccountId)
      await httpPostAddCompanyToGroup(selectedMyGroupId, { accountId: currentInputAccountId })
      await getCurrentGroup(selectedMyGroupId)
      // // setAutocompleteInputValue('')
      // // setCompanyFilter('')
      // return
    },
    [currentInputAccountId, companyList, autocompeteInputValue]
  )

  return (
    <div className="mypage mypage4">
      <article>
        <div className="button-wrap">
          {/*<TradeButton color="red" onClick={() => navigate(-1)}>*/}
          {/*  뒤로가기*/}
          {/*</TradeButton>*/}
          <TradeButton color="green">저장</TradeButton>
        </div>
        <div className="ksd-account-info">
          <div className="form-wrap">
            <h3>내그룹 리스트</h3>
            <Box
              role="myGroupBox"
              component="form"
              // style={{ padding: '0 30px', display: 'flex', flexWrap: 'wrap' }}
              sx={{
                // '& > :not(style)': { width: '220px', height: '35px', marginRight: '20px' },
                button: {
                  height: '40px',
                  marginTop: '3px',
                  color: '#1976d2',
                  marginBottom: '15px',
                  border: 'solid 1px',
                  borderRadius: '3px',
                  padding: '0 8px',
                  fontFamily: 'NotoSansKRMedium sans-serif',
                },
              }}
              noValidate
              autoComplete="off"
            >
              {myGroupList.map((group, index) => (
                <MyGroupTextFeild
                  key={group.id}
                  selectedMyGroupId={selectedMyGroupId}
                  setSelectedMyGroupId={setSelectedMyGroupId}
                  editingMyGroupIndex={editingMyGroupIndex}
                  setEditingMyGroupIndex={setEditingMyGroupIndex}
                  index={index}
                  group={group}
                  fetchMyGroupList={fetchMyGroupList}
                  getCurrentGroup={getCurrentGroup}
                  myGroupList={myGroupList}
                  setCurrentGroup={setCurrentGroup}
                ></MyGroupTextFeild>
              ))}
              {myGroupList.length < 10 && (
                <IconButton
                  sx={
                    {
                      // marginTop: '-',
                      // height: '24px !important',
                      // width: '24px !important',
                      // border: 'solid 1px',
                      // verticalAlign: 'middle',
                      // backgroundColor: 'transparent !important',
                    }
                  }
                  onClick={() => handleGroupAddBtn()}
                >
                  내그룹추가<AddIcon></AddIcon>
                </IconButton>
              )}
            </Box>
          </div>
          <div
            className="search-mypage"
            // style={{ width: '100%' }}
          >
            <Autocomplete
              // inputRef={ref}
              // open={true}
              className="mypage-autocomplete"
              blurOnSelect={true}
              clearOnEscape={true}
              id="search-mypage-autocomplete"
              options={companyList}
              // size="small"
              sx={{
                paddingTop: '0px !important',
                paddingBottom: '0px !important',
                button: { background: 'transparent !important' },
                input: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: '14px',
                },
                div: {
                  paddingTop: '5px !important',
                  paddingBottom: '5px !important',
                  '&:nth-child(3)': {
                    // backgroundColor: 'yellow !important',
                    top: 'calc(50% - 19px)',
                  },
                },
              }}
              value={autocompeteValue}
              inputValue={autocompeteInputValue}
              freeSolo={true}
              onClose={(e: any, reason: any) => {
                if (autoCompleteCloseFlagCheck()) {
                  return
                }
                if (reason === 'blur') {
                  return
                }
                setCompanyFilter('')
                setAutocompleteInputValue('')
                handleAutocompleteInputClose(e, reason, selectedMyGroupId)
              }}
              onInputChange={(e: any, value, reason) => {
                if (reason === 'reset') {
                  setCompanyFilter('')
                  setAutocompleteInputValue('')
                  return
                }
                setCompanyFilter(value)
                setAutocompleteInputValue(value)
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                handleAutocompleteKeyDown(e, selectedMyGroupId)
              }}
              onHighlightChange={(e, option) => {
                if (option === null) {
                  return
                }
                setCurrentInputAccountId(option.accountId)
              }}
              getOptionLabel={(option) => option.companyName + ' ' + option.label}
              filterOptions={(options) =>
                options.filter((option) => {
                  // console.log('companyFilter', option.label.includes(companyFilter.split(' ')[0]))
                  const optionStr = option.companyName + ' ' + option.label
                  if (optionStr.includes(companyFilter)) {
                    return option
                    // }
                    // if (option.companyName.includes(companyFilter.split)) {
                    //   return option
                    // }
                  }
                })
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.accountId}>
                    {option.companyName} {option.label}
                  </li>
                )
              }}
              renderInput={(params) => {
                return (
                  <MuiTextField
                    // value={}
                    // onChange={(e: any) => setCompanyFilter(e.target.value)}
                    onKeyDown={async (e: any) => {
                      if (e.key === 'Enter') {
                        await httpPostAddCompanyToGroup(selectedMyGroupId, {
                          accountId: currentInputAccountId,
                        })
                        await getCurrentGroup(selectedMyGroupId)
                        return
                      }
                    }}
                    // value={autocompeteInputValue}
                    inputRef={ref}
                    {...params}
                    label=""
                    placeholder="기관명 또는 계좌로 검색"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          <SearchIcon sx={{ color: '#3281a8' }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                )
              }}
              popupIcon={''}
            />
            {/* </Autocomplete> */}
            {/* <SearchIcon /> */}
          </div>
          <div className="fixed-table saved-list">
            <table>
              <thead>
                <tr>
                  <th>기관명</th>
                  <th>계좌</th>
                  <th>SLB 계정 코드</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {currentGroup &&
                  currentGroup.accountList.map((group, index) => {
                    return (
                      <tr key={index}>
                        <td>{group.companyName}</td>
                        <td
                          onClick={() => {
                            navigator.clipboard.writeText(group.companyCode)
                          }}
                        >
                          {group.companyCode + group.companyPropertyCode}
                          <ContentCopyIcon
                            sx={{
                              float: 'right',
                              fontSize: '14px',
                              // marginRight: '30px',
                              marginTop: '6px',
                              cursor: 'pointer',
                            }}
                          ></ContentCopyIcon>
                        </td>
                        <td>{group.companySLBCode}</td>
                        <td>
                          <CloseIcon
                            sx={{ verticalAlign: 'middle', color: 'crimson', cursor: 'pointer' }}
                            onClick={
                              async () => {
                                if (window.confirm(delConfirm)) {
                                  await httpDelMyGroupCompany(selectedMyGroupId, group.accountId)
                                  await getCurrentGroup(selectedMyGroupId)
                                }
                              }
                              // () => console.log('test')
                              // httpPostAddCompanyToGroup(selectedMyGroupId, {
                              //   companyCode: group.companyCode,
                              // })
                            }
                          ></CloseIcon>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </div>
  )
}

export default MyPage4
