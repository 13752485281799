import { httpPutUserPassword } from '@src/api/httpAPI/httpUserAPI'
import PopupWrapper from '@src/components/PopupWrapper'
import { PASSWORD_REQEUST, VALUECHECK, REQUEST } from '@src/constants/NotiText'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { FunctionComponent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { sessionStorageAPI } from '../../api/storageAPI'

interface Props {
  close: () => void
}

const MyPagePasswordPopup: FunctionComponent<Props> = (props) => {
  const [currentPw, setCurrentPw] = useState<string>('')
  const [newPw, setNewPw] = useState<string>('')
  const [confirmNewPw, setConfirmNewPw] = useState<string>('')

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)

  const trans = useTranslation()

  const changePassword = useCallback(async () => {
    if (newPw !== confirmNewPw) {
      setErrorModal({
        ...errorModal,
        isOpen: true,
        innerString: trans.t('modal.valueCheck.needPasswordCheck'),
      })
      return
    }
    const allow = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$')
    if (!allow.exec(newPw)) {
      setErrorModal({
        ...errorModal,
        isOpen: true,
        innerString: '비밀번호는 영어 대소문자, 숫자, 특수문자를 조합한 8자리 이상이여야 합니다.',
      })
      return
    }
    try {
      const response = await httpPutUserPassword(currentPw, newPw)
      if (response.status === 400) {
        setErrorModal({ ...errorModal, isOpen: true, innerString: response['error'] })
        return
      }
      alert(
        '비밀번호가 변경되었습니다. 로그인 정보 갱신이 필요합니다. \n로그인 페이지로 이동합니다.'
      )
      // props.close()
      sessionStorageAPI.removeItem('token')
      window.location.href = '/user/login'
    } catch {
      setErrorModal({ ...errorModal, isOpen: true, innerString: trans.t('modal.request.invalid') })
    }
  }, [currentPw, newPw, confirmNewPw])

  return (
    <PopupWrapper close={() => props.close()}>
      <div className="password-popup">
        <h3>{trans.t('passwordChange')}</h3>
        <h4>{trans.t('passwordChangeDiscription')}</h4>
        <div style={{ height: 50 }}></div>
        <div className="pw-reset-textfield">
          <label htmlFor="">{trans.t('currentPassword')}</label>
          <input type="password" value={currentPw} onChange={(e) => setCurrentPw(e.target.value)} />
        </div>
        <div className="pw-reset-textfield">
          <label htmlFor="">{trans.t('newPassword')}</label>
          <input type="password" value={newPw} onChange={(e) => setNewPw(e.target.value)} />
        </div>
        <div className="pw-reset-textfield">
          <label htmlFor="">{trans.t('confirmPassword')}</label>
          <input
            type="password"
            value={confirmNewPw}
            onChange={(e) => setConfirmNewPw(e.target.value)}
          />
        </div>
        <button onClick={changePassword}>{trans.t('save')}</button>
      </div>
    </PopupWrapper>
  )
}

export default MyPagePasswordPopup
