import { styled } from '@mui/material/styles'
import useVolumeFormat from '@src/hooks/useVolumeFormat'
import {
  isInputKeyCanBeEntered,
  isRateInputCanBeOnChange,
} from '@src/util/input-limit/isRateInputLimit3digitsCheck'
import { isKeyTabOrEnter } from '@src/util/isKeyTapOrEnter'
import { rateCheck } from '@src/util/rateCheck'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface OptionRateInputProps {
  searchQuery: SearchQuery
  setSearchQuery: React.Dispatch<React.SetStateAction<SearchQuery>>
  handleGetBorrowPool: () => Promise<void>
  resetCondition: () => void
}

const Label = styled('label')({
  display: 'flex',
  alignItems: 'center',
  marginRight: '5px',
})

const Input = styled('input')({
  width: '110px !important',
})

// const Span = styled('span')({ marginLeft: '5px', marginRight: '5px' })

const inputRateKeys = ['Backspace', 'Delete', 'v', 'c', 'z', 'x', '.']

const OptionRateInput = ({
  searchQuery,
  setSearchQuery,
  handleGetBorrowPool,
  resetCondition,
}: OptionRateInputProps) => {
  const volumeFormat = useVolumeFormat()
  const trans = useTranslation()

  const [min, setMin] = useState('')
  const [max, setMax] = useState('')
  return (
    <div className="text-field">
      <label>{trans.t('rate')}</label>
      <Input
        maxLength={9}
        sx={{
          textAlign: 'right',
          '&::placeholder': {
            color: 'black',
          },
        }}
        value={min}
        onBlur={(e) => {
          if (e.target.value === '') {
            return
          }
          if (e.target.value.indexOf('.') === -1) {
            setMin(e.target.value + '.00')
            return
          }
          const splited = e.target.value.split('.')
          if (splited[1].length === 0) {
            setMin(e.target.value + '00')
          }
          if (splited[1].length === 1) {
            setMin(e.target.value + '0')
          }
        }}
        onChange={(e) => {
          if (e.target.value === '') {
            setMin('')
            setSearchQuery({ ...searchQuery, minRate: '' })
            return
          }
          const newValue = rateCheck(e, volumeFormat)
          if (!newValue) {
            return
          }
          let prevCommaCount = min.split(',').length - 1
          prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
          const start = e.target.selectionStart
          let nextCommaCount = newValue.split(',').length - 1
          nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
          const commaDiff = nextCommaCount - prevCommaCount
          e.target.value = newValue
          e.target.setSelectionRange(start + commaDiff, start + commaDiff)
          if (e.target.value.length < 2) {
            e.target.setSelectionRange(1, 1)
          }
          setMin(newValue)
          setSearchQuery({ ...searchQuery, minRate: newValue.replaceAll(',', '') })
        }}
        onKeyDown={(e: any) => {
          const start = e.target.selectionStart
          const end = e.target.selectionEnd
          const isLengthOne = start === end
          if (e.key === 'Delete' && e.target.value[start] === ',' && isLengthOne) {
            e.target.setSelectionRange(start + 1, start + 1)
            e.preventDefault()
            return
          }
          if (isKeyTabOrEnter(e)) {
            if (e.target.value === '') {
              return
            }
            if (e.target.value.indexOf('.') === -1) {
              setMin(e.target.value + '.00')
              return
            }
            const splited = e.target.value.split('.')
            if (splited[1].length === 0) {
              setMin(e.target.value + '00')
            }
            if (splited[1].length === 1) {
              setMin(e.target.value + '0')
            }
            resetCondition()
            handleGetBorrowPool()
            return
          }
          if (!isInputKeyCanBeEntered(e)) {
            return false
          }
        }}
        placeholder="0.00"
      ></Input>

      <span className="date-dash">~</span>
      <Input
        sx={{ textAlign: 'right' }}
        value={max}
        onBlur={(e) => {
          if (e.target.value.indexOf('.') === -1) {
            setMax(e.target.value + '.00')
            return
          }
          const splited = e.target.value.split('.')
          if (splited[1].length === 0) {
            setMax(e.target.value + '00')
          }
          if (splited[1].length === 1) {
            setMax(e.target.value + '0')
          }
        }}
        onChange={(e) => {
          if (e.target.value === '') {
            setMax('')
            setSearchQuery({ ...searchQuery, maxRate: '' })
            return
          }
          const newValue = rateCheck(e, volumeFormat)
          if (!newValue) {
            return
          }
          let prevCommaCount = max.split(',').length - 1
          prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
          const start = e.target.selectionStart
          let nextCommaCount = newValue.split(',').length - 1
          nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
          const commaDiff = nextCommaCount - prevCommaCount
          e.target.value = newValue
          e.target.setSelectionRange(start + commaDiff, start + commaDiff)
          if (e.target.value.length < 2) {
            e.target.setSelectionRange(1, 1)
          }
          setMax(newValue)
          setSearchQuery({ ...searchQuery, maxRate: newValue.replaceAll(',', '') })
        }}
        onKeyDown={(e: any) => {
          const start = e.target.selectionStart
          const end = e.target.selectionEnd
          const isLengthOne = start === end
          if (e.key === 'Delete' && e.target.value[start] === ',' && isLengthOne) {
            e.target.setSelectionRange(start + 1, start + 1)
            e.preventDefault()
            return
          }
          if (isKeyTabOrEnter(e)) {
            if (e.target.value.indexOf('.') === -1) {
              setMax(e.target.value + '.00')
              return
            }
            const splited = e.target.value.split('.')
            if (splited[1].length === 0) {
              setMax(e.target.value + '00')
            }
            if (splited[1].length === 1) {
              setMax(e.target.value + '0')
            }
            resetCondition()
            handleGetBorrowPool()
            return
          }
          if (!isInputKeyCanBeEntered(e)) {
            return false
          }
        }}
        placeholder=""
      ></Input>
    </div>
  )
}

export default OptionRateInput
