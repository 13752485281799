export const OrderStatusText = {
  접수: 'RECEIVED',
  확정: 'MATCHED',
  주문취소: 'ORDER_CANCELED',
  매칭취소: 'MATCH_CANCELED',
  거부: 'REJECTED',
  요청: 'REQUESTED',
  RECEIVED: '접수',
  MATCHED: '확정',
  CANCELED: '취소',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  CANCEL_REQUESTED: '취소 요청',
  REJECTED: '거부',
  REQUESTED: '요청',
  BORROW: '차입',
  LEND: '대여',
  TODAY: 'T',
  NEXT_DAY: 'T1',
}

export const OrderStatusTextEn = {
  접수: 'RECEIVED',
  확정: 'MATCHED',
  주문취소: 'ORDER_CANCELED',
  매칭취소: 'MATCH_CANCELED',
  거부: 'REJECTED',
  요청: 'REQUESTED',
  RECEIVED: 'Received',
  MATCHED: 'Confirmed',
  CANCELED: 'Canceled',
  ORDER_CANCELED: 'Order Canceled',
  MATCH_CANCELED: 'Match Canceled',
  CANCEL_REQUESTED: 'Cancel requested',
  REJECTED: 'Rejected',
  REQUESTED: 'Requested',
  BORROW: 'Borrow',
  LEND: 'Lend',
  TODAY: 'T',
  NEXT_DAY: 'T1',
}
