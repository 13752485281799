import { MenuItem } from '@mui/material'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router'

interface Props {
  click?: () => void
  tabs: {
    path: string
    selected: boolean
    title: string
  }[]
}

const Tab: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate()

  return (
    <div className="tab">
      <ul>
        {props.tabs.map((tab) => (
          <li
            key={tab.title}
            onClick={() => {
              navigate(tab.path)
              if (props.click) {
                props.click()
              }
            }}
            className={tab.selected ? 'selected' : ''}
            // sx={[
            //   {
            //     textAlign: 'center',
            //     backgroundColor: '#00245F',
            //     display: 'inline',
            //     height: '40px',
            //     lineHeight: '31px !important',
            //   },
            //   () => ({
            //     '&:hover': {
            //       backgroundColor: '#DBD8D7',
            //     },
            //   }),
            // ]}
          >
            {tab.title}
            {/* <span style={{ lineHeight: '40px', marginBottom: '15px' }}>{tab.title}</span> */}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Tab
