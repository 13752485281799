// 경쟁거래 업로드

import { httpGetOrderBookListByIssueCode } from '@src/api/httpAPI/httpOrderBookAPI'
import { httpPostOrder } from '@src/api/httpAPI/httpOrdersAPI'
import Button from '@src/components/Button'
import Checkbox from '@src/components/Checkbox'
import ContextButtons from '@src/components/ContextButtons'
import OrderTypeButtons from '@src/components/OrderTypeButtons'
import SearchIssue from '@src/components/SearchIssue/SearchIssue'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import useOrderBook from '@src/hooks/useOrderBook'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ResultPopup from '@src/components/ResultPopup'

import { formatNumber } from '@src/util/formatNumber'
import { useSearchParams } from 'react-router-dom'
import { httpGetStock } from '@src/api/httpAPI/httpStockAPI'
import Loading from '@src/components/Loading'
import LogoutButton from '@src/components/Button/LogoutButton'
import { COPY_PASTE, TRADE_REQUEST, VALUECHECK } from '@src/constants/NotiText'
import { useTranslation } from 'react-i18next'

const OrdersUpload = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setLoading] = useState<boolean>(false)
  // const [orderType, setOrderType] = useState<OrderType>(searchParams.get('orderType') as OrderType)

  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const [query, setQuery] = useState<string>('')

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })

  const [isCopyPasteButtonShow, setIsCopyPasteButtonShow] = useState<boolean>(false)
  const [isOpenResultPopup, setIsOpenResultPopup] = useState<boolean>(false)

  const [resultOrdersRequest, setResultOrdersRequset] = useState<ResponsePostOrdersType[]>([])
  const trans = useTranslation()

  const { orderBookList, setOrderBookList } = useOrderBook<{
    requestVolume: number
  }>()

  const orderType = useMemo(() => {
    return searchParams.get('orderType') as OrderType
  }, [searchParams])

  const searchedOrderBookList = useMemo(() => {
    return orderBookList.filter(({ issueCode, issueName, type }) =>
      query.trim().length
        ? (issueCode.includes(query) || issueName.includes(query)) && type == orderType
        : type == orderType
    )
  }, [orderBookList, query, orderType])

  const uploadOrder = useCallback(async () => {
    try {
      setLoading(true)
      const selectedOrderBookList = orderBookList
        .filter((_, index) => selectedIndex.includes(index))
        .map(({ issueCode, rate, volume }) => ({
          issueCode,
          orderType,
          // orderType: orderType == 'LEND' ? 'BORROW' : 'LEND',
          rate,
          volume,
        }))
      if (!selectedOrderBookList.every(({ volume }) => volume > 0)) {
        alert(trans.t('modal.valueCheck.invalidValue'))
        return
      }
      const { data } = await httpPostOrder(selectedOrderBookList)
      setIsOpenResultPopup(true)
      setResultOrdersRequset(data)
    } catch {
      alert(trans.t('modal.tradeRequest.fail'))
    } finally {
      setLoading(false)
    }
  }, [orderBookList, selectedIndex, orderType])

  const handleCopyButton = useCallback(() => {
    if (!searchedOrderBookList.length) return
    navigator.clipboard
      .writeText(
        searchedOrderBookList
          .map(({ issueCode, issueName, volume, rate, type }) => {
            return Object.values({
              type: OrderTypeText[type == 'LEND' ? 'BORROW' : 'LEND'],
              issueCode,
              issueName,
              volume,
              rate,
            }).join('\t')
          })
          .join('\n')
      )
      .catch(() => {
        alert(COPY_PASTE.copyFail)
      })
      .finally(() => {
        setIsCopyPasteButtonShow(false)
      })
  }, [navigator, orderType, searchedOrderBookList])

  const handlePasteButton = useCallback(async () => {
    try {
      const clipboard = await navigator.clipboard.readText()
      const clipboardItems = clipboard.split('\n').filter((v) => v)
      if (!(clipboardItems instanceof Array)) throw new Error('붙여넣기에 실패하였습니다.')

      const issueCodes = clipboardItems.map((clipboardItem) => {
        const items = clipboardItem.split('\t')
        if (items.length !== 4) throw new Error('붙여넣기에 실패하였습니다.')

        const issueCode = items[0]
        return issueCode
      })

      const stocks = await httpGetStock(issueCodes)

      const newOrderBookList: (OrderBookType & { requestVolume: number })[] = await Promise.all(
        clipboardItems.map(async (clipboardItem) => {
          const items = clipboardItem.split('\t')
          if (items.length !== 4) throw new Error('붙여넣기에 실패하였습니다.')
          // const [type, _issueCode, issueName, _volume, _rate] = items
          const [_issueCode, issueName, _volume, _rate] = items

          const volume = Number(_volume)
          const rate = parseFloat(Number(_rate).toFixed(2))

          if (!volume) throw new Error(trans.t('modal.valueCheck.invalidvalue'))
          if (String(volume).length > 8)
            throw new Error(trans.t('modal.valueCheck.upToEightCharacters'))
          if (Number.isNaN(rate)) throw new Error(trans.t('modal.valueCheck.invalidrate'))
          // if (rate >= 1000) throw new Error('요율은 정수 3자리까지 입력해주세요')
          // if (OrderTypeText[type] != (orderType == 'LEND' ? 'BORROW' : 'LEND'))
          //   throw new Error('대차구분이 올바르지 않습니다.')

          // const orderBook = await httpGetStock({
          //   issueCode: _issueCode,
          //   type: orderType,
          // })

          return {
            rate,
            requestVolume: volume,
            type: orderType,
            issueCode: _issueCode,
            issueName,
            volume,
            previousClosingPrice: stocks.find(({ issueCode }) => issueCode == _issueCode)
              .previousClosingPrice,
          }
        })
      )

      setOrderBookList((prevOrderBookList) => [...prevOrderBookList, ...newOrderBookList])
      setSelectedIndex([...orderBookList, ...newOrderBookList].map((_, index) => index))
    } catch (err) {
      alert(err?.message)
    } finally {
      setIsCopyPasteButtonShow(false)
    }
  }, [navigator, orderType, orderBookList])

  const handleWindowClick = useCallback((e) => {
    setIsCopyPasteButtonShow
  }, [])

  useEffect(() => {
    setSelectedIndex([])
    setOrderBookList([])
  }, [orderType])

  useEffect(() => {
    if (isCopyPasteButtonShow) {
      window.addEventListener('click', handleWindowClick)
    } else {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [isCopyPasteButtonShow])

  return (
    <>
      {isLoading && <Loading />}
      <section className={`orders-upload ${orderType == 'BORROW' ? 'LEND' : 'BORROW'}`}>
        <header>바스켓 업로드</header>
        <article>
          <header>
            <OrderTypeButtons
              handleChangeOrderType={(orderType) => {
                // setSelectedIndex([])
                // setOrderBookList([])
                // if (orderType == 'LEND') setOrderType('BORROW')
                // if (orderType == 'BORROW') setOrderType('LEND')
              }}
            />
          </header>
          <div className="bigger-wrapper">
            <div className="action-buttons">
              <Button
                onClick={() => {
                  setOrderBookList([])
                  setQuery('')
                  setSelectedIndex([])
                }}
                color="red"
              >
                리셋
              </Button>
              <Button
                color={selectedIndex.length ? 'green' : 'grey'}
                onClick={() => {
                  if (selectedIndex.length) uploadOrder()
                }}
              >
                신청하기
              </Button>
            </div>
            <SearchIssue
              searchIcon
              placeholder="업로드 리스트에서 종목 검색"
              handleSearch={(issue) => {
                setQuery(issue)
                setSelectedIndex([])
              }}
            />
            <div
              className="fixed-table"
              onContextMenu={(e) => {
                e.preventDefault()
                setContextMenuPosition({ x: e.pageX, y: e.pageY })
                setIsCopyPasteButtonShow(true)
              }}
            >
              <table>
                <thead>
                  <th>
                    <Checkbox
                      checked={selectedIndex.length == orderBookList.length}
                      onClick={() => {
                        if (selectedIndex.length == orderBookList.length) {
                          setSelectedIndex([])
                        } else {
                          setSelectedIndex(orderBookList.map((_, index) => index))
                        }
                      }}
                    />
                  </th>
                  <th>{trans.t('issueCode')}</th>
                  <th>{trans.t('issueName')}</th>
                  <th>{trans.t('borrowListTable.volume')}</th>
                  <th>{trans.t('borrowListTable.rate')}</th>
                  <th>필요담보(원)</th>
                  <th></th>
                </thead>
                <tbody>
                  {searchedOrderBookList.map((orderBook, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        const checked = selectedIndex.includes(index)
                        if (!checked) {
                          setSelectedIndex((selectedIndex) => [...selectedIndex, index])
                        } else {
                          setSelectedIndex((selectedIndex) =>
                            selectedIndex.filter((_index) => _index !== index)
                          )
                        }
                      }}
                    >
                      <td>
                        <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
                      </td>
                      <td>{orderBook.issueCode}</td>
                      <td className="left">{orderBook.issueName}</td>
                      <td className="right">{formatNumber(orderBook.requestVolume)}</td>
                      <td className="right">{orderBook.rate.toFixed(2)}</td>
                      <td className="right">
                        {formatNumber(orderBook.previousClosingPrice * orderBook.requestVolume * 1)}
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <footer>
            <ul>
              <li>종목수 : {searchedOrderBookList.length}</li>
              <li>
                수량합계 : {searchedOrderBookList.reduce((acc, cur) => acc + cur.requestVolume, 0)}
              </li>
              <li>
                필요담보합계 :{' '}
                {formatNumber(
                  searchedOrderBookList.reduce(
                    (acc, cur) => acc + cur.previousClosingPrice * cur.requestVolume * 1,
                    0
                  )
                )}
                원
              </li>
            </ul>
          </footer>
        </article>
        {isCopyPasteButtonShow && (
          <ContextButtons
            handleClickPasteButton={handlePasteButton}
            handleClickCopyButton={handleCopyButton}
            position={contextMenuPosition}
          >
            붙여넣기
          </ContextButtons>
        )}
      </section>
      {isOpenResultPopup && (
        <ResultPopup
          close={() => {
            setIsOpenResultPopup(false)
            setResultOrdersRequset(null)
          }}
          title="신청이 완료되었습니다."
          result={resultOrdersRequest}
        />
      )}
    </>
  )
}

export default OrdersUpload
