export const pageTypeLend = {
  tabWapper: 'tab lend',
  mainWrapper: 'lend-search LEND',
  csvFileName: '대여_조회및취소',
  csvFileNameEn: 'Lending_Management',
  responseCsvFileName: '대여_취소요청응답',
  responseCsvFileNameEn: 'Lending_CancelRequest',
  fixedTableStyle: 'fixed-table fixed-table-lend',
  pageType: 'lend',
}

export const pageTypeBorrow = {
  tabWapper: 'tab borrow',
  mainWrapper: 'borrow-search BORROW',
  csvFileName: '차입_조회및취소',
  csvFileNameEn: 'Borrowing_Management',
  responseCsvFileName: '차입_취소요청응답',
  responseCsvFileNameEn: 'Borrowing_CancelRequest',
  fixedTableStyle: 'fixed-table fixed-table-borrow',
  pageType: 'borrow',
}
