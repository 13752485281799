const valueCheck = {
  zeroValue: '주문수량을 확인해주세요.',
  noOrder: '선택된 주문이 없습니다.',
  noIds: '선택된 상대가 없습니다.',
  noValue: '주문수량을 확인해주세요.',
  noInput: '입력값이 없습니다.',
  noGroup: '선택된 그룹이 없습니다.',
  emailInvalid: '이메일 형식에 맞지 않습니다.',
  leastOneCharacter: '최소 글자수가 입력되지 않았습니다.(최소 1글자 이상)',
  upToEightCharacters: '최대 입력수량을 초과했습니다.(최대 8자리)',
  need12Characters: '계좌번호 형식에 맞지 않습니다.(12자리 숫자)',
  needPasswordCheck: '변경할 비밀번호를 확인해주세요.',
  invalidAccountNumber: '계좌번호가 없습니다',
  invalidvalue: '호가잔량 오류',
  invalidrate: '요율 오류',
  invalidValue: '신청수량 오류',
  invalidIssueCode: '종목코드 오류',
}

const request = {
  succeed: '요청에 성공했습니다.',
  fail: '요청에 실패했습니다.',
  invalid: '잘못된 요청입니다.',
  confirm: '요청을 전송하시겠습니까?',
  serverError: '서버 오류가 발생했습니다.',
  updateFail: '갱신에 실패하였습니다.',
  removeCounterparty: '기관을 삭제하시겠습니까?',
  removeMyGroup: '그룹을 삭제하시겠습니까?',
}

const tradeRequest = {
  complete: '거래가 신청되었습니다.',
  error: '거래 신청 중 오류가 발생했습니다.',
  errorAndNeedCheck: '거래 신청 중 오류가 발생했습니다.',
  confirmListPrefix: '선택한 거래를 신청하시겠습니까?',
  fail: '거래 신청에 실패했습니다.',
}

const passwordRequest = {
  succeed: '비밀번호 변경에 성공했습니다.',
  fail: '비밀번호 변경에 실패하였습니다.',
}

const save = {
  succeed: '저장에 성공하였습니다.',
  fail: '저장에 실패하였습니다.',
}

const login = {
  succeed: '로그인에 성공했습니다.',
  fail: '로그인에 실패했습니다.',
}

const cancel = {
  cancelConfirm: '취소하시겠습니까?',
  cancelAllConfirm: '전체내역을 취소 하시겠습니까?',
  cancelFailed: '취소에 실패했습니다.',
  cancelSucceed: '취소에 성공했습니다.',
}

const result = {
  succeed: '전송에 성공했습니다.',
  fail: '전송에 실패했습니다.',
}

const copyPaste = {
  copyFail: '복사에 실패하였습니다.',
  pasteFail: '레이아웃 양식에 맞지 않습니다.',
}

const message = {
  lendPool: '대여풀',
  confirm: '메시지를 전송합니다.',
  succeed: '메시지 전송에 성공했습니다.',
  fail: '메시지 전송에 실패했습니다.',
  noType: '거래구분을 선택해주세요',
  noPartner: '상대기관을 선택해주세요',
  applyToRegister: 'Admin will contact you shortly.',
  forgotPassword:
    '가입한 이메일(ID)을 사용하여 비밀번호 초기화 요청 메일을 보내주시면 확인 후 임시비밀번호를 보내드립니다.',
  forgotPasswordEnglish:
    'Please email us using your registered email. We will send you the temporary password.',
}

const ko = {
  originalQty: '신청수량(주)',
  originalRate: '신청요율(%)',
  updatedQty: '변경수량(주)',
  updatedRate: '변경요율(%)',
  cancelButton: '취소',
  offerRate: '변경요율(%)',
  ksdFormat: '예탁원 파일생성',
  canceled: '취소',
  invalidDateRange: '신청일에 잘못된 값이 입력되었습니다.',
  noIssueCode: '종목없음',
  deleted: '삭제되었습니다.',
  save: '저장',
  companyName: '기관명',
  myGroup: '내 그룹 리스트',
  select: '선택',
  accountNo: '계좌',
  slbAccountNo: 'SLB 계정 코드',
  team: '사용부서',
  memo: '비고',
  add: '추가',
  delete: '삭제',
  ksdAccountInfo: 'KSD 등록 계좌 정보',
  passwordChange: '비밀번호 재설정',
  passwordChangeDiscription:
    '비밀번호는 영어 대소문자, 숫자, 특수문자를 조합한 8자리 이상이여야 합니다.',
  currentPassword: '현재 비밀번호',
  newPassword: '새로운 비밀번호',
  confirmPassword: '새로운 비밀번호 확인',
  name: '이름',
  email: '이메일',
  phoneNo: '연락처',
  resend: '재전송하시겠습니까?',
  matched: '확정',
  cancelRequested: '취소요청',
  lend: '대여',
  borrow: '차입',
  reSubmit: '재전송',
  cancelRequestedDate: '취소요청시간',
  requestorAsset: '요청기관',
  matchedDate: '확정일시',
  matchedId: '확정번호',
  listAndCancel: '조희 및 취소',
  cancelHistoryTab: '취소내역',
  sendHistory: '발신내역',
  receivedHistory: '수신내역',
  applicationDateSearchDescription: '최초 조회 시 전영업일부터 당일까지 조회',
  applicationDay: '신청일',
  cancellable: '취소가능',
  request: '신청',
  trade: '거래',
  update: '변경',
  cancel: '취소',
  updateVolume: '변경수량(주)',
  updateRate: '변경요율(%)',
  fixedAmount: '확정금액(원)',
  borrower: '차입자',
  lendpool: '대여풀',
  partnerAsset: '상대기관',
  confirms: {
    reject: '거부하시겠습니까?',
    accept: '동의하시겠습니까',
    update: '변경요청을 전송하시겠습니까?',
  },
  remainLendPool: '대여풀잔량',
  volumeAndOver: '주 초과수량',
  singleVolume: '주',
  rejectAndCheckPool:
    '대여풀 수량을 초과하여 동의 처리에 실패했습니다.\n대여풀을 추가로 등록하거나 가능 수량만 선택해 주세요.\n\n',
  putRequestReject: {
    case1: '차입자 신청수량이 희망수량을 초과해 동의할 수 없습니다.',
    case2: '변경수량이 희망수량을 초과해 요청할 수 없습니다.',
    case3: '변경수량이 차입자 신청수량을 초과해 동의할 수 없습니다.',
    case4: '대여자 신청수량이 희망수량을 초과해 동의할 수 없습니다.',
    case5: '변경수량이 희망수량을 초과해 요청할 수 없습니다.',
    case6: '변경수량이 대여자 신청수량을 초과해 동의할 수 없습니다.',
  },
  reject: '거부',
  updateRequest: '변경요청',
  accept: '동의',
  orderConfirm: '선택한 거래를 신청하시겠습니까?',
  company: '기관',
  rate: '요율',
  byCompany: '기관별',
  byIssue: '종목별',
  groupCondition: '그룹조건',
  message: '메시지',
  logout: '로그아웃',
  refresh: '새로고침',
  issue: '종목',
  amount: '금액(원)',
  search: '검색',
  searchByGroup: '그룹명으로 검색',
  searchInList: '리스트내 검색',
  cancelSubmit: '취소신청',
  cancelAllSubmit: '전체등록내역 취소',
  downloadAllList: '전체내역 다운로드',
  tradeRequested: '거래신청',
  orderSubmit: '거래신청',
  confirmSubmit: '선택한 거래를 신청하시겠습니까?',
  notiBar: {
    lend: '대여',
    borrow: '차입',
    orderResponse: '거래응답',
    orderConfirmed: '확정',
    orderCancelResponse: '취소응답',
    recall: '리콜',
    urgent: '긴급',
    lendPool: '대여풀',
    borrowPool: '차입수요',
    issueCount: '종목수',
    issueAmount: '총금액',
  },
  sideBar: {
    locate: '차입 신청',
    loan: '대여풀',
    pendingTrade: '협의거래',
    matchedTrade: '확정내역',
    lend: {
      title: '대여',
      poolReigister: '등록',
      poolRegisterList: '등록내역 조회 및 취소',
      poolMatched: '종목별 확정현황',
      list: '차입신청목록',
      orderResponse: '거래 응답',
      orderListCancel: '취소',
      orderList: '내역 조회',
      search: '조회 및 취소',
      searchResponse: '취소요청 응답',
    },
    borrow: {
      title: '차입',
      list: '차입가능풀 조회',
      requestOrder: '신청',
      requestList: '신청내역 조회 및 취소',
      orderResponse: '거래 응답',
      orderListCancel: '취소',
      orderList: '내역 조회',
      search: '조회 및 취소',
      searchResponse: '취소요청 응답',
    },
    confirm: '컨퍼메이션',
  },
  settlementType: {
    today: '상대지정',
    nextDay: '상대지정 T1',
    live: '실시간 체결',
  },
  all: '전체',
  reset: '리셋',
  submit: '전송',
  confirmCheck: '건을 등록합니다.',
  registerOption: {
    none: '선택된 상대 없음',
    lendingAll: '모든 사용자에게 공개',
    all: '모든 사용자에게 공개',
    asset: '운용사 전체 공개',
    companyOpen: 'e운용사 전체 공개',
    securityOpen: 'e증권사 전체 공개',
    companyAll: '운용사 전체',
    securityAll: '증권사 전체',
    companyLabel: '운용사 지정',
    securityLabel: '증권사 지정',
    myGroupLabel: '내 그룹에게 공개',
  },
  issueCode: '종목코드',
  issueName: '종목명',
  table: {
    volume: '수량(주)',
    rate: '요율(%)',
    amount: '금액(원)',
  },
  lendListTable: {
    all: '전체',
    applicationDate: '신청일시',
    createdDate: '등록일자',
    orderType: '구분',
    settlementType: '결제유형',
    poolStatus: '상태',
    innerPoolStatus: {
      all: '전체',
      received: '신청',
      matched: '전부확정',
      partiallyMatched: '일부확정',
      canceled: '취소',
    },
    tradeType: '거래유형',
    innerTradeType: {
      designated: '지정',
      custom: '맞춤',
      none: '없음',
      bidOffer: '경쟁',
    },
    issueCode: '종목코드',
    issueName: '종목명',
    volume: '등록수량(주)',
    rate: '등록요율(%)',
    normalRate: '요율(%)',
    normalVolume: '수량(주)',
    orderedVolume: '확정대기 수량(주)',
    lendOrderedVolume: '확정대기 수량(주)',
    borrowOrderedVolume: '확정대기 수량(주)',
    matchedVolume: '확정수량(주)',
    remainVolume: '잔여수량(주)',
    requestVolume: '제안수량(주)',
    desiredVolume: '희망수량(주)',
    lendDesiredVolume: '희망수량(주)',
    desiredRate: '희망요율(%)',
    lendDesiredRate: '희망요율(%)',
    lendResponseDesiredVolume: '희망수량(주)',
    lendResponseDesiredRate: '희망요율(%)',
    burnoutRate: '소진비율(%)',
    poolRemainVolume: '대여풀 잔여수량(주)',
    poolRemainVolumeButton1: '대여풀',
    poolRemainVolumeButton2: '잔여수량(주)',
    disclosedTarget: '기관명',
    swap: '스왑',
  },
  borrowListTable: {
    borrowRequestedQty: '신청수량(주)',
    borrowBidRate: '신청요율(%)',
    offerQty: '신청수량(주)',
    offerRate: '신청요율(%)',
    liveOfferQty: '신청수량(주)',
    liveOfferRate: '신청요율(%)',
    borrowDesiredVolume: '희망수량(주)',
    borrowDesiredRate: '희망요율(%)',
    borrowOrderedVolume: '확정대기 수량(주)',
    borrower: '차입자',
    all: '전체',
    createdDate: '신청일자',
    orderType: '구분',
    settlementType: '결제유형',
    poolStatus: '상태',
    innerPoolStatus: {
      all: '전체',
      received: '신청',
      matched: '전부확정',
      partiallyMatched: '일부확정',
      canceled: '취소',
    },
    issueCode: '종목코드',
    bidQty: '희망수량(주)',
    bidRate: '희망요율(%)',
    issueName: '종목명',
    volume: '신청수량(주)',
    normalVolume: '수량(주)',
    normalRate: '요율(%)',
    desiredVolume: '희망수량(주)',
    borrowerVolumeButton1: '차입자',
    borrowerVolumeButton2: '신청수량(주)',
    borrowerBidRateButton1: '차입자',
    borrowerBidRateButton2: '신청요율(%)',
    borrowerOfferVolumeButton1: '대여자',
    borrowerOfferVolumeButton2: '신청수량(주)',
    borrowerOfferRateButton1: '대여자',
    borrowerOfferRateButton2: '신청요율(%)',
    rate: '신청요율(%)',
    orderedVolume: '확정대기 수량(주)',
    matchedVolume: '확정수량(주)',
    remainVolume: '잔여수량(주)',
    unfilledQty: '잔여수량(주)',
    disclosedTarget: '기관명',
    swap: '스왑',
  },
  example: '[예시]',
  messagePage: {
    received: '수신',
    sent: '발신',
    write: '메시지 작성',
    read: '읽음',
    unread: '읽지않음',
    status: '상태',
    counterParty: '상대기관',
    sendDate: '발송일',
    urgent: '긴급',
    recall: '리콜',
    contact: '연락처문의',
    time: '시간',
    borrowLend: '거래구분',
    type: '유형',
    selectCounterparty: '선택하세요',
    selectBorL: '선택하세요',
    messageLimit: '메세지(3000 byte 이내)',
    selectConfirmed: '확정내역 선택',
    settlementDate: '결제일',
    confirmedDate: '확정일',
    confirmedRate: '확정요율(%)',
  },
  modal: {
    cancel: cancel,
    copyPaste: copyPaste,
    login: login,
    message: message,
    passwordRequest: passwordRequest,
    request: request,
    result: result,
    save: save,
    tradeRequest: tradeRequest,
    valueCheck: valueCheck,
    confirm: '확인',
    calcel: '취소',
  },
}

export default ko
