import httpClient from './httpClient'

export const httpGetStock = async (issueCode: string[]) => {
  const { data } = await httpClient.get<StockType[]>('/api/v1/stocks', {
    params: {
      issueCode: issueCode.join(),
    },
  })

  return data
}
