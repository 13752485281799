export const getResponseStatus = (
  requestStatus: RequestStatusType | 'CANCEL',
  requestType: RequestOptionType,
  lang?: string
) => {
  switch (lang) {
    case 'en':
      if (requestType === 'ACCEPT') {
        return requestStatus === 'RECEIVED'
          ? 'Trade requested'
          : requestStatus === 'ACCEPTED'
          ? 'Trade accepted'
          : requestStatus === 'REJECTED'
          ? 'Trade rejected'
          : requestStatus === 'CANCEL' && 'Trade Canceled'
      }
      if (requestType === 'UPDATE') {
        return requestStatus === 'RECEIVED'
          ? 'Update requested'
          : requestStatus === 'ACCEPTED'
          ? 'Update accepted'
          : requestStatus === 'REJECTED'
          ? 'Update rejected'
          : requestStatus === 'CANCEL' && 'Update canceled'
      }
      if (requestType === 'CANCEL') {
        return requestStatus === 'RECEIVED'
          ? 'Cancel requested'
          : requestStatus === 'ACCEPTED'
          ? 'Cancel accepted'
          : requestStatus === 'REJECTED'
          ? 'Cancel rejected'
          : requestStatus === 'CANCEL' && 'Cancel Canceled'
      }
      break
    default:
      if (requestType === 'ACCEPT') {
        return requestStatus === 'RECEIVED'
          ? '거래신청'
          : requestStatus === 'ACCEPTED'
          ? '거래동의'
          : requestStatus === 'REJECTED'
          ? '거래거부'
          : requestStatus === 'CANCEL' && '거래취소'
      }
      if (requestType === 'UPDATE') {
        return requestStatus === 'RECEIVED'
          ? '변경요청'
          : requestStatus === 'ACCEPTED'
          ? '변경동의'
          : requestStatus === 'REJECTED'
          ? '변경거부'
          : requestStatus === 'CANCEL' && '변경취소'
      }
      if (requestType === 'CANCEL') {
        return requestStatus === 'RECEIVED'
          ? '취소신청'
          : requestStatus === 'ACCEPTED'
          ? '취소동의'
          : requestStatus === 'REJECTED'
          ? '취소거부'
          : requestStatus === 'CANCEL' && '취소철회'
      }
  }
  return ''
}
