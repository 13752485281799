import * as React from 'react'
import { styled } from '@mui/material/styles'
import ToggleButtonDefault from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useTranslation } from 'react-i18next'

interface ColorToggleButtonProps {
  btnOnClick: (value: string) => void
}

const Label = styled('label')({
  display: 'flex',
  alignItems: 'center',
})

const ToggleButton = styled(ToggleButtonDefault)({
  backgroundColor: '#ffffff !important',
  '&.MuiToggleButton-root': {
    lineHeight: '14px',
    borderRadius: '1px',
  },
  '&.MuiToggleButton-sizeSmall:hover': {
    backgroundColor: 'rgba(17, 142, 199, 0.2) !important',
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#1976d2 !important',
    borderRadius: '1px',
  },
})

export default function ColorToggleButton({ btnOnClick }: ColorToggleButtonProps) {
  const [alignment, setAlignment] = React.useState('STOCK')
  const trans = useTranslation()

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment === null) return
    setAlignment(newAlignment)
  }

  return (
    <div className="text-field left">
      <label>{trans.t('groupCondition')}</label>
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        sx={{
          '&.MuiToggleButtonGroup-root': {
            // marginLeft: '10px',
            border: 'solid 0.5px #1976d2',
          },
        }}
      >
        <ToggleButton
          onClick={(e, v) => btnOnClick(v)}
          sx={{ lineHeight: undefined, color: '#000000' }}
          value="STOCK"
        >
          {trans.t('byIssue')}
        </ToggleButton>
        <ToggleButton
          onClick={(e, v) => btnOnClick(v)}
          sx={{ lineHeight: undefined, color: '#000000' }}
          value="COMPANY"
        >
          {trans.t('byCompany')}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}
