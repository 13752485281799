import Checkbox from '@src/components/Checkbox'
import Popup from '@src/components/PopupState/PopupState'
import { infScrollPageState } from '@src/stores/infScrollPageState'
import parseNumberWithComma from '@src/util/parseNumComma'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import {
  CompanyGroupRow,
  CompanySingleRow,
  IssueGroupRow,
  IssueSingleRow,
} from './LendListTableRow'

interface Props {
  actionRecord: ActionRecord
  searchQuery: SearchQuery
  setSearchQuery: React.Dispatch<React.SetStateAction<SearchQuery>>
  groupByIssue: any[]
  groupByCompany: any[]
  selectedIds: number[]
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>
  setActionRecord: React.Dispatch<React.SetStateAction<ActionRecords>>
  setIsFirstPage: React.Dispatch<React.SetStateAction<boolean>>
  pool: PoolType[]
  tab: string
  // rowRef: React.MutableRefObject<HTMLTableRowElement>
}

interface Order {
  poolId: number
  transactionType: string
  volume: number
  rate: number
  isSwap: boolean
}

const LendListTable: FunctionComponent<Props> = (props) => {
  // console.log('cnt')
  const actionRecord = props.actionRecord
  const [selectedIds, setSelectedIds] = [props.selectedIds, props.setSelectedIds]
  const [infScrollPage, setInfScrollPage] = useRecoilState(infScrollPageState)

  const [transactionRefsControl, setTransactionRefsControl] = useState('DESIGNATED')
  const [isSwapRefsControl, setIsSwapRefsControl] = useState(true)

  const trans = useTranslation('translation', { keyPrefix: 'lendListTable' })

  const tableDivRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (infScrollPage === 0) {
      // const topPos = tableDivRef.current.offsetTop
      // console.log('topPos', topPos)
      tableDivRef.current.scrollTop = -200
    }
  }, [infScrollPage])

  // useEffect(() => {
  //   props.setSelectedPoolIds(
  //     props.pool.filter((_, index) => selectedIds.includes(index)).map(({ id }) => id)
  //   )
  // }, [selectedIds])

  const thArray: string[] = [
    '종목코드',
    '구분',
    '종목명',
    '수량(주)',
    '요율(%)',
    '결제유형',
    '기관명',
    '거래유형',
    '희망수량(주)',
    '스왑',
    // '희망요율(%)',
  ]

  const getIsInfScrollObserver = (pool: any[], index: number) => {
    if (index === pool.length - 10) {
      return true
    }
    return false
  }

  const handlePopupMenuClick = (
    popupMenuValue: string,
    queryKey: string,
    popupClose: () => void
  ) => {
    const newPayLoad = { ...props.searchQuery }
    newPayLoad[queryKey] = popupMenuValue
    props.setSearchQuery(newPayLoad)
    props.setIsFirstPage(true)
    setInfScrollPage(0)
    popupClose()
  }

  return (
    <div
      ref={tableDivRef}
      className={
        'fixed-table tlist' + ' ' + 'tab-' + props.tab.toUpperCase() + ' ' + 'order-normal'
      }
    >
      <table>
        <thead>
          <tr>
            <th>
              <Checkbox
                readOnly
                checked={selectedIds.length !== 0 && selectedIds.length === props.pool.length}
                onClick={() => {
                  if (selectedIds.length === props.pool.length) {
                    setSelectedIds([])
                  } else {
                    setSelectedIds(props.pool.map((select) => select.id))
                  }
                }}
              />
            </th>
            <th>{trans.t('orderType')}</th>
            <th>{trans.t('settlementType')}</th>
            <th>{trans.t('issueCode')}</th>
            <th>{trans.t('issueName')}</th>
            <th>{trans.t('normalVolume')}</th>
            <th>{trans.t('normalRate')}</th>
            <th>{trans.t('disclosedTarget')}</th>
            <th className={'lend-th-change'}>
              <Popup
                visibleText={trans.t('tradeType')}
                menuItems={[
                  {
                    text: trans.t('all'),
                    value: '',
                  },
                  {
                    text: trans.t('innerTradeType.designated'),
                    value: 'DESIGNATED',
                  },
                  {
                    text: trans.t('innerTradeType.custom'),
                    value: 'CUSTOM',
                  },
                  {
                    text: trans.t('innerTradeType.none'),
                    value: 'NONE',
                  },
                ]}
                queryKey="transactionType"
                handlePopupMenuClick={handlePopupMenuClick}
              ></Popup>
            </th>
            <th>{trans.t('lendDesiredVolume')}</th>
            <th>{trans.t('lendDesiredRate')}</th>
            <th>{trans.t('poolRemainVolume')}</th>
            <th className={'lend-th-change'}>
              <Popup
                visibleText={trans.t('swap')}
                menuItems={[
                  {
                    text: trans.t('all'),
                    value: '',
                  },
                  {
                    text: 'Y',
                    value: 'true',
                  },
                  {
                    text: 'N',
                    value: 'false',
                  },
                ]}
                queryKey="isSwap"
                handlePopupMenuClick={handlePopupMenuClick}
              ></Popup>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.searchQuery.orderBy === 'COMPANY'
            ? props.groupByCompany.map((row, index) => {
                return row.id ? (
                  <CompanySingleRow
                    key={index}
                    outputPool={row}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    actionRecord={actionRecord}
                    isInfScrollObserver={getIsInfScrollObserver(props.groupByCompany, index)}
                  />
                ) : (
                  <CompanyGroupRow
                    key={index}
                    outputPool={row}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    actionRecord={actionRecord}
                    isInfScrollObserver={getIsInfScrollObserver(props.groupByCompany, index)}
                  />
                )
              })
            : props.groupByIssue.map((row, index) => {
                return row.id ? (
                  <IssueSingleRow
                    key={index}
                    outputPool={row}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    actionRecord={actionRecord}
                    isInfScrollObserver={getIsInfScrollObserver(props.groupByIssue, index)}
                    transactionRefsControl={transactionRefsControl}
                    isSwapRefsControl={isSwapRefsControl}
                  />
                ) : (
                  <IssueGroupRow
                    key={index}
                    outputPool={row}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    actionRecord={actionRecord}
                    isInfScrollObserver={getIsInfScrollObserver(props.groupByIssue, index)}
                  />
                )
              })}
        </tbody>
      </table>
      <div className="table-bottom"></div>
    </div>
  )
}

export default LendListTable
