export const csvHeader = [
  '구분',
  '결제유형',
  '거래유형',
  '상태',
  '신청일시',
  '확정일시',
  '확정번호',
  '상대기관',
  '종목코드',
  '종목명',
  '수량(주)',
  '요율(%)',
  '스왑',
]

export const csvHeaderEn = [
  'B/L',
  'Value date',
  'Settlement type',
  'Status',
  'Input time',
  'Contract time',
  'Trade ID',
  'Counterparty',
  'Ticker',
  'Security',
  'Quantity(shs)',
  'Rate(%)',
  'Swap',
]

export const csvKey = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  '!orderStatus',
  '0orderDateTime',
  '0matchedDateTime',
  'ledgerId',
  'PARTNER',
  '0stock.issueCode',
  'stock.issueName',
  'volume',
  '#rate',
  '?isSwap',
]

export const csvKeyEn = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  '!orderStatus',
  '0orderDateTime',
  '0matchedDateTime',
  'ledgerId',
  'PARTNER',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'volume',
  '#rate',
  '?isSwap',
]

export const csvHeaderResponse = [
  '구분',
  '결제유형',
  '거래유형',
  '확정일시',
  '확정번호',
  '상대기관',
  '종목코드',
  '종목명',
  '수량(주)',
  '요율(%)',
  '취소요청시간',
  '스왑',
]

export const csvHeaderResponseEn = [
  'B/L',
  'Value date',
  'Settlement type',
  'Contract time',
  'Trade ID',
  'Counterparty',
  'Ticker',
  'Security',
  'Quantity(shs)',
  'Rate(%)',
  'Request time',
  'Swap',
]

export const csvKeyResponse = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  '0matchedDateTime',
  'ledgerId',
  'PARTNER',
  '0stock.issueCode',
  'stock.issueName',
  'currentVolume',
  '#currentRate',
  '0requestDateTime',
  '?isSwap',
]

export const csvKeyResponseEn = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  '0matchedDateTime',
  'ledgerId',
  'PARTNER',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'currentVolume',
  '#currentRate',
  '0requestDateTime',
  '?isSwap',
]

export const csvHeaderHistory = [
  '구분',
  '결제유형',
  '거래유형',
  '상태',
  '확정일시',
  '확정번호',
  '요청기관',
  '상대기관',
  '종목코드',
  '종목명',
  '수량(주)',
  '요율(%)',
  '취소요청시간',
  '스왑',
]

export const csvHeaderHistoryEn = [
  'B/L',
  'Value date',
  'Settlement type',
  'Status',
  'Contract time',
  'Trade ID',
  'Requestor',
  'Counterparty',
  'Ticker',
  'Security',
  'Quantity(shs)',
  'Rate(%)',
  'Request time',
  'Swap',
]

export const csvKeyHistory = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  '!requestStatus',
  '0matchedDateTime',
  'ledgerId',
  'requesterAccount.companyName',
  'PARTNER',
  '0stock.issueCode',
  'stock.issueName',
  'currentVolume',
  '#currentRate',
  '0requestDateTime',
  '?isSwap',
]

export const csvKeyHistoryEn = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  '!requestStatus',
  '0matchedDateTime',
  'ledgerId',
  'requesterAccount.companyEnglishName',
  'PARTNER',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'currentVolume',
  '#currentRate',
  '0requestDateTime',
  '?isSwap',
]

export const csvHeaderNotRequester = [
  '구분',
  '결제유형',
  '거래유형',
  '상태',
  '신청일시',
  '상대기관',
  '종목코드',
  '종목명',
  '수량(주)',
  '요율(%)',
  '변경수량(주)',
  '변경요율(%)',
  '스왑',
]

export const csvKeyNotRequester = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  '!requestType',
  '0requestDateTime',
  'PARTNER',
  '0stock.issueCode',
  'stock.issueName',
  'currentVolume',
  '#currentRate',
  '-requestedVolume',
  '#requestedRate',
  '?isSwap',
]

export const csvDict = {
  LEND: '대여',
  BORROW: '차입',
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  RECEIVED: '접수',
  MATCHED: '확정',
  CANCELED: '취소',
  ORDER_CANCELED: '주문취소',
  CANCEL_REQUESTED: '취소요청',
  MATCH_CANCELED: '취소',
  NONE: '없음',
}

export const csvDictEn = {
  LEND: 'Lend',
  BORROW: 'Borrow',
  CUSTOM: 'Customized',
  DESIGNATED: 'Arranged',
  COMPETITIVE: 'Bid offer',
  NONE: 'No selection',
  ACCEPT: 'Trade requested',
  UPDATE: 'Update requested',
  CANCEL: 'Cancel requested',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  RECEIVED: '접수',
  MATCHED: 'Confirmed',
  CANCELED: 'Canceled',
  ORDER_CANCELED: '주문취소',
  CANCEL_REQUESTED: 'Cancel requested',
  MATCH_CANCELED: '취소',
}

export const csvDictHistoryEn = {
  LEND: 'Lend',
  BORROW: 'Borrow',
  CUSTOM: 'Customized',
  DESIGNATED: 'Arranged',
  COMPETITIVE: 'Bid offer',
  NONE: 'No selection',
  ACCEPT: 'Trade requested',
  UPDATE: 'Update requested',
  CANCEL: 'Cancel requested',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  MATCHED: 'Confirmed',
  CANCELED: 'Canceled',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  CANCEL_REQUESTED: 'Cancel requested',
  ACCEPTED: 'Cancel accepted',
  RECEIVED: 'Cancel requested',
  REJECTED: 'Cancel rejected',
}

export const csvDictHistory = {
  LEND: '대여',
  BORROW: '차입',
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  MATCHED: '확정',
  CANCELED: '취소',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  CANCEL_REQUESTED: '취소 요청',
  ACCEPTED: '취소동의',
  RECEIVED: '취소요청',
  REJECTED: '취소거부',
  NONE: '없음',
}
