import { styled } from '@mui/system'
import Checkbox from '@src/components/Checkbox'
import { CLASSES } from '@src/constants/Global'

type TdChilds = any[]

const AlignRightTd = styled('td')({
  textAlign: 'right',
})

const isInnerStrAlignRight = (text: string) => {
  return isNaN(parseInt(text))
    ? false
    : text.substring(0, 2) === '00'
    ? false
    : text.includes(',')
    ? true
    : text.includes('.')
    ? true
    : text === '0'
    ? true
    : parseInt(text) < 1000
    ? true
    : false
}

// index === 0 elem: [selectedIndex.includes(index), !getIsCancelable(order.poolStatus)],
export const getTableBodyTds = (tdChilds: TdChilds) => {
  return tdChilds.map((elem, index) => {
    if (index === 0) {
      return (
        <td key={index}>{elem[1] && <Checkbox readOnly checked={elem[0]} onClick={() => {}} />}</td>
      )
    }
    if (index === 4) {
      return (
        <td key={index} className={CLASSES.tableCellFilterable}>
          {elem}
        </td>
      )
    }
    if (elem === 'N' || elem === 'Y') {
      return (
        <td key={index} className={CLASSES.tableCellFilterable}>
          {elem}
        </td>
      )
    }
    if (isInnerStrAlignRight(elem)) {
      return <AlignRightTd key={index}>{elem}</AlignRightTd>
    }
    return <td key={index}>{elem}</td>
  })
}

export default getTableBodyTds
