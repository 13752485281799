export const csvHeader = [
  '등록일자',
  '구분',
  '결제유형',
  // '상태',
  '종목코드',
  '종목명',
  '등록수량(주)',
  '제안수량(주)',
  '확정수량(주)',
  '잔여수량(주)',
  '소진비율',
]

export const csvHeaderEn = [
  'Date',
  'B/L',
  'Value date',
  // '상태',
  'Ticker',
  'Security',
  'Quantity(shs)',
  'Pending Qty',
  'Confirmed qty',
  'Remaining Qty',
  'Hit ratio',
]

export const csvKey = [
  '@createdDate',
  '!orderType',
  '!settlementType',
  // '!poolStatus',
  '0stock.issueCode',
  'stock.issueName',
  'volume',
  'orderedVolume',
  'matchedVolume',
  'remainVolume',
  'burnout',
]

export const csvKeyEn = [
  '@createdDate',
  '!orderType',
  '!settlementType',
  // '!poolStatus',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'volume',
  'orderedVolume',
  'matchedVolume',
  'remainVolume',
  'burnout',
]

export const csvHeaderLive = [
  '등록일자',
  '구분',
  // '상태',
  '종목코드',
  '종목명',
  '수량(주)',
  '확정수량(주)',
  '잔여수량(주)',
]

export const csvHeaderLiveEn = [
  'Date',
  'B/L',
  // '상태',
  'Ticker',
  'Security',
  'Quantity(shs)',
  'Confirmed qty',
  'Remaining Qty',
]

export const csvKeyLive = [
  '@createdDate',
  '!orderType',
  // '!poolStatus',
  '0stock.issueCode',
  'stock.issueName',
  'volume',
  'matchedVolume',
  'remainVolume',
]

export const csvKeyLiveEn = [
  '@createdDate',
  '!orderType',
  // '!poolStatus',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'volume',
  'matchedVolume',
  'remainVolume',
]

export const csvDict = {
  LEND: '대여',
  BORROW: '차입',
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  RECEIVED: '접수',
  MATCHED: '확정',
  CANCELED: '취소',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  NONE: '없음',
}

export const csvDictEn = {
  LEND: 'Lend',
  BORROW: 'Borrow',
  CUSTOM: 'Customized',
  DESIGNATED: 'Arranged',
  COMPETITIVE: 'Bid offer',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  RECEIVED: 'Requested',
  MATCHED: 'Confirmed',
  CANCELED: 'Caleled',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  NONE: '없음',
}
