import { useGetRateSuffix } from '@src/hooks/useGetRateSuffix'
import useVolumeFormat from '@src/hooks/useVolumeFormat'
import { postPoolToAllState } from '@src/stores/postPoolToAllState'
import { memo, useState, FunctionComponent, useRef, useCallback, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Checkbox from '@src/components/Checkbox'
import useDebounce from '@src/hooks/useDebounce'
import { httpGetStock } from '@src/api/httpAPI/httpStockAPI'
import { COPY_PASTE, VALUECHECK } from '@src/constants/NotiText'
import TextField from '@src/components/TextField'
import { formatNumber } from '@src/util/formatNumber'
import { localStorageAPI } from '../../api/storageAPI'
import { useTranslation } from 'react-i18next'

export const TableRow: FunctionComponent<{
  index: number
  data: Partial<PoolType>
  setData: React.Dispatch<React.SetStateAction<PoolType[]>>
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  isNew?: boolean
  isExample?: boolean
  tab: string
  contextWindowEventEnabled: any
  setContextWindowEventEnabled: any
  isPasting: any
}> = ({
  data,
  selectedIndex,
  setSelectedIndex,
  setData,
  index,
  isNew,
  isExample,
  tab,
  contextWindowEventEnabled,
  setContextWindowEventEnabled,
  isPasting,
}) => {
  //   console.log('tablerow?')
  const [issueCode, setIssueCode] = useState<string>(data.stock.issueCode)
  const [issueName, setIssueName] = useState<string>(data.stock.issueName)
  const [volume, setVolume] = useState<string>(String(data.volume))
  const [rate, setRate] = useState<string>(data.rate?.toFixed(2))
  const [transactionType, setTransactionType] = useState<TransactionType>('DESIGNATED')
  const [isSwap, setIsSwap] = useState<boolean>(false)

  const trans = useTranslation()

  const volumeFormat = useVolumeFormat()

  const getRateSuffix = useGetRateSuffix()

  const postPoolToAll = useRecoilValue(postPoolToAllState)

  const debouncedRate = useDebounce(rate, 1000)

  const mounted = useRef(false)

  const changeTableInput = useCallback(
    (newData: Partial<PoolType>) => {
      setData((prevData) => [
        ...prevData.slice(0, index),
        {
          ...data,
          ...newData,
        } as PoolType,
        ...prevData.slice(index + 1),
      ])
    },
    [setData, data, index]
  )

  useEffect(() => {
    if (postPoolToAll === true) {
      setIsSwap(false)
    }
  }, [postPoolToAll])

  useEffect(() => {
    if (issueCode.length !== 6 || !mounted.current) return
    httpGetStock([issueCode])
      .then(([stock]) => {
        let newData = {}
        if (localStorageAPI.getItem('lang') === 'en') {
          newData = {
            stock: {
              issueCode: stock.issueCode,
              issueName: stock?.issueEnglishName || '',
              tradePrice: stock?.tradePrice || 0,
              previousClosingPrice: stock?.previousClosingPrice || 0,
            },
            transactionType: transactionType,
            isSwap: isSwap,
          }
        } else {
          newData = {
            stock: {
              issueCode: stock.issueCode,
              issueName: stock?.issueName || '',
              tradePrice: stock?.tradePrice || 0,
              previousClosingPrice: stock?.previousClosingPrice || 0,
            },
            transactionType: transactionType,
            isSwap: isSwap,
          }
        }
        changeTableInput({
          ...newData,
        })

        if (isNew) {
          setSelectedIndex([...selectedIndex, index])
        }
      })
      .catch(() => {
        if (isExample) {
          setIssueCode('')
          setIssueName(trans.t('noIssueCode'))
          return
        }
        changeTableInput({
          stock: {
            issueCode: '',
            issueName: '',
            previousClosingPrice: 0,
            tradePrice: 0,
          },
          volume: 0,
          rate: 0,
          transactionType: transactionType,
          isSwap: isSwap,
        })
        setSelectedIndex(selectedIndex.filter((_index) => _index !== index))
      })
  }, [issueCode])

  useEffect(() => {
    if (!data.stock.issueCode || rate == null) return
    // if (Number(debouncedRate) >= 1000) {
    //   alert('요율은 3자리까지 입력이 가능합니다.')
    //   setRate(data.rate || 0)
    //   return
    // }
    const fixedRate = Number(rate.replaceAll(',', '')).toFixed(2)

    changeTableInput({
      rate: parseFloat(fixedRate),
    })
    // setRate(fixedRate)
  }, [rate])

  useEffect(() => {
    if (!data.stock.issueCode) return
    const replaced = volume.replaceAll(',', '')

    if (String(replaced).length > 8) {
      alert(trans.t('modal.valueCheck.upToEightCharacters'))
      return
    }

    const _volume = Number(replaced)

    changeTableInput({
      volume: _volume,
    })
  }, [volume])

  useEffect(() => {
    if (!data.stock.issueCode) return
    changeTableInput({
      transactionType,
    })
  }, [transactionType])

  useEffect(() => {
    if (!data.stock.issueCode) return
    changeTableInput({
      isSwap,
    })
  }, [isSwap])

  useEffect(() => {
    if (!mounted.current) mounted.current = true
  }, [])

  return (
    <tr
    // onClick={() => {
    //   if (isNew || isExample || !data.stock.issueCode) return
    //   const checked = selectedIndex.includes(index)
    //   if (!checked) {
    //     setSelectedIndex((selectedIndex) => [...selectedIndex, index])
    //   } else {
    //     setSelectedIndex((selectedIndex) => selectedIndex.filter((_index) => _index !== index))
    //   }
    // }}
    >
      <td
        style={{
          fontSize: '14px',
          color: '#7a7a7a',
        }}
        onClick={() => {
          if (isNew || isExample || !data.stock.issueCode) return
          const checked = selectedIndex.includes(index)
          if (!checked) {
            setSelectedIndex((selectedIndex) => [...selectedIndex, index])
          } else {
            setSelectedIndex((selectedIndex) => selectedIndex.filter((_index) => _index !== index))
          }
        }}
      >
        {isExample ? (
          trans.t('example')
        ) : data.stock.issueCode ? (
          <Checkbox readOnly checked={selectedIndex.includes(index)} onClick={() => {}} />
        ) : null}
      </td>
      <td>
        <TextField
          type="text"
          value={issueCode}
          placeholder={isExample ? '005930' : ''}
          pattern="[0-9]*"
          maxLength={6}
          onChange={(e) => {
            if (e.target.validity.valid) {
              setIssueCode(e.target.value)
            }
          }}
          onFocus={(e) => {
            e.target.select()
            setContextWindowEventEnabled(false)
          }}
          onBlur={() => {
            setContextWindowEventEnabled(true)
          }}
        />
      </td>
      <td className="left">
        <TextField
          type="text"
          value={issueName}
          disabled={true}
          placeholder={
            localStorageAPI.getItem('lang') === 'ko' && isExample ? '삼성전자(공란가능)' : ''
          }
          onChange={(e) => {
            setIssueName(e.target.value)
          }}
          onFocus={(e) => {
            e.target.select()
            setContextWindowEventEnabled(false)
          }}
          onBlur={() => {
            setContextWindowEventEnabled(true)
          }}
        />
      </td>
      <td>
        <TextField
          issueCode={issueCode}
          inputStyle={{ textAlign: 'right' }}
          type="text"
          value={volume}
          pattern="[0-9, ',']*"
          placeholder={isExample ? '1,000' : ''}
          maxLength={10}
          min={0}
          disabled={!data.stock.issueCode}
          onKeyDown={(e: any) => {
            const start = e.target.selectionStart
            const end = e.target.selectionEnd
            const isLengthOne = start === end
            if (e.key === 'Delete' && e.target.value[start] === ',' && isLengthOne) {
              e.target.setSelectionRange(start + 1, start + 1)
              e.preventDefault()
            }
          }}
          onChange={(e) => {
            if (e.target.validity.valid) {
              let prevCommaCount = volume.split(',').length - 1
              prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
              const start = e.target.selectionStart
              let currentValue = e.target.value.replaceAll(',', '')
              if (currentValue[0] === '0') {
                currentValue = currentValue.substring(1, currentValue.length)
              }
              const newValue = volumeFormat(currentValue, ',')
              let nextCommaCount = newValue.split(',').length - 1
              nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
              const commaDiff = nextCommaCount - prevCommaCount
              setVolume(newValue)
              e.target.value = newValue
              e.target.setSelectionRange(start + commaDiff, start + commaDiff)
              if (e.target.value.length < 2) {
                e.target.setSelectionRange(1, 1)
              }
            }
          }}
          onFocus={(e) => {
            e.target.select()
            setContextWindowEventEnabled(false)
          }}
          onBlur={() => {
            setContextWindowEventEnabled(true)
          }}
        />
      </td>
      <td>
        <TextField
          inputStyle={{ textAlign: 'right' }}
          type="text"
          value={rate}
          min={0}
          maxLength={9}
          placeholder={isExample ? '0.20' : ''}
          pattern="[0-9, ',' , '.']*"
          disabled={!data.stock.issueCode}
          onChange={(e) => {
            let prevCommaCount = rate.split(',').length - 1
            prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
            const start = e.target.selectionStart
            const _value = e.target.value.replaceAll(',', '')
            let dotIndex = _value.indexOf('.')
            const splited = e.target.value.split('.')
            if (splited.length > 2) {
              return
            }
            if (splited[1] && splited[1].includes(',')) {
              return
            }
            if (splited[1]?.length > 2) {
              return
            }
            let beforeDecimal = splited[0]
            if (beforeDecimal.length > 1 && beforeDecimal[0] === '0') {
              beforeDecimal = beforeDecimal.substring(1, beforeDecimal.length)
            }
            const afterDecimal = splited[1] === undefined ? '' : splited[1]
            const currentValue = beforeDecimal.replaceAll(',', '')
            const newBeforeDecimal = volumeFormat(currentValue, ',')
            const commaCount = newBeforeDecimal.split(',').length - 1
            if (dotIndex !== -1) {
              dotIndex = dotIndex + commaCount
            }
            if (dotIndex === -1) {
              const newValue = newBeforeDecimal
              let nextCommaCount = newValue.split(',').length - 1
              nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
              const commaDiff = nextCommaCount - prevCommaCount
              setRate(newValue)
              e.target.value = newValue
              e.target.setSelectionRange(start + commaDiff, start + commaDiff)
              if (e.target.value.length < 2) {
                e.target.setSelectionRange(1, 1)
              }
              return
            } else {
              const _newValue = newBeforeDecimal + afterDecimal
              const newValue =
                _newValue.substring(0, dotIndex) +
                '.' +
                _newValue.substring(dotIndex, _newValue.length)
              let nextCommaCount = newValue.split(',').length - 1
              nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
              const commaDiff = nextCommaCount - prevCommaCount
              setRate(newValue)
              e.target.value = newValue
              e.target.setSelectionRange(start + commaDiff, start + commaDiff)
              if (e.target.value.length < 2) {
                e.target.setSelectionRange(1, 1)
              }
              return
            }
          }}
          onFocus={(e) => {
            e.target.select()
            setContextWindowEventEnabled(false)
          }}
          onBlur={(e) => {
            setContextWindowEventEnabled(true)
            getRateSuffix(e, setRate)
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              getRateSuffix(e, setRate)
            }
            const start = e.target.selectionStart
            const end = e.target.selectionEnd
            const isLengthOne = start === end
            if (e.key === 'Delete' && e.target.value[start] === ',' && isLengthOne) {
              e.target.setSelectionRange(start + 1, start + 1)
              e.preventDefault()
            }
          }}
        />
      </td>
      <td className="right">{formatNumber(data.stock.tradePrice * data.volume)}</td>
      {tab !== 'live' && (
        <td onClick={(e) => e.stopPropagation()}>
          <select onChange={(e) => setTransactionType(e.target.value as TransactionType)}>
            <option value="DESIGNATED">{trans.t('lendListTable.innerTradeType.designated')}</option>
            <option value="CUSTOM">{trans.t('lendListTable.innerTradeType.custom')}</option>
            <option value="NONE">{trans.t('lendListTable.innerTradeType.none')}</option>
          </select>
        </td>
      )}
      {tab !== 'live' && (
        <td
          onClick={(e) => {
            if (postPoolToAll) return
            e.stopPropagation()
            setIsSwap(!isSwap)
          }}
        >
          {!postPoolToAll && <input type="checkbox" readOnly checked={isSwap}></input>}
        </td>
      )}
      <td></td>
    </tr>
  )
}

export const MemoizedTableRow = memo(TableRow)
