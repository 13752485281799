import { httpDeletePool, httpGetPoolHistory, httpPostPool } from '@src/api/httpAPI/httpPoolAPI'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import LendPoolRegisterListTable from './ListTable'
import LogoutButton from '@src/components/Button/LogoutButton'
import { httpDeletetOrder, httpGetOrder } from '@src/api/httpAPI/httpOrdersAPI'
import LendPoolRegisterListLiveTable from './LiveTable'
import { useNavigate, useParams } from 'react-router'
import Tab from '@src/components/Tab'
import LendPoolRegisterListOption from './ListOption'
import LendPoolRegisterListLiveOption from './LiveOption'
import TradeButton from '@src/components/Button/TradeButton'
import { Button } from '@mui/material'
import { downloadCsvWithDictAndBurnout } from '@src/util/getCsv'
import { httpDelLiveOrdersAll, httpDelOrdersAll } from '@src/api/httpAPI/httpLendPoolRegisterAPI'
import { pageTypeBorrow, pageTypeLend } from '@src/constants/PostedPoolList'
import { CANCEL, RESULT } from '@src/constants/NotiText'
import { useRecoilState, useRecoilValue } from 'recoil'
import { forceFetchState } from '@src/stores/forceFetchState'
import {
  csvBorrowHeader,
  csvBorrowHeaderEn,
  csvBorrowHeaderLive,
  csvBorrowHeaderLiveEn,
  csvBorrowKey,
  csvBorrowKeyEn,
  csvBorrowKeyLive,
  csvBorrowKeyLiveEn,
  csvDict,
  csvDictEn,
  csvDictLive,
  csvDictLiveEn,
  csvLendHeader,
  csvLendHeaderEn,
  csvLendHeaderLive,
  csvLendHeaderLiveEn,
  csvLendKey,
  csvLendKeyEn,
  csvLendKeyLive,
  csvLendKeyLiveEn,
} from './csv'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

// 대여 - 등록내역 조회 및 취소

interface PostedPoolListProps {
  pageType: PageType
}

const PostedPoolList: FunctionComponent<PostedPoolListProps> = ({
  pageType,
}: PostedPoolListProps) => {
  const constants = pageType === 'LEND' ? pageTypeLend : pageTypeBorrow

  const params = useParams()
  const TAB = params.tab?.toUpperCase() || 'TODAY'

  const [tab, setTab] = useState<TabType>(TAB as TabType)
  const [isLoading, setIsLoading] = useState(true)

  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const [selectedPoolIds, setSelectedPoolIds] = useState<number[]>([])
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([])
  const [livePoolStatus, setLivePoolStatus] = useState<PoolStatusType>('')

  const [isSwap, setIsSwap] = useState<'' | boolean>('')

  const [forceFetch, setForceFetch] = useRecoilState(forceFetchState)
  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const forceRefresh = useRecoilValue(forceRefreshState)

  const [page, setPage] = useState(0)

  const [query, setQuery] = useState<string>('')
  const [poolStatus, setPoolStatus] = useState<PoolStatusType | 'ALL'>('ALL')

  const [poolHistory, setPoolHistory] = useState<PoolHistoryType[]>([])
  const [orderList, setOrderList] = useState<OrderDataType[]>([])

  const trans = useTranslation()

  // const tab = useMemo(() => params.tab, [params.tab])

  const deletePoolsCallback = async () => {
    try {
      await httpDeletePool(selectedPoolIds)
      // await httpDeletePool([999])
      setToastModal({
        ...toastModal,
        isOpen: true,
        innerString: trans.t('modal.cancel.cancelSucceed'),
      })
      reset()
    } catch (error) {
      if (error) {
        console.error(error)
      }
      setErrorModal({
        ...errorModal,
        innerString: trans.t('modal.cancel.cancelFailed'),
        isOpen: true,
      })
    }
  }

  const deleteOrdersCallback = async () => {
    try {
      await httpDeletetOrder(selectedOrderIds)
      setToastModal({
        ...toastModal,
        isOpen: true,
        innerString: trans.t('modal.cancel.cancelSucceed'),
      })
      reset()
    } catch (error) {
      if (error) {
        console.error(error)
      }
      setErrorModal({
        ...errorModal,
        innerString: trans.t('modal.cancel.cancelFailed'),
        isOpen: true,
      })
    }
  }

  const deletePools = useCallback(async () => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: trans.t('modal.cancel.cancelConfirm'),
      confirmFunction: deletePoolsCallback,
      closeFunction: () => {
        return
      },
    })
  }, [selectedPoolIds])

  const deleteOrders = useCallback(async () => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: trans.t('modal.cancel.cancelConfirm'),
      confirmFunction: deleteOrdersCallback,
      closeFunction: () => {
        return
      },
    })
  }, [selectedOrderIds])

  // disabled={selectedIndex.length === 0}
  // color="red"
  // onClick={() => (tab === 'LIVE' ? deleteOrders() : deletePools())}

  const postShortCutEvent = (e) => {
    if (e.key === 'F8') {
      if (selectedIndex.length === 0) {
        return
      }
      tab === 'LIVE' ? deleteOrders() : deletePools()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', postShortCutEvent)
    return () => {
      window.removeEventListener('keydown', postShortCutEvent)
    }
  }, [selectedIndex, tab, selectedOrderIds, selectedPoolIds])

  const reset = useCallback(() => {
    setSelectedIndex([])
    setSelectedPoolIds([])
    setSelectedOrderIds([])
    setPoolStatus('ALL')
    setLivePoolStatus('')
    setQuery('')
    setIsSwap('')
    setPage(0)
    setForceFetch(!forceFetch)
  }, [forceFetch])

  useEffect(() => {
    isLoading === false && reset()
  }, [forceRefresh])

  useEffect(() => {
    setIsLoading(false)
  })

  const handleCsvDownload = useCallback(async () => {
    const payload = {
      orderType: pageType,
      settlementType: tab === 'TODAY' ? 'TODAY' : 'NEXT_DAY',
    }
    const res = await httpGetPoolHistory(payload)
    let fileName
    let csvRequire
    let dict
    if (localStorageAPI.getItem('lang') === 'en') {
      fileName = constants.csvFileNameEn
      csvRequire = {
        header: constants.pageType === 'borrow' ? csvBorrowHeaderEn : csvLendHeaderEn,
        key: constants.pageType === 'borrow' ? csvBorrowKeyEn : csvLendKeyEn,
      }
      dict = csvDictEn
    } else {
      fileName = constants.csvFileName
      csvRequire = {
        header: constants.pageType === 'borrow' ? csvBorrowHeader : csvLendHeader,
        key: constants.pageType === 'borrow' ? csvBorrowKey : csvLendKey,
      }
      dict = csvDict
    }
    downloadCsvWithDictAndBurnout(
      res,
      csvRequire.header,
      csvRequire.key,
      dict,
      fileName,
      localStorageAPI.getItem('lang')
    )
  }, [query, tab, poolStatus])

  const handleCsvDownloadLive = useCallback(async () => {
    const payload = {
      orderType: pageType,
      poolStatus: livePoolStatus,
    }
    const res = await httpGetOrder(payload)
    let fileName
    let csvRequire
    let dict
    if (localStorageAPI.getItem('lang') === 'en') {
      fileName = constants.csvFileNameEn
      csvRequire = {
        header: constants.pageType === 'borrow' ? csvBorrowHeaderLiveEn : csvLendHeaderLiveEn,
        key: constants.pageType === 'borrow' ? csvBorrowKeyLiveEn : csvLendKeyLiveEn,
      }
      dict = csvDictLiveEn
    } else {
      fileName = constants.csvFileName
      csvRequire = {
        header: constants.pageType === 'borrow' ? csvBorrowHeaderLive : csvLendHeaderLive,
        key: constants.pageType === 'borrow' ? csvBorrowKeyLive : csvLendKeyLive,
      }
      dict = csvDictLive
    }
    // const fileName = constants.csvFileName
    // const csvRequire = {
    //   header: constants.pageType === 'borrow' ? csvBorrowHeaderLive : csvLendHeaderLive,
    //   key: constants.pageType === 'borrow' ? csvBorrowKeyLive : csvLendKeyLive,
    // }
    downloadCsvWithDictAndBurnout(
      res,
      csvRequire.header,
      csvRequire.key,
      dict,
      fileName,
      localStorageAPI.getItem('lang')
    )
  }, [query, tab, poolStatus])

  const handleCancleAllBtnCallback = async () => {
    const orderType = pageType
    const settlementType = tab === 'TODAY' ? 'TODAY' : 'NEXT_DAY'
    try {
      const delResStatus = await httpDelOrdersAll(orderType, settlementType)
      if (delResStatus !== 200) {
        throw Error(RESULT.fail)
      }
      setToastModal({
        ...toastModal,
        isOpen: true,
        innerString: trans.t('modal.cancel.cancelSucceed'),
      })
      reset()
    } catch (error) {
      if (error) {
        console.error(error)
      }
      setToastModal({
        ...toastModal,
        isOpen: true,
        innerString: trans.t('modal.cancel.cancelFailed'),
      })
    }
  }

  const handleCancleAllBtn = async () => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: trans.t('modal.cancel.cancelAllConfirm'),
      confirmFunction: handleCancleAllBtnCallback,
      closeFunction: () => {
        return
      },
    })
    // if (window.confirm(trans.t('cancel.cancelAllConfirm'))) {
    //   const orderType = pageType
    //   const settlementType = tab === 'TODAY' ? 'TODAY' : 'NEXT_DAY'
    //   try {
    //     const delResStatus = await httpDelOrdersAll(orderType, settlementType)
    //     if (delResStatus !== 200) {
    //       throw Error(RESULT.fail)
    //     }
    //     alert(trans.t('cancel.cancelSucceed'))
    //     reset()
    //   } catch (err) {
    //     console.error(err)
    //     alert(trans.t('cancel.cancelFailed'))
    //   }
    // }
  }

  const handleCancleAllLiveBtnCallback = async () => {
    const orderType = pageType
    try {
      const delResStatus = await httpDelLiveOrdersAll(orderType)
      if (delResStatus !== 200) {
        throw Error(RESULT.fail)
      }
      setToastModal({
        ...toastModal,
        isOpen: true,
        innerString: trans.t('modal.cancel.cancelSucceed'),
      })
      reset()
    } catch (error) {
      if (error) {
        console.error(error)
      }
      setToastModal({
        ...toastModal,
        isOpen: true,
        innerString: trans.t('modal.cancel.cancelFailed'),
      })
    }
  }

  const handleCancleAllLiveBtn = async () => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: trans.t('modal.cancel.cancelAllConfirm'),
      confirmFunction: handleCancleAllLiveBtnCallback,
      closeFunction: () => {
        return
      },
    })
  }

  return (
    <div className={constants.mainWrapper}>
      <article key={tab}>
        <div className={constants.tabWapper}>
          <ul>
            <li
              onClick={() => {
                setTab('TODAY')
                reset()
              }}
              className={tab == 'TODAY' ? 'selected' : ''}
            >
              {trans.t('settlementType.today')}
            </li>
            <li
              onClick={() => {
                setTab('NEXT_DAY')
                reset()
              }}
              className={tab == 'NEXT_DAY' ? 'selected' : ''}
            >
              {trans.t('settlementType.nextDay')}
            </li>
            <li
              onClick={() => {
                setTab('LIVE')
                reset()
              }}
              className={tab == 'LIVE' ? 'selected' : ''}
            >
              {trans.t('settlementType.live')}
            </li>
          </ul>
        </div>
        <div className="button-wrap">
          <TradeButton
            disabled={selectedIndex.length === 0}
            color="red"
            onClick={() => (tab === 'LIVE' ? deleteOrders() : deletePools())}
          >
            {trans.t('cancelSubmit') + '(F8)'}
          </TradeButton>
        </div>
        {tab === 'LIVE' ? (
          <LendPoolRegisterListLiveOption
            reset={reset}
            query={query}
            setQuery={setQuery}
            poolStatus={poolStatus}
            setPoolStatus={setPoolStatus}
            tab={tab}
            setPage={setPage}
          />
        ) : (
          <LendPoolRegisterListOption
            reset={reset}
            query={query}
            setQuery={setQuery}
            poolStatus={poolStatus}
            setPoolStatus={setPoolStatus}
            tab={tab}
            setPage={setPage}
          />
        )}
        {/* #fa4e60 */}
        <Button
          variant="outlined"
          color="error"
          sx={{
            marginLeft: '30px',
            marginTop: '20px !important',
            color: '#fa4e60',
            borderColor: '#fa4e60',
          }}
          onClick={() => (tab === 'LIVE' ? handleCancleAllLiveBtn() : handleCancleAllBtn())}
        >
          {trans.t('cancelAllSubmit')}
        </Button>
        {tab == 'LIVE' ? (
          <LendPoolRegisterListLiveTable
            setSelectedOrderIds={setSelectedOrderIds}
            orderList={orderList}
            setOrderList={setOrderList}
            pageType={pageType}
            page={page}
            setPage={setPage}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            query={query}
            livePoolStatus={livePoolStatus}
            setLivePoolStatus={setLivePoolStatus}
            constants={constants}
            tab={tab}
          />
        ) : (
          <LendPoolRegisterListTable
            setSelectedPoolIds={setSelectedPoolIds}
            poolHistory={poolHistory}
            poolStatus={poolStatus}
            setPoolStatus={setPoolStatus}
            settlementType={tab == 'TODAY' ? 'TODAY' : 'NEXT_DAY'}
            query={query}
            setPoolHistory={setPoolHistory}
            pageType={pageType}
            page={page}
            setPage={setPage}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            constants={constants}
            tab={tab}
            isSwap={isSwap}
            setIsSwap={setIsSwap}
          />
        )}
        <Button
          onClick={() => {
            if (tab === 'LIVE') {
              handleCsvDownloadLive()
              return
            }
            handleCsvDownload()
          }}
          variant="outlined"
          sx={{ marginLeft: '30px', marginTop: '5px !important' }}
        >
          {trans.t('downloadAllList')}
        </Button>
      </article>
    </div>
  )
}

export default PostedPoolList
