import httpClient from './httpClient'

export const httpPostCustomOrders = async (params: {
  orderList: {
    issueCode: string
    orderType: OrderType
    rate: number
    volume: number
  }[]
  partnerAccountId: number
  settlementType: SettlementType
}) => {
  const { data } = await httpClient.post<ResponseCustomPostOrdersType[]>('/api/v1/custom-orders', {
    ...params,
  })
  return data
}

export const httpGetCustomOrdersRequest = async (params: {
  partnerAccountId: number | ''
  query?: string
  orderType: OrderType
  type?: 'ACCEPT' | 'CANCEL' | 'CHECK' | 'POST' | 'REJECT' | 'UPDATE'
  status?: StatusType
  page: number
  size: number
  settlementType: SettlementType
}) => {
  const { data } = await httpClient.get<CustomOrderRequestType[]>(
    '/api/v1/custom-orders/requests',
    {
      params: {
        ...params,
      },
    }
  )
  return data
}

export const httpPostCustomOrdersRequest = async (
  params: {
    orderId: number
    rate: number
  }[]
) => {
  const { data } = await httpClient.post<ResponseCustomPostRequestOrdersType[]>(
    `/api/v1/custom-orders/requests`,
    params
  )
  return data
}

export const httpPutCustomOrdersRequest = async (
  params: {
    isAccept: boolean
    orderId: number
    requestId: number
  }[]
) => {
  const { data } = await httpClient.put<ResponseCustomPutRequestOrdersType[]>(
    `/api/v1/custom-orders/requests`,
    params
  )
  return data
}
