import { useState } from 'react'

import Form from '@src/components/Form'
import TextField from '@src/components/TextField'
import { httpPostJoin } from '@src/api/httpAPI/httpAuthAPI'
import { useNavigate } from 'react-router'
import Button from '@src/components/Button'
import JoinPopup from './JoinPopup'
import { useRecoilState } from 'recoil'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import TextFieldWithIcon from '../../../components/TextField/TextFieldWithIcon'

const numReg = new RegExp('^[0-9]+$')

const Join = () => {
  const navigate = useNavigate()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [slbAccount, setSlbAccount] = useState<string>('')
  const [ksdAccount, setKsdAccount] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const [confirmationEmailCount, setConfirmationEmailCount] = useState<number>(1)
  const [confirmationEmailList, setConfirmationEmailList] = useState<Map<number, string>>(new Map())

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)

  const handleJoinButton = async () => {
    try {
      if (password !== confirmPassword) {
        setToastModal({ ...toastModal, isOpen: true, innerString: '비밀번호가 일치하지 않습니다.' })
        // alert('비밀번호가 일치하지 않습니다.')
        return
      }
      if (slbAccount.length === 1) {
        setToastModal({
          ...toastModal,
          isOpen: true,
          innerString: 'SLB account는 공란이거나 2자리여야합니다.',
        })
        // alert('비밀번호가 일치하지 않습니다.')
        return
      }
      const allow = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$')
      if (!allow.exec(password)) {
        setErrorModal({
          ...errorModal,
          isOpen: true,
          innerString: '비밀번호는 영어 대소문자, 숫자, 특수문자를 조합한 8자리 이상이여야 합니다.',
        })
        return
      }

      if (ksdAccount.length < 12) {
        setToastModal({ ...toastModal, isOpen: true, innerString: 'KSD 계좌는 12자리입니다.' })
        return
      }

      const response = await httpPostJoin({
        username,
        password,
        name,
        phone,
        slbAccount: slbAccount,
        ksdAccount,
        companyName,
        confirmationEmailList: Array.from(confirmationEmailList.values()).map((email) => ({
          email,
        })),
      })

      const _response = response as any
      switch (response.status) {
        case 200:
          setIsPopupOpen(true)
          break
        case 400:
          if (_response.error === 'company not found') {
            setErrorModal({
              ...errorModal,
              isOpen: true,
              innerString:
                'KSD account 로 등록된 기관정보가 없습니다.\n이메일(help@directional.net)로 기관등록 요청 을 하시기 바랍니다.\n(등록 요청사항 : KSD account/회사명)',
            })
          } else {
            setErrorModal({
              ...errorModal,
              isOpen: true,
              innerString: _response.error,
            })
          }
          break
        default:
          if (response.data.error === 'user already exist') {
            setErrorModal({
              ...errorModal,
              isOpen: true,
              innerString: '이미 등록되었거나 신청중인 이메일입니다.',
            })
          } else {
            setErrorModal({
              ...errorModal,
              isOpen: true,
              innerString: '회원가입에 실패했습니다.',
            })
          }
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <article className="join">
      <Form>
        <img src={require('@src/assets/login_logo.png')} alt="logo" />
        <div className="text-field-wrapper">
          {/* <TextField
            id="email"
            label="EMAIL"
            type="email"
            position="top"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          /> */}
          <TextField
            id="username"
            label="Email"
            type="text"
            position="top"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            position="top"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            id="confirm_password"
            label="Confirm password"
            type="password"
            position="top"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <TextField
            id="name"
            label="User name"
            type="text"
            position="top"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            id="phoneNumber"
            label="Phone number"
            type="text"
            position="top"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            id="company"
            label="Company name"
            type="text"
            position="top"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <TextFieldWithIcon
            id="kds_account"
            label="KSD account(12 digits)"
            type="text"
            position="top"
            value={ksdAccount}
            maxLength={12}
            onChange={(e) => {
              if (e.target.value === '') {
                setKsdAccount(e.target.value)
                return
              }
              if (numReg.exec(e.target.value)) setKsdAccount(e.target.value)
            }}
          />
          <TextField
            type="text"
            id="slb_accouint"
            label="SLB account(2 digits, optional)"
            position="top"
            value={slbAccount}
            maxLength={2}
            onChange={(e) => {
              if (e.target.value === '') {
                setSlbAccount(e.target.value)
                return
              }

              if (numReg.exec(e.target.value)) setSlbAccount(e.target.value)
            }}
          />
          {Array.from({ length: confirmationEmailCount }).map((_, index) => (
            <TextField
              inputStyle={
                {
                  // paddingRight: '50px',
                }
              }
              key={`confirmation_email_${index}`}
              id={`confirmation_email_${index}`}
              label={'Email for receiving SBL confirmation'}
              type="email"
              position="top"
              value={confirmationEmailList.get(index)}
              onChange={(e) => {
                const newMap = new Map(confirmationEmailList)
                newMap.set(index, e.target.value)
                setConfirmationEmailList(newMap)
              }}
            >
              {confirmationEmailCount == index + 1 && confirmationEmailCount < 8 && (
                <span
                  onClick={() =>
                    setConfirmationEmailCount(
                      (prevConfirmationEmailCount) => ++prevConfirmationEmailCount
                    )
                  }
                >
                  +
                </span>
              )}
            </TextField>
          ))}
        </div>
        <div className="button-wrapper">
          <Button color="grey" onClick={() => navigate('/user/login')}>
            Cancel
          </Button>
          <Button color="blue" onClick={() => handleJoinButton()}>
            Submit
          </Button>
        </div>
      </Form>
      <JoinPopup isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} />
      {/* {isPopupOpen && <JoinPopup />} */}
    </article>
  )
}

export default Join
