import { Button, SxProps } from '@mui/material'
import { ReactNode } from 'react'

interface TradeButtonProps {
  children: ReactNode
  color: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  sx?: SxProps
  disabled?: boolean
}

const TradeButton = ({ children, color, onClick, sx, disabled = false }: TradeButtonProps) => {
  const backgorund =
    disabled === true
      ? '#a39ba1 !important'
      : color === 'red'
      ? '#fa4e60 !important'
      : color === 'yellow'
      ? '#f7ae5f !important'
      : color === 'blue'
      ? '#1976d2 !important'
      : color === 'green'
      ? 'rgb(78, 151, 0) !important'
      : ''
  return (
    <Button
      variant="contained"
      onClick={disabled ? null : onClick}
      sx={{
        ...sx,
        fontWeight: 'bold',
        fontSize: '16px',
        height: '40px',
        width: '140px',
        background: backgorund,
        borderRadius: '2px',
        padding: '1px, 6px',
        marginTop: '0px !important',
        boxShadow: 3,
      }}
    >
      {children}
    </Button>
  )
}

export default TradeButton
