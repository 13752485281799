export const OrderTypeText = {
  BORROW: '차입',
  LEND: '대여',
  RECALL: '리콜',
  차입: 'BORROW',
  대여: 'LEND',
  리콜: 'RECALL',
}

export const OrderTypeTextKo = {
  BORROW: '차입',
  LEND: '대여',
  RECALL: '리콜',
  차입: 'BORROW',
  대여: 'LEND',
  리콜: 'RECALL',
}

export const OrderTypeTextEn = {
  BORROW: 'Borrow',
  LEND: 'Lend',
  RECALL: 'Recall',
  차입: 'BORROW',
  대여: 'LEND',
  리콜: 'RECALL',
}
