import { useCallback, useEffect, FunctionComponent } from 'react'
import ReactDOM from 'react-dom'

interface PropsType {
  id?: string
  className?: string
  style?: React.CSSProperties
  close: () => void
  children: React.ReactNode
}

const PopupWrapper: FunctionComponent<PropsType> = (props) => {
  useEffect(() => {
    const handleKeyDownEscape = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Esc':
        case 'Escape':
          event.preventDefault()
          props.close()
      }
    }
    document.body.style.overflow = 'hidden'
    window.addEventListener('keydown', handleKeyDownEscape)
    return () => {
      document.body.style.overflow = 'auto'
      window.removeEventListener('keydown', handleKeyDownEscape)
    }
  }, [props.close])

  const handleClickBackground = useCallback(
    (event) => {
      if (event.currentTarget === event.target) {
        props.close()
      }
    },
    [props.close]
  )

  return ReactDOM.createPortal(
    <div
      id={props.id}
      className={`popup ${props.className ?? ''}`}
      style={props.style}
      onClick={handleClickBackground}
    >
      {props.children}
    </div>,
    document.getElementById('b2b') as HTMLElement
  )
}

export default PopupWrapper
