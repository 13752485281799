import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import langEn from './en.js'
import langKo from './ko.js'

const resource = {
  en: {
    translation: langEn,
  },
  ko: {
    translation: langKo,
  },
}

i18n.use(initReactI18next).init({
  resources: resource,
  // 초기 설정 언어
  lng: 'ko',
  fallbackLng: {
    en: ['en'],
    ko: ['ko'],
    default: ['ko'],
  },
  debug: true,
  defaultNS: 'translation',
  ns: 'translation',
  keySeparator: '.',
  // keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
