import { httpGetAccounts } from '@src/api/httpAPI/httpAccountAPI'
import { httpPostCustomOrders } from '@src/api/httpAPI/httpCustomOrdersAPI'
import Button from '@src/components/Button'
import Checkbox from '@src/components/Checkbox'
import OrderTypeButtons from '@src/components/OrderTypeButtons'
import SelectOption from '@src/components/SelectOption'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'

import { httpGetStock } from '@src/api/httpAPI/httpStockAPI'
import ResultPopup from '@src/components/ResultPopup'
import { formatNumber } from '@src/util/formatNumber'
import useDebounce from '@src/hooks/useDebounce'
import TextField from '@src/components/TextField'
import { httpGetOrderBookListByIssueCode } from '@src/api/httpAPI/httpOrderBookAPI'
import LogoutButton from '@src/components/Button/LogoutButton'
import { VALUECHECK } from '@src/constants/NotiText'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../../api/storageAPI'

const CustomOrdersRequest = () => {
  const [orderType, setOrderType] = useState<OrderType>('BORROW')
  const [orderBookList, setOrderBookList] = useState<OrderBookType[]>([])
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const [accounts, setAccounts] = useState<AccountType[]>([])
  const [settlement, setSettlement] = useState<SettlementType>('TODAY')
  const [selectedAccount, setSelectedAccount] = useState<AccountType>(null)
  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const [isPasteButtonShow, setIsPasteButtonShow] = useState<boolean>(false)
  const [isOpenResultPopup, setIsOpenResultPopup] = useState<boolean>(false)
  const [resultOrdersRequest, setResultOrdersRequest] = useState<ResponseCustomPostOrdersType[]>([])
  const trans = useTranslation()
  const tableRef = useRef<HTMLDivElement>(null)

  const getAccounts = useCallback(async () => {
    const { data } = await httpGetAccounts()
    setAccounts(data)
  }, [])

  const requestCustomOrder = useCallback(async () => {
    try {
      const selectedOrderBookList = orderBookList
        .filter((_, index) => selectedIndex.includes(index))
        .map(({ issueCode, rate, volume }) => ({
          issueCode,
          orderType,
          rate,
          volume,
        }))
      const resultData = await httpPostCustomOrders({
        orderList: selectedOrderBookList,
        partnerAccountId: selectedAccount.accountId,
        settlementType: settlement,
      })

      setIsOpenResultPopup(true)
      setResultOrdersRequest(resultData)
    } catch {
      alert('전송에 실패하였습니다.')
    }
  }, [selectedIndex, orderBookList, selectedAccount, orderType, settlement])

  const getStocks = useCallback(async (issueCodes: string[]) => {
    const stocks = await httpGetStock(issueCodes)
    return stocks
  }, [])

  const handlePasteButton = useCallback(async () => {
    try {
      const clipboard = await navigator.clipboard.readText()
      if (!selectedAccount) throw new Error('상대기관을 선택해주세요')

      const clipboardItems = clipboard.split('\n').filter((v) => v)
      if (!(clipboardItems instanceof Array)) throw new Error('붙여넣기에 실패하였습니다.')

      const issueCodes = clipboardItems
        .map((clipboardItem) => {
          const [issueCode] = clipboardItem.split('\t')
          return issueCode
        })
        .filter((v) => v)

      const stocks = await getStocks(issueCodes)

      const newOrderBookList = clipboardItems.map((clipboardItem) => {
        const items = clipboardItem.split('\t')
        if (items.length !== 4) throw new Error('붙여넣기에 실패하였습니다.')
        const [_issueCode, issueName, _volume, _rate] = items

        const volume = Number(_volume)
        const rate = parseFloat(Number(_rate).toFixed(2))

        if (!volume) throw new Error(trans.t('modal.valueCheck.invalidvalue'))
        if (String(volume).length > 8)
          throw new Error(trans.t('modal.valueCheck.upToEightCharacters'))
        if (Number.isNaN(rate)) throw new Error(trans.t('modal.valueCheck.invalidrate'))
        if (rate >= 1000) throw new Error('요율은 정수 3자리까지 입력해주세요')

        return {
          issueCode: _issueCode,
          issueName,
          volume,
          rate,
          type: orderType,
          previousClosingPrice: stocks.find(({ issueCode }) => issueCode == _issueCode)
            .previousClosingPrice,
        }
      })
      setOrderBookList((prevOrderBookList) => [...prevOrderBookList, ...newOrderBookList])
      // if ([...orderBookList, ...newOrderBookList].length > rowCount) {
      //   setRowCount([...orderBookList, ...newOrderBookList].length)
      // }
      setSelectedIndex([...orderBookList, ...newOrderBookList].map((_, index) => index))
      setIsPasteButtonShow(false)
    } catch (err) {
      alert(err.message)
    } finally {
      setIsPasteButtonShow(false)
      setContextMenuPosition({ x: 0, y: 0 })
    }
  }, [navigator, orderType, selectedAccount, orderBookList])

  const handleWindowClick = useCallback((e) => {
    setIsPasteButtonShow(false)
  }, [])

  useEffect(() => {
    getAccounts()
  }, [])

  useEffect(() => {
    if (isPasteButtonShow) {
      window.addEventListener('click', handleWindowClick)
    } else {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [isPasteButtonShow])

  useEffect(() => {
    setTimeout(() => {
      tableRef.current.scrollTo(0, tableRef.current.scrollHeight)
    }, 100)
  }, [orderBookList.length])

  useEffect(() => {
    setOrderBookList([])
    setSelectedIndex([])
  }, [orderType])

  return (
    <>
      <section className={`custom-orders-request ${orderType}`}>
        <article>
          <header>
            <OrderTypeButtons handleChangeOrderType={setOrderType} />
          </header>
          <div className="bigger-wrapper">
            <div className="action-buttons">
              <Button
                onClick={() => {
                  setOrderBookList([])
                  setSelectedIndex([])
                }}
                color="red"
              >
                리셋
              </Button>
              <Button
                color={selectedIndex.length ? 'green' : 'grey'}
                onClick={() => {
                  if (selectedIndex.length) requestCustomOrder()
                }}
              >
                전송
              </Button>
            </div>
            <div className="options">
              <div></div>
              {/* <Button color="grey" radius onClick={() => setRowCount((rowCount) => ++rowCount)}>
              + 행추가
            </Button> */}
              <div className="select-options">
                <SelectOption
                  value={settlement}
                  onChange={(e) => {
                    setSettlement(e.target.value as SettlementType)
                  }}
                  label="결제일"
                  options={[
                    {
                      label: 'T',
                      value: 'TODAY',
                    },
                    {
                      label: 'T1',
                      value: 'NEXT_DAY',
                    },
                  ]}
                />
                <SelectOption
                  label="상대기관"
                  defaultValue=""
                  onChange={(e) => {
                    const account = accounts.find(
                      ({ accountId }) => accountId == Number(e.target.value)
                    )
                    setSelectedAccount(account ? account : null)
                  }}
                  options={[
                    { label: '선택해주세요', value: '', isHidden: true },
                    ...accounts.map(({ companyName, accountId }) => ({
                      label: `${companyName}`,
                      value: accountId,
                    })),
                  ]}
                />
              </div>
            </div>
            <div
              className="fixed-table"
              ref={tableRef}
              onContextMenu={(e) => {
                e.preventDefault()
                setContextMenuPosition({ x: e.pageX, y: e.pageY })
                setIsPasteButtonShow(true)
              }}
            >
              <table>
                <thead>
                  <th>
                    <Checkbox
                      checked={selectedIndex.length == orderBookList.length}
                      onClick={() => {
                        if (selectedIndex.length == orderBookList.length) {
                          setSelectedIndex([])
                        } else {
                          setSelectedIndex(orderBookList.map((_, index) => index))
                        }
                      }}
                    />
                  </th>
                  <th></th>
                  <th>{trans.t('issueCode')}</th>
                  <th>{trans.t('issueName')}</th>
                  <th>{trans.t('borrowListTable.volume')}</th>
                  <th>{trans.t('borrowListTable.rate')}</th>
                  <th>{trans.t('partnerAsset')}</th>
                  <th>금액(원)</th>
                  <th></th>
                </thead>
                <tbody>
                  {orderBookList.map((orderBook, index) => (
                    <TableRow
                      key={`${orderBook.issueCode}-${index}`}
                      index={index}
                      orderBook={orderBook}
                      setOrderBookList={setOrderBookList}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                      selectedAccount={selectedAccount}
                    />
                  ))}
                  <TableRow
                    index={orderBookList.length}
                    orderBook={{
                      issueCode: '',
                      issueName: '',
                      volume: null,
                      previousClosingPrice: 0,
                      rate: null,
                      type: orderType,
                    }}
                    setOrderBookList={setOrderBookList}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    isNew
                    isExample={orderBookList.length == 0}
                    selectedAccount={selectedAccount}
                  />
                </tbody>
              </table>
            </div>
          </div>
          <footer>
            <ul>
              <li>종목수 : {orderBookList.length}</li>
              <li>수량합계 : {orderBookList.reduce((acc, cur) => acc + cur.volume, 0)}</li>
              <li>
                금액합계 :{' '}
                {formatNumber(
                  orderBookList.reduce(
                    (acc, cur) => acc + cur.previousClosingPrice * cur.volume * 1,
                    0
                  )
                )}
                원
              </li>
            </ul>
          </footer>
        </article>
        {isPasteButtonShow && (
          <Button
            color="grey"
            style={{
              width: '70px',
              height: '30px',
              position: 'absolute',
              top: `${contextMenuPosition.y}px`,
              left: `${contextMenuPosition.x}px`,
            }}
            onClick={(e) => {
              e.stopPropagation()
              handlePasteButton()
            }}
          >
            붙여넣기
          </Button>
        )}
      </section>
      {isOpenResultPopup && (
        <ResultPopup
          close={() => {
            setIsOpenResultPopup(false)
            setResultOrdersRequest([])
          }}
          title="신청이 완료되었습니다."
          result={resultOrdersRequest}
        />
      )}
    </>
  )
}

interface TableRowPropsType {
  index: number
  orderBook: OrderBookType
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  setOrderBookList: React.Dispatch<React.SetStateAction<OrderBookType[]>>
  isNew?: boolean
  isExample?: boolean
  selectedAccount: AccountType
}

const TableRow: FunctionComponent<TableRowPropsType> = ({
  orderBook,
  selectedIndex,
  setSelectedIndex,
  setOrderBookList,
  index,
  isNew,
  isExample,
  selectedAccount,
}) => {
  const [volume, setVolume] = useState<number>(orderBook.volume)
  const [rate, setRate] = useState<number | string>(orderBook.rate?.toFixed(2))
  const [issueCode, setIssueCode] = useState<string>(orderBook.issueCode)
  const [issueName, setIssueName] = useState<string>(orderBook.issueName)
  const debouncedVolume = useDebounce(volume, 1000)
  const debouncedRate = useDebounce(rate, 1000)
  const mounted = useRef(false)

  const trans = useTranslation()

  const changeTableInput = useCallback(
    (data: Partial<OrderBookType>) => {
      setOrderBookList((orderBookList) => [
        ...orderBookList.slice(0, index),
        {
          ...orderBook,
          ...data,
        },
        ...orderBookList.slice(index + 1),
      ])
    },
    [setOrderBookList, orderBook, index]
  )

  useEffect(() => {
    if (!orderBook.issueCode) return
    if (String(debouncedVolume).length > 8) {
      alert(trans.t('modal.valueCheck.upToEightCharacters'))
      return
    }
    // if (!Number.isInteger(debouncedVolume) && debouncedVolume !== 0) {
    //   alert('정수만 입력해주세요')
    //   return
    // }
    // if (debouncedVolume > orderBook.availableVolume) {
    //   alert('신청가능한 수량이 아닙니다.')
    //   setVolume(0)
    //   return
    // }
    changeTableInput({ volume: debouncedVolume })
  }, [debouncedVolume])

  useEffect(() => {
    if (!orderBook.issueCode || debouncedRate == null) return
    // if (Number(debouncedRate) >= 1000) {
    //   alert('요율은 3자리까지 입력이 가능합니다.')
    //   setRate(orderBook.rate)
    //   return
    // }

    const fixedRate = Number(rate).toFixed(2)

    httpGetOrderBookListByIssueCode({
      issueCode: orderBook.issueCode,
      type: orderBook.type,
      rate: parseFloat(fixedRate),
    }).then((orderBook) => {
      changeTableInput({ ...orderBook, rate: parseFloat(fixedRate) })
      setRate(fixedRate)
    })
  }, [debouncedRate])

  useEffect(() => {
    if (!orderBook.issueCode) return
    changeTableInput({ issueName })
  }, [issueName])

  useEffect(() => {
    if (issueCode.length !== 6 || !mounted.current) return
    if (!selectedAccount) {
      alert('상대기관을 선택해주세요')
      setIssueCode('')
      return
    }
    httpGetOrderBookListByIssueCode({
      issueCode,
      type: orderBook.type,
    })
      .then((stock) => {
        changeTableInput({
          issueCode: stock.issueCode,
          issueName: stock.issueName,
          availableVolume: stock.availableVolume,
          previousClosingPrice: stock.previousClosingPrice,
        })
        if (isNew) {
          setSelectedIndex([...selectedIndex, index])
          setIssueCode('')
        }
      })
      .catch(() => {
        if (isExample) {
          setIssueCode('')
          setIssueName(trans.t('noIssueCode'))
          return
        }
        changeTableInput({
          issueCode: '',
          issueName: trans.t('noIssueCode'),
          volume: 0,
          previousClosingPrice: 0,
          rate: 0,
          availableVolume: 0,
          type: orderBook.type,
        })
        setSelectedIndex(selectedIndex.filter((_index) => _index !== index))
      })
  }, [issueCode])

  useEffect(() => {
    if (!mounted.current) mounted.current = true
  }, [])

  return (
    <tr
      key={index}
      onClick={() => {
        if (isNew || isExample || !orderBook.issueCode) return
        const checked = selectedIndex.includes(index)
        if (!checked) {
          setSelectedIndex((selectedIndex) => [...selectedIndex, index])
        } else {
          setSelectedIndex((selectedIndex) => selectedIndex.filter((_index) => _index !== index))
        }
      }}
    >
      <td>
        {isExample ? (
          '<입력예시>'
        ) : isNew || !orderBook.issueCode ? null : (
          <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
        )}
      </td>
      {isNew ? (
        <td />
      ) : (
        <td
          style={{
            cursor: 'pointer',
            color: '#c74311',
            textDecoration: 'underline',
          }}
          onClick={(e) => {
            e.stopPropagation()
            setOrderBookList((orderBookList) => [
              ...orderBookList.slice(0, index),
              ...orderBookList.slice(index + 1),
            ])
            setSelectedIndex(selectedIndex.filter((_index) => index !== _index))
          }}
        >
          삭제
        </td>
      )}

      <td>
        <TextField
          type="text"
          value={issueCode}
          pattern="[0-9]*"
          placeholder={isExample ? '005930' : ''}
          maxLength={6}
          onChange={(e) => {
            if (e.target.validity.valid) {
              setIssueCode(e.target.value)
            }
          }}
        />
      </td>
      <td className="left">
        <TextField
          type="text"
          value={issueName}
          placeholder={
            localStorageAPI.getItem('lang') === 'ko' && isExample ? '삼성전자(공란가능)' : ''
          }
          onChange={(e) => {
            setIssueName(e.target.value)
          }}
        />
      </td>
      <td className="right">
        <TextField
          type="text"
          value={volume}
          pattern="[0-9]*"
          maxLength={8}
          disabled={!orderBook.issueCode}
          min={0}
          onChange={(e) => {
            if (e.target.validity.valid) {
              setVolume(Number(e.target.value))
            }
          }}
        />
      </td>
      <td className="right">
        <TextField
          type="text"
          value={rate}
          disabled={!orderBook.issueCode}
          min={0}
          pattern="^\d*(\.\d{0,2})?$"
          onChange={(e) => {
            if (isFinite(Number(e.target.value))) {
              setRate(e.target.value)
            }
          }}
        />
      </td>
      <td>
        {selectedAccount && `${selectedAccount.companyName} (${selectedAccount.companyCode})`}
      </td>
      <td className="right">
        {formatNumber(orderBook.previousClosingPrice * orderBook.volume * 1)}
      </td>
      <td></td>
    </tr>
  )
}

export default CustomOrdersRequest
