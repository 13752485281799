import styled from '@emotion/styled'
import useVolumeFormat from '@src/hooks/useVolumeFormat'
import { isRateInputCanBeOnChange } from '@src/util/input-limit/isRateInputLimit3digitsCheck'
import { rateCheck } from '@src/util/rateCheck'
import { useEffect, useRef, useState } from 'react'

interface PropsType {
  outputPool: any
  actionRecord: any
  inputTmpKey: any
  defaultValue: number
}

const Input = styled.input`
  text-align: right !important;
  width: 100%;
  box-shadow: inset 0 1px 3px 0 rgb(171 171 171 / 50%);
  border: none;
`

export const PostRateInput = ({
  outputPool,
  actionRecord,
  inputTmpKey,
  defaultValue,
}: PropsType) => {
  const [rate, setRate] = useState('0.00')
  useEffect(() => {
    inputRef.current.addEventListener('wheel', function (e) {
      e.preventDefault()
    })
    // add custom scroll code if you want
  }, [])

  const volumeFormat = useVolumeFormat()

  const inputRef = useRef<HTMLInputElement>()
  return (
    <Input
      maxLength={9}
      // value={actionRecord[inputTmpKey] ? actionRecord[inputTmpKey].value : 0}
      value={rate}
      ref={inputRef}
      type="text"
      onClick={(e) => {
        e.stopPropagation()
      }}
      onKeyDown={(e: any) => {
        const start = e.target.selectionStart
        const end = e.target.selectionEnd
        const isLengthOne = start === end
        if (e.key === 'Delete' && e.target.value[start] === ',' && isLengthOne) {
          e.target.setSelectionRange(start + 1, start + 1)
          e.preventDefault()
          return
        }
        if (e.key === 'Enter') {
          if (e.target.value === '') {
            return
          }
          if (e.target.value.indexOf('.') === -1) {
            setRate(e.target.value + '.00')
            return
          }
          const splited = e.target.value.split('.')
          if (splited[1].length === 0) {
            setRate(e.target.value + '00')
          }
          if (splited[1].length === 1) {
            setRate(e.target.value + '0')
          }
        }
      }}
      onBlur={(e) => {
        if (e.target.value === '') {
          return
        }
        if (e.target.value.indexOf('.') === -1) {
          setRate(e.target.value + '.00')
          return
        }
        const splited = e.target.value.split('.')
        if (splited[1].length === 0) {
          setRate(e.target.value + '00')
        }
        if (splited[1].length === 1) {
          setRate(e.target.value + '0')
        }
      }}
      onChange={(e) => {
        // if (!isRateInputCanBeOnChange(e)) {
        //   return
        // }
        actionRecord[inputTmpKey] = {
          ...actionRecord[inputTmpKey],
          rate: parseFloat(e.target.value.replaceAll(',', '')),
        }
        if (e.target.value === '') {
          setRate('')
          return
        }
        const newValue = rateCheck(e, volumeFormat)
        if (!newValue) {
          return
        }
        let prevCommaCount = rate.split(',').length - 1
        prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
        const start = e.target.selectionStart
        let nextCommaCount = newValue.split(',').length - 1
        nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
        const commaDiff = nextCommaCount - prevCommaCount
        e.target.value = newValue
        e.target.setSelectionRange(start + commaDiff, start + commaDiff)
        if (e.target.value.length < 2) {
          e.target.setSelectionRange(1, 1)
        }
        setRate(newValue)
        // e.target.value
      }}
      onFocus={(e) => {
        if (e.target.value === '0.00') {
          e.target.value = outputPool.rate.toFixed(2)
          actionRecord[inputTmpKey] = {
            ...actionRecord[inputTmpKey],
            rate: parseFloat(e.target.value.replaceAll(',', '')),
          }
          const newValue = rateCheck(e, volumeFormat)
          if (!newValue) {
            return
          }
          setRate(newValue)
          e.target.value = newValue
        }
        e.target.select()
      }}
    />
  )
}
