import { succeedModalState } from '@src/stores/succeedModalState'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

export const SucceedModal = () => {
  const [succeedModal, setSucceedModal] = useRecoilState(succeedModalState)
  const checkRef = useRef<HTMLButtonElement>()

  const trans = useTranslation('translation', { keyPrefix: 'modal' })

  useEffect(() => {
    checkRef.current.focus()
  }, [succeedModal])
  return (
    <div className={succeedModal.isOpen ? 'confirm-modal-wrap' : 'display-none'}>
      <div>{succeedModal.innerString}</div>
      <div className={'confirm-modal-btn'}>
        {/* <button ref={confirmRef} onClick={succeedModal.confirmFunction}>
          확인
        </button> */}
        <button ref={checkRef} onClick={() => setSucceedModal({ ...succeedModal, isOpen: false })}>
          {trans.t('confirm')}
        </button>
      </div>
    </div>
  )
}
