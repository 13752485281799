import httpClient from './httpClient'

export const httpGetOrderBookList = async (params: {
  type: OrderType
  page: number
  size: number
  query?: string
}) => {
  const { data } = await httpClient.get<OrderBookType[]>('/api/v1/order-book', {
    params: {
      ...params,
    },
  })
  return data
}
export const httpGetOrderBookListByIssueCode = async (params: {
  issueCode: string
  type: OrderType
  rate?: number
}) => {
  const { data } = await httpClient.get<ResponseOrderBookType>(
    `/api/v1/order-book/${params.issueCode}`,
    {
      params: {
        ...params,
      },
    }
  )

  return data
}

export const httpGetOrderBookStatistics = async (params: { type: OrderType }) => {
  const { data } = await httpClient.get<OrderBookStatisticsType>('/api/v1/order-book/statistics', {
    params: {
      ...params,
    },
  })

  return data
}

// export const httpDeletetOrder = async (issueCode: string, params: RequestGetOrderBookType) =>
//   await httpClient.delete<ResponseGetOrderBookType>(`/api/v1/order-book/${issueCode}`, {
//     params,
//   })
