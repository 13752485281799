export const PoolStatusText = {
  RECEIVED: '신청',
  MATCHED: '전부확정',
  PARTIALLY_MATCHED: '일부확정',
  CANCELED: '취소',
  신청: 'RECEIVED',
  전부확정: 'MATCHED',
  일부확정: 'PARTIALLY_MATCHED',
  취소: 'CANCELED',
}

export const PoolStatusTextKo = {
  RECEIVED: '신청',
  MATCHED: '전부확정',
  PARTIALLY_MATCHED: '일부확정',
  CANCELED: '취소',
  신청: 'RECEIVED',
  전부확정: 'MATCHED',
  일부확정: 'PARTIALLY_MATCHED',
  취소: 'CANCELED',
}

export const PoolStatusTextEn = {
  RECEIVED: 'Requested',
  MATCHED: 'Confirmed',
  PARTIALLY_MATCHED: 'Partially confirmed',
  CANCELED: 'Canceled',
  신청: 'RECEIVED',
  전부확정: 'MATCHED',
  일부확정: 'PARTIALLY_MATCHED',
  취소: 'CANCELED',
}
