export const csvHeader = [
  '구분',
  '결제유형',
  '거래유형',
  '상태',
  '신청일시',
  '상대기관',
  '종목코드',
  '종목명',
  '수량(주)',
  '요율(%)',
  '변경수량(주)',
  '변경요율(%)',
  '스왑',
]

export const csvHeaderEn = [
  'B/L',
  'Value date',
  'Settlement type',
  'Status',
  'Input time',
  'Counterparty',
  'Ticker',
  'Security',
  'Quantity(shs)',
  'Rate(%)',
  'Updated qty',
  'Updated rate',
  'Swap',
]

export const csvKey = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  'RESP_STAT',
  '0requestDateTime',
  'partnerAccount.companyName',
  '0stock.issueCode',
  'stock.issueName',
  'currentVolume',
  '#currentRate',
  '-requestedVolume',
  '#requestedRate',
  '?isSwap',
]

export const csvKeyEn = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  'RESP_STAT',
  '0requestDateTime',
  'partnerAccount.companyEnglishName',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'currentVolume',
  '#currentRate',
  '-requestedVolume',
  '#requestedRate',
  '?isSwap',
]

export const csvHeaderNotRequester = [
  '구분',
  '결제유형',
  '거래유형',
  '상태',
  '신청일시',
  '상대기관',
  '종목코드',
  '종목명',
  '수량(주)',
  '요율(%)',
  '변경수량(주)',
  '변경요율(%)',
  '스왑',
]

export const csvHeaderNotRequesterEn = [
  'B/L',
  'Value date',
  'Settlement type',
  'Status',
  'Input time',
  'Counterparty',
  'Ticker',
  'Security',
  'Quantity(shs)',
  'Rate(%)',
  'Qty update',
  'Rate update',
  'Swap',
]

export const csvKeyNotRequester = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  'RESP_STAT',
  '0requestDateTime',
  'partnerAccount.companyName',
  '0stock.issueCode',
  'stock.issueName',
  'currentVolume',
  '#currentRate',
  '-requestedVolume',
  '#requestedRate',
  '?isSwap',
]

export const csvKeyNotRequesterEn = [
  '!orderType',
  '!settlementType',
  '!transactionType',
  'RESP_STAT',
  '0requestDateTime',
  'partnerAccount.companyEnglishName',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'currentVolume',
  '#currentRate',
  '-requestedVolume',
  '#requestedRate',
  '?isSwap',
]

export const csvDict = {
  LEND: '대여',
  BORROW: '차입',
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  RECEIVED: '접수',
  MATCHED: '확정',
  CANCELED: '취소',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  NONE: '없음',
}

export const csvDictEn = {
  LEND: 'Lend',
  BORROW: 'Borrow',
  CUSTOM: 'Customized',
  DESIGNATED: 'Arranged',
  COMPETITIVE: 'Bid offer',
  NONE: 'No selection',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  RECEIVED: '접수',
  MATCHED: '확정',
  CANCELED: '취소',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
}
