import TextField from '@src/components/TextField'
import { userState } from '@src/stores/userStore'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import TradeButton from '@src/components/Button/TradeButton'
import { httpPutUser } from '@src/api/httpAPI/httpUserAPI'
import { SAVE } from '@src/constants/NotiText'
import MyPagePasswordPopup from './MyPagePasswordPopup'
import { useTranslation } from 'react-i18next'

const MyPage2 = () => {
  const [user, setUser] = useRecoilState(userState)
  const [name, setName] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [phoneCount, setPhoneCount] = useState<number>(1)
  const [phoneList, setPhoneList] = useState<Map<number, string>>(new Map())
  const [passwordPopupOpen, setPasswordPopupOpen] = useState<boolean>(false)

  const [confirmationEmailCount, setConfirmationEmailCount] = useState<number>(0)
  const [confirmationEmailList, setConfirmationEmailList] = useState<Map<number, string>>(new Map())
  const [accountList, setAccountList] = useState<UserType['accountList']>([])

  const trans = useTranslation()

  const saveUser = useCallback(async () => {
    try {
      const { data } = await httpPutUser({
        name,
        username,
        phoneList: Array.from(phoneList, ([_, phone]) => ({ phone })),
        accountList,
        confirmationEmailList: Array.from(confirmationEmailList, ([_, email]) => ({ email })),
      })

      setUser(data)
      alert(SAVE.succeed)
    } catch {
      alert(SAVE.fail)
    }
  }, [name, username, phoneList, confirmationEmailList, accountList])

  useEffect(() => {
    if (!user) return
    setName(user.name)
    setUsername(user.username)
    setPhoneList(
      user.phoneList.reduce((map, { phone }, index) => {
        map.set(index, phone)
        return map
      }, new Map())
    )
    setConfirmationEmailList(
      user.confirmationEmailList.reduce((map, { email }, index) => {
        map.set(index, email)
        return map
      }, new Map())
    )
    setConfirmationEmailCount(
      user.confirmationEmailList.length == 0 ? 1 : user.confirmationEmailList.length
    )

    setAccountList(user.accountList)
    // getUserGroup()
  }, [user])

  return (
    <div className="mypage mypage2">
      <article>
        <div className="button-wrap">
          {/*<TradeButton color="red" onClick={() => navigate(-1)}>*/}
          {/*  뒤로가기*/}
          {/*</TradeButton>*/}
          <TradeButton color="green" onClick={() => saveUser()}>
            저장
          </TradeButton>
        </div>
        <div className="user-info">
          <h3>내정보</h3>
          <div className="account-info">
            <TextField
              disabled={true}
              id="name"
              label={trans.t('name')}
              type="text"
              position="top"
              placeholder={name}
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              disabled={true}
              id="username"
              label="이메일"
              type="text"
              position="top"
              placeholder={username}
              defaultValue={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div className="set-pw" onClick={() => setPasswordPopupOpen(true)}>
              비밀번호 재설정
            </div>
            <br />
            <hr />
            <br />
            <TextField
              key={`phone0`}
              id={`phone0`}
              label={'연락처1'}
              type="text"
              position="top"
              defaultValue={phoneList.get(0)}
              onChange={(e) => {
                const newMap = new Map(phoneList)
                newMap.set(0, e.target.value)
                setPhoneList(newMap)
              }}
            ></TextField>
            <TextField
              key={`phone1`}
              id={`phone1`}
              label={'연락처2'}
              type="text"
              position="top"
              defaultValue={phoneList.get(1)}
              onChange={(e) => {
                const newMap = new Map(phoneList)
                newMap.set(1, e.target.value)
                setPhoneList(newMap)
              }}
            ></TextField>
            {/* <TextField
              id="name"
              label="회사명"
              type="text"
              position="top"
              // value={username}
              // onChange={(e) => setUsername(e.target.value)}
            /> */}

            {Array.from({ length: confirmationEmailCount == 0 ? 1 : confirmationEmailCount }).map(
              (_, index) => (
                <TextField
                  // style={{
                  //   marginTop: index == 0 ? '10px' : '0',
                  //   marginBottom: 0,
                  // }}
                  key={`confirmation_email${index}`}
                  id={`confirmation_email${index}`}
                  label={index == 0 ? '컨퍼메이션 이메일' : ''}
                  type="email"
                  position="top"
                  defaultValue={confirmationEmailList.get(index)}
                  onChange={(e) => {
                    const newMap = new Map(confirmationEmailList)
                    newMap.set(index, e.target.value)
                    setConfirmationEmailList(newMap)
                  }}
                >
                  <>
                    {index > 0 && (
                      <span
                        className="del"
                        onClick={() => {
                          const newMap = new Map(confirmationEmailList)
                          newMap.delete(index)
                          setConfirmationEmailList(newMap)
                          setConfirmationEmailCount(
                            (prevConfirmationEmailCount) => --prevConfirmationEmailCount
                          )
                        }}
                      >
                        삭제
                      </span>
                    )}
                    {confirmationEmailCount == index + 1 && confirmationEmailCount < 5 && (
                      <span
                        className="add"
                        onClick={() =>
                          setConfirmationEmailCount(
                            (prevConfirmationEmailCount) => ++prevConfirmationEmailCount
                          )
                        }
                      >
                        추가
                      </span>
                    )}
                  </>
                </TextField>
              )
            )}
          </div>
        </div>
      </article>
      {passwordPopupOpen && <MyPagePasswordPopup close={() => setPasswordPopupOpen(false)} />}
    </div>
  )
}

export default MyPage2
