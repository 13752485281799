export const rateCheck = (e, volumeFormat) => {
  const _value = e.target.value.replaceAll(',', '')
  let dotIndex = _value.indexOf('.')
  const splited = e.target.value.split('.')
  if (splited.length > 2) {
    return false
  }
  if (splited[1] && splited[1].includes(',')) {
    return false
  }
  if (splited[1]?.length > 2) {
    return false
  }
  let beforeDecimal = splited[0]
  if (beforeDecimal.length > 1 && beforeDecimal[0] === '0') {
    beforeDecimal = beforeDecimal.substring(1, beforeDecimal.length)
  }
  const afterDecimal = splited[1] === undefined ? '' : splited[1]
  if (isNaN(afterDecimal + 1)) {
    return false
  }
  const currentValue = beforeDecimal.replaceAll(',', '')
  const newBeforeDecimal = volumeFormat(currentValue, ',')
  const commaCount = newBeforeDecimal.split(',').length - 1
  if (dotIndex !== -1) {
    dotIndex = dotIndex + commaCount
  }
  if (dotIndex === -1) {
    const newValue = newBeforeDecimal
    return newValue
  } else {
    const _newValue = newBeforeDecimal + afterDecimal
    const newValue =
      _newValue.substring(0, dotIndex) + '.' + _newValue.substring(dotIndex, _newValue.length)
    return newValue
  }
}

export const rateCheckWithValue = (rateValue, volumeFormat) => {
  const _value = rateValue.replaceAll(',', '')
  let dotIndex = _value.indexOf('.')
  const splited = rateValue.split('.')
  if (splited.length > 2) {
    return false
  }
  if (splited[1] && splited[1].includes(',')) {
    return false
  }
  if (splited[1]?.length > 2) {
    return false
  }
  let beforeDecimal = splited[0]
  if (beforeDecimal.length > 1 && beforeDecimal[0] === '0') {
    beforeDecimal = beforeDecimal.substring(1, beforeDecimal.length)
  }
  const afterDecimal = splited[1] === undefined ? '' : splited[1]
  if (isNaN(afterDecimal + 1)) {
    return false
  }
  const currentValue = beforeDecimal.replaceAll(',', '')
  const newBeforeDecimal = volumeFormat(currentValue, ',')
  const commaCount = newBeforeDecimal.split(',').length - 1
  if (dotIndex !== -1) {
    dotIndex = dotIndex + commaCount
  }
  if (dotIndex === -1) {
    const newValue = newBeforeDecimal
    return newValue
  } else {
    const _newValue = newBeforeDecimal + afterDecimal
    const newValue =
      _newValue.substring(0, dotIndex) + '.' + _newValue.substring(dotIndex, _newValue.length)
    return newValue
  }
}
