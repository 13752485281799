import { Navigate, Route, Routes } from 'react-router'
import { localStorageAPI, sessionStorageAPI } from './api/storageAPI'
import SideBar from './components/SideBar'
import Login from './pages/User/Login'
import Join from './pages/User/Join'
import User from './pages/User/User'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import OrdersList from './pages/Orders/OrdersList'
import OrdersRequest from './pages/Orders/OrdersRequest'
import Account from './pages/MyPage'
import Mypage4 from './pages/MyPage/UserGroup'
import Mypage3 from './pages/MyPage/UserAccount'
import Mypage2 from './pages/MyPage/UserInfo'
import MessageSend from './pages/Message/MessageSend'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userState } from './stores/userStore'
import { httpGetUser } from './api/httpAPI/httpUserAPI'
import OrdersUpload from './pages/Orders/OrdersUpload'
import DesignatedOrdersRequest from './pages/DesignatedOrders/DesignatedOrdersRequest'
import DesignatedOrdersChange from './pages/DesignatedOrders/DesignatedOrdersChange'
import DesignatedOrdersAgree from './pages/DesignatedOrders/DesignatedOrdersAgree'
import CustomOrdersRequest from './pages/CustomOrders/CustomOrdersRequest'
import CustomOrdersChange from './pages/CustomOrders/CustomOrdersChange'
import CustomOrdersAgree from './pages/CustomOrders/CustomOrdersAgree'
import BorrowSearch from './pages/BorrowSearch'
import SearchAgree from './pages/Search/SearchAgree'
import SearchReturn from './pages/Search/SearchReturn'
import SearchBalance from './pages/Search/SearchBalance'
import MessageList from './pages/Message/MessageList'
// import Confirm from './pages/Confirm'
import MessageDetail from './pages/Message/MessageDetail/MessageDetail'
import useFCM from './hooks/useFCM'
import { getFcmToken } from './util/fcm'
import audio from '@src/assets/alarm.mp3'
import LendSearch from './pages/LendSearch/'
import LendConfirm from './pages/LendConfirm'
import LendPoolRegister from './pages/LendPoolRegister'
import LendPoolRegisterList from './pages/LendPoolRegisterList'
import BorrowPoolMatched from './pages/LendPoolMatched'
import LendList from './pages/LendList'
import LendOrderResponse from './pages/LendOrderResponse'
import LendOrderList from './pages/LendOrderList'
import BorrowPoolList from './pages/BorrowPoolList'
import BorrowRequestOrder from './pages/BorrowRequestOrder'
import BorrowRequestList from './pages/BorrowRequestList'
import BorrowOrderResponse from './pages/BorrowOrderResponse'
import BorrowOrderList from './pages/BorrowOrderList'
import Topbar from './components/Topbar/Topbar'
import { topNotiCountsState } from '@src/stores/topNotiCountsState'
import { httpGetTopNotiCounts } from '@src/api/httpAPI/httpNotiCountApi'
import LendOrderListcancel from './pages/LendOrderListcancel'
import LendSearchResponse from './pages/LendSearchResponse'
import BorrowSearchResponse from './pages/BorrowSearchResponse'
import BorrowOrderListCancel from './pages/BorrowOrderListCancel'
import { ConfirmModal } from './components/Modal/ConfirmModal'
import { ErrorModal } from './components/Modal/ErrorModal'
import { SucceedModal } from './components/Modal/SucceedModal'
import { ToastModal } from './components/Modal/ToastModal'
import { succeedModalState } from './stores/succeedModalState'
import { businessDaysCalenderState } from './stores/calenderState'
import { httpGetCalender } from './api/httpAPI/httpCalender'
import Home from './pages/Home/Home'
import { topBlinkState } from './stores/topBlinkState'
import { getTopBlink } from './util/getTopBlink'
import UserInfo from './pages/MyPage/UserInfo'
import UserAccount from './pages/MyPage/UserAccount'
import UserGroup from './pages/MyPage/UserGroup'
import { langState } from './stores/langState'

const Router = () => {
  const location = useLocation()
  const token = sessionStorageAPI.getItem('token')

  const [isAuth, setIsAuth] = useState<boolean>(!!token)

  // const { receiveMessage } = useFCM()

  const [lang, setLang] = useRecoilState(langState)

  useEffect(() => {
    const stylePath = lang === 'ko' ? '/ko.css' : '/en.css'
    // : 'https://test.directional.net/main2.css'

    var head = document.head
    var link = document.createElement('link')

    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = stylePath

    head.appendChild(link)

    // var ko = document.querySelectorAll("a[href='/ko.css']")
    // var en = document.querySelectorAll("a[href='/en.css']")

    // if (ko.length === 2) {
    //   head.removeChild(ko[1])
    // }
    // if (en.length === 2) {
    //   head.removeChild(en[1])
    // }

    // if (stylePath === '/ko.css' && en.length === 1) {
    //   head.removeChild(en[0])
    // }

    // if (stylePath === '/en.css' && ko.length === 1) {
    //   head.removeChild(ko[0])
    // }

    // if (stylePath === '/ko.css') {
    //   head.removeChild(ko)
    // } else {
    //   head.removeChild(en)
    // }

    return () => {
      head.removeChild(link)
    }
  }, [lang])

  const [succeedModal, setSucceedModal] = useRecoilState(succeedModalState)

  const { receiveMessage } = useFCM()
  // const getUser = useCallback(async () => {
  //   const { data } = await httpGetUser()
  //   setUser(data)
  // }, [])

  // const getNotificationCount = useCallback(async () => {
  //   const notiCounts = await httpGetTopNotiCounts()
  //   setTopNotiCounts(notiCounts)
  // }, [])

  useEffect(() => {
    const token = sessionStorageAPI.getItem('token')
    setIsAuth(!!token)
  }, [location.pathname])

  // const handleReceiveMessage = async (e: any) => {
  //   // console.log('call receiveMessage')
  //   getNotificationCount()
  // }

  return isAuth ? <RouterAuth /> : <RouterGuest />
}

export const RouterAuth = () => {
  const [succeedModal, setSucceedModal] = useRecoilState(succeedModalState)
  const location = useLocation()
  const setBusinessDaysCalender = useSetRecoilState(businessDaysCalenderState)
  const [topNotiCounts, setTopNotiCounts] = useRecoilState(topNotiCountsState)
  const [topBlink, setTopBlink] = useRecoilState(topBlinkState)
  const { receiveMessage } = useFCM()

  const setUser = useSetRecoilState(userState)

  const getUser = useCallback(async () => {
    const { data } = await httpGetUser()
    setUser(data)
  }, [])
  // const getNotificationCount = useCallback(async () => {
  //   const notiCounts = await httpGetTopNotiCounts()
  //   setTopNotiCounts(notiCounts)
  // }, [])

  const fetchBusinessDaysCalender = async () => {
    const days = await httpGetCalender()
    window.sessionStorage.setItem('after', days.previous)
    window.sessionStorage.setItem('before', days.today)
    setBusinessDaysCalender(days)
  }

  useEffect(() => {
    const token = sessionStorageAPI.getItem('token')
    if (token) {
      getUser()
      getFcmToken()
      fetchBusinessDaysCalender()
    }
  }, [])

  useEffect(() => {
    const token = sessionStorageAPI.getItem('token')
    if (token) {
      // receiveMessage(topNotiCounts, topBlink)
    }
  }, [topNotiCounts, topBlink])

  // useEffect(() => {
  //   window.addEventListener('visibilitychange', handleReceiveMessage)
  //   return () => removeEventListener('visibilitychange', handleReceiveMessage)
  // }, [topNotiCounts, topBlink])

  // useEffect(() => {
  //   if (location.pathname.split('/').length === 5) {
  //     return
  //   }
  //   const token = sessionStorageAPI.getItem('token')
  //   if (token) {
  //     getNotificationCount()
  //   }
  // }, [location.pathname])

  return (
    <div className="container">
      <audio className="fcm-alarm" src={audio}></audio>
      {/* <iframe style={{ display: 'none' }} id="alarm-play" src={audio} /> */}
      <SideBar />
      <div className="container-second">
        {/* <button
          onClick={() => {
            console.log('???')
            const ifameElem = document.getElementById('alarm-play') as HTMLIFrameElement
            console.log('ifameElem', ifameElem)
            console.log('ifameElem.contentWindow', ifameElem.contentWindow)
            ifameElem.contentWindow.postMessage(
              '{"event":"command","func":"playVideo","args":""}',
              '*'
            )
            ifameElem.contentWindow.document.getElementsByTagName('video')[0].play()
          }}
        >
          iframeplay
        </button> */}
        <Topbar />
        <div className="container-main">
          <ConfirmModal />
          <SucceedModal />
          <ErrorModal />
          <ToastModal />
          <Routes>
            <Route path="home" element={<Home />} />
            <Route path="mypage" element={<Account />} />
            <Route path="user-info" element={<UserInfo />} />
            <Route path="user-account" element={<UserAccount />} />
            <Route path="user-group" element={<UserGroup />} />
            <Route path="message">
              <Route path="list" element={<MessageList />} />
              <Route path="send" element={<MessageSend />} />
              <Route path="detail/:messageId" element={<MessageDetail />} />
              <Route path="*" element={<Navigate to="/message/list" />} />
            </Route>
            <Route path="lend">
              <Route path="pool">
                <Route path="register">
                  <Route path="" element={<Navigate to="/lend/pool/register/today" replace />} />
                  <Route path=":tab" element={<LendPoolRegister />} />
                </Route>
                <Route path="register-list">
                  <Route
                    path=""
                    element={<Navigate to="/lend/pool/register-list/today" replace />}
                  />
                  <Route path=":tab" element={<LendPoolRegisterList />} />
                </Route>

                <Route path="matched">
                  <Route path="" element={<Navigate to="/lend/pool/matched/today" replace />} />
                  <Route path=":tab" element={<BorrowPoolMatched />} />
                </Route>
              </Route>
              <Route path="lend-list" element={<LendList />} />
              <Route path="order">
                <Route path="response" element={<LendOrderResponse />} />
                <Route path="list" element={<LendOrderList />} />
                <Route path="listcancel" element={<LendOrderListcancel />} />
              </Route>
              <Route path="search" element={<LendSearch />} />
              <Route path="search-response" element={<LendSearchResponse />} />
              <Route path="confirm" element={<LendConfirm />} />
            </Route>
            <Route path="borrow">
              <Route path="list" element={<BorrowPoolList />} />
              <Route path="request">
                <Route path="order">
                  <Route path="" element={<Navigate to="/borrow/request/order/today" replace />} />
                  <Route path=":tab" element={<BorrowRequestOrder />} />
                </Route>
                <Route path="order">
                  <Route path="" element={<Navigate to="/borrow/request/order/today" replace />} />
                  <Route path=":tab" element={<BorrowRequestOrder />} />
                </Route>
                <Route path="list">
                  <Route path="" element={<BorrowRequestList />} />
                </Route>
              </Route>
              <Route path="balance" element={<SearchBalance />} />
              <Route path="confirm" element={<LendConfirm />} />

              {/* <Route
          <Route path="order" element={<BorrowRequestOrder />} />
          <Route path="list" element={<BorrowRequestList />} />
        </Route> */}
              <Route path="order">
                <Route path="response" element={<BorrowOrderResponse />} />
                <Route path="list" element={<BorrowOrderList />} />
                <Route path="listcancel" element={<BorrowOrderListCancel />} />
              </Route>
              <Route path="search" element={<BorrowSearch />} />
              <Route path="search-response" element={<BorrowSearchResponse />} />
            </Route>
            <Route path="confirm" element={<LendConfirm />} />
            {/*
             */}
            <Route path="orders">
              <Route path="list" element={<OrdersList />} />
              <Route path="request" element={<OrdersRequest />} />
              <Route path="upload" element={<OrdersUpload />} />
              <Route path="*" element={<Navigate to="/orders/list?orderType=BORROW" />} />
            </Route>
            <Route path="designated">
              <Route path="request" element={<DesignatedOrdersRequest />} />
              <Route path="change" element={<DesignatedOrdersChange />} />
              <Route path="agree" element={<DesignatedOrdersAgree />} />
              <Route path="*" element={<Navigate to="/designated/request" />} />
            </Route>
            <Route path="custom">
              <Route path="request" element={<CustomOrdersRequest />} />
              <Route path="change" element={<CustomOrdersChange />} />
              <Route path="agree" element={<CustomOrdersAgree />} />
              <Route path="*" element={<Navigate to="/designated/request" />} />
            </Route>
            <Route path="search">
              {/* <Route path="list" element={<SearchList />} /> */}
              <Route path="agree" element={<SearchAgree />} />
              <Route path="return" element={<SearchReturn />} />
              <Route path="balance" element={<SearchBalance />} />
              <Route path="*" element={<Navigate to="/search/list" />} />
            </Route>
            {/* <Route path="confirm" element={<Confirm />} /> */}
            <Route path="*" element={<Navigate to="/lend/pool/register/today" />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export const RouterGuest = () => (
  <div className="container">
    <ToastModal />
    <ErrorModal />
    <Routes>
      <Route path="user/login" element={<Login />}>
        {/* <Route path="login" element={<Login />} /> */}
      </Route>
      <Route path="user/join" element={<Join />} />
      <Route path="*" element={<Navigate to="/user/login" />} />
    </Routes>
  </div>
)

export default Router
