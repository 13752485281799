// 경쟁거래 호가조회

import { httpGetOrderBookStatistics } from '@src/api/httpAPI/httpOrderBookAPI'
import Button from '@src/components/Button'
import LogoutButton from '@src/components/Button/LogoutButton'
import Checkbox from '@src/components/Checkbox'
import ExcelButton from '@src/components/ExcelButton/ExcelButton'
import OrderTypeButtons from '@src/components/OrderTypeButtons'
import SearchIssue from '@src/components/SearchIssue/SearchIssue'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import useOrderBook from '@src/hooks/useOrderBook'
import { formatNumber } from '@src/util/formatNumber'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const OrdersList = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [orderBookStatistics, setOrderBookStatistics] = useState<OrderBookStatisticsType>(null)

  const tableRef = useRef<HTMLTableElement>(null)

  const [orderType, setOrderType] = useState<OrderType>(searchParams.get('orderType') as OrderType)
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const [query, setQuery] = useState<string>('')

  const trans = useTranslation()

  const { orderBookList, setOrderBookList, getOrderBookList, page, setPage, isEmpty } =
    useOrderBook()

  const handleClickRequestButton = useCallback(() => {
    // if (selectedIndex.length) {
    location.pathname = '/orders/request'
    searchParams.append('referer', '/orders/list')
    location.search = searchParams.toString()

    navigate(`${location.pathname}?${location.search}`)
    // } else {
    //   alert('종목을 선택해주세요')
    // }
  }, [selectedIndex, searchParams])

  const selectedOrderBookList = useMemo(
    () => orderBookList.filter((_, index) => selectedIndex.includes(index)),
    [orderBookList, selectedIndex]
  )

  const getOrderBookStatistics = useCallback(async () => {
    const orderBookStatistics = await httpGetOrderBookStatistics({
      type: orderType == 'BORROW' ? 'LEND' : 'BORROW',
    })

    setOrderBookStatistics(orderBookStatistics)
  }, [orderType])

  useEffect(() => {
    getOrderBookStatistics()
  }, [orderType])

  useEffect(() => {
    getOrderBookList(page, query, orderType == 'BORROW' ? 'LEND' : 'BORROW')
  }, [page, query, orderType])

  useEffect(() => {
    setPage(0)
    setOrderBookList([])
    setSelectedIndex([])
    tableRef.current.scrollTo({ top: 0 })
  }, [orderType, query])

  useEffect(() => {
    setSearchParams(
      {
        issues: selectedOrderBookList.length ? JSON.stringify(selectedOrderBookList) : '',
        orderType,
      },
      {
        replace: true,
      }
    )
  }, [selectedOrderBookList, searchParams, orderType])

  return (
    <section className={`orders-list ${orderType == 'BORROW' ? 'LEND' : 'BORROW'}`}>
      <article>
        <header>
          <OrderTypeButtons
            handleChangeOrderType={(orderType) => {
              setOrderType(orderType)
              // if (orderType == 'LEND') setOrderType('BORROW')
              // if (orderType == 'BORROW') setOrderType('LEND')
            }}
          />
        </header>
        <div className="bigger-wrapper">
          <Button className="main-btn" onClick={handleClickRequestButton}>
            신청하기
          </Button>
          <ul>
            <li>종목수 : {formatNumber(orderBookStatistics?.issueCount)}</li>
            {/*<li>수량합계 : {formatNumber(orderBookStatistics?.totalVolume)}</li>*/}
            <li>금액합계: {formatNumber(orderBookStatistics?.totalValue)}</li>
          </ul>
          <SearchIssue searchIcon handleSearch={(query) => setQuery(query)} />
          <div className="fixed-table" ref={tableRef}>
            <table>
              <thead>
                <th>
                  <Checkbox
                    checked={selectedIndex.length == orderBookList.length}
                    onClick={() => {
                      if (selectedIndex.length == orderBookList.length) {
                        setSelectedIndex([])
                      } else {
                        setSelectedIndex(orderBookList.map((_, index) => index))
                      }
                    }}
                  />
                </th>
                <th>{trans.t('issueCode')}</th>
                <th>{trans.t('issueName')}</th>
                <th>호가잔량(주)</th>
                <th>{trans.t('table.rate')}</th>
                <th></th>
              </thead>
              <tbody>
                {isEmpty !== null && isEmpty && (
                  <tr>
                    <td colSpan={6}>
                      검색한 종목의 {OrderTypeText[orderType]} 가능한 호가가 없습니다.
                    </td>
                  </tr>
                )}
                {orderBookList.map((orderBook, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      const checked = selectedIndex.includes(index)
                      if (!checked) {
                        setSelectedIndex((selectedIndex) => [...selectedIndex, index])
                      } else {
                        setSelectedIndex((selectedIndex) =>
                          selectedIndex.filter((_index) => _index !== index)
                        )
                      }
                    }}
                  >
                    <td>
                      <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
                    </td>
                    <td>{orderBook.issueCode}</td>
                    <td className="left">{orderBook.issueName}</td>
                    <td className="right">{formatNumber(orderBook.volume)}</td>
                    <td className="right">{orderBook.rate.toFixed(2)}</td>
                    <td></td>
                  </tr>
                ))}
                <div className="table-bottom"></div>
              </tbody>
            </table>
          </div>
        </div>
        <footer>
          <ExcelButton
            data={orderBookList.map((orderBook) => ({
              ...orderBook,
              issueCode: `=""${orderBook.issueCode}""`,
              orderType: OrderTypeText[orderType == 'LEND' ? 'BORROW' : 'LEND'],
            }))}
            headers={headers}
            fileName={`경쟁거래_${DateTime.local().toFormat('yyyyMMdd')}`}
          >
            엑셀다운로드
          </ExcelButton>
        </footer>
      </article>
    </section>
  )
}

export default OrdersList

const headers = [
  { label: '대차구분', key: 'orderType' },
  { label: '종목코드', key: 'issueCode' },
  { label: '종목명', key: 'issueName' },
  { label: '호가잔량(주)', key: 'volume' },
  { label: '요율', key: 'rate' },
]
