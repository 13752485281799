import { toastModalState } from '@src/stores/toastModalState'
import parseNumComma from '@src/util/parseNumComma'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

let timerList = []

export const ToastModal = () => {
  const [toastModal, setToastModal] = useRecoilState(toastModalState)

  useEffect(() => {
    timerList.map((_timer) => {
      clearTimeout(_timer)
    })
    if (toastModal.isOpen === true) {
      const timer = setTimeout(() => {
        setToastModal({ ...toastModal, isOpen: false })
        timerList = timerList.filter((_timer) => _timer !== timer)
      }, toastModal.openTime)
      timerList.push(timer)
    }
  }, [toastModal])
  return (
    <div className={toastModal.isOpen ? 'notice-wrap' : 'notice-wrap-none'}>
      <div className={'notice-over-amount'}>{toastModal.innerString}</div>
    </div>
  )
}
