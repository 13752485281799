export const getfailedUpdateText = (failedUpdateRequest: any[], lang?) => {
  let text1
  let text2
  if (lang === 'en') {
    text1 = 'Request failed due to no updated column.'
    text2 = 'Update completed except for the list above.'
  } else {
    text1 = '변경 사항 없음으로 요청 실패'
    text2 = '위 목록 이외 요청에 성공 했습니다.'
  }
  const forText = failedUpdateRequest.map((req) => {
    const rate = typeof req.rate === 'string' ? req.rate : req.rate.toFixed(2)
    let volumeText
    let counterparty
    let issueName
    let companyName

    if (lang === 'en') {
      issueName = req.stock.issueEnglishName
      companyName = req.partnerAccount.companyEnglishName
      volumeText = req.volume === 'null' ? 'Qty update not entered, ' : req.volume + 'shs, '
      counterparty = 'Counterparty: '
    } else {
      issueName = req.stock.issueName
      companyName = req.partnerAccount.companyName
      volumeText = req.volume === 'null' ? '수량 입력 없음, ' : req.volume + '주, '
      counterparty = '거래상대방: '
    }
    const str =
      issueName + ', ' + volumeText + rate + '% / ' + counterparty + companyName + ', ' + '\n'
    return str
  })
  let text = ''
  forText.forEach((req) => {
    text += req
  })
  text = text.substring(0, text.length - 3)
  text = text + '\n' + text1 + '\n' + text2
  return text
}

export const getfailedUpdateTextEnglish = (failedUpdateRequest: any[], lang?) => {
  console.log('failedUpdateRequest', failedUpdateRequest)
  const forText = failedUpdateRequest.map((req) => {
    const rate = typeof req.rate === 'string' ? req.rate : req.rate.toFixed(2)
    const str =
      req.stock.issueEnglishName +
      ', ' +
      req.volume +
      '주, ' +
      rate +
      '% / 거래상대방: ' +
      req.partnerAccount.companyEnglishName +
      ', ' +
      '\n'
    return str
  })
  let text = ''
  forText.forEach((req) => {
    text += req
  })
  text = text.substring(0, text.length - 3)
  text = text + '\n' + '변경 사항 없음으로 요청 실패' + '\n' + '위 목록 이외 요청에 성공 했습니다.'
  return text
}
