import { infScrollPageState } from '@src/stores/infScrollPageState'
import { useRecoilState } from 'recoil'

export const useInfScrollAddPage = () => {
  const [infScrollPage, setInfScrollPage] = useRecoilState(infScrollPageState)
  const addPage = () => {
    setInfScrollPage(infScrollPage + 1)
  }
  return addPage
}
