import httpClient from './httpClient'

export const httpPostJoin = async (params: RequestPostJoinType) =>
  await httpClient.post<ResponsePostJoinType>('/auth/sign-up', {
    ...params,
  })

export const httpPostLogin = async ({
  username,
  password,
}: {
  username: string
  password: string
}) =>
  httpClient.post<{ token: string }>('/auth/token', {
    username,
    password,
  })

export const httpPostLogout = async () => await httpClient.post('/auth/sign-out')

export const httpPostSubmit = async (email: string) =>
  await httpClient.post('/auth/submit', { email })
