import { httpGetPoolHistory } from '@src/api/httpAPI/httpPoolAPI'
import Checkbox from '@src/components/Checkbox'
import { OrderStatusText } from '@src/constants/OrderStatusText'
import { infScrollPageState } from '@src/stores/infScrollPageState'
import parseNumberWithComma from '@src/util/parseNumComma'
import axios from 'axios'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import {
  CompanyGroupRow,
  CompanySingleRow,
  IssueGroupRow,
  IssueSingleRow,
} from './BorrowPoolListTableRow'

interface Props {
  actionRecord: ActionRecord
  searchQuery: SearchQuery
  groupByIssue: any[]
  groupByCompany: any[]
  selectedIds: number[]
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>
  setActionRecord: React.Dispatch<React.SetStateAction<ActionRecords>>
  pool: PoolType[]
  isSwapIds: number[]
  setIsSwapIds: React.Dispatch<React.SetStateAction<number[]>>
  tab: string
  // rowRef: React.MutableRefObject<HTMLTableRowElement>
}

interface Order {
  poolId: number
  transactionType: string
  volume: number
  rate: number
  isSwap: boolean
}

const BorrowPoolListTable: FunctionComponent<Props> = (props) => {
  // console.log('cnt')
  const actionRecord = props.actionRecord
  const [selectedIds, setSelectedIds] = [props.selectedIds, props.setSelectedIds]
  const [infScrollPage, setInfScrollPage] = useRecoilState(infScrollPageState)
  const [isSwapIds, setIsSwapIds] = [props.isSwapIds, props.setIsSwapIds]

  const tableDivRef = useRef<HTMLDivElement>()

  const trans = useTranslation()

  // const isSwapCheckedIds = useMemo(()=>
  //   actionRecord.map(elem => elem.isSwap)
  // ),[actionRecord])

  useEffect(() => {
    if (infScrollPage === 0) {
      // const topPos = tableDivRef.current.offsetTop
      // console.log('topPos', topPos)
      tableDivRef.current.scrollTop = -200
    }
  }, [infScrollPage])

  // useEffect(() => {
  //   props.setSelectedPoolIds(
  //     props.pool.filter((_, index) => selectedIds.includes(index)).map(({ id }) => id)
  //   )
  // }, [selectedIds])

  const thArray: string[] = [
    trans.t('borrowListTable.orderType'),
    trans.t('borrowListTable.settlementType'),
    trans.t('borrowListTable.issueCode'),
    trans.t('borrowListTable.issueName'),
    trans.t('borrowListTable.offerQty'),
    trans.t('borrowListTable.offerRate'),
    trans.t('borrowListTable.disclosedTarget'),
    trans.t('lendListTable.tradeType'),
    trans.t('borrowListTable.bidQty'),
    trans.t('borrowListTable.bidRate'),
    trans.t('borrowListTable.swap'),
  ]

  const poolLength = props.pool.length || 0
  // const isSwapIdsLength = isSwapIds.length

  const isSwapHeadChecked = poolLength !== 0 && poolLength === isSwapIds.length

  const getIsInfScrollObserver = (pool: any[], index: number) => {
    if (index === pool.length - 10) {
      return true
    }
    return false
  }

  const ids = props.pool.map((elem) => elem.id)

  return (
    <div
      ref={tableDivRef}
      className={'fixed-table tlist order-normal BORROW' + ' ' + 'tab-' + props.tab.toUpperCase()}
    >
      <table>
        <thead>
          <tr>
            <th>
              <Checkbox
                checked={selectedIds.length !== 0 && selectedIds.length === props.pool.length}
                onClick={() => {
                  if (selectedIds.length === props.pool.length) {
                    setSelectedIds([])
                  } else {
                    setSelectedIds(props.pool.map((select) => select.id))
                  }
                }}
              />
            </th>
            {thArray.map((thStr, index) => {
              if (thStr === trans.t('borrowListTable.swap')) {
                return (
                  <th key={index}>
                    <label>{thStr}</label>
                    <input
                      type="checkbox"
                      onClick={() => {
                        if (!isSwapHeadChecked) {
                          setIsSwapIds(ids)
                          return
                        }
                        setIsSwapIds([])
                      }}
                      readOnly
                      checked={isSwapHeadChecked}
                    ></input>
                  </th>
                )
              }
              return <th key={index}>{thStr}</th>
            })}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.searchQuery.orderBy === 'COMPANY'
            ? props.groupByCompany.map((row, index) => {
                return row.id ? (
                  <CompanySingleRow
                    key={index}
                    outputPool={row}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    actionRecord={actionRecord}
                    isInfScrollObserver={getIsInfScrollObserver(props.groupByCompany, index)}
                    isSwapIds={isSwapIds}
                    setIsSwapIds={setIsSwapIds}
                  />
                ) : (
                  <CompanyGroupRow
                    key={index}
                    outputPool={row}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    actionRecord={actionRecord}
                    isInfScrollObserver={getIsInfScrollObserver(props.groupByCompany, index)}
                  />
                )
              })
            : props.groupByIssue.map((row, index) => {
                return row.id ? (
                  <IssueSingleRow
                    key={index}
                    outputPool={row}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    actionRecord={actionRecord}
                    isInfScrollObserver={getIsInfScrollObserver(props.groupByIssue, index)}
                    isSwapIds={isSwapIds}
                    setIsSwapIds={setIsSwapIds}
                  />
                ) : (
                  <IssueGroupRow
                    key={index}
                    outputPool={row}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    actionRecord={actionRecord}
                    isInfScrollObserver={getIsInfScrollObserver(props.groupByIssue, index)}
                  />
                )
              })}
        </tbody>
      </table>
      <div className="table-bottom"></div>
    </div>
  )
}

export default BorrowPoolListTable
