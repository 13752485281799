import {
  httpGetMessageDtail,
  httpGetNotification,
  httpPostMessage,
} from '@src/api/httpAPI/httpMessageAPI'
import { httpGetUser } from '@src/api/httpAPI/httpUserAPI'
import Button from '@src/components/Button'
import LogoutButton from '@src/components/Button/LogoutButton'
import TradeButton from '@src/components/Button/TradeButton'
import Checkbox from '@src/components/Checkbox'
import Loading from '@src/components/Loading'
import ResultPopup from '@src/components/ResultPopup'
import SelectOption from '@src/components/SelectOption'
import { MESSAGE } from '@src/constants/NotiText'
import { OrderStatusText } from '@src/constants/OrderStatusText'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import { notiCountState } from '@src/stores/notiCountState'
import { userState } from '@src/stores/userStore'
import { formatNumber } from '@src/util/formatNumber'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router'
import { useRecoilState } from 'recoil'
import { topNotiCountsState } from '@src/stores/topNotiCountsState'
import { httpGetTopNotiCounts } from '@src/api/httpAPI/httpNotiCountApi'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { useTranslation } from 'react-i18next'

const MessageDetail = () => {
  const [orderType, setOrderType] = useState<OrderType | ''>('')
  const [messageType, setMessageType] = useState<MessageStatusType>('URGENT')
  const [message, setMessage] = useState<string>('')
  const [selectedLedgerId, setSelectedLedgerId] = useState<LedgerType['ledgerId'][]>([])
  const [isOpenResultPopup, setIsOpenResultPopup] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const params = useParams()
  const [messageDetail, setMessageDetail] = useState<MessageDetailType | null>(null)

  const [user, setUser] = useRecoilState<UserType>(userState)

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const trans = useTranslation()

  // const [topNotiCounts, setTopNotiCounts] = useRecoilState(topNotiCountsState)

  const partnerAccount = useMemo(() => {
    if (!messageDetail) return null
    if (messageDetail.isSender) {
      return messageDetail.to
    } else {
      return messageDetail.from
    }
  }, [messageDetail])

  const getUser = async () => {
    const { data } = await httpGetUser()
    setUser(data)
  }

  // const getNotificationCount = useCallback(async () => {
  //   const notiCounts = await httpGetTopNotiCounts()
  //   setTopNotiCounts(notiCounts)
  // }, [])

  useEffect(() => {
    getUser()
    // getNotificationCount()
  }, [])

  const sendMessageCallback = async () => {
    try {
      // setLoading(true)
      await httpPostMessage({
        ledgerIdList: selectedLedgerId,
        message,
        messageType,
        orderType,
        originalMessageId: messageDetail.id,
        partnerId: partnerAccount?.accountId,
      })
      setToastModal({ ...toastModal, isOpen: true, innerString: MESSAGE.succeed })
    } catch {
      setErrorModal({ ...errorModal, isOpen: true, innerString: MESSAGE.fail })
    } finally {
      // setLoading(false)
    }
  }

  const sendMessage = useCallback(async () => {
    if (!orderType) {
      setToastModal({ ...toastModal, isOpen: true, innerString: '거래구분을 선택해주세요' })
      return
    }
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: MESSAGE.confirm,
      confirmFunction: sendMessageCallback,
      closeFunction: () => {
        return
      },
    })
  }, [message, orderType, messageType, selectedLedgerId, messageDetail, partnerAccount])

  const getMessageDetail = useCallback(async (messageId) => {
    const messageDtail = await httpGetMessageDtail(messageId)
    setMessageDetail(messageDtail)
  }, [])

  useEffect(() => {
    const messageId = Number(params.messageId)
    if (messageId) getMessageDetail(messageId)
  }, [params])

  return (
    <>
      {isLoading && <Loading />}
      <div className={`message-detail ${orderType}`}>
        <article>
          <header>
            <div className="button-wrap">
              {/*<Button color="grey" onClick={() => navigate('/message/list')}>*/}
              {/*  닫기*/}
              {/*</Button>*/}
              <TradeButton color="green" onClick={() => sendMessage()}>
                답신발송
              </TradeButton>
            </div>
          </header>
          <div className="options">
            <SelectOption
              label="상대기관"
              value={partnerAccount?.companyName}
              options={[
                {
                  label: partnerAccount?.companyName,
                  value: partnerAccount?.accountId,
                },
              ]}
            />
            <SelectOption
              label="거래구분"
              value={orderType}
              onChange={(e) => {
                setOrderType(e.target.value as OrderType)
              }}
              options={[
                {
                  label: '선택하세요',
                  value: '',
                },
                {
                  label: '차입',
                  value: 'BORROW',
                },
                {
                  label: '대여',
                  value: 'LEND',
                },
                // {
                //   label: '리콜',
                //   value: 'RECALL',
                // },
              ]}
            />
            <SelectOption
              label="유형"
              value={messageType}
              onChange={(e) => {
                setMessageType(e.target.value as MessageStatusType)
              }}
              options={[
                {
                  label: '긴급',
                  value: 'URGENT',
                },
                {
                  label: '리콜',
                  value: 'RECALL',
                },
                {
                  label: '연락처문의',
                  value: 'CONTACT',
                },
              ]}
            />
          </div>
          <textarea
            placeholder="메세지(3000 byte 이내)"
            value={message}
            onChange={(e) => {
              if (message.length < 3000) setMessage(e.target.value)
            }}
          ></textarea>
          <h3>받은 메세지</h3>
          <textarea value={messageDetail?.message} />
          <h3>첨부된 확정내역</h3>
          <div className="fixed-table">
            <table>
              <thead>
                {/* <th>
                  <Checkbox
                    checked={selectedLedgerId.length == messageDetail?.ledger.length}
                    onClick={() => {
                      if (!messageDetail) return
                      if (selectedLedgerId.length == messageDetail?.ledger.length) {
                        setSelectedLedgerId([])
                      } else {
                        setSelectedLedgerId(messageDetail?.ledger.map((ledger) => ledger.ledgerId))
                      }
                    }}
                  />
                </th> */}

                <th>상태</th>
                <th>결제일</th>
                <th>확정일</th>
                <th>{trans.t('lendListTable.orderType')}</th>
                <th>{trans.t('partnerAsset')}</th>
                <th>{trans.t('issueCode')}</th>
                <th>{trans.t('issueName')}</th>
                <th>{trans.t('table.volume')}</th>
                <th>확정요율(%)</th>
                {/* <th>필요담보(원)</th> */}
                <th></th>
                <th></th>
              </thead>
              <tbody>
                {messageDetail?.ledger.map((ledger, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      const checked = selectedLedgerId.includes(ledger.ledgerId)
                      if (!checked) {
                        setSelectedLedgerId((selectedLedgerId) => [
                          ...selectedLedgerId,
                          ledger.ledgerId,
                        ])
                      } else {
                        setSelectedLedgerId((selectedLedgerId) =>
                          selectedLedgerId.filter((ledgerId) => ledgerId !== ledger.ledgerId)
                        )
                      }
                    }}
                  >
                    {/* <td>
                      <Checkbox
                        checked={selectedLedgerId.includes(ledger.ledgerId)}
                        onClick={() => {}}
                      />
                    </td> */}
                    <td>{OrderStatusText[ledger.orderStatus]}</td>
                    <td>{ledger.orderDateTime?.substring(0, 8)}</td>
                    <td>{ledger.matchedDateTime?.substring(0, 8)}</td>
                    <td>{OrderTypeText[ledger.orderType]}</td>
                    <td className="left">
                      {ledger.partnerAccount &&
                        `${ledger.partnerAccount.companyName}(${ledger.partnerAccount.companyCode}-${ledger.partnerAccount.companyPropertyCode}-${ledger.partnerAccount.companySLBCode})`}
                    </td>
                    <td>{ledger.stock.issueCode}</td>
                    <td className="left">{ledger.stock.issueName}</td>
                    <td className="right">{formatNumber(ledger.volume)}</td>
                    <td className="right">{ledger.rate.toFixed(2)}</td>
                    {/* <td className="right">
                      {formatNumber(ledger.stock.previousClosingPrice * ledger.volume * 1)}
                    </td> */}
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </article>
      </div>
      {isOpenResultPopup && (
        <ResultPopup
          title="메세지 전송이 완료되었습니다."
          close={() => {
            navigate('/message/list')
          }}
        />
      )}
    </>
  )
}

export default MessageDetail
