import Checkbox from '@src/components/Checkbox'
import Popup from '@src/components/PopupState/PopupState'
import { CLASSES } from '@src/constants/Global'
import { OrderStatusText, OrderStatusTextEn } from '@src/constants/OrderStatusText'
import { RequestTypeText } from '@src/constants/RequestTypeText'
import { TransactionTypeText, TransactionTypeTextEn } from '@src/constants/TransactionTypeText'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import { contverTrueFalseToYorN } from '@src/util/contverTrueFalseToYorN'
import { formatNumber } from '@src/util/formatNumber'
import parseNumberWithComma from '@src/util/parseNumComma'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { localStorageAPI } from '../../api/storageAPI'
import { TdAlignRight } from '../Table/StyledTd/TdAlignRight'

interface QueryPayload {
  query?: string
  companyName?: string
  settlementType?: SettlementType | string
  transactionType?: TransactionType | string
  requestType?: RequestOptionType | string
  isRequester?: boolean
}

interface Props {
  topTab: 'LEDGER' | 'RESPONSE' | 'HISTORY'
  setSelectedRequestIds: React.Dispatch<React.SetStateAction<number[]>>
  requests: any[]
  setRequests: React.Dispatch<React.SetStateAction<RequestV2Type[]>>
  queryPayload: QueryPayload
  setQueryPayload: React.Dispatch<React.SetStateAction<QueryPayload>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  pageType: PageType
  constants: any
}

const LendOrderResponseTable: FunctionComponent<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = [props.selectedIndex, props.setSelectedIndex]
  const pageType = props.pageType.toLowerCase()

  const forceRefresh = useRecoilValue(forceRefreshState)

  const tableDivRef = useRef<HTMLDivElement>()
  const trans = useTranslation()

  useEffect(() => {
    tableDivRef.current.scrollTop = -200
  }, [forceRefresh])

  useEffect(() => {
    if (props.topTab === 'LEDGER') {
      props.setSelectedRequestIds(
        props.requests
          .filter((_, index) => selectedIndex.includes(index))
          .map(({ ledgerId }) => ledgerId)
      )
      return
    }
    if (props.topTab === 'RESPONSE') {
      props.setSelectedRequestIds(
        props.requests
          .filter((_, index) => selectedIndex.includes(index))
          .map(({ requestId }) => requestId)
      )
      return
    }
  }, [selectedIndex])

  const handlePopupMenuClick = (
    popupMenuValue: string,
    queryKey: string,
    popupClose: () => void
  ) => {
    const newPayLoad = { ...props.queryPayload }
    newPayLoad[queryKey] = popupMenuValue
    props.setQueryPayload(newPayLoad)
    props.setPage(0)
    popupClose()
  }

  const getCancelableIndex = (requests) => {
    const indexes: number[] = []
    requests.forEach((request: any, index: number) => {
      if (request.isCancelable === true) {
        indexes.push(index)
      }
    })
    return indexes
  }

  const RequestStatusText = {
    ACCEPTED: '취소동의',
    RECEIVED: '취소요청',
    REJECTED: '취소거부',
  }

  const RequestStatusTextEn = {
    ACCEPTED: 'Cancel accepted',
    RECEIVED: 'Cancel requested',
    REJECTED: 'Cancel rejected',
  }

  const cancelAbleIndexes = useMemo(() => getCancelableIndex(props.requests), [props.requests])

  const matchedStatusText = {
    MATCHED: '확정',
    CANCEL_REQUESTED: '취소요청',
    CANCEL: '취소',
    MATCH_CANCELED: '취소',
  }

  const matchedStatusTextEn = {
    MATCHED: 'Confirmed',
    CANCEL_REQUESTED: 'Cancel requested',
    CANCEL: 'Canceled',
    MATCH_CANCELED: 'Canceld',
  }

  const fixedTableClass = props.constants.fixedTableStyle

  return (
    <div
      className={
        fixedTableClass + ' ' + props.constants.pageType.toUpperCase() + ' ' + 'tab-' + props.topTab
      }
      ref={tableDivRef}
    >
      <table>
        <thead>
          {props.topTab === 'LEDGER' && (
            <th>
              <Checkbox
                checked={
                  selectedIndex.length !== 0 && selectedIndex.length === cancelAbleIndexes.length
                }
                onClick={() => {
                  if (selectedIndex.length == cancelAbleIndexes.length) {
                    setSelectedIndex([])
                  } else {
                    setSelectedIndex(cancelAbleIndexes)
                  }
                }}
              />
            </th>
          )}
          {props.topTab === 'RESPONSE' && (
            <th>
              <Checkbox
                checked={
                  selectedIndex.length !== 0 && selectedIndex.length === props.requests.length
                }
                onClick={() => {
                  if (selectedIndex.length == props.requests.length) {
                    setSelectedIndex([])
                  } else {
                    setSelectedIndex(props.requests.map((_, index) => index))
                  }
                }}
              />
            </th>
          )}
          <th>{trans.t('lendListTable.orderType')}</th>
          <th className="lend-th-change">
            <Popup
              visibleText={trans.t('lendListTable.settlementType')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: 'T',
                  value: 'TODAY',
                },
                {
                  text: 'T1',
                  value: 'NEXT_DAY',
                },
              ]}
              queryKey="settlementType"
              handlePopupMenuClick={handlePopupMenuClick}
              pageType={pageType}
            ></Popup>
          </th>
          <th className="lend-th-change">
            <Popup
              visibleText={trans.t('lendListTable.tradeType')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.designated'),
                  value: 'DESIGNATED',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.custom'),
                  value: 'CUSTOM',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.none'),
                  value: 'NONE',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.bidOffer'),
                  value: 'COMPETITIVE',
                },
              ]}
              queryKey="transactionType"
              handlePopupMenuClick={handlePopupMenuClick}
              pageType={pageType}
            ></Popup>
          </th>
          {props.topTab === 'LEDGER' && (
            <th className="lend-th-change">
              <Popup
                visibleText={trans.t('lendListTable.poolStatus')}
                menuItems={[
                  {
                    text: trans.t('lendListTable.all'),
                    value: '',
                  },
                  {
                    text: trans.t('matched'),
                    value: 'MATCHED',
                  },
                  {
                    text: trans.t('cancelRequested'),
                    value: 'CANCEL_REQUESTED',
                  },
                  {
                    text: trans.t('canceled'),
                    value: 'MATCH_CANCELED',
                  },
                ]}
                queryKey="orderStatus"
                handlePopupMenuClick={handlePopupMenuClick}
                pageType={pageType}
              ></Popup>
            </th>
          )}
          {props.topTab === 'HISTORY' && (
            <th className="lend-th-change">
              <Popup
                visibleText={trans.t('lendListTable.poolStatus')}
                menuItems={[
                  {
                    text: trans.t('lendListTable.all'),
                    value: '',
                  },
                  {
                    text: trans.t('cancelAccepted'),
                    value: 'ACCEPTED',
                  },
                  {
                    text: trans.t('cancelRequested'),
                    value: 'RECEIVED',
                  },
                  {
                    text: trans.t('cancelRejected'),
                    value: 'REJECTED',
                  },
                ]}
                queryKey="requestStatus"
                handlePopupMenuClick={handlePopupMenuClick}
                pageType={pageType}
              ></Popup>
            </th>
          )}
          {props.topTab === 'LEDGER' && <th>{trans.t('lendListTable.applicationDate')}</th>}
          <th>{trans.t('matchedDate')}</th>
          <th>{trans.t('matchedId')}</th>
          {props.topTab === 'HISTORY' && <th>{trans.t('requestorAsset')}</th>}
          <th>{trans.t('partnerAsset')}</th>
          <th>{trans.t('issueCode')}</th>
          <th>{trans.t('issueName')}</th>
          <th>{trans.t('table.volume')}</th>
          <th>{trans.t('table.rate')}</th>
          {/* 
          {props.topTab === 'LEDGER' && (
            <th className="lend-th-change">
              <Popup
                visibleText={trans.t('cancellable')}
                menuItems={[
                  {
                    text: trans.t('lendListTable.all'),
                    value: '',
                  },
                  {
                    text: 'Y',
                    value: 'true',
                  },
                  {
                    text: 'N',
                    value: 'false',
                  },
                ]}
                queryKey="isCancelable"
                handlePopupMenuClick={handlePopupMenuClick}
                pageType={pageType}
              ></Popup>
            </th>
          )} */}
          {props.topTab !== 'LEDGER' && <th>{trans.t('cancelRequestedDate')}</th>}
          <th className="lend-th-change">
            <Popup
              visibleText={trans.t('lendListTable.swap')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: 'Y',
                  value: 'true',
                },
                {
                  text: 'N',
                  value: 'false',
                },
              ]}
              queryKey="isSwap"
              handlePopupMenuClick={handlePopupMenuClick}
              pageType={pageType}
            ></Popup>
          </th>
          <th></th>
        </thead>
        <tbody>
          {props.requests.map((request, index) => (
            <tr
              onClick={() => {
                if (props.topTab === 'LEDGER' && !request.isCancelable) {
                  return
                }
                const checked = selectedIndex.includes(index)
                if (!checked) {
                  setSelectedIndex((selectedIndex) => [...selectedIndex, index])
                } else {
                  setSelectedIndex((selectedIndex) =>
                    selectedIndex.filter((_index) => _index !== index)
                  )
                }
              }}
            >
              {props.topTab === 'LEDGER' && (
                <td>
                  {request.isCancelable === true && (
                    <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
                  )}
                </td>
              )}
              {props.topTab === 'RESPONSE' && (
                <td>
                  <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
                </td>
              )}
              <td>
                {localStorageAPI.getItem('lang') === 'en'
                  ? OrderStatusTextEn[request.orderType]
                  : OrderStatusText[request.orderType]}
              </td>
              <td className={CLASSES.tableCellFilterable}>
                {!request.settlementType ? '' : request.settlementType === 'TODAY' ? 'T' : 'T1'}
              </td>
              <td className={CLASSES.tableCellFilterable}>
                {localStorageAPI.getItem('lang') === 'en'
                  ? TransactionTypeTextEn[request.transactionType]
                  : TransactionTypeText[request.transactionType]}
              </td>
              {props.topTab === 'LEDGER' && (
                <td className={CLASSES.tableCellFilterable}>
                  {localStorageAPI.getItem('lang') === 'en'
                    ? matchedStatusTextEn[request.orderStatus]
                    : matchedStatusText[request.orderStatus]}
                </td>
              )}
              {props.topTab === 'HISTORY' && (
                <td className={CLASSES.tableCellFilterable}>
                  {localStorageAPI.getItem('lang') === 'en'
                    ? RequestStatusTextEn[request.requestStatus]
                    : RequestStatusText[request.requestStatus]}
                </td>
              )}
              {props.topTab === 'LEDGER' && <td>{request.orderDateTime?.substring(0, 14)}</td>}
              <td>{request.matchedDateTime?.substring(0, 14)}</td>
              <td>{request.ledgerId}</td>
              {props.topTab === 'HISTORY' && (
                <td>
                  {localStorageAPI.getItem('lang') === 'en'
                    ? request.requesterAccount?.companyEnglishName
                    : request.requesterAccount?.companyName}
                </td>
              )}
              <td className={request.isSwap ? 'swap-highlight-cell' : undefined}>
                <span>
                  {localStorageAPI.getItem('lang') === 'en'
                    ? request.partnerAccount.companyEnglishName
                    : request.partnerAccount.companyName}{' '}
                  <br></br>
                  {'(' +
                    request.partnerAccount.companyCode +
                    '-' +
                    request.partnerAccount.companyPropertyCode +
                    '-' +
                    request.partnerAccount.companySLBCode +
                    ')'}
                </span>
              </td>
              <td>{request.stock.issueCode}</td>
              <td>
                {localStorageAPI.getItem('lang') === 'en'
                  ? request.stock.issueEnglishName
                  : request.stock.issueName}
              </td>
              <TdAlignRight>
                {request.volume
                  ? parseNumberWithComma(request.volume)
                  : request.requestedVolume
                  ? parseNumberWithComma(request.requestedVolume)
                  : request.currentVolume
                  ? parseNumberWithComma(request.currentVolume)
                  : ''}
              </TdAlignRight>
              <TdAlignRight>
                {request.rate?.toFixed(2) ||
                  request.requestedRate?.toFixed(2) ||
                  request.currentRate?.toFixed(2)}
              </TdAlignRight>
              {/* {props.topTab === 'LEDGER' && (
                <td className={CLASSES.tableCellFilterable}>
                  {contverTrueFalseToYorN(request.isCancelable)}
                </td>
              )} */}
              {props.topTab !== 'LEDGER' && <td>{request.requestDateTime?.substring(0, 14)}</td>}
              <td className={request.isSwap ? 'swap-highlight-cell' : undefined}>
                {contverTrueFalseToYorN(request.isSwap)}
              </td>
              <td></td>
            </tr>
          ))}
          <div className="table-bottom"></div>
        </tbody>
      </table>
    </div>
  )
}

export default LendOrderResponseTable
