import Checkbox from '@src/components/Checkbox'
import Popup from '@src/components/PopupState/PopupState'
import PopupTwoValue from '@src/components/PopupState/PopupTwoValue'
import { CLASSES } from '@src/constants/Global'
import { OrderStatusText, OrderStatusTextEn } from '@src/constants/OrderStatusText'
import { RequestTypeText } from '@src/constants/RequestTypeText'
import { TransactionTypeText, TransactionTypeTextEn } from '@src/constants/TransactionTypeText'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import { contverTrueFalseToYorN } from '@src/util/contverTrueFalseToYorN'
import { formatNumber } from '@src/util/formatNumber'
import { getResponseStatus } from '@src/util/getResponseStatus'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { localStorageAPI } from '../../api/storageAPI'
import NestedPopup from '../PopupState/NestedPopup'

interface QueryPayload {
  query?: string
  companyName?: string
  settlementType?: SettlementType | ''
  transactionType?: TransactionType | ''
  requestType?: RequestOptionType | ''
  isRequester?: boolean
}

interface Props {
  setSelectedRequestIds: React.Dispatch<React.SetStateAction<number[]>>
  requests: RequestV2Type[]
  setRequests: React.Dispatch<React.SetStateAction<RequestV2Type[]>>
  queryPayload: QueryPayload
  setQueryPayload: React.Dispatch<React.SetStateAction<QueryPayload>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  pageType: PageType
  constants: any
  isRequester: any
}

const cellFilterAbleClass = 'cell-filterable'

const LendOrderResponseTable: FunctionComponent<Props> = (props) => {
  const pageType = props.pageType.toLowerCase()
  const [selectedIndex, setSelectedIndex] = [props.selectedIndex, props.setSelectedIndex]
  const forceRefresh = useRecoilValue(forceRefreshState)
  const tableDivRef = useRef<HTMLDivElement>()
  const trans = useTranslation()

  useEffect(() => {
    tableDivRef.current.scrollTop = -200
  }, [forceRefresh])

  useEffect(() => {
    props.setSelectedRequestIds(
      props.requests
        .filter((_, index) => selectedIndex.includes(index))
        .map(({ requestId }) => requestId)
    )
  }, [selectedIndex])

  const handlePopupMenuClick = (
    popupMenuValue: string,
    queryKey: string,
    popupClose: () => void
  ) => {
    const newPayLoad = { ...props.queryPayload }
    newPayLoad[queryKey] = popupMenuValue
    props.setQueryPayload(newPayLoad)
    props.setPage(0)
    popupClose()
  }

  const handlePopupTwoValueMenuClick = (
    popupMenuValue: string[],
    queryKey: string[],
    popupClose: () => void
  ) => {
    const newPayLoad = { ...props.queryPayload }
    for (let i = 0; i < popupMenuValue.length; i++) {
      newPayLoad[queryKey[i]] = popupMenuValue[i]
    }
    props.setQueryPayload(newPayLoad)
    props.setPage(0)
    popupClose()
  }

  const getCalcleAbleIndex = (requests) => {
    const result = []
    requests.forEach((elem, index) => {
      if (elem.isCancelable === false) {
        return
      }
      result.push(index)
    })
    return result
  }

  const calcleAbleIndex = getCalcleAbleIndex(props.requests)

  // const fixedTableClass = 'fixed-table' + constants.
  const isRequesterToString = useMemo(() => {
    if (props.isRequester) {
      return 'tab-REQUESTER'
    }
    return 'tab-NOTREQUESTER'
  }, [props.isRequester])
  return (
    <div
      className={
        props.constants.fixedTableStyle + ' ' + pageType.toUpperCase() + ' ' + isRequesterToString
      }
      ref={tableDivRef}
    >
      <table>
        <thead>
          {/* {props.queryPayload.isRequester && (
            <th>
              <Checkbox
                checked={
                  selectedIndex.length !== 0 && selectedIndex.length === calcleAbleIndex.length
                }
                onClick={() => {
                  if (selectedIndex.length === calcleAbleIndex.length) {
                    setSelectedIndex([])
                  } else {
                    setSelectedIndex(calcleAbleIndex)
                  }
                }}
              />
            </th>
          )} */}
          <th>{trans.t('lendListTable.orderType')}</th>
          <th className={'lend-th-change'}>
            <Popup
              visibleText={trans.t('lendListTable.settlementType')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: 'T',
                  value: 'TODAY',
                },
                {
                  text: 'T1',
                  value: 'NEXT_DAY',
                },
              ]}
              queryKey="settlementType"
              handlePopupMenuClick={handlePopupMenuClick}
              pageType={pageType}
            ></Popup>
          </th>
          <th className={'lend-th-change'}>
            <Popup
              visibleText={trans.t('lendListTable.tradeType')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.designated'),
                  value: 'DESIGNATED',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.custom'),
                  value: 'CUSTOM',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.none'),
                  value: 'NONE',
                },
              ]}
              queryKey="transactionType"
              handlePopupMenuClick={handlePopupMenuClick}
              pageType={pageType}
            ></Popup>
          </th>
          <th className={'lend-th-change'}>
            <NestedPopup
              visibleText={trans.t('lendListTable.poolStatus')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: ['', ''],
                },
                {
                  text: trans.t('trade'),
                  value: ['ACCEPT', ''],
                },
                {
                  text: trans.t('update'),
                  value: ['UPDATE', ''],
                },
                {
                  text: trans.t('cancel'),
                  value: ['CANCEL', ''],
                },
              ]}
              queryKey={['requestType', 'requestStatus']}
              handlePopupTwoValueMenuClick={handlePopupTwoValueMenuClick}
              pageType={pageType}
            ></NestedPopup>
          </th>
          <th>{trans.t('lendListTable.applicationDate')}</th>
          <th>{trans.t('partnerAsset')}</th>
          <th>{trans.t('issueCode')}</th>
          <th>{trans.t('issueName')}</th>
          <th>{trans.t('originalQty')}</th>
          <th>{trans.t('originalRate')}</th>
          <th>{trans.t('updatedQty')}</th>
          <th>{trans.t('updatedRate')}</th>
          {/* {props.queryPayload.isRequester && (
            <th className={'lend-th-change'}>
              <Popup
                visibleText={trans.t('cancellable')}
                menuItems={[
                  {
                    text: trans.t('lendListTable.all'),
                    value: '',
                  },
                  {
                    text: 'Y',
                    value: 'true',
                  },
                  {
                    text: 'N',
                    value: 'false',
                  },
                ]}
                queryKey="isCancelable"
                handlePopupMenuClick={handlePopupMenuClick}
                pageType={pageType}
              ></Popup>
            </th>
          )} */}
          <th className={'lend-th-change'}>
            <Popup
              visibleText={trans.t('lendListTable.swap')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: 'Y',
                  value: 'true',
                },
                {
                  text: 'N',
                  value: 'false',
                },
              ]}
              queryKey="isSwap"
              handlePopupMenuClick={handlePopupMenuClick}
              pageType={pageType}
            ></Popup>
          </th>
          <th></th>
        </thead>
        <tbody>
          {props.requests.map((request, index) => (
            <tr
              onClick={() => {
                if (!request.isCancelable) {
                  return
                }
                const checked = selectedIndex.includes(index)
                if (!checked) {
                  setSelectedIndex((selectedIndex) => [...selectedIndex, index])
                } else {
                  setSelectedIndex((selectedIndex) =>
                    selectedIndex.filter((_index) => _index !== index)
                  )
                }
              }}
            >
              {/* {props.queryPayload.isRequester && (
                <td>
                  {request.isCancelable && (
                    <Checkbox readOnly checked={selectedIndex.includes(index)} />
                  )}
                </td>
              )} */}
              <td>
                {localStorageAPI.getItem('lang') === 'en'
                  ? OrderStatusTextEn[request.orderType]
                  : OrderStatusText[request.orderType]}
              </td>
              <td className={CLASSES.tableCellFilterable}>
                {request.settlementType == 'TODAY' ? 'T' : 'T1'}
              </td>
              <td className={CLASSES.tableCellFilterable}>
                {localStorageAPI.getItem('lang') === 'en'
                  ? TransactionTypeTextEn[request.transactionType]
                  : TransactionTypeText[request.transactionType]}
              </td>
              <td className={CLASSES.tableCellFilterable}>
                {getResponseStatus(
                  request.requestStatus,
                  request.requestType,
                  localStorageAPI.getItem('lang')
                )}
              </td>
              <td>{request.requestDateTime.substring(0, 14)}</td>
              <td className={request.isSwap && 'swap-highlight-cell'}>
                {localStorageAPI.getItem('lang') === 'en'
                  ? request.partnerAccount.companyEnglishName
                  : request.partnerAccount.companyName}
              </td>
              <td>{request.stock.issueCode}</td>
              <td>
                {localStorageAPI.getItem('lang') === 'en'
                  ? request.stock.issueEnglishName
                  : request.stock.issueName}
              </td>
              <td className="right">{formatNumber(request.currentVolume)}</td>
              <td className="right">{request.currentRate.toFixed(2)}</td>
              <td className="right">
                {request.requestedVolume ? formatNumber(request.requestedVolume) : ''}
              </td>
              <td className="right">
                {request.requestedRate ? request.requestedRate.toFixed(2) : ''}
              </td>
              {/* {props.queryPayload.isRequester && (
                <td className={CLASSES.tableCellFilterable}>
                  {contverTrueFalseToYorN(request.isCancelable)}
                </td>
              )} */}
              <td className={request.isSwap && 'swap-highlight-cell'}>
                {contverTrueFalseToYorN(request.isSwap)}
              </td>
              <td></td>
            </tr>
          ))}
          <div className="table-bottom"></div>
        </tbody>
      </table>
    </div>
  )
}

export default LendOrderResponseTable
