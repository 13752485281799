import { httpDeletePool, httpGetPoolHistory, httpGetPoolStatus } from '@src/api/httpAPI/httpPoolAPI'
import TextField from '@src/components/TextField'
import useInfiniteScroll from '@src/hooks/useInfiniteScroll'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import LendPoolRegisterListTable from './LendPoolMatchedTable'
import LogoutButton from '@src/components/Button/LogoutButton'
import LendPoolMatchedLiveTable from './LendPoolMatchedLiveTable'
import { httpDeletetOrder, httpGetOrder, httpGetOrderStatus } from '@src/api/httpAPI/httpOrdersAPI'
import { useNavigate, useParams } from 'react-router'
import { SpanCursorPointer } from '@src/components/Span/SpanCursorPointer'
import QueryOptionBox from '@src/components/OptionBox/QueryOptionBoxBasic'
import { downloadCsvWithDictAndBurnout } from '@src/util/getCsv'
import {
  csvDict,
  csvDictEn,
  csvHeader,
  csvHeaderEn,
  csvHeaderLive,
  csvHeaderLiveEn,
  csvKey,
  csvKeyEn,
  csvKeyLive,
  csvKeyLiveEn,
} from './csv'
import TradeButton from '@src/components/Button/TradeButton'
import { Button } from '@mui/material'
import { CANCEL } from '@src/constants/NotiText'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

// 대여 - 종목별 확정현황
const LendPoolMatched: FunctionComponent = () => {
  const params = useParams()
  const tab = useMemo(() => params.tab, [params.tab])

  const navigate = useNavigate()

  const [selectedPoolIds, setSelectedPoolIds] = useState<number[]>([])
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([])

  const [reloadFlag, setReloadFlag] = useState(false)
  const [query, setQuery] = useState<string>('')
  const [poolStatus, setPoolStatus] = useState<PoolStatusType | 'ALL'>('ALL')
  const [isLast, setIsLast] = useState(true)
  const [page, setPage] = useState(0)
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const [poolHistory, setPoolHistory] = useState<PoolHistoryType[]>([])
  const [order, setOrder] = useState<OrderDataType[]>([])

  const trans = useTranslation()

  useEffect(() => {
    setPage(0)
    return () => setPage(0)
  }, [])

  const deletePools = useCallback(async () => {
    await httpDeletePool(selectedPoolIds)
  }, [selectedPoolIds])

  const deleteOrders = useCallback(async () => {
    try {
      await Promise.all(
        selectedOrderIds.map(async (orderId) => {
          await httpDeletetOrder(orderId)
        })
      )
      alert(trans.t('modal.cancel.cancelSucceed'))
    } catch {
      alert(trans.t('modal.cancel.cancelFailed'))
    }
  }, selectedOrderIds)

  const getPoolHistory = useCallback(
    async (page?: number, query?: string) => {
      if (tab === 'live') {
        return
      }
      // setIsLoading(true)
      try {
        const poolHistory = await httpGetPoolStatus({
          orderType: 'LEND',
          settlementType: tab == 'today' ? 'TODAY' : 'NEXT_DAY',

          query,
          poolStatus: poolStatus == 'ALL' ? '' : poolStatus,
          page: page,
          size: 20,
        })
        if (page == 0) {
          setPoolHistory(poolHistory)
        } else {
          setPoolHistory((prevPoolHistory) => [...prevPoolHistory, ...poolHistory])
        }
        console.log('poolHistory', poolHistory)
        if (poolHistory == null) {
          setIsLast(true)
          setIsEmpty(true)
        }
        setIsLast(poolHistory.length < 1)
        if (page == 0 && !poolHistory.length) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    },
    [tab, query, poolStatus, page]
  )

  const getOrderList = useCallback(
    async (page?: number) => {
      if (tab !== 'live') return

      try {
        const order = await httpGetOrderStatus({ query, orderType: 'LEND', page, size: 20 })

        if (page == 0) {
          setOrder(order)
        } else {
          setOrder((prevOrder) => [...prevOrder, ...order])
        }

        setIsLast(order.length < 1)
        if (page == 0 && !order.length) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      } catch (err) {
        //
      }
      setIsLoading(false)
    },
    [tab, page, query]
  )

  useEffect(() => {
    if (page === 0) return
    if (tab === 'live') {
      getOrderList(page)
    }
    if (tab !== 'live') {
      getPoolHistory(page, query)
    }
  }, [page])

  // useEffect(() => {}, [poolStatus, query, tab])

  useEffect(() => {
    setPage(0)
    setIsLast(false)
    setIsLoading(false)
    setPoolStatus('ALL')
    if (tab === 'live') getOrderList(0)
    if (tab !== 'live') getPoolHistory(0, query)
    onInfiniteScrollInit(document.querySelector('.table-bottom'))
  }, [poolStatus, query, tab, reloadFlag])

  const reset = useCallback(() => {
    if (isLoading) return
    setQuery('')
    setPoolStatus('ALL')
    setReloadFlag(!reloadFlag)
  }, [reloadFlag, isLoading])

  useEffect(() => {
    if (!isLoading) {
      onInfiniteScrollInit(document.querySelector('.table-bottom'))
      onInfiniteScrollUpdate()
    }
  }, [isLoading])

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setIsLoading(true)
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  useEffect(() => {
    if (isLoading || isLast) onInfiniteScrollDisconnect()
    else onInfiniteScrollUpdate()
  }, [isLoading, isLast])

  const handleCsvDownload = async () => {
    const fileName =
      localStorageAPI.getItem('lang') === 'en' ? 'Lending_UploadStatus' : '대여_종목별확정현황'
    const payload = {
      orderType: 'LEND',
      settlementType: tab === 'today' ? 'TODAY' : 'NEXT_DAY',
    }
    const res = await httpGetPoolStatus(payload)
    let csvInfo
    if (localStorageAPI.getItem('lang') === 'en') {
      csvInfo = {
        header: csvHeaderEn,
        csvKey: csvKeyEn,
        csvDict: csvDictEn,
        fileName: 'Lending_UploadStatus',
      }
    } else {
      csvInfo = {
        header: csvHeader,
        csvKey: csvKey,
        csvDict: csvDict,
        fileName: '대여_종목별확정현황',
      }
    }

    downloadCsvWithDictAndBurnout(
      res,
      csvInfo.header,
      csvInfo.csvKey,
      csvInfo.csvDict,
      csvInfo.fileName,
      localStorageAPI.getItem('lang')
    )
  }

  // const order = await httpGetOrderStatus({ orderType: 'LEND', page, size: 20 })

  const handleCsvDownloadLive = async () => {
    const res = await httpGetOrderStatus({ orderType: 'LEND' })

    let csvInfo
    if (localStorageAPI.getItem('lang') === 'en') {
      csvInfo = {
        header: csvHeaderLiveEn,
        csvKey: csvKeyLiveEn,
        csvDict: csvDictEn,
        fileName: 'Lending_UploadStatus',
      }
    } else {
      csvInfo = {
        header: csvHeaderLive,
        csvKey: csvKeyLive,
        csvDict: csvDict,
        fileName: '대여_종목별확정현황',
      }
    }

    downloadCsvWithDictAndBurnout(
      res,
      csvInfo.header,
      csvInfo.csvKey,
      csvInfo.csvDict,
      csvInfo.fileName,
      localStorageAPI.getItem('lang')
    )
  }

  return (
    <div className="lend-pool-matched LEND">
      <article key={tab}>
        <div className="tab">
          <ul>
            <li
              onClick={() => {
                setPage(0)
                navigate('/lend/pool/matched/today')
              }}
              className={tab == 'today' ? 'selected' : ''}
            >
              {trans.t('settlementType.today')}
            </li>
            <li
              onClick={() => {
                setPage(0)
                navigate('/lend/pool/matched/next_day')
              }}
              className={tab == 'next_day' ? 'selected' : ''}
            >
              {trans.t('settlementType.nextDay')}
            </li>
            <li
              onClick={() => {
                setPage(0)
                navigate('/lend/pool/matched/live')
              }}
              className={tab == 'live' ? 'selected' : ''}
            >
              {trans.t('settlementType.live')}
            </li>
          </ul>
        </div>

        <div className="button-wrap">
          <TradeButton
            color="red"
            onClick={() => (tab == 'live' ? deleteOrders() : deletePools())}
            sx={{ visibility: 'hidden' }}
          >
            신청취소
          </TradeButton>
        </div>
        {/* {tab !== 'live' && ( */}
        <QueryOptionBox reset={reset} query={query} setQuery={setQuery} tab={tab}></QueryOptionBox>
        {/* )} */}
        {/* {tab === 'live' && (
          <SpanCursorPointer onClick={() => reset()}>
            <RefreshIcon></RefreshIcon>
            <p>&nbsp;{trans.t('refresh')}</p>
          </SpanCursorPointer>
        )} */}

        {/* <Button
          variant="outlined"
          color="error"
          sx={{ marginLeft: '30px', marginTop: '20px !important' }}
          onClick={() => handleCancleAllBtn()}
        >
          전체등록내역 취소
        </Button> */}

        {tab == 'live' ? (
          <LendPoolMatchedLiveTable
            setSelectedOrderIds={setSelectedOrderIds}
            orderList={order}
            tab={tab}
          />
        ) : (
          <LendPoolRegisterListTable
            setSelectedPoolIds={setSelectedPoolIds}
            poolHistory={poolHistory}
            setPoolStatus={setPoolStatus}
            tab={tab}
          />
        )}

        <Button
          onClick={() => (tab !== 'live' ? handleCsvDownload() : handleCsvDownloadLive())}
          variant="outlined"
          sx={{ marginLeft: '30px', marginTop: '5px !important' }}
        >
          {trans.t('downloadAllList')}
        </Button>
      </article>
    </div>
  )
}

export default LendPoolMatched
