import { atom } from 'recoil'

export const topNotiCountsState = atom<TopNotiCounts>({
  key: 'topNotiCountsState',
  default: {
    borrow: {
      requestCount: 0,
      cancelRequestCount: 0,
      matchedCount: 0,
      today: {
        issueCount: 0,
        totalValue: 0,
      },
      nextDay: {
        issueCount: 0,
        totalValue: 0,
      },
      competitive: {
        issueCount: 0,
        totalValue: 0,
      },
    },
    lend: {
      requestCount: 0,
      cancelRequestCount: 0,
      matchedCount: 0,
      today: {
        issueCount: 0,
        totalValue: 0,
      },
      nextDay: {
        issueCount: 0,
        totalValue: 0,
      },
      competitive: {
        issueCount: 0,
        totalValue: 0,
      },
    },
    messageCount: 0,
    recallCount: 0,
    urgentCount: 0,
  },
})
