import { errorModalState } from '@src/stores/errorModalState'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

export const ErrorModal = () => {
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const checkRef = useRef<HTMLButtonElement>()

  const trans = useTranslation('translation', { keyPrefix: 'modal' })

  useEffect(() => {
    checkRef.current.focus()
  }, [errorModal])
  return (
    <div className={errorModal.isOpen ? 'confirm-modal-wrap' : 'display-none'}>
      <div style={{ whiteSpace: 'pre-line' }}>{errorModal.innerString}</div>
      <div className={'confirm-modal-btn'}>
        {/* <button ref={confirmRef} onClick={errorModal.confirmFunction}>
          확인
        </button> */}
        <button ref={checkRef} onClick={() => setErrorModal({ ...errorModal, isOpen: false })}>
          {trans.t('confirm')}
        </button>
      </div>
    </div>
  )
}
