import { SelectedGroup, SelectedPool } from '@src/pages/LendPoolRegister/LendPoolRegister'
import { CSSProperties, FunctionComponent, useEffect } from 'react'
import { postPoolToAllState } from '@src/stores/postPoolToAllState'
import { useRecoilState } from 'recoil'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  style?: CSSProperties
  width?: number
  id?: string
  label?: string
  checked?: boolean
  position?: 'left' | 'right' | 'top' | 'bottom'
  selectedGroup: SelectedGroup[]
  setSelectedGroup: (value: React.SetStateAction<SelectedGroup[]>) => void
  selectedPoolList: SelectedPool[]
  setSelectedPoolList: React.Dispatch<React.SetStateAction<SelectedPool[]>>
}

const GroupAllCheckbox: FunctionComponent<Props> = ({
  style,
  width,
  position,
  label,
  id,
  selectedGroup,
  setSelectedGroup,
  selectedPoolList,
  setSelectedPoolList,
  ...props
}) => {
  const [postPoolToAll, setPostPoolToAll] = useRecoilState(postPoolToAllState)

  useEffect(() => {
    if (selectedPoolList.length !== 0) {
      setPostPoolToAll(false)
      return
    }
  }, [selectedPoolList])

  const Label = () => {
    if (!label) return null
    return <label htmlFor={id}>{label}</label>
  }

  return (
    <div
      className={`checkbox ${position}`}
      style={style}
      onClick={() => {
        setSelectedPoolList([])
        setPostPoolToAll(!postPoolToAll)
      }}
    >
      {(position == 'left' || position == 'top') && <Label />}
      <input
        readOnly
        checked={postPoolToAll}
        type="checkbox"
        id={id}
        {...props}
        // key={JSON.stringify(props.checked)}
      />
      {(position == 'right' || position == 'bottom') && <Label />}
    </div>
  )
}

export default GroupAllCheckbox

GroupAllCheckbox.defaultProps = {
  position: 'left',
}
