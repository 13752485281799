// 경쟁거래 신청

import { httpGetOrderBookListByIssueCode } from '@src/api/httpAPI/httpOrderBookAPI'
import { httpPostOrder } from '@src/api/httpAPI/httpOrdersAPI'
import Button from '@src/components/Button'
import Checkbox from '@src/components/Checkbox'
import ContextButtons from '@src/components/ContextButtons'
import OrderTypeButtons from '@src/components/OrderTypeButtons'
import TextField from '@src/components/TextField'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import useDebounce from '@src/hooks/useDebounce'
import useOrderBook from '@src/hooks/useOrderBook'
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import ResultPopup from '@src/components/ResultPopup'

import { formatNumber } from '@src/util/formatNumber'
import LogoutButton from '@src/components/Button/LogoutButton'
import { COPY_PASTE, TRADE_REQUEST, VALUECHECK } from '@src/constants/NotiText'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../../api/storageAPI'

const OrdersRequest = () => {
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  // const [orderType, setOrderType] = useState<OrderType>(searchParams.get('orderType') as OrderType)
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const [isCopyPasteButtonShow, setIsCopyPasteButtonShow] = useState<boolean>(false)
  const [isOpenResultPopup, setIsOpenResultPopup] = useState<boolean>(false)
  const [resultOrdersRequest, setResultOrdersRequset] = useState<ResponsePostOrdersType[]>([])
  const trans = useTranslation()

  const { orderBookList, setOrderBookList } = useOrderBook<{
    requestVolume: number
  }>()

  const orderType = useMemo(() => {
    return searchParams.get('orderType') as OrderType
  }, [searchParams])

  const requestOrder = useCallback(async () => {
    try {
      const selectedOrderBookList = orderBookList
        .filter(({ issueCode }, index) => selectedIndex.includes(index) && issueCode)
        .map(({ issueCode, rate, requestVolume }) => ({
          issueCode,
          orderType,
          // orderType: orderType == 'LEND' ? 'BORROW' : 'LEND',
          rate,
          volume: requestVolume,
        }))
      if (!selectedOrderBookList.every(({ volume }) => volume > 0)) {
        alert(trans.t('modal.valueCheck.invalidValue'))
        return
      }
      const { data } = await httpPostOrder(selectedOrderBookList)
      setIsOpenResultPopup(true)
      setResultOrdersRequset(data)
    } catch {
      alert(trans.t('modal.tradeRequest.fail'))
    }
  }, [orderBookList, selectedIndex, orderType])

  const handleCopyButton = useCallback(() => {
    if (!orderBookList.length) return
    navigator.clipboard
      .writeText(
        orderBookList
          .map(({ issueCode, issueName, volume, requestVolume, rate, type }) => {
            return Object.values({
              type: OrderTypeText[type == 'LEND' ? 'BORROW' : 'LEND'],
              issueCode,
              issueName,
              volume,
              requestVolume,
              rate,
            }).join('\t')
          })
          .join('\n')
      )
      .catch(() => {
        alert(COPY_PASTE.copyFail)
      })
      .finally(() => {
        setIsCopyPasteButtonShow(false)
      })
  }, [navigator, orderType, orderBookList])

  const handlePasteButton = useCallback(async () => {
    try {
      const clipboard = await navigator.clipboard.readText()
      const clipboardItems = clipboard.split('\n').filter((v) => v)
      if (!(clipboardItems instanceof Array)) throw new Error(COPY_PASTE.pasteFail)

      const newOrderBookList: (OrderBookType & { requestVolume: number })[] = await Promise.all(
        clipboardItems.map(async (clipboardItem) => {
          const items = clipboardItem.split('\t')
          if (items.length !== 4) throw new Error(COPY_PASTE.pasteFail)
          // const [type, _issueCode, issueName, _volume, _rate] = items
          const [_issueCode, issueName, _volume, _rate] = items

          const volume = Number(_volume)
          const rate = parseFloat(Number(_rate).toFixed(2))

          if (!volume) throw new Error(trans.t('modal.valueCheck.invalidvalue'))
          if (String(volume).length > 8)
            throw new Error(trans.t('modal.valueCheck.upToEightCharacters'))
          if (Number.isNaN(rate)) throw new Error(trans.t('modal.valueCheck.invalidrate'))
          // if (rate >= 1000) throw new Error('요율은 정수 3자리까지 입력해주세요')
          // if (OrderTypeText[type] != (orderType == 'LEND' ? 'BORROW' : 'LEND'))
          //   throw new Error('대차구분이 올바르지 않습니다.')

          const orderBook = await httpGetOrderBookListByIssueCode({
            issueCode: _issueCode,
            type: orderType,
          })

          return {
            rate,
            requestVolume: volume,
            type: orderType,
            issueCode: orderBook.issueCode,
            issueName: orderBook.issueName,
            volume: orderBook.totalVolume,
            previousClosingPrice: orderBook.previousClosingPrice,
            availableVolume: orderBook.availableVolume,
            totalVolume: orderBook.totalVolume,
            weightedRate: orderBook.weightedRate,
          }
        })
      )

      setOrderBookList((prevOrderBookList) => [...prevOrderBookList, ...newOrderBookList])
      setSelectedIndex([...orderBookList, ...newOrderBookList].map((_, index) => index))
    } catch (err) {
      alert(err?.message)
    } finally {
      setIsCopyPasteButtonShow(false)
    }
  }, [navigator, orderType, orderBookList])

  const handleWindowClick = useCallback((e) => {
    setIsCopyPasteButtonShow(false)
  }, [])

  useEffect(() => {
    setSelectedIndex([])
    setOrderBookList([])
  }, [orderType])

  useEffect(() => {
    try {
      if (isCopyPasteButtonShow) {
        window.addEventListener('click', handleWindowClick)
      } else {
        window.removeEventListener('click', handleWindowClick)
      }
    } catch {
      //
    }
    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [isCopyPasteButtonShow])

  useEffect(() => {
    try {
      const issues = JSON.parse(searchParams.get('issues'))
      const orderType = searchParams.get('orderType')
      if (issues instanceof Array) {
        Promise.all(
          issues.map((issue) => {
            return httpGetOrderBookListByIssueCode({
              issueCode: issue.issueCode,
              type: issue.type,
            }).then((orderBook) => {
              return {
                ...issue,
                issueCode: orderBook.issueCode,
                issueName: orderBook.issueName,
                volume: orderBook.totalVolume,
                requestVolume: issue.volume,
                previousClosingPrice: orderBook.previousClosingPrice,
                availableVolume: orderBook.availableVolume,
                totalVolume: orderBook.totalVolume,
                weightedRate: orderBook.weightedRate,
              }
            })
          })
        ).then((orderBookList) => {
          setOrderBookList(orderBookList)
        })

        setSelectedIndex(issues.map((_, index) => index))
      }
      // if (orderType) {
      //   const nextSearchParams = new URLSearchParams()
      //   nextSearchParams.set('orderType', orderType)
      //   setSearchParams(nextSearchParams, { replace: true })
      // }
    } catch {
      setSelectedIndex([])
    }
  }, [])

  return (
    <>
      <section className={`orders-request ${orderType == 'BORROW' ? 'LEND' : 'BORROW'}`}>
        <article>
          <header>
            <OrderTypeButtons
              handleChangeOrderType={(orderType) => {
                // setSelectedIndex([])
                // setOrderBookList([])
                // setOrderType(orderType)
                // if (orderType == 'LEND') setOrderType('BORROW')
                // if (orderType == 'BORROW') setOrderType('LEND')
              }}
            />
          </header>
          <div className="bigger-wrapper">
            <div className="action-buttons">
              {searchParams.get('referer') == '/orders/list' && (
                <Button onClick={() => navigate(-1)}>뒤로</Button>
              )}
              <Button
                className="main-btn"
                color={selectedIndex.length ? 'green' : 'grey'}
                onClick={() => {
                  if (selectedIndex.length) requestOrder()
                }}
              >
                신청하기
              </Button>
            </div>
            <ul></ul>
            <div
              className="fixed-table"
              onContextMenu={(e) => {
                e.preventDefault()
                setContextMenuPosition({ x: e.pageX, y: e.pageY })
                setIsCopyPasteButtonShow(true)
              }}
            >
              <table>
                <thead>
                  <th>
                    <Checkbox
                      checked={selectedIndex.length == orderBookList.length}
                      onClick={() => {
                        if (selectedIndex.length == orderBookList.length) {
                          setSelectedIndex([])
                        } else {
                          setSelectedIndex(orderBookList.map((_, index) => index))
                        }
                      }}
                    />
                  </th>
                  <th>{trans.t('issueCode')}</th>
                  <th>{trans.t('issueName')}</th>
                  <th>호가잔량(주)</th>
                  <th>평균요율(%)</th>
                  <th>확정가능수량(주)</th>
                  <th>{trans.t('borrowListTable.volume')}</th>
                  <th>신청요율(주)</th>
                  <th>필요담보(원)</th>
                  <th></th>
                </thead>
                <tbody>
                  {orderBookList.map((orderBook, index) => (
                    <TableRow
                      key={`${orderBook.issueCode}-${index}`}
                      index={index}
                      orderBook={orderBook}
                      setOrderBookList={setOrderBookList}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                    />
                  ))}
                  <TableRow
                    key={orderBookList.length}
                    index={orderBookList.length}
                    orderBook={{
                      issueCode: '',
                      issueName: '',
                      volume: 0,
                      requestVolume: null,
                      previousClosingPrice: 0,
                      rate: null,
                      type: orderType,
                    }}
                    setOrderBookList={setOrderBookList}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    isNew
                    isExample={orderBookList.length == 0}
                  />
                </tbody>
              </table>
            </div>
          </div>
          <footer>
            <ul>
              <li>종목수 : {orderBookList.length}</li>
              <li>수량합계 : {orderBookList.reduce((acc, cur) => acc + cur.requestVolume, 0)}</li>
              <li>
                필요담보합계 :{' '}
                {formatNumber(
                  orderBookList.reduce(
                    (acc, cur) => acc + cur.previousClosingPrice * cur.requestVolume * 1,
                    0
                  )
                )}
                원
              </li>
            </ul>
          </footer>
        </article>
        {isCopyPasteButtonShow && (
          <ContextButtons
            handleClickPasteButton={handlePasteButton}
            handleClickCopyButton={handleCopyButton}
            position={contextMenuPosition}
          >
            붙여넣기
          </ContextButtons>
        )}
      </section>
      {isOpenResultPopup && (
        <ResultPopup
          close={() => {
            navigate('/orders/request')
          }}
          title="신청이 완료되었습니다."
          result={resultOrdersRequest}
        />
      )}
    </>
  )
}

interface TableRowType {
  index: number
  orderBook: OrderBookType & { requestVolume: number }
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  setOrderBookList: React.Dispatch<React.SetStateAction<OrderBookType[]>>
  isNew?: boolean
  isExample?: boolean
}

const TableRow: FunctionComponent<TableRowType> = ({
  orderBook,
  selectedIndex,
  setSelectedIndex,
  setOrderBookList,
  index,
  isNew,
  isExample,
}) => {
  const [requestVolume, setRequestVolume] = useState<number>(orderBook.requestVolume)
  const [rate, setRate] = useState<number | string>(orderBook.rate?.toFixed(2))
  const [issueCode, setIssueCode] = useState<string>(orderBook.issueCode)
  const [issueName, setIssueName] = useState<string>(orderBook.issueName)
  const debouncedVolume = useDebounce(requestVolume, 1000)
  const debouncedRate = useDebounce(rate, 1000)
  const mounted = useRef(false)
  const trans = useTranslation()

  const changeTableInput = useCallback(
    (data: Partial<OrderBookType & { requestVolume: number }>) => {
      setOrderBookList((orderBookList) => [
        ...orderBookList.slice(0, index),
        {
          ...orderBook,
          ...data,
        },
        ...orderBookList.slice(index + 1),
      ])
    },
    [setOrderBookList, orderBook, index]
  )

  useEffect(() => {
    if (!orderBook.issueCode) return

    if (String(debouncedVolume).length > 8) {
      alert(trans.t('modal.valueCheck.upToEightCharacters'))
      return
    }
    // if (!Number.isInteger(debouncedVolume) && debouncedVolume !== 0) {
    //   alert('정수만 입력해주세요')
    //   return
    // }
    // if (debouncedVolume > orderBook.availableVolume) {
    //   alert('신청가능한 수량이 아닙니다.')
    //   setRequestVolume(0)
    //   return
    // }
    changeTableInput({ requestVolume: debouncedVolume })
  }, [debouncedVolume])

  useEffect(() => {
    if (!orderBook.issueCode || debouncedRate == null) return
    // if (Number(debouncedRate) >= 1000) {
    //   alert('요율은 3자리까지 입력이 가능합니다.')
    //   setRate(orderBook.rate)
    //   return
    // }

    const fixedRate = Number(rate).toFixed(2)

    httpGetOrderBookListByIssueCode({
      issueCode: orderBook.issueCode,
      type: orderBook.type,
      rate: parseFloat(fixedRate),
    }).then((orderBook) => {
      changeTableInput({ ...orderBook, rate: parseFloat(fixedRate) })
      setRate(fixedRate)
    })

    // changeTableInput({ rate: parseFloat(Number(rate).toFixed(2)) })
  }, [debouncedRate])

  useEffect(() => {
    if (!orderBook.issueCode) return
    changeTableInput({ issueName })
  }, [issueName])

  useEffect(() => {
    if (issueCode.length !== 6 || !mounted.current) return
    httpGetOrderBookListByIssueCode({
      issueCode,
      type: orderBook.type,
    })
      .then((stock) => {
        changeTableInput({
          issueCode: stock.issueCode,
          issueName: stock.issueName,
          volume: stock.totalVolume,
          previousClosingPrice: stock.previousClosingPrice,
          availableVolume: stock.availableVolume,
          totalVolume: stock.totalVolume,
          weightedRate: stock.weightedRate,
        })
        if (isNew) {
          setSelectedIndex([...selectedIndex, index])
          setIssueCode('')
        }
      })
      .catch(() => {
        if (isExample) {
          setIssueCode('')
          setIssueName(trans.t('noIssueCode'))
          return
        }
        changeTableInput({
          issueCode: '',
          issueName: trans.t('noIssueCode'),
          volume: 0,
          requestVolume: 0,
          previousClosingPrice: 0,
          rate: 0,
          availableVolume: 0,
          totalVolume: 0,
          weightedRate: 0,
          type: orderBook.type,
        })
        setSelectedIndex(selectedIndex.filter((_index) => _index !== index))
      })
  }, [issueCode])

  useEffect(() => {
    if (!mounted.current) mounted.current = true
  }, [])

  return (
    <tr
      onClick={() => {
        if (isNew || isExample || !orderBook.issueCode) return
        const checked = selectedIndex.includes(index)
        if (!checked) {
          setSelectedIndex((selectedIndex) => [...selectedIndex, index])
        } else {
          setSelectedIndex((selectedIndex) => selectedIndex.filter((_index) => _index !== index))
        }
      }}
    >
      <td>
        {isExample ? (
          '<입력예시>'
        ) : isNew || !orderBook.issueCode ? null : (
          <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
        )}
      </td>
      <td>
        <TextField
          type="text"
          value={issueCode}
          placeholder={isExample ? '005930' : ''}
          pattern="[0-9]*"
          maxLength={6}
          onChange={(e) => {
            if (e.target.validity.valid) {
              setIssueCode(e.target.value)
            }
          }}
        />
      </td>
      <td className="left">
        <TextField
          placeholder={
            localStorageAPI.getItem('lang') === 'ko' && isExample ? '삼성전자(공란가능)' : ''
          }
          type="text"
          value={issueName}
          onChange={(e) => {
            setIssueName(e.target.value)
          }}
        />
      </td>
      <td className="right">
        {isExample ? '1,000(잔량조회)' : formatNumber(orderBook.totalVolume)}
      </td>
      <td className="right">
        {isExample ? '1.00(가중평균)' : formatNumber(orderBook.weightedRate)}
      </td>
      <td className="right">
        {isExample ? '1,000(수량조회)' : formatNumber(orderBook.availableVolume)}
      </td>
      <td>
        <TextField
          type="text"
          value={requestVolume}
          placeholder={isExample ? '1,000' : ''}
          pattern="[0-9]*"
          maxLength={8}
          min={0}
          disabled={!orderBook.issueCode}
          onChange={(e) => {
            if (e.target.validity.valid) {
              setRequestVolume(Number(e.target.value))
            }
          }}
        />
      </td>
      <td>
        <TextField
          type="text"
          value={rate}
          placeholder={isExample ? '0.20' : ''}
          min={0}
          pattern="^\d*(\.\d{0,2})?$"
          disabled={!orderBook.issueCode}
          onChange={(e) => {
            if (isFinite(Number(e.target.value))) {
              setRate(e.target.value)
            }
            // if (e.target.validity.valid && Number(e.target.value) < 1000) {
            //   setRate(e.target.value)
            // }
          }}
        />
      </td>
      <td className="right">
        {isExample
          ? '100,000,000(자동완성)'
          : formatNumber(orderBook.previousClosingPrice * orderBook.requestVolume * 1)}
      </td>
      <td></td>
    </tr>
  )
}

export default OrdersRequest
