import React from 'react'

import { Button, ListItemAvatar, Menu } from '@mui/material'
import { styled } from '@mui/system'
import MenuItem from '@mui/material/MenuItem'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import PopupTwoValue from './PopupTwoValue'

import NestedPopupInside from './NestedPopupInside'
import { useTranslation } from 'react-i18next'

interface PopupMenuItem {
  text: string
  value?: string[]
}

interface PopupProps {
  visibleText: string
  menuItems: PopupMenuItem[]
  queryKey?: string[]
  handlePopupTwoValueMenuClick?: (
    popupMenuValue: string[],
    queryKey: string[],
    popupClose: () => void
  ) => void
  pageType?: string
}

const MenuItemStatus = styled(MenuItem)({
  fontSize: '13px',
})

const NestedPopup = ({
  visibleText,
  menuItems,
  queryKey,
  handlePopupTwoValueMenuClick,
  pageType,
}: PopupProps) => {
  const trans = useTranslation()

  const popupBtnStyle = [
    {
      textTransform: 'none',
      fontWeight: 'bold',
      padding: '2px',
      backgroundColor: 'inherit',
      boxShadow: 'none !important',
      lineHeight: 'normal',
    },
    {
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
      '& >span': {
        marginTop: '20px !important',
      },
    },
  ]
  return (
    <PopupState variant="popover" popupId="basic-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="contained" sx={popupBtnStyle} {...bindTrigger(popupState)}>
            {visibleText}
            <KeyboardArrowDownIcon sx={{ width: '15px' }}></KeyboardArrowDownIcon>
          </Button>
          <Menu {...bindMenu(popupState)}>
            {menuItems.map((item, index) => {
              if (index === 0) {
                return (
                  <MenuItemStatus
                    onClick={() =>
                      handlePopupTwoValueMenuClick(item.value, queryKey, popupState.close)
                    }
                  >
                    {item.text}
                  </MenuItemStatus>
                )
              } else {
                return (
                  <div>
                    <NestedPopupInside
                      text={item.text}
                      queryKey={queryKey}
                      value={item.value[0]}
                      handlePopupTwoValueMenuClick={handlePopupTwoValueMenuClick}
                      parentClose={popupState.close}
                    ></NestedPopupInside>
                  </div>
                )
              }
              // onClick={() => handlePopupTwoValueMenuClick(item.value, queryKey, popupState.close)}
              //   >
              // {item.text}
              //   </MenuItemStatus>
            })}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}

export default NestedPopup
