export const MessageTypeText = {
  CONFIRMATION: '컨퍼메이션',
  ETC: '기타',
  NEW: '신규',
  RECALL: '리콜',
  UPDATE: '변경',
  URGENT: '긴급',
  CONTACT: '연락처문의',
  컨퍼메이션: 'CONFIRMATION',
  기타: 'ETC',
  신규: 'NEW',
  리콜: 'RECALL',
  변경: 'UPDATE',
  긴급: 'URGENT',
}

export const MessageTypeTextEn = {
  CONFIRMATION: '컨퍼메이션',
  ETC: '기타',
  NEW: '신규',
  RECALL: 'Recall',
  UPDATE: '변경',
  URGENT: 'Urgent',
  CONTACT: 'Contact request',
  컨퍼메이션: 'CONFIRMATION',
  기타: 'ETC',
  신규: 'NEW',
  리콜: 'RECALL',
  변경: 'UPDATE',
  긴급: 'URGENT',
}
