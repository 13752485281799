const getSeparetedByIssue = (borrowPool: PoolType[]) => {
  const issuNames: string[] = []
  const sepratedByIssue: PoolSepratedByName = {}
  borrowPool.forEach((borrow: any) => {
    if (!issuNames.includes(borrow.stock.issueName)) {
      issuNames.push(borrow.stock.issueName)
      sepratedByIssue[borrow.stock.issueName] = [borrow]
    } else {
      sepratedByIssue[borrow.stock.issueName].push(borrow)
    }
  })
  return sepratedByIssue
}

const getInfoByIssue = (sepratedByIssue: PoolSepratedByName) => {
  const infoByIssue: InfoByIssue = {}
  const keys = Object.keys(sepratedByIssue)
  const values: Array<PoolType[]> = Object.values(sepratedByIssue)
  for (let i = 0; i < keys.length; i++) {
    infoByIssue[keys[i]] = {
      issueCode: values[i][0].stock.issueCode,
      issueName: values[i][0].stock.issueName,
      issueEnglishName: values[i][0].stock.issueEnglishName,
      settlementType: values[i][0].settlementType,
      tradePrice: values[i][0].stock.tradePrice,
      volume: 0,
      remainVolume: 0,
      interestTotal: 0,
      rate: 0,
      idList: [],
    }
    values[i].forEach((value) => {
      infoByIssue[keys[i]].volume += value.volume
      infoByIssue[keys[i]].remainVolume += value.remainVolume
      infoByIssue[keys[i]].interestTotal += value.remainVolume * value.rate
      infoByIssue[keys[i]].idList.push(value.id)
    })
  }
  for (let i = 0; i < keys.length; i++) {
    infoByIssue[keys[i]].rate =
      infoByIssue[keys[i]].interestTotal / infoByIssue[keys[i]].remainVolume
  }
  return infoByIssue
}

export const getOutput = (
  separetedByIssueName: PoolSepratedByName,
  infoByIssueName: InfoByIssue
) => {
  const output: Array<IssueInfo | PoolType> = []
  const len = Object.keys(infoByIssueName).length
  for (let i = 0; i < len; i++) {
    output.push(Object.values(infoByIssueName)[i])
    const aSeparetedByIssueName: any = Object.values(separetedByIssueName)[i]
    for (let j = 0; j < aSeparetedByIssueName.length; j++) {
      const outPutLine = JSON.parse(JSON.stringify(Object.values(separetedByIssueName)[i][j]))
      output.push(outPutLine)
    }
  }
  return output
}

export const getGroupByIssueOutput = (borrowPool: PoolType[]) => {
  const sepratedByIssue = getSeparetedByIssue(borrowPool)
  const infoByIssue = getInfoByIssue(sepratedByIssue)
  const output = getOutput(sepratedByIssue, infoByIssue)
  return output
}
