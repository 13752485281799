import { FunctionComponent } from 'react'
import { CSVLink } from 'react-csv'

interface Props {
  headers: { label: string; key: string }[]
  data: {
    [key in string]: any
  }[]
  fileName: string
  children: React.ReactNode
}

const ExcelButton: FunctionComponent<Props> = (props) => {
  return (
    <CSVLink
      data={props.data}
      headers={props.headers}
      filename={props.fileName}
      className="excel-button"
      onClick={(e) => {
        if (!props.data.length) e.preventDefault()
      }}
    >
      {props.children}
    </CSVLink>
  )
}

export default ExcelButton
