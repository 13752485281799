import { atom } from 'recoil'

interface ToastModalState {
  isOpen: boolean
  innerString: string
  openTime: number
}

export const toastModalState = atom<ToastModalState>({
  key: 'toastModalState',
  default: {
    isOpen: false,
    innerString: '',
    openTime: 1000,
  },
})
