export const REQUEST = {
  succeed: '요청에 성공했습니다.',
  fail: '요청에 실패했습니다.',
  invalid: '잘못된 요청입니다.',
  confirm: '요청을 전송하시겠습니까?',
  serverError: '서버 오류가 발생했습니다.',
  updateFail: '갱신에 실패하였습니다.',
}

export const TRADE_REQUEST = {
  complete: '거래가 신청되었습니다.',
  error: '거래 신청 중 오류가 발생했습니다.',
  errorAndNeedCheck: '거래 신청 중 오류가 발생했습니다.',
  confirmListPrefix: '선택한 거래를 신청하시겠습니까?',
  fail: '거래 신청에 실패했습니다.',
}

export const PASSWORD_REQEUST = {
  succeed: '비밀번호 변경에 성공했습니다.',
  fail: '비밀번호 변경에 실패하였습니다.',
}

export const SAVE = {
  succeed: '저장에 성공하였습니다.',
  fail: '저장에 실패하였습니다.',
}

export const LOGIN = {
  succeed: '로그인에 성공했습니다.',
  fail: '로그인에 실패했습니다.',
}

export const CANCEL = {
  cancelConfirm: '취소하시겠습니까?',
  cancelAllConfirm: '전체내역을 취소 하시겠습니까?',
  cancelFailed: '취소에 실패했습니다.',
  cancelSucceed: '취소에 성공했습니다.',
}

export const VALUECHECK = {
  zeroValue: '주문수량을 확인해주세요.',
  noOrder: '선택된 주문이 없습니다.',
  noIds: '선택된 상대가 없습니다.',
  noValue: '주문수량을 확인해주세요.',
  noInput: '입력값이 없습니다.',
  noGroup: '선택된 그룹이 없습니다.',
  emailInvalid: '이메일 형식에 맞지 않습니다.',
  leastOneCharacter: '최소 글자수가 입력되지 않았습니다.(최소 1글자 이상)',
  upToEightCharacters: '최대 입력수량을 초과했습니다.(최대 8자리)',
  need12Characters: '계좌번호 형식에 맞지 않습니다.(12자리 숫자)',
  needPasswordCheck: '변경할 비밀번호를 확인해주세요.',
  invalidAccountNumber: '계좌번호가 없습니다',
  invalidvalue: '호가잔량 오류',
  invalidrate: '요율 오류',
  invalidValue: '신청수량 오류',
  invalidIssueCode: '종목코드 오류',
}

export const RESULT = {
  succeed: '전송에 성공했습니다.',
  fail: '전송에 실패했습니다.',
}

export const COPY_PASTE = {
  copyFail: '복사에 실패하였습니다.',
  pasteFail: '레이아웃 양식에 맞지 않습니다.',
}

export const MESSAGE = {
  confirm: '메시지를 전송합니다.',
  succeed: '메시지 전송에 성공했습니다.',
  fail: '메시지 전송에 실패했습니다.',
  noType: '거래구분을 선택해주세요',
  noPartner: '상대기관을 선택해주세요',
  applyToRegister: 'Admin will contact you shortly.',
  forgotPassword:
    '가입한 이메일(ID)을 사용하여 비밀번호 초기화 요청 메일을 보내주시면 확인 후 임시비밀번호를 보내드립니다.',
  forgotPasswordEnglish:
    'Please email us using your registered email. We will send you the temporary password.',
}
