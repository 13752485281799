export const Home = () => {
  return (
    <div className="home">
      <article>
        <div className="dashboard-wrap lend-dashboard-wrap">
          <div className="dashboard-title-wrap">
            <div className="dashboard-title">대여</div>
          </div>
        </div>
      </article>
    </div>
  )
}

export default Home
