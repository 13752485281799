import httpClient from './httpClient'

export const httpGetUser = async () => await httpClient.get<UserType>('/api/v1/users')

export const httpPutUser = async (user: Partial<UserType>) =>
  await httpClient.put<UserType>('/api/v1/users', { ...user })

export const httpPostMessageToken = async (token: string) =>
  await httpClient.post('/api/v1/users/messageToken', { token })

export const httpGetUserCompanyList = async () => await httpClient.get('/api/v1/users/companies')

export const httpPutUserPassword = async (currentPassword: string, newPassword: string) =>
  await httpClient.put('/api/v1/users/password', {
    currentPassword,
    newPassword,
  })

export const httpPostUserGroup = async (companyCode: string) => {
  await httpClient.post('/api/v1/users/group', { companyCode })
}

export const httpGetUserGroup = async () => {
  const { data } = await httpClient.get<UserGroupType[]>('/api/v1/users/group')

  return data
}
