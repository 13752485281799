import { httpGetDesignatedOrdersRequest } from '@src/api/httpAPI/httpDesignatedOrdersAPI'
import { useCallback, useEffect, useState } from 'react'
import useInfiniteScroll from './useInfiniteScroll'

export default function useDesignatedOrder<T>() {
  const [isLoading, setIsLoading] = useState(false)
  const [designatedOrderList, setDesignatedOrderList] = useState<DesignatedOrderRequestType[]>([])
  const [isLast, setIsLast] = useState(true)
  const [page, setPage] = useState(0)

  const getDesignatedOrderList = useCallback(
    async ({
      page,
      query,
      orderType,
      type,
      partnerAccountId,
      settlement,
    }: {
      page: number
      query: string
      orderType: OrderType
      type: 'ACCEPT' | 'CANCEL' | 'CHECK' | 'POST' | 'REJECT' | 'UPDATE'
      partnerAccountId: number | ''
      settlement: SettlementType
    }) => {
      setIsLoading(true)
      try {
        const designatedOrderList = await httpGetDesignatedOrdersRequest({
          type,
          orderType,
          page,
          size: 20,
          query,
          status: 'RECEIVED',
          partnerAccountId,
          settlementType: settlement,
        })

        if (page == 0) {
          setDesignatedOrderList(designatedOrderList)
        } else {
          setDesignatedOrderList((prevDesignatedOrderList) => [
            ...prevDesignatedOrderList,
            ...designatedOrderList,
          ])
        }

        setIsLast(designatedOrderList.length < 1)
      } catch (err) {
        //
      }
      setIsLoading(false)
    },
    []
  )

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  useEffect(() => {
    onInfiniteScrollInit(document.querySelector('.table-bottom'))
  }, [])

  useEffect(() => {
    if (isLoading || isLast) onInfiniteScrollDisconnect()
    else onInfiniteScrollUpdate()
  }, [isLoading, isLast])

  return {
    designatedOrderList,
    setDesignatedOrderList,
    page,
    setPage,
    getDesignatedOrderList,
  }
}
