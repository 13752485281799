import { httpGetPoolHistory } from '@src/api/httpAPI/httpPoolAPI'
import Checkbox from '@src/components/Checkbox'
import { OrderStatusText } from '@src/constants/OrderStatusText'
import { infScrollPageState } from '@src/stores/infScrollPageState'
import { isGroupChecked } from '@src/util/borrow-pool-list'
import { getIsInfScrollObserver } from '@src/util/borrow-pool-list/live'
import parseNumberWithComma from '@src/util/parseNumComma'
import axios from 'axios'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { LiveGroupRow, LiveSingleRow } from './BorrowPoolListLiveRow'
import {
  CompanyGroupRow,
  CompanySingleRow,
  IssueGroupRow,
  IssueSingleRow,
} from './BorrowPoolListTableRow'

interface Props {
  actionRecord: ActionRecord
  livePoolOutput: any
  setActionRecord: React.Dispatch<React.SetStateAction<ActionRecords>>
  selectedIds: number[]
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>
  tab: string
}

interface BorrowLiveInfo {
  idList?: number[]
  id?: number
  issueCode: string
  tradeType: string
  issueName: string
  volume: number
  rate: number
  settlementType: SettlementType
  desiredVolume?: number
}

export const BorrowPoolListTable: FunctionComponent<Props> = (props) => {
  const actionRecord = props.actionRecord
  const [selectedIds, setSelectedIds] = [props.selectedIds, props.setSelectedIds]
  const [infScrollPage, setInfScrollPage] = useRecoilState(infScrollPageState)

  const tableDivRef = useRef<HTMLDivElement>()

  const trans = useTranslation()

  useEffect(() => {
    if (infScrollPage === 0) {
      // const topPos = tableDivRef.current.offsetTop
      // console.log('topPos', topPos)
      tableDivRef.current.scrollTop = -200
    }
  }, [infScrollPage])

  const thArray: string[] = [
    trans.t('borrowListTable.orderType'),
    trans.t('borrowListTable.issueCode'),
    trans.t('borrowListTable.issueName'),
    trans.t('borrowListTable.normalVolume'),
    trans.t('borrowListTable.normalRate'),
    trans.t('borrowListTable.desiredVolume'),
    // '구분',
    // '종목코드',
    // '종목명',
    // '수량(주)',
    // '요율(%)',
    // // '결제유형',
    // '희망수량(주)',
  ]
  // return <></>
  return (
    <div
      ref={tableDivRef}
      className={'fixed-table live order-live BORROW' + ' ' + 'tab-' + props.tab.toUpperCase()}
    >
      <table>
        <thead>
          <tr>
            <th>
              <Checkbox
                readOnly
                checked={
                  selectedIds.length !== 0 &&
                  selectedIds.length === props.livePoolOutput.filter((pool) => pool.id).length
                }
                // defaultChecked={selectedIndex.length == props.pool.length}
                onClick={() => {
                  if (
                    selectedIds.length === props.livePoolOutput.filter((pool) => pool.id).length
                  ) {
                    setSelectedIds([])
                  } else {
                    setSelectedIds(
                      props.livePoolOutput.filter((pool) => pool.id).map((select) => select.id)
                    )
                  }
                }}
              />
            </th>
            {thArray.map((thStr) => (
              <th key={thStr}>{thStr}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.livePoolOutput.map((row: LivePoolOutput, index: number) => {
            if (row.idList) {
              return
            }
            return (
              <LiveSingleRow
                key={row.id}
                actionRecord={actionRecord}
                outputPool={row}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                isInfScrollObserver={getIsInfScrollObserver(props.livePoolOutput, index)}
              />
            )
          })}
          {/* //    : (
          //     <LiveGroupRow
          //       outputPool={row}
          //       selectedIds={selectedIds}
          //       setSelectedIds={setSelectedIds}
          //       isInfScrollObserver={getIsInfScrollObserver(props.livePoolOutput, index)}
          //     />
          //   )
          // })} */}
        </tbody>
      </table>
    </div>
  )
}

export default BorrowPoolListTable
