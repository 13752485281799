interface InputOnChangeEvent {
  target: {
    value: string
  }
}

interface InputKeyDownEvent {
  preventDefault: () => void
  code: string
  key: string
}

const numberRegExp = new RegExp('^[0-9]*$')

const volumeRateKeys = ['Backspace', 'Delete', 'v', 'c', 'z', 'x']
const inputRateKeys = [
  'Backspace',
  'Delete',
  'v',
  'c',
  'z',
  'x',
  '.',
  'Tab',
  'ArrowLeft',
  'ArrowRight',
]

const isValidKey = (e: any) => {
  if (e.keyCode >= 48 && e.keyCode <= 57) {
    return true
  }
  if (e.keyCode >= 96 && e.keyCode <= 105) {
    return true
  }
  return false
}

export const isRateInputCanBeOnChange = (e: InputOnChangeEvent) => {
  if (e.target.value === '') {
    return true
  }
  if (e.target.value.split('.').length > 2) {
    return false
  }
  if (e.target.value.split('.')[1]?.toString().length > 2) {
    return false
  }
  if (
    e.target.value.substring(0, 1) === '.' ||
    e.target.value.substring(e.target.value.length, e.target.value.length + 1) === '.'
  ) {
    return false
  }
  const parsed = e.target.value.replace('.', '')
  if (parsed.length > 6) {
    return false
  }
  if (!numberRegExp.test(parsed)) {
    return false
  }
  return true
}

export const isInputKeyCanBeEntered = (e: InputKeyDownEvent) => {
  if (inputRateKeys.includes(e.key)) {
    return true
  }
  if (isValidKey) {
    return true
  }
  e.preventDefault()
  return false
}

export const isVolumeInputCanBeOnChange = (e: InputOnChangeEvent) => {
  if (e.target.value === '') {
    return true
  }
  const replaced = e.target.value.replaceAll(',', '')
  if (numberRegExp.test(replaced)) {
    e.target.value = replaced
    return true
  }
  if (!numberRegExp.test(e.target.value)) {
    return false
  }
  return true
}
