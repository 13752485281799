import { CSSProperties, FunctionComponent } from 'react'

interface Props {
  style?: CSSProperties
  radius?: boolean
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  color?: 'blue' | 'red' | 'grey' | 'green'
  type?: 'button' | 'submit' | 'reset'
  children: React.ReactNode
}

const Button: FunctionComponent<Props> = (props) => {
  return (
    <button
      type={props.type}
      className={`${props.color} ${props.className || ''}`}
      style={{
        ...props.style,
        borderRadius: props.style?.borderRadius
          ? props.style.borderRadius
          : props.radius
          ? '20px'
          : 'none',
      }}
      onClick={(e) => {
        if (props.type == 'button') {
          e.preventDefault()
          if (props.onClick) props.onClick(e)
        }
      }}
    >
      {props.children}
    </button>
  )
}

export default Button

Button.defaultProps = {
  color: 'grey',
  type: 'button',
}
