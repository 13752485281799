import { atom } from 'recoil'

interface SucceedModalState {
  isOpen: boolean
  innerString: string
  closeFunction: () => void
}

export const succeedModalState = atom<SucceedModalState>({
  key: 'succeedModalState',
  default: {
    isOpen: false,
    innerString: '성공임',
    closeFunction: () => {
      return
    },
  },
})
