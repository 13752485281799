import styled from '@emotion/styled'
import {
  Paper,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material'
import { FunctionComponent, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import Button from '../Button'

interface Props {
  handleClickPasteButton?: () => void
  handleClickCopyButton?: () => void
  children: React.ReactNode
  position: { x: number; y: number }
}

const ContextButton = styled(Button)`
  background-color: #e5e4e2 !important;
  &:hover {
    background-color: #d0cfd0 !important;
  }
`

const IconMenu: FunctionComponent<Props> = ({
  handleClickPasteButton,
  handleClickCopyButton,
  position,
}) => {
  // useEffect(() => {
  //   let flag = true
  //   window.addEventListener('keydown', (e) => {
  //     if (!e.ctrlKey) {
  //       return
  //     }
  //     if (e.key === 'c') {
  //       if (flag) {
  //         flag = false
  //         handleClickCopyButton()
  //       }
  //     }
  //     if (e.key === 'v') {
  //       if (flag) {
  //         flag = false
  //         handleClickPasteButton()
  //       }
  //     }
  //   })
  // }, [])

  return (
    <Paper
      sx={{
        width: 140,
        maxWidth: '100%',
        position: 'absolute',
        top: `${position.y - 130}px`,
        left: `${position.x - 260}px`,
        backgroundColor: '#F4F4F4',
      }}
    >
      <MenuList sx={{ padding: '0px' }}>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            handleClickCopyButton()
          }}
        >
          <ListItemText>복사하기</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ctrl+c
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            handleClickPasteButton()
          }}
        >
          <ListItemText>붙여넣기</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ctrl+v
          </Typography>
        </MenuItem>
      </MenuList>
    </Paper>
  )
}

export default IconMenu
