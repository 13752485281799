import Checkbox from '@src/components/Checkbox'
import Popup from '@src/components/PopupState/PopupState'
import { ConfirmStatusText } from '@src/constants/ConfirmStatusText'
import { CLASSES } from '@src/constants/Global'
import { OrderStatusText, OrderStatusTextEn } from '@src/constants/OrderStatusText'
import { RequestTypeText } from '@src/constants/RequestTypeText'
import { TransactionTypeText, TransactionTypeTextEn } from '@src/constants/TransactionTypeText'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import { contverTrueFalseToYorN } from '@src/util/contverTrueFalseToYorN'
import { formatNumber } from '@src/util/formatNumber'
import parseNumberWithComma from '@src/util/parseNumComma'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { localStorageAPI } from '../../api/storageAPI'

interface searchNameCell {
  text: string
  value: string
}

interface QueryPayload {
  query?: string
  companyName?: string
  settlementType?: SettlementType | ''
  transactionType?: TransactionType | ''
  requestType?: RequestOptionType | ''
  isRequester?: boolean
}

interface Props {
  setSelectedRequestIds: React.Dispatch<React.SetStateAction<number[]>>
  requests: any[]
  setRequests: React.Dispatch<React.SetStateAction<RequestV2Type[]>>
  queryPayload: QueryPayload
  setQueryPayload: React.Dispatch<React.SetStateAction<QueryPayload>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  companyNames: searchNameCell[]
  issueNames: searchNameCell[]
  currentOrderTypeUpper: string
}

const getConfirmationStatusText = (
  confirmationStatus: ConfirmationStatusType,
  orderType: OrderType
) => {
  if (confirmationStatus === 'COMPLETED') {
    if (orderType === 'BORROW') {
      return '수신완료'
    }
    if (orderType === 'LEND') {
      return '발송완료'
    }
  }
  if (confirmationStatus === 'ENABLE') {
    return '발송가능'
  }
  if (confirmationStatus === 'UNABLE') {
    return '상대방발송전'
  }
}

const isCanConfirm = (confirmationStatus: ConfirmationStatusType, orderType: OrderType) => {
  if (confirmationStatus === 'COMPLETED' && orderType === 'BORROW') {
    return false
  }
  return true
}

const LendOrderResponseTable: FunctionComponent<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = [props.selectedIndex, props.setSelectedIndex]
  const forceRefresh = useRecoilValue(forceRefreshState)
  const tableDivRef = useRef<HTMLTableElement>(null)
  const trans = useTranslation()

  useEffect(() => {
    tableDivRef.current.scrollTop = -200
  }, [forceRefresh])

  useEffect(() => {
    props.setSelectedRequestIds(
      props.requests
        .filter((_, index) => selectedIndex.includes(index))
        .map(({ ledgerId }) => ledgerId)
    )
  }, [selectedIndex])

  const handlePopupMenuClick = (
    popupMenuValue: string,
    queryKey: string,
    popupClose: () => void
  ) => {
    const newPayLoad = { ...props.queryPayload }
    newPayLoad[queryKey] = popupMenuValue
    props.setQueryPayload(newPayLoad)
    props.setPage(0)
    popupClose()
  }

  const getSelectableIndexList = (requests) => {
    const indexes: number[] = []
    requests.forEach((request, index) => {
      if (getConfirmationStatusText(request.confirmationStatus, request.orderType) === '발송완료') {
        indexes.push(index)
      }
    })
    return indexes
  }

  const selectableIndexList = useMemo(() => {
    return getSelectableIndexList(props.requests)
  }, [props.requests])

  // const selectableIndexList = props.requests.map((_, index) => index)

  return (
    <div
      className={'fixed-table fixed-table-confirm' + ' ' + props.currentOrderTypeUpper}
      ref={tableDivRef}
    >
      <table>
        <thead>
          <th>
            <Checkbox
              checked={
                selectedIndex.length !== 0 && selectedIndex.length === selectableIndexList.length
              }
              onClick={() => {
                if (selectedIndex.length == selectableIndexList.length) {
                  setSelectedIndex([])
                } else {
                  setSelectedIndex(selectableIndexList)
                }
              }}
            />
          </th>
          <th className="lend-th-change">
            <Popup
              visibleText={trans.t('lendListTable.orderType')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: trans.t('lend'),
                  value: 'LEND',
                },
                {
                  text: trans.t('borrow'),
                  value: 'BORROW',
                },
              ]}
              queryKey="orderType"
              handlePopupMenuClick={handlePopupMenuClick}
            ></Popup>
          </th>
          <th className="lend-th-change">
            <Popup
              visibleText={trans.t('lendListTable.settlementType')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: 'T',
                  value: 'TODAY',
                },
                {
                  text: 'T1',
                  value: 'NEXT_DAY',
                },
              ]}
              queryKey="settlementType"
              handlePopupMenuClick={handlePopupMenuClick}
            ></Popup>
          </th>
          <th className="lend-th-change">
            <Popup
              visibleText={trans.t('lendListTable.tradeType')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.designated'),
                  value: 'DESIGNATED',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.custom'),
                  value: 'CUSTOM',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.none'),
                  value: 'NONE',
                },
                {
                  text: trans.t('lendListTable.innerTradeType.bidOffer'),
                  value: 'COMPETITIVE',
                },
              ]}
              queryKey="transactionType"
              handlePopupMenuClick={handlePopupMenuClick}
            ></Popup>
          </th>
          {/* <th>{trans.t('lendListTable.poolStatus')}</th> */}
          {/* <th>{trans.t('lendListTable.applicationDate')}</th> */}
          <th>{trans.t('matchedDate')}</th>
          <th>{trans.t('matchedId')}</th>
          <th className="lend-th-change">
            <Popup
              visibleText={trans.t('partnerAsset')}
              menuItems={props.companyNames}
              queryKey="companyName"
              handlePopupMenuClick={handlePopupMenuClick}
            ></Popup>
          </th>
          <th>{trans.t('issueCode')}</th>
          <th className="lend-th-change">
            <Popup
              visibleText={trans.t('issueName')}
              menuItems={props.issueNames}
              queryKey="query"
              handlePopupMenuClick={handlePopupMenuClick}
            ></Popup>
          </th>
          <th>{trans.t('table.volume')}</th>
          <th>{trans.t('table.rate')}</th>

          {/* <th className="lend-th-change">
            <Popup
              visibleText={trans.t('cancellable')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: 'Y',
                  value: 'true',
                },
                {
                  text: 'N',
                  value: 'false',
                },
              ]}
              queryKey="isCancelable"
              handlePopupMenuClick={handlePopupMenuClick}
            ></Popup>
          </th> */}

          <th className="lend-th-change">
            <Popup
              visibleText={trans.t('lendListTable.swap')}
              menuItems={[
                {
                  text: trans.t('lendListTable.all'),
                  value: '',
                },
                {
                  text: 'Y',
                  value: 'true',
                },
                {
                  text: 'N',
                  value: 'false',
                },
              ]}
              queryKey="isSwap"
              handlePopupMenuClick={handlePopupMenuClick}
            ></Popup>
          </th>
          <th></th>
        </thead>
        <tbody>
          {props.requests.map((request, index) => (
            <tr
              onClick={() => {
                if (!isCanConfirm(request.confirmationStatus, request.orderType)) return
                const checked = selectedIndex.includes(index)
                if (!checked) {
                  setSelectedIndex((selectedIndex) => [...selectedIndex, index])
                } else {
                  setSelectedIndex((selectedIndex) =>
                    selectedIndex.filter((_index) => _index !== index)
                  )
                }
              }}
            >
              <td>
                {isCanConfirm(request.confirmationStatus, request.orderType) && (
                  <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
                )}
              </td>
              <td className={CLASSES.tableCellFilterable}>
                {localStorageAPI.getItem('lang') === 'en'
                  ? OrderStatusTextEn[request.orderType]
                  : OrderStatusText[request.orderType]}
              </td>
              <td className={CLASSES.tableCellFilterable}>
                {!request.settlementType ? '' : request.settlementType === 'TODAY' ? 'T' : 'T1'}
              </td>
              <td className={CLASSES.tableCellFilterable}>
                {localStorageAPI.getItem('lang') === 'en'
                  ? TransactionTypeTextEn[request.transactionType]
                  : TransactionTypeText[request.transactionType]}
              </td>
              {/* <td>{getConfirmationStatusText(request.confirmationStatus, request.orderType)}</td> */}
              {/* <td>{request.orderDateTime?.substring(0, 14)}</td> */}
              <td>{request.matchedDateTime?.substring(0, 14)}</td>
              <td>{request.ledgerId}</td>
              <td className={CLASSES.tableCellFilterable}>
                <span>
                  {localStorageAPI.getItem('lang') === 'en'
                    ? request.partnerAccount.companyEnglishName
                    : request.partnerAccount.companyName}{' '}
                  <br></br>
                  {'(' +
                    request.partnerAccount.companyCode +
                    '-' +
                    request.partnerAccount.companyPropertyCode +
                    '-' +
                    request.partnerAccount.companySLBCode +
                    ')'}
                </span>
              </td>
              <td>{request.stock.issueCode}</td>
              <td className={CLASSES.tableCellFilterable}>
                {localStorageAPI.getItem('lang') === 'en'
                  ? request.stock.issueEnglishName
                  : request.stock.issueName}
              </td>
              <td className="right">{parseNumberWithComma(request.volume)}</td>
              <td className="right">{request.rate.toFixed(2)}</td>
              {/* <td className={CLASSES.tableCellFilterable}>
                {contverTrueFalseToYorN(request.isCancelable)}
              </td> */}
              <td className={CLASSES.tableCellFilterable}>
                {contverTrueFalseToYorN(request.isSwap)}
              </td>
              <td></td>
            </tr>
          ))}
          <div className="table-bottom"></div>
        </tbody>
      </table>
    </div>
  )
}

export default LendOrderResponseTable
