import { useEffect, useRef, useState } from 'react'

interface Props {
  (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void
}

function useInfiniteScroll(cb: Props) {
  const [target, setTarget] = useState<HTMLElement | null>(null)
  const io = useRef<IntersectionObserver | null>(null)

  const options = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0,
  }

  useEffect(() => {
    if (!target) return
    io.current = new IntersectionObserver(cb, options)
    io.current.observe(target)
    return () => {
      io.current?.disconnect()
    }
  }, [target])

  const onInfiniteScrollInit = (target: HTMLElement | null) => {
    setTarget(target)
  }
  const onInfiniteScrollUpdate = () => {
    return io.current && io.current.observe(target as HTMLElement)
  }

  const onInfiniteScrollDisconnect = () => {
    return io.current && io.current.disconnect()
  }

  return { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect }
}

export default useInfiniteScroll
