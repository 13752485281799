import { styled } from '@mui/system'
import { consoleSandbox } from '@sentry/utils'
import useVolumeFormat from '@src/hooks/useVolumeFormat'
import { overAmountModalState } from '@src/stores/overAmountModalState'
import parseNumComma from '@src/util/parseNumComma'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'

interface PropsType {
  outputPool: any
  actionRecord: any
  inputTmpKey: any
  colorChangeHighNum?: number
}

const Input = styled('input')({
  textAlign: 'right',
  width: '100%',
  boxShadow: 'inset 0 1px 3px 0 rgb(171 171 171 / 50%)',
  border: 'none',
})

const inputVolumeKeys = [
  'Backspace',
  'Delete',
  'v',
  'c',
  'z',
  'x',
  'Tab',
  'ArrowLeft',
  'ArrowRight',
]

export const BorrowPoolInput = ({
  outputPool,
  actionRecord,
  inputTmpKey,
  colorChangeHighNum,
}: PropsType) => {
  const [value, setValue] = useState('0')
  const [isHighNum, setIsHignNum] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [overAmountModal, setOverAmountModal] = useRecoilState(overAmountModalState)

  const numberFormat = useVolumeFormat()

  useEffect(() => {
    inputRef.current.addEventListener('wheel', function (e) {
      e.preventDefault()
    })
  }, [])

  // && e.code.substring(0, 6) !== 'numpad'

  const isValidKey = (e: any) => {
    if (e.keyCode >= 48 && e.keyCode <= 57) {
      return true
    }
    if (e.keyCode >= 96 && e.keyCode <= 105) {
      return true
    }
    return false
  }

  const inputRef = useRef<HTMLInputElement>()
  return (
    <>
      {/* <BorrowPoolInputTostModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        maxVolume={outputPool.remainVolume}
      ></BorrowPoolInputTostModal> */}
      <Input
        sx={{ color: isHighNum ? 'red' : 'inherit', textAlign: 'right' }}
        value={value}
        ref={inputRef}
        type="text"
        min="0"
        maxLength={12}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onKeyDown={(e: any) => {
          const start = e.target.selectionStart
          const end = e.target.selectionEnd
          const isLengthOne = start === end
          if (e.key === 'Delete' && e.target.value[start] === ',' && isLengthOne) {
            e.target.setSelectionRange(start + 1, start + 1)
            e.preventDefault()
            return
          }
          if (inputVolumeKeys.includes(e.key)) {
            return
          }
          if (!isValidKey(e)) {
            e.preventDefault()
            return
          }
        }}
        onCompositionStart={(e) => {
          inputRef.current.blur()
          requestAnimationFrame(() => {
            inputRef.current.focus()
          })
        }}
        onChange={(e) => {
          let currentValue = e.target.value.replaceAll(',', '')
          if (currentValue[0] === '0') {
            currentValue = currentValue.substring(1, currentValue.length)
          }
          if (currentValue.replaceAll(',', '') > outputPool.remainVolume) {
            setOverAmountModal({
              ...overAmountModal,
              maxVolume: outputPool.remainVolume,
              isOpen: true,
            })
            const newValue = numberFormat(String(outputPool.remainVolume))
            let prevCommaCount = value.split(',').length - 1
            prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
            const start = e.target.selectionStart
            let nextCommaCount = newValue.split(',').length - 1
            nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
            const commaDiff = nextCommaCount - prevCommaCount
            e.target.value = newValue
            e.target.setSelectionRange(start + commaDiff, start + commaDiff)
            if (e.target.value.length < 2) {
              e.target.setSelectionRange(1, 1)
            }
            setValue(newValue)
            actionRecord[inputTmpKey] = {
              ...actionRecord[inputTmpKey],
              volume: newValue.replaceAll(',', ''),
            }
            if (Number(newValue.replaceAll(',', '')) > colorChangeHighNum) {
              setIsHignNum(true)
            } else {
              setIsHignNum(false)
            }
            return
          }
          actionRecord[inputTmpKey] = {
            ...actionRecord[inputTmpKey],
            volume: e.target.value.replaceAll(',', ''),
          }
          const newValue = numberFormat(currentValue.replaceAll(',', ''))
          let prevCommaCount = value.split(',').length - 1
          prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
          const start = e.target.selectionStart
          let nextCommaCount = newValue.split(',').length - 1
          nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
          const commaDiff = nextCommaCount - prevCommaCount
          e.target.value = newValue
          e.target.setSelectionRange(start + commaDiff, start + commaDiff)
          if (e.target.value.length < 2) {
            e.target.setSelectionRange(1, 1)
          }
          setValue(newValue)
          if (Number(newValue.replaceAll(',', '')) > colorChangeHighNum) {
            setIsHignNum(true)
          } else {
            setIsHignNum(false)
          }
        }}
        onFocus={(e) => {
          if (e.target.value === '0') {
            e.target.value = outputPool.remainVolume.toString()
            actionRecord[inputTmpKey] = {
              ...actionRecord[inputTmpKey],
              volume: e.target.value.replaceAll(',', ''),
            }
            if (parseInt(e.target.value) > colorChangeHighNum) {
              setIsHignNum(true)
            } else {
              setIsHignNum(false)
            }
            const newValue = numberFormat(e.target.value.replaceAll(',', ''))
            setValue(newValue)
            e.target.value = newValue
          }
          e.target.select()
        }}
      />
    </>
  )
}

export const BorrowPoolLiveInput = ({ outputPool, actionRecord, inputTmpKey }: PropsType) => {
  const [value, setValue] = useState('0')
  const [overAmountModal, setOverAmountModal] = useRecoilState(overAmountModalState)
  useEffect(() => {
    inputRef.current.addEventListener('wheel', function (e) {
      e.preventDefault()
    })
    // add custom scroll code if you want
  }, [])

  const numberFormat = useVolumeFormat()

  const inputRef = useRef<HTMLInputElement>()
  return (
    <Input
      value={value}
      // value={actionRecord[inputTmpKey] ? actionRecord[inputTmpKey].value : 0}
      ref={inputRef}
      type="text"
      onClick={(e) => {
        e.stopPropagation()
      }}
      onChange={(e) => {
        if (e.target.value.replaceAll(',', '') > outputPool.volume) {
          setOverAmountModal({
            ...overAmountModal,
            maxVolume: outputPool.volume,
            isOpen: true,
          })
          const newValue = numberFormat(String(outputPool.volume))
          let prevCommaCount = value.split(',').length - 1
          prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
          const start = e.target.selectionStart
          let nextCommaCount = newValue.split(',').length - 1
          nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
          const commaDiff = nextCommaCount - prevCommaCount
          e.target.value = newValue
          e.target.setSelectionRange(start + commaDiff, start + commaDiff)
          if (e.target.value.length < 2) {
            e.target.setSelectionRange(1, 1)
          }
          setValue(newValue)
          actionRecord[inputTmpKey] = {
            ...actionRecord[inputTmpKey],
            volume: newValue.replaceAll(',', ''),
          }
          return
        }
        actionRecord[inputTmpKey] = {
          ...actionRecord[inputTmpKey],
          volume: e.target.value.replaceAll(',', ''),
        }
        const newValue = numberFormat(e.target.value.replaceAll(',', ''))
        setValue(newValue)
      }}
      onFocus={(e) => {
        if (e.target.value === '0' || e.target.value === '') {
          e.target.value = outputPool.volume.toString()
          actionRecord[inputTmpKey] = {
            ...actionRecord[inputTmpKey],
            volume: e.target.value.replaceAll(',', ''),
          }
        }
        const newValue = numberFormat(e.target.value.replaceAll(',', ''))
        e.target.value = newValue
        setValue(newValue)
        e.target.select()
      }}
    />
  )
}
