import * as React from 'react'

interface PropsType {
  style?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
  spinnerStyle?: React.CSSProperties
}
const Loading: React.FunctionComponent<PropsType> = (props) => {
  return (
    <div style={{ ...props.wrapperStyle }}>
      <div className="loading-spinner-wrapper" style={{ ...props.style }}>
        <div
          className="loading-spinner"
          style={{ position: props.style?.position, ...props.spinnerStyle }}
        />
      </div>
    </div>
  )
}

export default Loading
