import { TopBlinkState } from '@src/stores/topBlinkState'

export const getTopBlink = (
  topNotiCounts: TopNotiCounts,
  newNotiCounts: TopNotiCounts,
  prevTopBlink: TopBlinkState
) => {
  const topBlinkState: TopBlinkState = {
    borrow: {
      requestCount: prevTopBlink.borrow.requestCount
        ? true
        : newNotiCounts.borrow.requestCount > topNotiCounts.borrow.requestCount
        ? true
        : false,
      cancelRequestCount: prevTopBlink.borrow.cancelRequestCount
        ? true
        : newNotiCounts.borrow.cancelRequestCount > topNotiCounts.borrow.cancelRequestCount
        ? true
        : false,
      matchedCount: prevTopBlink.borrow.matchedCount
        ? true
        : newNotiCounts.borrow.matchedCount > topNotiCounts.borrow.matchedCount
        ? true
        : false,
    },
    lend: {
      requestCount: prevTopBlink.lend.requestCount
        ? true
        : newNotiCounts.lend.requestCount > topNotiCounts.lend.requestCount
        ? true
        : false,
      cancelRequestCount: prevTopBlink.lend.cancelRequestCount
        ? true
        : newNotiCounts.lend.cancelRequestCount > topNotiCounts.lend.cancelRequestCount
        ? true
        : false,
      matchedCount: prevTopBlink.lend.matchedCount
        ? true
        : newNotiCounts.lend.matchedCount > topNotiCounts.lend.matchedCount
        ? true
        : false,
    },
    recallCount: prevTopBlink.recallCount
      ? true
      : newNotiCounts.recallCount > topNotiCounts.recallCount
      ? true
      : false,
    urgentCount: prevTopBlink.urgentCount
      ? true
      : newNotiCounts.urgentCount > topNotiCounts.urgentCount
      ? true
      : false,
  }
  return topBlinkState
}
