import { CSSProperties, FunctionComponent, useCallback } from 'react'

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  style?: CSSProperties
  position?: 'left' | 'right' | 'top' | 'bottom'
  id?: string
  label?: string
  options?: {
    label: string
    value: any
    isHidden?: boolean
  }[]
}

const SelectOption: FunctionComponent<Props> = ({
  style,
  position,
  id,
  label,
  children,
  options,
  ...props
}) => {
  const Label = useCallback(() => {
    if (!label) return null
    return <label htmlFor={id}>{label}</label>
  }, [label, id])

  return (
    <div className={`select-option ${position}`} style={style}>
      {(position == 'left' || position == 'top') && <Label />}
      <select id={id} {...props}>
        {options?.map(({ label, value, isHidden }) => (
          <option key={`${label}-${value}`} value={value} hidden={isHidden} disabled={isHidden}>
            {label}
          </option>
        ))}
      </select>
      {children}
      {(position == 'right' || position == 'bottom') && <Label />}
    </div>
  )
}

export default SelectOption

SelectOption.defaultProps = {
  position: 'left',
}
