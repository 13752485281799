import Checkbox from '@src/components/Checkbox'
import { OrderStatusText } from '@src/constants/OrderStatusText'
import { OrderTypeText, OrderTypeTextEn } from '@src/constants/OrderTypeText'
import { PoolStatusText } from '@src/constants/PoolStatusText'
import { formatNumber } from '@src/util/formatNumber'
import { getBurnoutRate } from '@src/util/getBurnoutRate'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import React from 'react'
import { Button, Menu } from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { MenuItemStatus } from '@src/components/MenuItem/MenuItemStatus'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface Props {
  setSelectedPoolIds: React.Dispatch<React.SetStateAction<number[]>>
  poolHistory: PoolHistoryType[]
  setPoolStatus: React.Dispatch<React.SetStateAction<PoolStatusType | 'ALL'>>
  tab: string
}

const LendPoolMatched: FunctionComponent<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const trans = useTranslation('translation', { keyPrefix: 'lendListTable' })

  useEffect(() => {
    props.setSelectedPoolIds(
      props.poolHistory.filter((_, index) => selectedIndex.includes(index)).map(({ id }) => id)
    )
  }, [selectedIndex])

  const handleMenuItemStatusClick = useCallback((value: string, popupStateClose: () => void) => {
    props.setPoolStatus(value as PoolStatusType | 'ALL')
    popupStateClose()
  }, [])

  return (
    <div className={'fixed-table tlist' + ' ' + 'tab-' + props.tab.toUpperCase()}>
      <table>
        <thead>
          {/* <th>
            <Checkbox
              checked={selectedIndex.length == props.poolHistory.length}
              onClick={() => {
                if (selectedIndex.length == props.poolHistory.length) {
                  setSelectedIndex([])
                } else {
                  setSelectedIndex(props.poolHistory.map((_, index) => index))
                }
              }}
            />
          </th> */}
          <th>{trans.t('createdDate')}</th>
          <th>{trans.t('orderType')}</th>
          <th>{trans.t('settlementType')}</th>
          {/* <th>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    상태<KeyboardArrowDownIcon sx={{ width: '15px' }}></KeyboardArrowDownIcon>
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItemStatus
                      onClick={() => handleMenuItemStatusClick('ALL', popupState.close)}
                    >
                      전체
                    </MenuItemStatus>
                    <MenuItemStatus
                      onClick={() => handleMenuItemStatusClick('RECEIVED', popupState.close)}
                    >
                      신청
                    </MenuItemStatus>
                    <MenuItemStatus
                      onClick={() => handleMenuItemStatusClick('MATCHED', popupState.close)}
                    >
                      전부확정
                    </MenuItemStatus>
                    <MenuItemStatus
                      onClick={() =>
                        handleMenuItemStatusClick('PARTIALLY_MATCHED', popupState.close)
                      }
                    >
                      일부확정
                    </MenuItemStatus>
                    <MenuItemStatus
                      onClick={() => handleMenuItemStatusClick('CANCELED', popupState.close)}
                    >
                      취소
                    </MenuItemStatus>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </th> */}
          {/* <th>{trans.t('issueCode')}</th>
          <th>{trans.t('issueName')}</th>
          <th>등록수량(주)</th>
          <th>제안수량(주)</th>
          <th>확정수량(주)</th>
          <th>잔여수량(주)</th>
          <th>소진비율(%)</th> */}
          <th>{trans.t('issueCode')}</th>
          <th>{trans.t('issueName')}</th>
          <th>{trans.t('volume')}</th>
          <th>{trans.t('requestVolume')}</th>
          <th>{trans.t('matchedVolume')}</th>
          <th>{trans.t('remainVolume')}</th>
          <th>{trans.t('burnoutRate')}</th>
          <th></th>
        </thead>
        <tbody>
          {props.poolHistory.map((pool, index) => (
            <tr
              key={index}
              onClick={() => {
                const checked = selectedIndex.includes(index)
                if (!checked) {
                  setSelectedIndex((selectedIndex) => [...selectedIndex, index])
                } else {
                  setSelectedIndex((selectedIndex) =>
                    selectedIndex.filter((_index) => _index !== index)
                  )
                }
              }}
            >
              {/* <td>
                <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
              </td> */}
              <td>{pool.createdDate.substring(0, 8)}</td>
              <td>
                {localStorageAPI.getItem('lang') === 'en'
                  ? OrderTypeTextEn[pool.orderType]
                  : OrderTypeText[pool.orderType]}
              </td>
              <td>{pool.settlementType == 'TODAY' ? 'T' : 'T1'}</td>
              <td>{pool.stock.issueCode}</td>
              <td>
                {localStorageAPI.getItem('lang') === 'en'
                  ? pool.stock.issueEnglishName
                  : pool.stock.issueName}
              </td>
              <td className="right">{formatNumber(pool.volume)}</td>
              <td className="right">{formatNumber(pool.orderedVolume)}</td>
              <td className="right">{formatNumber(pool.matchedVolume)}</td>
              <td className="right">{formatNumber(pool.remainVolume)}</td>
              <td className="right">{getBurnoutRate(pool.volume, pool.remainVolume)}</td>
              <td></td>
            </tr>
          ))}
          <div className="table-bottom"></div>
        </tbody>
      </table>
    </div>
  )
}
export default LendPoolMatched
