const request = {
  succeed: 'Request submitted.',
  fail: 'Submission failed.',
  invalid: 'Invalid request.',
  confirm: 'Submit request?',
  serverError: 'Server error occurred.',
  updateFail: 'Update failed.',
  removeCounterparty: 'Remove counterparty?',
  removeMyGroup: 'Remove group?',
}

const tradeRequest = {
  complete: 'Request submitted.',
  error: 'Error occurred.',
  errorAndNeedCheck: 'Error occurred.',
  confirmListPrefix: 'Submit request?',
  fail: 'Submission failed.',
}

const passwordRequest = {
  succeed: 'Password changed.',
  fail: 'Password change failed.',
}

const save = {
  succeed: 'Saved successfully.',
  fail: 'Failed to save.',
}

const login = {
  succeed: 'logged in successfully.',
  fail: 'Login failed.',
}

const cancel = {
  cancelConfirm: 'Submit cancel request?',
  cancelAllConfirm: 'Cancel all?',
  cancelFailed: 'Request failed.',
  cancelSucceed: 'Request submitted.',
}

const valueCheck = {
  zeroValue: 'Please input quantity.',
  noOrder: 'No selected orders.',
  noIds: 'Please select counterparty.',
  noValue: 'Pls check quantity.',
  noInput: 'No input.',
  noGroup: 'No selected group',
  emailInvalid: 'Invalid email format.',
  leastOneCharacter: 'Input required(minimum 1 character)',
  upToEightCharacters: 'Maximum length of input exceeded(8 characters)',
  need12Characters: 'Invalid account number (12 digits required)',
  needPasswordCheck: 'Please check password to change.',
  invalidAccountNumber: 'Please choose the account to add.',
  invalidvalue: 'Invalid remained balance',
  invalidrate: 'Invalid rate',
  invalidValue: 'Invalid requested quantity',
  invalidIssueCode: 'Invalid ticker',
}

const result = {
  succeed: 'Submission Successful.',
  fail: 'Submission Failed.',
}

const copyPaste = {
  copyFail: 'Copy failed.',
  pasteFail: 'Invalid layout.',
}

const message = {
  confirm: 'Submit message?',
  succeed: 'Message submitted.',
  fail: 'Submission failed.',
  noType: 'Please select type.',
  noPartner: 'Please select counterparty.',
  applyToRegister: 'Admin will contact you shortly.',
  forgotPassword:
    '가입한 이메일(ID)을 사용하여 비밀번호 초기화 요청 메일을 보내주시면 확인 후 임시비밀번호를 보내드립니다.',
  forgotPasswordEnglish:
    'Please email us using your registered email. We will send you the temporary password.',
}

const en = {
  rateUpdate1: 'Rate',
  rateUpdate2: 'update',
  originalQty: 'Original qty',
  originalRate: 'Original rate',
  updatedQty: 'Updated qty',
  updatedRate: 'Updated rate',
  cancelButton: 'Submit',
  offerRate: 'Offer rate',
  ksdFormat: 'KSD Format',
  invalidDateRange: 'Invalid value for Date Range.',
  noIssueCode: "Ticker doesn't exist",
  deleted: 'Removed.',
  save: 'Save',
  companyName: 'company',
  myGroup: 'My group',
  select: 'Select',
  accountNo: 'Account no.',
  slbAccountNo: 'SLB account',
  team: 'Team',
  memo: 'Note',
  add: 'Add',
  delete: 'Delete',
  ksdAccountInfo: 'KSD account information ',
  passwordChange: 'Password change',
  passwordChangeDiscription:
    'Password must be at least 8 characters containing 1 uppercase character, 1 lower case character, 1 numeric, and 1 special character.',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  name: 'Name',
  email: 'Email',
  phoneNo: 'Phone no.',
  resend: 'Send confirmation to counterparty?',
  requestorAsset: 'Requestor',
  cancelAccepted: 'Cancel accepted',
  cancelRejected: 'Cancel rejected',
  matched: 'Confirmed',
  lend: 'Lend',
  borrow: 'Borrow',
  reSubmit: 'Send',
  cancelRequestedDate: 'Request time',
  matchedDate: 'Contract time',
  matchedId: 'Trade ID',
  listAndCancel: 'Management',
  cancelHistoryTab: 'Blotter',
  sendHistory: 'Sent',
  receivedHistory: 'Received',
  applicationDateSearchDescription: '',
  applicationDay: 'Date Range',
  cancellable: 'e취소가능',
  request: 'Request',
  requested: 'Requested',
  trade: 'Trade',
  update: 'Update',
  updated: 'Updated',
  cancel: 'Cancel',
  cancelRequested: 'Cancel requested',
  canceled: 'Canceled',
  updateVolume: 'Qty update',
  updateVolume1: 'Qty',
  updateVolume2: 'update',
  updateRate: 'Rate update',
  fixedAmount: 'Notional(KRW)',
  borrower: 'e차입자',
  lendpool: 'e대여풀',
  partnerAsset: 'Counterparty',
  confirms: {
    reject: 'Reject requested trade?',
    accept: 'Accept requested trade?',
    update: 'Submit updated trade?',
  },
  putRequestReject: {
    case1: 'Borrower’s requested qty exceeds offer qty.',
    case2: 'Qty update exceeds offer qty.',
    case3: "Qty update exceeds borrower's requested qty.",
    case4: "Lender's offer qty exceeds bid qty.",
    case5: 'Qty update exceeds bid qty.',
    case6: "Qty update exceeds lender's offer qty.",
  },
  reject: 'Reject',
  rejected: 'Rejected',
  updateRequest: 'Update',
  updateRequested: 'Update requested',
  accept: 'Accept',
  accepted: 'Accepted',
  orderConfirm: 'Submit request?',
  company: 'Counterparty',
  rate: 'Offer rate',
  byCompany: 'Counterparty',
  byIssue: 'Security',
  groupCondition: 'Sort by',
  message: 'Message',
  logout: 'Log out',
  refresh: 'Refresh',
  issue: 'Security',
  amount: 'Notional(KRW)',
  search: 'Search',
  searchByGroup: 'Search',
  searchInList: 'Search',
  cancelSubmit: 'Cancel',
  cancelAllSubmit: 'Cancel All',
  downloadAllList: 'Download',
  orderSubmit: 'Submit',
  confirmSubmit: 'Submit request?',
  tradeRequested: 'Trade requested',
  notiBar: {
    lend: 'Lending',
    borrow: 'Borrowing',
    orderResponse: 'Trade request',
    orderConfirmed: 'Confirmed',
    orderCancelResponse: 'Cancel request',
    recall: 'Recall',
    urgent: 'Urgent',
    lendPool: 'Lending pool',
    borrowPool: 'Borrow request',
    issueCount: 'Security',
    issueAmount: 'Total amount',
  },
  sideBar: {
    loan: 'Loan',
    locate: 'Locate',
    pendingTrade: 'Pending Trade',
    matchedTrade: 'Matched Trade',
    lend: {
      title: 'Lending',
      poolReigister: 'Upload Inventory',
      poolRegisterList: 'Upload Management',
      poolMatched: 'Upload Status',
      list: 'Borrow Request',
      orderResponse: 'Borrow Request',
      orderListCancel: 'Trade Cancel',
      orderList: 'Blotter',
      search: 'Management',
      searchResponse: 'Cancel Request',
    },
    borrow: {
      title: 'BORROWING',
      list: 'Targeted Availability',
      requestOrder: 'Search Borrow',
      requestList: 'Management',
      orderResponse: 'Loan Request',
      orderListCancel: 'Trade Cancel',
      orderList: 'Blotter',
      search: 'Management',
      searchResponse: 'Cancel Request',
    },
    confirm: 'Confirmation',
  },
  settlementType: {
    today: 'Targeted Trade(T)',
    nextDay: 'Targeted Trade(T1)',
    live: 'Bid offer',
  },
  all: 'All',
  reset: 'Reset',
  submit: 'Submit',
  confirmCheck: 'Submit request for',
  registerOption: {
    none: 'No selection',
    lendingAll: 'Open to All',
    asset: 'e운용사 전체 공개',
    companyOpen: 'e운용사 전체 공개',
    securityOpen: 'e증권사 전체 공개',
    companyAll: 'Asset Managers(All)',
    securityAll: 'Brokers(All)',
    companyLabel: 'Asset Manager',
    securityLabel: 'Broker',
    myGroupLabel: 'My Group',
  },
  issueCode: 'Ticker',
  issueName: 'Security',
  table: {
    volume: 'Quantity(shs)',
    rate: 'Rate(%)',
    amount: 'Notional(KRW)',
  },
  lendListTable: {
    all: 'All',
    applicationDate: 'Input time',
    createdDate: 'Date',
    orderType: 'B/L',
    settlementType: 'Value date',
    poolStatus: 'Status',
    innerPoolStatus: {
      all: 'All',
      received: 'Requested',
      matched: 'Confirmed',
      partiallyMatched: 'Partially confirmed',
      canceled: 'Canceled',
    },
    tradeType: 'Settlement type',
    innerTradeType: {
      designated: 'Arranged',
      custom: 'Customized',
      none: 'No selection',
      bidOffer: 'Bid offer',
    },
    issueCode: 'Ticker',
    issueName: 'Security',
    volume: 'Quantity(shs)',
    rate: 'Rate(%)',
    normalRate: 'Rate(%)',
    normalVolume: 'Quantity(shs)',
    orderedVolume: 'e확정대기 수량(주)',
    lendOrderedVolume: 'Requested qty',
    borrowOrderedVolume: 'bbbRequested qty',
    matchedVolume: 'Confirmed qty',
    remainVolume: 'Remaining qty',
    requestVolume: 'Pending qty',
    desiredVolume: 'e희망수량(주)',
    lendDesiredVolume: 'Offer qty',
    desiredRate: 'e희망요율(%)',
    lendDesiredRate: 'Offer rate',
    lendResponseDesiredVolume: 'Uploaded qty',
    lendResponseDesiredRate: 'Offer rate',
    lendResponseDesiredRate1: 'Offer',
    lendResponseDesiredRate2: 'rate',
    burnoutRate: 'Hit ratio',
    poolRemainVolume: 'Remaining qty',
    poolRemainVolumeButton1: 'Remaining',
    poolRemainVolumeButton2: 'qty',
    disclosedTarget: 'Counterparty',
    swap: 'Swap',
  },
  borrowListTable: {
    borrowRequestedQty: 'Requested qty',
    borrowBidRate: 'Bid rate',
    offerQty: 'Offer qty',
    offerRate: 'Offer rate',
    liveOfferQty: 'Requested qty',
    liveOfferRate: 'Bid rate',
    borrowDesiredVolume: 'Requested qty',
    borrowDesiredRate: 'Bid rate',
    borrowDesiredRate1: 'Bid',
    borrowDesiredRate2: 'rate',
    all: 'All',
    createdDate: 'Date',
    orderType: 'B/L',
    settlementType: 'Value date',
    poolStatus: 'Status',
    innerPoolStatus: {
      all: 'All',
      received: 'Requested',
      matched: 'Confirmed',
      partiallyMatched: 'Partially confirmed',
      canceled: 'Canceled',
    },
    borrowOrderedVolume: 'Pending qty',
    issueCode: 'Ticker',
    issueName: 'Security',
    volume: 'Requested qty',
    normalVolume: 'Offer qty',
    normalRate: 'Offer rate',
    desiredVolume: 'Bid qty',
    borrowerVolumeButton1: 'Requested',
    borrowerVolumeButton2: 'qty',
    borrowerBidRateButton1: 'Bid',
    borrowerBidRateButton2: 'rate',
    borrowerOfferVolumeButton1: 'Offered',
    borrowerOfferVolumeButton2: 'qty',
    borrowerOfferRateButton1: 'Offer',
    borrowerOfferRateButton2: 'rate',
    bidQty: 'Bid qty',
    bidRate: 'Bid rate',
    rate: 'Bid qty',
    orderedVolume: 'e확정대기 수량(주)',
    matchedVolume: 'Confirmed qty',
    remainVolume: 'Remaining Qty',
    unfilledQty: 'Unfilled qty',
    disclosedTarget: 'Counterparty',
    swap: 'Swap',
  },
  example: '[Example]',
  messagePage: {
    received: 'Received',
    sent: 'Sent',
    write: 'Write',
    read: 'Read',
    unread: 'Unread',
    status: 'Status',
    counterParty: 'Counter party',
    sendDate: 'YYYYMMDD',
    urgent: 'Urgent',
    recall: 'Recall',
    contact: 'Contact request',
    time: 'Time',
    borrowLend: 'Borrow/Lend',
    type: 'Type',
    selectCounterparty: 'Select',
    selectBorL: 'Select trade',
    messageLimit: 'Message(max. 3000 byte)',
    selectConfirmed: 'Select confirmed trade',
    settlementDate: 'Settlement date',
    confirmedDate: 'Confirmed date',
    confirmedRate: 'Confirmed rate(%)',
  },
  modal: {
    cancel: cancel,
    copyPaste: copyPaste,
    login: login,
    message: message,
    passwordRequest: passwordRequest,
    request: request,
    result: result,
    save: save,
    tradeRequest: tradeRequest,
    valueCheck: valueCheck,
    confirm: 'Ok',
    calcel: 'Cancel',
  },
}

export default en
