import { Autocomplete, TextField } from '@mui/material'
import { httpGetAccounts } from '@src/api/httpAPI/httpAccountAPI'
import { httpGetLedger, httpGetLedgerV2 } from '@src/api/httpAPI/httpLedgerAPI'
import { httpPostMessage } from '@src/api/httpAPI/httpMessageAPI'
import Button from '@src/components/Button'
import LogoutButton from '@src/components/Button/LogoutButton'
import TradeButton from '@src/components/Button/TradeButton'
import Checkbox from '@src/components/Checkbox'
import Loading from '@src/components/Loading'
import ResultPopup from '@src/components/ResultPopup'
import SelectOption from '@src/components/SelectOption'
import { MESSAGE } from '@src/constants/NotiText'
import { OrderStatusText, OrderStatusTextEn } from '@src/constants/OrderStatusText'
import { OrderTypeText, OrderTypeTextEn } from '@src/constants/OrderTypeText'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { formatNumber } from '@src/util/formatNumber'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilState } from 'recoil'
import { localStorageAPI } from '../../../api/storageAPI'

const MessageSend = () => {
  const [orderType, setOrderType] = useState<OrderType | ''>('')
  const [messageType, setMessageType] = useState<MessageStatusType>('URGENT')
  const [accounts, setAccounts] = useState<AccountType[]>([])
  const [selectedAccount, setSelectedAccount] = useState<AccountType>(null)
  const [message, setMessage] = useState<string>('')
  const [ledgers, setLedgers] = useState<LedgerType[]>([])
  const [selectedLedgerId, setSelectedLedgerId] = useState<LedgerType['ledgerId'][]>([])
  const [isOpenResultPopup, setIsOpenResultPopup] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const trans = useTranslation()

  const navigate = useNavigate()

  const getAccounts = useCallback(async () => {
    const { data } = await httpGetAccounts()
    setAccounts(data)
  }, [])

  const getLedgers = useCallback(async () => {
    const date = DateTime.local().toFormat('yyyyMMdd')
    const ledgers = await httpGetLedgerV2({
      // orderType: pageType as OrderType,
      query: '',
      companyName: '',
      // page,
      settlementType: '',
      transactionType: '',
      // requestType: '' as RequestStatusType,
      size: 999,
    })
    setLedgers(ledgers)
  }, [])

  const sendMessageCallback = async () => {
    try {
      // setLoading(true)
      await httpPostMessage({
        ledgerIdList: selectedLedgerId,
        message,
        messageType,
        orderType,
        originalMessageId: null,
        partnerId: selectedAccount.accountId,
      })
      setToastModal({ ...toastModal, isOpen: true, innerString: MESSAGE.succeed })
      // alert(MESSAGE.succeed)
      // setIsOpenResultPopup(true)
    } catch {
      setErrorModal({ ...errorModal, isOpen: true, innerString: MESSAGE.fail })
      // alert(MESSAGE.fail)
    } finally {
      // setLoading(false)
    }
  }

  const sendMessage = useCallback(async () => {
    if (!selectedAccount) {
      setToastModal({ ...toastModal, isOpen: true, innerString: MESSAGE.noPartner })
      // alert(MESSAGE.noPartner)
      return
    }
    if (!orderType) {
      setToastModal({ ...toastModal, isOpen: true, innerString: MESSAGE.noType })
      // alert(MESSAGE.noType)
      return
    }
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: MESSAGE.confirm,
      confirmFunction: sendMessageCallback,
      closeFunction: () => {
        return
      },
      // tab: tab,
    })
  }, [message, orderType, messageType, selectedLedgerId, selectedAccount])

  useEffect(() => {
    getAccounts()
    getLedgers()
  }, [])

  const sendButtonDisabled = orderType === '' || selectedAccount === null

  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
  ]

  return (
    <>
      {isLoading && <Loading />}
      <div className={`message-send ${orderType}`}>
        <article>
          <div className="button-wrap">
            <TradeButton disabled={sendButtonDisabled} color="blue" onClick={() => sendMessage()}>
              {localStorageAPI.getItem('lang') === 'en' ? 'Send' : '전송'}
            </TradeButton>
          </div>
          <div className="options">
            <label>{trans.t('messagePage.counterParty')} </label>
            <Autocomplete
              disablePortal
              // fullWidth={true}
              // open={true}
              clearIcon=""
              id="messege-partner-autocomplete"
              className="messege-partner-autocomplete"
              options={[
                ...accounts.map(({ companyName, companyEnglishName, accountId }) => {
                  const labalCompanyName =
                    localStorageAPI.getItem('lang') === 'en' ? companyEnglishName : companyName
                  return {
                    label: labalCompanyName,
                    value: accountId,
                  }
                }),
              ]}
              sx={{
                fontFamily: 'NotoSansKRRegular sans-serif !important',
                // height: '30px',
                // width: '200px',
                marginBottom: '6px',
                marginRight: '30px',
                // paddingTop: '0px !important',
                // paddingBottom: '0px !important',
                // paddingLeft: '0px !important',
                button: { background: 'transparent !important' },
                input: {
                  paddingTop: '12px !important',
                  paddingBottom: '6px !important',
                  paddingLeft: '16px !important',
                  // padding:'0px !important',
                  fontSize: '13px',
                  fontFamily: 'NotoSansKRRegular sans-serif !important',
                  // height:'30px',
                  // width: '160px !important',
                  // minWidth: '160px !important',
                },
                div: {
                  borderRadius: '25px',
                  padding: '0px',
                  // paddingTop: '8px !important',
                  // paddingBottom: '3px !important',
                  '&:nth-child(2)': {
                    // backgroundColor: 'yellow !important',
                    // top: 'calc(50% - 20px) !important',
                  },
                  '&:nth-child(1)': {
                    // backgroundColor: 'yellow !important',
                    // top: 'calc(50% - 15px) !important',
                    padding: '0px',
                  },
                },
                // li: {
                //   paddingTop: '4px !important',
                //   paddingBottom: '4px !important',
                // },
                fieldset: {
                  //   height:'30px',
                  borderColor: 'rgb(118 118 118) !important',
                  //   // borderColor: '#000000',
                },
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.value} className={'message-autocomplete-li'}>
                    {option.label}
                  </li>
                )
              }}
              renderInput={(params) => {
                const InputProps = { ...params.InputProps }
                // InputProps.endAdornment = <>'1'</>

                // InputProps.className = 'message-autocomplete-input'
                return (
                  <TextField
                    placeholder={trans.t('messagePage.selectCounterparty')}
                    sx={{
                      width: '200px',
                      input: {
                        '&::placeholder': {
                          opacity: '1',
                        },
                      },
                    }}
                    {...params}
                    InputProps={InputProps}
                    label=""
                    fullWidth
                  />
                )
              }}
              onChange={(e: any, value: any) => {
                const account = accounts.find(({ accountId }) => accountId == Number(value.value))
                setSelectedAccount(account ? account : null)
              }}
            />
            {/* <SelectOption
              label="상대기관"
              defaultValue=""
              onChange={(e) => {
                const account = accounts.find(
                  ({ accountId }) => accountId == Number(e.target.value)
                )
                setSelectedAccount(account ? account : null)
              }}
              options={[
                { label: '선택해주세요', value: '', isHidden: true },
                ...accounts.map(({ companyName, accountId }) => ({
                  label: `${companyName}`,
                  value: accountId,
                })),
              ]}
            /> */}
            <SelectOption
              label={trans.t('messagePage.borrowLend')}
              value={orderType}
              onChange={(e) => {
                setOrderType(e.target.value as OrderType)
              }}
              options={[
                {
                  label: trans.t('messagePage.selectBorL'),
                  value: '',
                },
                {
                  label: trans.t('borrow'),
                  value: 'BORROW',
                },
                {
                  label: trans.t('lend'),
                  value: 'LEND',
                },
                // {
                //   label: '리콜',
                //   value: 'RECALL',
                // },
              ]}
            />
            <SelectOption
              label={trans.t('messagePage.type')}
              value={messageType}
              onChange={(e) => {
                setMessageType(e.target.value as MessageStatusType)
              }}
              options={[
                {
                  label: trans.t('messagePage.urgent'),
                  value: 'URGENT',
                },
                {
                  label: trans.t('messagePage.recall'),
                  value: 'RECALL',
                },
                {
                  label: trans.t('messagePage.contact'),
                  value: 'CONTACT',
                },
              ]}
            />
          </div>
          <textarea
            placeholder={trans.t('messagePage.messageLimit')}
            value={message}
            onChange={(e) => {
              if (message.length < 3000) setMessage(e.target.value)
            }}
          ></textarea>
          <h3>{trans.t('messagePage.selectConfirmed')}</h3>
          <div className="fixed-table">
            <table>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      checked={
                        selectedLedgerId.length !== 0 && selectedLedgerId.length == ledgers.length
                      }
                      onClick={() => {
                        if (selectedLedgerId.length == ledgers.length) {
                          setSelectedLedgerId([])
                        } else {
                          setSelectedLedgerId(ledgers.map((ledger) => ledger.ledgerId))
                        }
                      }}
                    />
                  </th>
                  <th>{trans.t('messagePage.status')}</th>
                  <th>{trans.t('messagePage.settlementDate')}</th>
                  <th>{trans.t('messagePage.confirmedDate')}</th>
                  <th>{trans.t('lendListTable.orderType')}</th>
                  <th>{trans.t('partnerAsset')}</th>
                  <th>{trans.t('issueCode')}</th>
                  <th>{trans.t('issueName')}</th>
                  <th>{trans.t('table.volume')}</th>
                  <th>{trans.t('messagePage.confirmedRate')}</th>
                  {/* <th>필요담보(원)</th> */}
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {ledgers.map((ledger, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      const checked = selectedLedgerId.includes(ledger.ledgerId)
                      if (!checked) {
                        setSelectedLedgerId((selectedLedgerId) => [
                          ...selectedLedgerId,
                          ledger.ledgerId,
                        ])
                      } else {
                        setSelectedLedgerId((selectedLedgerId) =>
                          selectedLedgerId.filter((ledgerId) => ledgerId !== ledger.ledgerId)
                        )
                      }
                    }}
                  >
                    <td>
                      <Checkbox
                        checked={selectedLedgerId.includes(ledger.ledgerId)}
                        onClick={() => {}}
                      />
                    </td>
                    <td>
                      {localStorageAPI.getItem('lang') === 'en'
                        ? OrderStatusTextEn[ledger.orderStatus]
                        : OrderStatusText[ledger.orderStatus]}
                    </td>
                    <td>{ledger.orderDateTime?.substring(0, 8)}</td>
                    <td>{ledger.matchedDateTime?.substring(0, 8)}</td>
                    <td>
                      {localStorageAPI.getItem('lang') === 'en'
                        ? OrderTypeTextEn[ledger.orderType]
                        : OrderTypeText[ledger.orderType]}
                    </td>
                    <td className="left">
                      {ledger.partnerAccount &&
                        `${
                          localStorageAPI.getItem('lang') === 'en'
                            ? ledger.partnerAccount.companyEnglishName
                            : ledger.partnerAccount.companyName
                        }(${ledger.partnerAccount.companyCode}-${
                          ledger.partnerAccount.companyPropertyCode
                        }-${ledger.partnerAccount.companySLBCode})`}
                    </td>
                    <td>{ledger.stock.issueCode}</td>
                    <td className="left">
                      {localStorageAPI.getItem('lang') === 'en'
                        ? ledger.stock.issueEnglishName
                        : ledger.stock.issueName}
                    </td>
                    <td className="right">{formatNumber(ledger.volume)}</td>
                    <td className="right">{ledger.rate.toFixed(2)}</td>
                    {/* <td className="right">
                      {formatNumber(ledger.stock.previousClosingPrice * ledger.volume * 1)}
                    </td> */}
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <footer></footer>
        </article>
      </div>
      {/* {isOpenResultPopup && (
        <ResultPopup
          title="메세지 전송이 완료되었습니다."
          close={() => {
            navigate('/message/list')
          }}
        />
      )} */}
    </>
  )
}

export default MessageSend
