import Checkbox from '@src/components/Checkbox'
import { BorrowPoolInput } from '@src/components/Input/BorrowPoolInput'
import { infScrollPageState } from '@src/stores/infScrollPageState'
// import { infScrollRefState } from '@src/stores/infScrollRefStore'
import parseNumberWithComma from '@src/util/parseNumComma'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'

import { useInView } from 'react-intersection-observer'
import { useInfScrollAddPage } from '@src/hooks/useInfScrollPage'
import { PostRateInput } from '@src/components/Input/PostRateInput'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

const isGroupChecked = (boxIds: number[], selectedIds: number[]) => {
  let bool = true
  boxIds.forEach((id) => {
    if (!selectedIds.includes(id)) {
      bool = false
      return
    }
  })
  return bool
}

interface CompanySingleRowProps {
  outputPool: any
  selectedIds: number[]
  setSelectedIds: (value: React.SetStateAction<number[]>) => void
  actionRecord: ActionRecord
  isInfScrollObserver: boolean
  isSwapIds: number[]
  setIsSwapIds: React.Dispatch<React.SetStateAction<number[]>>
}

export const CompanySingleRow = ({
  outputPool,
  selectedIds,
  setSelectedIds,
  actionRecord,
  isInfScrollObserver,
  isSwapIds,
  setIsSwapIds,
}: CompanySingleRowProps) => {
  const inputTmpKey = outputPool.id
  const checked = selectedIds.includes(outputPool.id)
  const addPage = useInfScrollAddPage()
  const [ref, inView] = useInView()
  const trans = useTranslation()
  useEffect(() => {
    if (inView === true) {
      addPage()
    }
  }, [inView])
  return (
    <tr
      ref={isInfScrollObserver ? ref : null}
      key={outputPool.id}
      onClick={() => {
        if (checked) {
          const removed = selectedIds.filter((id) => id !== outputPool.id)
          setSelectedIds(removed)
        } else {
          const merged = selectedIds.concat([outputPool.id])
          setSelectedIds(merged)
        }
      }}
    >
      <td>
        <Checkbox readOnly checked={checked} />
      </td>
      <td></td>
      <td>{outputPool.settlementType === 'TODAY' ? 'T ' : 'T1'}</td>
      <td>{outputPool.stock.issueCode}</td>
      <td>
        {localStorageAPI.getItem('lang') === 'en'
          ? outputPool.stock.issueEnglishName
          : outputPool.stock.issueName}
      </td>
      <td className="right">{parseNumberWithComma(outputPool.remainVolume)}</td>
      <td className="right">{outputPool.rate.toFixed(2)}</td>
      <td></td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <select
          defaultValue={outputPool.transactionType === 'CUSTOM' ? 'CUSTOM' : 'DESIGNATED'}
          onChange={(e) => {
            actionRecord[inputTmpKey] = {
              ...actionRecord[inputTmpKey],
              transactionType: e.target.value,
            }
          }}
        >
          <option value="DESIGNATED">{trans.t('lendListTable.innerTradeType.designated')}</option>
          <option value="CUSTOM">{trans.t('lendListTable.innerTradeType.custom')}</option>
          <option value="NONE">{trans.t('lendListTable.innerTradeType.none')}</option>
        </select>
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <BorrowPoolInput
          outputPool={outputPool}
          actionRecord={actionRecord}
          inputTmpKey={inputTmpKey}
        />
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <PostRateInput
          outputPool={outputPool}
          actionRecord={actionRecord}
          inputTmpKey={inputTmpKey}
          defaultValue={outputPool.rate}
        />
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {/* <label></label> */}
        <input
          readOnly
          type="checkbox"
          checked={isSwapIds.includes(inputTmpKey)}
          onClick={(e) => {
            const isOn = isSwapIds.includes(inputTmpKey)
            if (!isOn) {
              setIsSwapIds([...isSwapIds, inputTmpKey])
              return
            }
            setIsSwapIds(
              isSwapIds.filter((id) => {
                return id !== inputTmpKey
              })
            )
          }}
        ></input>
      </td>
      <td></td>
    </tr>
  )
}

interface CompanyGroupRowProps {
  outputPool: any
  selectedIds: number[]
  setSelectedIds: (value: React.SetStateAction<number[]>) => void
  actionRecord: ActionRecord
  isInfScrollObserver: boolean
}

export const CompanyGroupRow = ({
  outputPool,
  selectedIds,
  setSelectedIds,
  actionRecord,
  isInfScrollObserver,
}: CompanyGroupRowProps) => {
  const checked = isGroupChecked(outputPool.idList, selectedIds)
  const addPage = useInfScrollAddPage()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true) {
      addPage()
    }
  }, [inView])
  return (
    <tr
      ref={isInfScrollObserver ? ref : null}
      key={outputPool.companyCode}
      style={{ backgroundColor: '#fde0e0' }}
      onClick={() => {
        if (checked) {
          const removed = selectedIds.filter((id) => !outputPool.idList.includes(id))
          setSelectedIds(removed)
        } else {
          const merged = [...new Set([...selectedIds, ...outputPool.idList])]
          setSelectedIds(merged)
        }
      }}
    >
      <td>
        <Checkbox readOnly checked={checked} />
      </td>
      <td>차입</td>
      <td></td>
      <td></td>
      <td>{/* {outputPool.rate.toFixed(2)} */}</td>
      <td className="right">{parseNumberWithComma(outputPool.remainVolume)}</td>
      <td>{/* {outputPool.settlementType === 'TODAY' ? 'T ' : 'T1'} */}</td>
      <td>
        {localStorageAPI.getItem('lang') === 'en'
          ? outputPool.companyEnglishName
          : outputPool.companyName}
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      ></td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      ></td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      ></td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      ></td>
      <td></td>
    </tr>
  )
}

export const IssueSingleRow = ({
  outputPool,
  selectedIds,
  setSelectedIds,
  actionRecord,
  // index,
  isInfScrollObserver,
  isSwapIds,
  setIsSwapIds,
}) => {
  const inputTmpKey = outputPool.id
  const checked = selectedIds.includes(outputPool.id)
  const addPage = useInfScrollAddPage()
  const [ref, inView] = useInView()
  const trans = useTranslation()
  useEffect(() => {
    if (inView === true) {
      addPage()
    }
  }, [inView])
  return (
    <tr
      ref={isInfScrollObserver ? ref : null}
      key={outputPool.id}
      onClick={() => {
        if (checked) {
          const removed = selectedIds.filter((id) => id !== outputPool.id)
          setSelectedIds(removed)
        } else {
          const merged = selectedIds.concat([outputPool.id])
          setSelectedIds(merged)
        }
      }}
    >
      <td>
        <Checkbox readOnly checked={checked} />
      </td>
      <td></td>
      <td>{/* {outputPool.settlementType === 'TODAY' ? 'T ' : 'T1'} */}</td>
      <td></td>
      <td></td>
      <td className="right">{parseNumberWithComma(outputPool.remainVolume)}</td>
      <td className="right">{outputPool.rate.toFixed(2)}</td>
      <td>
        {localStorageAPI.getItem('lang') === 'en'
          ? outputPool.account.companyEnglishName
          : outputPool.account.companyName}
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <select
          defaultValue={outputPool.transactionType === 'CUSTOM' ? 'CUSTOM' : 'DESIGNATED'}
          onChange={(e) => {
            actionRecord[inputTmpKey] = {
              ...actionRecord[inputTmpKey],
              transactionType: e.target.value,
            }
          }}
        >
          <option value="DESIGNATED">{trans.t('lendListTable.innerTradeType.designated')}</option>
          <option value="CUSTOM">{trans.t('lendListTable.innerTradeType.custom')}</option>
          <option value="NONE">{trans.t('lendListTable.innerTradeType.none')}</option>
        </select>
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <BorrowPoolInput
          outputPool={outputPool}
          actionRecord={actionRecord}
          inputTmpKey={inputTmpKey}
        />
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <PostRateInput
          outputPool={outputPool}
          actionRecord={actionRecord}
          inputTmpKey={inputTmpKey}
          defaultValue={outputPool.rate}
        />
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {/* <label></label> */}
        <input
          readOnly
          type="checkbox"
          checked={isSwapIds.includes(inputTmpKey)}
          onClick={(e) => {
            const isOn = isSwapIds.includes(inputTmpKey)
            if (!isOn) {
              setIsSwapIds([...isSwapIds, inputTmpKey])
              return
            }
            setIsSwapIds(
              isSwapIds.filter((id) => {
                return id !== inputTmpKey
              })
            )
          }}
        ></input>
        {/* <select
          defaultValue={'N'}
          onChange={(e) => {
            const bool = e.target.value === 'Y' ? true : false
            actionRecord[inputTmpKey] = {
              ...actionRecord[inputTmpKey],
              isSwap: bool,
            }
          }}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
        </select> */}
      </td>
      <td></td>
    </tr>
  )
}

interface IssueGroupRowProp {
  outputPool: any
  selectedIds: number[]
  setSelectedIds: (value: React.SetStateAction<number[]>) => void
  actionRecord: ActionRecord
  isInfScrollObserver: boolean
}

export const IssueGroupRow = ({
  outputPool,
  selectedIds,
  setSelectedIds,
  actionRecord,
  isInfScrollObserver,
}: IssueGroupRowProp) => {
  const addPage = useInfScrollAddPage()
  const [ref, inView] = useInView()
  const trans = useTranslation()
  useEffect(() => {
    if (inView === true) {
      addPage()
    }
  }, [inView])
  const checked = isGroupChecked(outputPool.idList, selectedIds)
  return (
    <tr
      ref={isInfScrollObserver ? ref : null}
      key={outputPool.issueCode}
      style={{ backgroundColor: '#fde0e0' }}
      onClick={() => {
        if (checked) {
          const removed = selectedIds.filter((id) => !outputPool.idList.includes(id))
          setSelectedIds(removed)
        } else {
          const merged = [...new Set([...selectedIds, ...outputPool.idList])]
          setSelectedIds(merged)
        }
      }}
    >
      <td>
        <Checkbox readOnly checked={checked} />
      </td>
      <td>{trans.t('borrow')}</td>
      <td>{outputPool.settlementType === 'TODAY' ? 'T ' : 'T1'}</td>
      <td>{outputPool.issueCode}</td>
      <td>
        {localStorageAPI.getItem('lang') === 'en'
          ? outputPool.issueEnglishName
          : outputPool.issueName}
      </td>
      <td className="right">{parseNumberWithComma(outputPool.remainVolume)}</td>
      <td>{/* {outputPool.rate.toFixed(2) === 'NaN' ? '0.00' : outputPool.rate.toFixed(2)} */}</td>
      <td></td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      ></td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      ></td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      ></td>
      <td
        onClick={(e) => {
          e.stopPropagation()
        }}
      ></td>
      <td></td>
    </tr>
  )
}
