import { OrderTypeText } from '@src/constants/OrderTypeText'
import { FunctionComponent, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Button from '../Button'

interface Props {
  orderType?: OrderType
  handleChangeOrderType: (orderType: OrderType) => void
}
const OrderTypeButtons: FunctionComponent<Props> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [orderType, setOrderType] = useState<OrderType>(
    (searchParams.get('orderType') as OrderType) || 'BORROW'
  )

  useEffect(() => {
    if (!props.orderType) return
    setOrderType(props.orderType)
  }, [props.orderType])

  useEffect(() => {
    props.handleChangeOrderType(orderType)

    searchParams.set('orderType', orderType)

    const nextSearchParams = new URLSearchParams()
    nextSearchParams.set('orderType', orderType)
    setSearchParams(nextSearchParams, { replace: true })
  }, [orderType])

  useEffect(() => {
    const orderType: OrderType = searchParams.get('orderType') as OrderType
    if (!OrderTypeText[orderType]) return

    setOrderType(orderType)
  }, [])

  return (
    <div className="order-type-buttons">
      <Button
        color={orderType == 'BORROW' ? 'blue' : 'grey'}
        className={orderType == 'BORROW' ? 'selected' : ''}
        onClick={() => setOrderType('BORROW')}
      >
        차입하기
      </Button>
      <Button
        color={orderType == 'LEND' ? 'blue' : 'grey'}
        className={orderType == 'LEND' ? 'selected' : ''}
        onClick={() => setOrderType('LEND')}
      >
        대여하기
      </Button>
    </div>
  )
}

export default OrderTypeButtons
