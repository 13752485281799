import httpClient from './httpClient'

export const httpGetRequest = async (params: {
  partnerAccountId: number | ''
  query: string
  orderType: OrderType | ''
  requestType: RequestOptionType
  transactionType: TransactionType | ''
}) => {
  const { data } = await httpClient.get<RequestType[]>('/api/v1/requests', {
    params,
  })

  return data
}

// declare type RequestStatusType = 'ACCEPTED' | 'POSTED' | 'RECEIVED' | 'REJECTED' | 'CANCEL' | ''

export const httpGetRequestV2 = async (params: {
  orderType?: OrderType
  isRequester?: boolean
  query?: string | ''
  companyName?: string | ''
  settlementType?: SettlementType | ''
  transactionType?: TransactionType | ''
  requestType?: string
  requestStatus?: string
  after?: string
  before?: string
  page?: number
  size?: number
}) => {
  // const { data } = await httpClient.get<RequestV2Type[]>('/api/v2/requests', {
  //   params,
  // })
  const res = await httpClient.get<RequestV2Type[]>('/api/v2/requests', {
    params,
  })
  return res.data
}

export const httpGetRequestHistoryV2 = async (params: {
  orderType?: OrderType
  isRequester?: boolean
  query?: string | ''
  companyName?: string | ''
  settlementType?: SettlementType | ''
  transactionType?: TransactionType | ''
  requestType?: string
  requestStatus?: string
  after?: string
  before?: string
  page?: number
  size?: number
}) => {
  // const { data } = await httpClient.get<RequestV2Type[]>('/api/v2/requests', {
  //   params,
  // })
  const res = await httpClient.get<RequestV2Type[]>('/api/v2/requests/history', {
    params,
  })
  return res.data
}

export const httpPutRequestV2 = async (params: {
  responseList: {
    orderId: number | string
    rate: number | string
    volume: number | string
    requestId: number | string
  }[]
  responseType: RequestOptionType
}) => {
  const res = await httpClient.put('/api/v2/requests', {
    ...params,
  })
  return res
}

export const httpDelRequestV2 = async (params: { ids: number[] }) => {
  const { data } = await httpClient.delete<RequestType[]>('/api/v2/requests', {
    params,
  })

  return data
}
