import { VALUECHECK } from '@src/constants/NotiText'

export { getGroupByCompanyOutput } from './by-company'
export { getGroupByIssueOutput } from './by-issue'
export { getLiveOutput } from './live'

interface ErrorMessage {
  noOrder: string
  zeroValue: string
}

export const getKrwTotal = (infoByIssue: any) => {
  let krwTotal = 0
  Object.values(infoByIssue).forEach((value: any) => (krwTotal += value.volume * value.tradePrice))
  return krwTotal
}

export const getPostPoolOrderPayLoad = (
  lang: string,
  selectedIds: number[],
  actionRecord: ActionRecords,
  pool: PoolType[],
  errorMessage: ErrorMessage,
  isSwapIds?: number[]
) => {
  const orderList: PostPoolOrder[] = []
  if (selectedIds.length < 1) {
    const errMsg = errorMessage.noOrder
    alert(errMsg)
    throw Error(errMsg)
  }
  let isError = false
  let errorIssueNames = ''
  for (let i = 0; i < selectedIds.length; i++) {
    const key = selectedIds[i].toString()
    const getOrderRateAndNames = (pool: PoolType[], selectedId: number) => {
      const found = pool.find((trade: PoolType) => trade.id === selectedId)
      const data = {
        rate: 0,
        issueName: found.stock.issueName || '',
        issueEnglishName: found.stock.issueEnglishName || '',
        companyName: found.account.companyName || '',
        companyEnglishName: found.account.companyEnglishName || '',
        isSwap: found.isSwap ? found.isSwap : isSwapIds?.includes(found.id),
        transactionType: found.transactionType || 'DESIGNATED',
      }
      return data
    }
    const orderRateAndName = getOrderRateAndNames(pool, selectedIds[i])
    const order = Object.assign(
      {
        poolId: selectedIds[i],
        transactionType: orderRateAndName.transactionType,
        volume: -1,
        rate: orderRateAndName.rate,
        isSwap: orderRateAndName.isSwap,
        issueName: orderRateAndName.issueName,
        issueEnglishName: orderRateAndName.issueEnglishName,
        companyName: orderRateAndName.companyName,
        companyEnglishName: orderRateAndName.companyEnglishName,
      },
      actionRecord[key]
    )
    // order.volume = order.volume * 1
    if (order.volume < 0) {
      const name = lang === 'en' ? order.issueEnglishName : order.issueName
      isError = true
      errorIssueNames += name + ', '
    }
    orderList.push(order)
  }
  if (isError) {
    errorIssueNames = errorIssueNames.substring(0, errorIssueNames.length - 2)
    throw Error(errorMessage.zeroValue + '(' + errorIssueNames + ')')
  }
  return orderList
}

export const getPostLivePoolOrderPayLoad = (
  lang: string,
  selectedIds: number[],
  livePool: any[],
  actionRecord: ActionRecord,
  orderType: OrderType,
  errorMessage: ErrorMessage
) => {
  const orderList: any[] = []
  if (selectedIds.length < 1) {
    const errMsg = errorMessage.noOrder
    alert(errMsg)
    throw Error(errMsg)
  }
  let valid = true
  const noVolumeIssueNames = []
  for (let i = 0; i < selectedIds.length; i++) {
    const key = selectedIds[i].toString()
    const getLiveOrderData = (livePool: any[], selectedId: number) => {
      const found = livePool.find((trade: any) => trade.id === selectedId)
      const data = {
        orderType: orderType,
        issueCode: found.issueCode || '0',
        volume: actionRecord[found.id]?.volume * 1 || 0,
        rate: found.rate || 0,
        issueName: found.issueName,
        issueEnglishName: found.issueEnglishName,
      }
      return data
    }
    const orderData = getLiveOrderData(livePool, selectedIds[i])
    if (orderData.volume === 0) {
      valid = false

      noVolumeIssueNames.push(lang === 'en' ? orderData.issueEnglishName : orderData.issueName)
    }
    const order = orderData
    if (order.volume < 0) {
      const errMsg = errorMessage.zeroValue
      throw Error(errMsg)
    }
    orderList.push(order)
  }
  if (!valid) {
    throw Error(errorMessage.zeroValue + '(' + noVolumeIssueNames.join(',') + ')')
  }
  return orderList
}

export const isGroupChecked = (boxIds: number[], selectedIds: number[]) => {
  let bool = true
  boxIds.forEach((id) => {
    if (!selectedIds.includes(id)) {
      bool = false
      return
    }
  })
  return bool
}
