const STORAGE_PREFIX = `io.directional`

/* eslint-disable no-console */
class StorageAPI {
  private storageType: 'localStorage' | 'sessionStorage'
  private get storage() {
    return window[this.storageType]
  }
  private get keyPrefix() {
    return `${STORAGE_PREFIX}`
  }

  public constructor(storageType: 'localStorage' | 'sessionStorage' = 'localStorage') {
    this.storageType = storageType
  }

  public getItem(key: string) {
    try {
      return this.storage.getItem(`${this.keyPrefix}/${key}`)
    } catch (error) {
      console.warn(error)
    }
  }

  public setItem(key: string, value = '') {
    try {
      this.storage.setItem(`${this.keyPrefix}/${key}`, value)
    } catch (error) {
      console.warn(error)
    }
  }

  public removeItem(key: string) {
    try {
      this.storage.removeItem(`${this.keyPrefix}/${key}`)
    } catch (error) {
      console.warn(error)
    }
  }

  public findAllKeys() {
    try {
      return Object.keys(this.storage)
    } catch (error) {
      console.warn(error)
    }
  }
}
/* eslint-enable no-console */

export const localStorageAPI = new StorageAPI('localStorage')
export const sessionStorageAPI = new StorageAPI('sessionStorage')
