import {
  httpDelRequestV2,
  httpGetRequestV2,
  httpPutRequestV2,
} from '@src/api/httpAPI/httpRequestAPI'
import SelectOption from '@src/components/SelectOption'
import TextField from '@src/components/TextField'
import useInfiniteScroll from '@src/hooks/useInfiniteScroll'
import { DateTime } from 'luxon'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import LendOrderResponseTable from './OrderListTable'
import LogoutButton from '@src/components/Button/LogoutButton'
import { SpanCursorPointer } from '@src/components/Span/SpanCursorPointer'
import TradeButton from '@src/components/Button/TradeButton'
import { isKeyTabOrEnter } from '@src/util/isKeyTapOrEnter'
import { httpGetCalender } from '@src/api/httpAPI/httpCalender'
import {
  csvDict,
  csvDictEn,
  csvHeader,
  csvHeaderEn,
  csvHeaderNotRequester,
  csvHeaderNotRequesterEn,
  csvKey,
  csvKeyEn,
  csvKeyNotRequester,
  csvKeyNotRequesterEn,
} from './csv'
import { downloadCsvWithDict } from '@src/util/getCsv'
import { Button } from '@mui/material'
import { pageTypeBorrow, pageTypeLend } from '@src/constants/PoolOrderList'
import { CANCEL, REQUEST } from '@src/constants/NotiText'
import { useRecoilState, useRecoilValue } from 'recoil'
import { errorModalState } from '@src/stores/errorModalState'
import { confirmModalState } from '@src/stores/confirmModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { checkDateValid } from '@src/util/checkDateValid'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface PoolOrderListProps {
  pageType: PageType
}

interface QueryPayload {
  stockQuery?: string
  companyQuery?: string
  settlementType?: SettlementType | ''
  transactionType?: TransactionType | ''
  requestType?: RequestOptionType | ''
  isRequester?: boolean
  after?: string
  before?: string
  isCancelable?: boolean
}

const PoolOrderList: FunctionComponent<PoolOrderListProps> = ({ pageType }: PoolOrderListProps) => {
  const constants = pageType === 'LEND' ? pageTypeLend : pageTypeBorrow
  const [stockQuery, setStockQuery] = useState<string>('')
  const [companyQuery, setCompanyQuery] = useState<string>('')
  const [isLast, setIsLast] = useState(true)
  const [page, setPage] = useState(0)
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [settlementType, setSettlementType] = useState<SettlementType | ''>('')
  const [transactionType, setTransactionType] = useState<TransactionType | ''>('')
  const [requestType, setRequestType] = useState<RequestOptionType | ''>('')

  const [selectedRequestId, setSelectedRequestId] = useState<number[]>([])
  const [requests, setRequests] = useState<RequestV2Type[]>([])

  const [isRequester, setIsRequester] = useState<boolean>(true)
  const [after, setAfter] = useState<string>(sessionStorage.getItem('after'))
  const [before, setBefore] = useState<string>(sessionStorage.getItem('before'))

  const [selectedIndex, setSelectedIndex] = useState<number[]>([])

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const [defaultDate, setDefaultDate] = useState({
    previous: '',
    today: '',
    next: '',
  })
  const [uuid, setUuid] = useState<number>(Math.random())
  const [queryPayload, setQueryPayload] = useState<QueryPayload>({
    stockQuery: '',
    companyQuery: '',
    settlementType: '',
    transactionType: '',
    requestType: '',
    isRequester: true,
    isCancelable: true,
    after: after,
    before: before,
  })
  const forceRefresh = useRecoilValue(forceRefreshState)
  const trans = useTranslation()

  const postShortCutEvent = (e) => {
    if (e.key === 'F8') {
      if (!isRequester) {
        return
      }
      if (selectedRequestId.length === 0) {
        return
      }
      delRequests()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', postShortCutEvent)
    return () => {
      window.removeEventListener('keydown', postShortCutEvent)
    }
  }, [selectedRequestId, isRequester])

  const delRequestsCallback = async () => {
    try {
      const res = await httpDelRequestV2({ ids: selectedRequestId })
      setRequests([])
      setSelectedRequestId([])
      setSelectedIndex([])
      setPage(0)
      resetField()
      setQueryPayload({
        stockQuery: '',
        companyQuery: '',
        settlementType: '',
        transactionType: '',
        requestType: '',
        isRequester: queryPayload.isRequester,
        isCancelable: true,
        after: sessionStorage.getItem('after'),
        before: sessionStorage.getItem('before'),
      })
      setToastModal({
        ...toastModal,
        isOpen: true,
        innerString: trans.t('modal.cancel.cancelSucceed'),
      })
    } catch (error) {
      if (error) {
        console.error(error)
      }
      setErrorModal({
        ...errorModal,
        isOpen: true,
        innerString: trans.t('modal.cancel.cancelFailed'),
      })
    }
  }

  const refresh = () => {
    setQueryPayload({
      stockQuery: '',
      companyQuery: '',
      settlementType: '',
      transactionType: '',
      requestType: '',
      isRequester: true,
      isCancelable: true,
      after: sessionStorage.getItem('after'),
      before: sessionStorage.getItem('before'),
    })
    resetField()
    setPage(0)
  }

  useEffect(() => {
    isLoading === false && refresh()
  }, [forceRefresh])

  const delRequests = async () => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: trans.t('modal.cancel.cancelConfirm'),
      confirmFunction: delRequestsCallback,
      closeFunction: () => {
        return
      },
      // tab: tab,
    })

    // if (window.confirm(trans.t('modal.request.confirm'))) {
    //   const res = await httpDelRequestV2({ ids: selectedRequestId })
    //   setRequests([])
    //   setSelectedRequestId([])
    //   setSelectedIndex([])
    //   setPage(0)
    //   resetField()
    //   setQueryPayload({
    //     stockQuery: '',
    //     companyQuery: '',
    //     settlementType: '',
    //     transactionType: '',
    //     requestType: '',
    //     isRequester: queryPayload.isRequester,
    //     isCancelable: true,
    //     after: after,
    //     before: before,
    //   })
    // }
  }

  // const fetchDefauiltDate = async () => {
  //   const _defaultDate = await httpGetCalender()
  //   setAfter(_defaultDate.previous)
  //   setBefore(_defaultDate.today)
  //   setDefaultDate(_defaultDate)
  // }

  // useEffect(() => {
  //   fetchDefauiltDate()
  // }, [])

  const getRequests = async (queryPayload) => {
    setIsLoading(true)
    try {
      const payload = Object.assign(
        {
          orderType: pageType as OrderType,
          isRequester: true,
          stockQuery: '',
          companyQuery: '',
          // after: after,
          // before: before,
          // page,
          settlementType: '',
          transactionType: '',
          requestType: '' as RequestStatusType,
          // size: 20,
          after: after,
          before: before,
        },
        queryPayload
      )
      const requests = await httpGetRequestV2(payload)

      // if (page == 0) {
      setRequests(requests)
      // } else {
      //   setRequests((prevRequests) => [...prevRequests, ...requests])
      // }
      setIsLast(true)
      // setIsLast(requests.length < 1)
      // if (page == 0 && !requests.length) {
      //   setIsEmpty(true)
      // } else {
      //   setIsEmpty(false)
      // }
    } catch {
      //
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getRequests(queryPayload)
  }, [queryPayload])

  // useEffect(() => {
  //   setStockQuery('')
  //   setCompanyQuery('')
  //   setPage(0)
  //   setSettlementType('TODAY')
  //   setTransactionType('DESIGNATED')
  //   setRequestType('ACCEPT')
  // }, [isRequester])

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  useEffect(() => {
    onInfiniteScrollInit(document.querySelector('.table-bottom'))
  }, [])

  useEffect(() => {
    if (isLoading || isLast) onInfiniteScrollDisconnect()
    else onInfiniteScrollUpdate()
  }, [isLoading, isLast])

  const resetField = () => {
    setStockQuery('')
    setCompanyQuery('')
    setAfter(sessionStorage.getItem('after'))
    setBefore(sessionStorage.getItem('before'))
  }

  const handleCsvDownload = async () => {
    const payload = Object.assign(
      {
        orderType: pageType as OrderType,
        stockQuery: '',
        companyQuery: '',
        settlementType: '',
        transactionType: '',
        requestType: '',
        isRequester: true,
        isCancelable: true,
        after: after,
        before: before,
      }
      // queryPayload
    )
    const requests = await httpGetRequestV2(payload)

    let csvInfo
    let fileName
    if (localStorageAPI.getItem('lang') === 'en') {
      csvInfo = {
        header: isRequester ? csvHeaderEn : csvHeaderNotRequesterEn,
        key: isRequester ? csvKeyEn : csvKeyNotRequesterEn,
        dict: csvDictEn,
        filePrefix: pageType === 'LEND' ? 'Lending_' : 'Borrowing_',
      }
      fileName = 'TradeCancel'
    } else {
      csvInfo = {
        header: isRequester ? csvHeader : csvHeaderNotRequester,
        key: isRequester ? csvKey : csvKeyNotRequester,
        dict: csvDict,
        filePrefix: pageType === 'LEND' ? '대여_' : '차입_',
      }
      fileName = '협의취소'
    }

    downloadCsvWithDict(
      requests,
      csvInfo.header,
      csvInfo.key,
      csvInfo.dict,
      csvInfo.filePrefix + fileName,
      localStorageAPI.getItem('lang')
    )
  }

  const mainWrapperSuffix = isRequester ? 'nego-requester' : 'nego-not-requester'
  const mainClass = constants.mainWrapper + ' ' + mainWrapperSuffix

  return (
    <div className={mainClass + '-cancel'}>
      <article>
        <div className="tab">
          <ul>
            {/* <li
              onClick={() => {
                setPage(0)
                setIsRequester(true)
                resetField()
                setQueryPayload({
                  stockQuery: '',
                  companyQuery: '',
                  settlementType: '',
                  transactionType: '',
                  requestType: '',
                  isRequester: true,
                  isCancelable: true,
                })
              }}
              className={isRequester ? 'selected' : ''}
            >
              취소가능내역
            </li> */}
            {/* <li
              onClick={() => {
                setPage(0)
                setIsRequester(false)
                resetField()
                setQueryPayload({
                  stockQuery: '',
                  companyQuery: '',
                  settlementType: '',
                  transactionType: '',
                  requestType: '',
                  isRequester: false,
                })
              }}
              className={!isRequester ? 'selected' : ''}
            >
              {trans.t('receivedHistory')}
            </li> */}
          </ul>
        </div>
        <div className="button-wrap">
          {/* <button onClick={() => putRequests('ACCEPT')}>취소</button> */}
          {/* {isRequester && ( */}
          <TradeButton
            disabled={selectedRequestId.length === 0}
            onClick={() => delRequests()}
            color="red"
            sx={!isRequester && { visibility: 'hidden' }}
          >
            {trans.t('cancelButton') + '(F8)'}
          </TradeButton>
          {/* )} */}
        </div>
        <div className="option">
          <div className="first-line">
            <h3>{trans.t('searchInList')}</h3>
            <SpanCursorPointer onClick={refresh}>
              <RefreshIcon></RefreshIcon>
              <p>&nbsp;{trans.t('refresh')}</p>
            </SpanCursorPointer>
          </div>
          <div className="second-line">
            <TextField
              label={trans.t('issue')}
              value={stockQuery}
              onChange={(e) => setStockQuery(e.target.value)}
              onKeyDown={(e: any) => {
                if (isKeyTabOrEnter(e)) {
                  setQueryPayload({ ...queryPayload, stockQuery: stockQuery })
                  setPage(0)
                }
              }}
            />
            <TextField
              label={trans.t('company')}
              value={companyQuery}
              onChange={(e) => setCompanyQuery(e.target.value)}
              onKeyDown={(e: any) => {
                if (isKeyTabOrEnter(e)) {
                  setQueryPayload({ ...queryPayload, companyQuery: companyQuery })
                  setPage(0)
                }
              }}
            />
            <TextField
              label={trans.t('applicationDay')}
              value={after}
              style={{ marginRight: '0' }}
              inputStyle={{ width: '110px', textAlign: 'center' }}
              onChange={(e) => {
                if (e.target.value.length > 8) return
                setAfter(e.target.value)
                if (e.target.value.length === 8) {
                  if (!checkDateValid(e.target.value)) {
                    setToastModal({
                      ...toastModal,
                      isOpen: true,
                      innerString: trans.t('invalidDateRange'),
                    })
                    return
                  }
                  setQueryPayload({ ...queryPayload, after: e.target.value })
                  setPage(0)
                }
              }}
            />
            <span className="date-dash">~</span>
            <TextField
              value={before}
              style={{ marginRight: '0' }}
              inputStyle={{ width: '110px', textAlign: 'center' }}
              onChange={(e) => {
                if (e.target.value.length > 8) return
                setBefore(e.target.value)
                if (e.target.value.length === 8) {
                  if (!checkDateValid(e.target.value)) {
                    setToastModal({
                      ...toastModal,
                      isOpen: true,
                      innerString: trans.t('invalidDateRange'),
                    })
                    return
                  }
                  setQueryPayload({ ...queryPayload, before: e.target.value })
                  setPage(0)
                }
              }}
            />
            <span style={{ fontSize: '14px', marginLeft: '5px' }}>
              {trans.t('applicationDateSearchDescription')}
            </span>
          </div>
        </div>

        <LendOrderResponseTable
          setSelectedRequestIds={setSelectedRequestId}
          requests={requests}
          setRequests={setRequests}
          queryPayload={queryPayload}
          setQueryPayload={setQueryPayload}
          setPage={setPage}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          pageType={pageType}
          constants={constants}
          isRequester={isRequester}
        />

        <Button
          sx={{ marginLeft: '30px' }}
          variant="outlined"
          onClick={() => {
            handleCsvDownload()
          }}
        >
          {trans.t('downloadAllList')}
        </Button>
      </article>
    </div>
  )
}

export default PoolOrderList
