import { overAmountModalState } from '@src/stores/overAmountModalState'
import parseNumComma from '@src/util/parseNumComma'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

let timerList = []

export const OverAmount = ({ innerStringPrefix }) => {
  const [overAmountModal, setOverAmountModal] = useRecoilState(overAmountModalState)

  useEffect(() => {
    timerList.map((_timer) => {
      clearTimeout(_timer)
    })
    if (overAmountModal.isOpen === true) {
      const timer = setTimeout(() => {
        setOverAmountModal({ ...overAmountModal, isOpen: false })
        timerList = timerList.filter((_timer) => _timer !== timer)
      }, 1000)
      timerList.push(timer)
    }
  }, [overAmountModal])

  return (
    <div className={overAmountModal.isOpen ? 'notice-wrap' : 'notice-wrap-none'}>
      <div className={'notice-over-amount'}>
        {innerStringPrefix} 많이 입력할 수 없습니다(최대 가능수량:{' '}
        {parseNumComma(overAmountModal.maxVolume)}
        주)
      </div>
    </div>
  )
}
