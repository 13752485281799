export const pageTypeLend = {
  title: '등록내역 조회 및 취소',
  pageType: 'lend',
  tabWapper: 'tab lend',
  mainWrapper: 'lend-pool-register-list LEND',
  csvFileName: '대여_등록내역조희및취소',
  csvFileNameEn: 'Lending_UploadManagement',
  orderDate: '등록일자',
  volume: '등록수량(주)',
  rate: '등록요율(%)',
  orderVolume: '확정대기 수량(주)',
}

export const pageTypeBorrow = {
  title: '신청내역 조회 및 취소',
  pageType: 'borrow',
  tabWapper: 'tab borrow',
  mainWrapper: 'borrow-request-list BORROW',
  csvFileName: '차입_신청내역조회및취소',
  csvFileNameEn: 'Borrowing_Management',
  orderDate: '신청일자',
  volume: '신청수량(주)',
  rate: '신청요율(%)',
  orderVolume: '확정대기 수량(주)',
}
