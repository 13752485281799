import { atom } from 'recoil'

interface BusinessDaysCalender {
  previous: string
  today: string
  next: string
}

export const businessDaysCalenderState = atom<BusinessDaysCalender>({
  key: 'businessDaysCalenderState',
  default: {
    previous: '',
    today: '',
    next: '',
  },
})
