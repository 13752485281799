import { atom } from 'recoil'

export interface TopBlinkState {
  borrow: {
    requestCount: boolean
    matchedCount: boolean
    cancelRequestCount: boolean
  }
  lend: {
    requestCount: boolean
    matchedCount: boolean
    cancelRequestCount: boolean
  }
  recallCount: boolean
  urgentCount: boolean
}

export const topBlinkState = atom<TopBlinkState>({
  key: 'topBlinkState',
  default: {
    borrow: {
      requestCount: false,
      cancelRequestCount: false,
      matchedCount: false,
    },
    lend: {
      requestCount: false,
      cancelRequestCount: false,
      matchedCount: false,
    },
    recallCount: false,
    urgentCount: false,
  },
})
