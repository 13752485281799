import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { httpGetMyGroup } from '@src/api/httpAPI/httpMyPageAPI'
import { SelectedGroup, SelectedPool } from '@src/pages/LendPoolRegister/LendPoolRegister'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface GroupAutocomplateProps {
  options: any[]
  selectedGroupId: string
  selectedPoolList: SelectedPool[]
  setSelectedPoolList: React.Dispatch<React.SetStateAction<SelectedPool[]>>
}

const GroupAutocomplate = ({
  options,
  selectedGroupId,
  selectedPoolList,
  setSelectedPoolList,
}: GroupAutocomplateProps) => {
  const [autoCompleteInputText, setAutoCompleteInputText] = useState(null)
  const [selectedComboboxOption, setSelectedComboboxOption] = useState(null)
  const [selectedMyGroup, setSelectedMyGroup] = useState(-1)
  const trans = useTranslation()

  useEffect(() => {
    setAutoCompleteInputText(null)
  }, [])

  useEffect(() => {
    fetchSelectedMyGroupData()
  }, [selectedMyGroup])

  const selectedIds = useMemo(
    () =>
      selectedPoolList.map((pool) => {
        return pool.accountId
      }),
    [selectedPoolList]
  )

  const selectedCodes = useMemo(
    () =>
      selectedPoolList.map((pool) => {
        return pool.companyCode
      }),
    [selectedPoolList]
  )

  const fetchSelectedMyGroupData = useCallback(async () => {
    if (selectedMyGroup === -1) {
      setSelectedPoolList([])
      setAutoCompleteInputText(null)
      return
    }
    const res = await httpGetMyGroup(selectedComboboxOption.id)
    let list = []
    if (localStorageAPI.getItem('lang') === 'en') {
      list = res.accountList.map(({ accountId, companyEnglishName }) => {
        return {
          accountId: accountId,
          companyName: companyEnglishName,
        }
      })
    } else {
      list = res.accountList.map(({ accountId, companyName }) => {
        return {
          accountId: accountId,
          companyName: companyName,
        }
      })
    }
    list = list.filter((elem: any) => !selectedIds.includes(elem.id))
    setSelectedPoolList([...selectedPoolList, ...list])
    setAutoCompleteInputText(null)
  }, [selectedComboboxOption, selectedMyGroup, selectedPoolList])

  const hanldeMyGroupInputChange = useCallback(
    async (e: any, value, reason) => {
      if (!e) {
        return
      }
      if (e._reactName === 'onChange') {
        return
      }
      if (e._reactName === 'onBlur') {
        return
      }
      const res = await httpGetMyGroup(selectedComboboxOption.id)
      let list = []
      res.accountList.forEach((account: any) => {
        if (account.companyType === 'SECURITY') {
          list.push({
            accountId: account.accountId,
            companyName:
              localStorageAPI.getItem('lang') === 'en'
                ? account.companyEnglishName
                : account.companyName,
          })
          return
        }
        list.push({
          companyCode: account.companyCode,
          companyName:
            localStorageAPI.getItem('lang') === 'en'
              ? account.companyEnglishName
              : account.companyName,
        })
      })
      list = list.filter((elem: any) => {
        if (elem.accountId) {
          return !selectedIds.includes(elem.accountId)
        }
        return true
      })

      list = list.filter((elem: any) => {
        if (elem.companyCode) {
          return !selectedCodes.includes(elem.companyCode)
        }
        return true
      })
      setSelectedPoolList([...selectedPoolList, ...list])
      setAutoCompleteInputText(value)
    },
    [selectedComboboxOption, selectedPoolList]
  )

  return (
    <Autocomplete
      autoSelect
      autoHighlight
      value={autoCompleteInputText}
      className="autocomplete-wrap"
      // sx={{
      //   width: '230px',
      //   button: {
      //     backgroundColor: '#ffffff !important;',
      //     height: '24px !important;',
      //     width: '24px !important;',
      //     padding: '0px !important;',
      //     marginTop: '2px !important',
      //   },
      // }}
      onInputChange={hanldeMyGroupInputChange}
      onHighlightChange={(e, option) => {
        if (option === null) {
          return
        }
        setSelectedComboboxOption(option)
      }}
      onBlur={() => setAutoCompleteInputText(null)}
      forcePopupIcon={true}
      size="small"
      options={options}
      renderOption={(props, option) => {
        // const selected = option.id
        // const checked = selectedMyGroup === selected
        return (
          <li
            {...props}
            key={option.id || option.companyCode}
            className={'li-group-list'}
            // onClick={(e) => {
            //   e.preventDefault()
            //   if (!checked) {
            //     setSelectedMyGroup(option.id)
            //     return
            //   }
            //   setSelectedMyGroup(-1)
            // }}
          >
            {/* <input readOnly type="checkbox" checked={checked}></input> */}
            {option.label}
          </li>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField
            onChange={(e) => setAutoCompleteInputText(e.target.value)}
            // onClick={() => {
            //   setSelectedGroup(selectedGroupId as SelectedGroup)
            // }}
            {...params}
            placeholder={trans.t('searchByGroup')}
          />
        )
      }}
    ></Autocomplete>
  )
}

export default GroupAutocomplate
