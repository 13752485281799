import { httpPostPool } from '@src/api/httpAPI/httpPoolAPI'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import BorrowRequestOrderOption from './BorrowRequestOrderOption'
import { MemoizedTable as BorrowRequestOrderTable } from './BorrowRequestOrderTable'
import LogoutButton from '@src/components/Button/LogoutButton'
import { useParams } from 'react-router-dom'
import { httpPostOrder } from '@src/api/httpAPI/httpOrdersAPI'
import { getPayloadAdditional } from '@src/util/getPayloadAdditional'
import Tab from '@src/components/Tab'
import TradeButton from '@src/components/Button/TradeButton'
import { useRecoilState } from 'recoil'
import { postPoolToAllState } from '@src/stores/postPoolToAllState'
import { RESULT, VALUECHECK } from '@src/constants/NotiText'
import { CLASSES } from '@src/constants/Global'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { WaitingToastModal } from '@src/components/Modal/WaitingToastModal'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'
import { langState } from '../../stores/langState'

interface Props {}

export interface SelectedPool {
  companyName: string
  accountId?: number
  companyCode?: string
}

export type SelectedGroup = 'MYGROUP' | 'ASSET' | 'SECURITY' | 'ALL'

interface InnerPoolList {
  issueCode: string
  rate: number
  volume: number
}

interface HttpPostPoolPayload {
  orderType: 'LEND' | 'BORROW'
  settlementType: 'TODAY' | 'NEXT_DAY'
  poolList: InnerPoolList[]
  poolType: 'ALL' | 'SELECT' | 'ASSET' | 'SECURITY'
  companyCodeList?: string[]
  accountIdList?: number[]
}

const BorrowRequestOrder: FunctionComponent<Props> = (props) => {
  const params = useParams()
  const tab = useMemo(() => params.tab, [params.tab])
  const [uuid, setUuid] = useState<number>(Math.random())
  const [poolType, setPoolType] = useState<PoolOptionType | null>(null)
  const [pools, setPools] = useState<PoolType[]>([])

  const [inputPools, setInputPools] = useState<PoolType[]>([])

  const [selectedGroup, setSelectedGroup] = useState<SelectedGroup[]>([])

  const [selectedPoolList, setSelectedPoolList] = useState<SelectedPool[]>([])

  const [postPoolToAll, setPostPoolToAll] = useRecoilState(postPoolToAllState)

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const [lang, setLang] = useRecoilState(langState)

  const [isWaiting, setIsWaiting] = useState<boolean>(false)
  const trans = useTranslation()

  const baseClass = 'borrow-request-order BORROW '

  const wrapperClass = useMemo(() => {
    if (tab === 'live') {
      return baseClass + ' ' + CLASSES.orderLive
    }
    return baseClass + ' ' + CLASSES.orderNormal
  }, [tab])

  useEffect(() => {
    return () => setPostPoolToAll(false)
  }, [])

  useEffect(() => {
    reset()
  }, [tab, lang])

  const postShortCutEvent = (e) => {
    if (e.key === 'F8') {
      if (inputPools.length === 0) {
        return
      }
      _postPool()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', postShortCutEvent)
    return () => {
      window.removeEventListener('keydown', postShortCutEvent)
    }
  }, [pools, selectedPoolList])

  const reset = useCallback(() => {
    setPoolType(null)
    setSelectedPoolList([])
    // setUuid(Math.random())
    setSelectedGroup([])
    setInputPools([])
    setPostPoolToAll(false)
  }, [])

  const postPoolCallback = async () => {
    try {
      if (inputPools.length > 200) {
        setIsWaiting(true)
      }
      const poolList = pools.map(({ stock, rate, volume, transactionType, isSwap }) => {
        if (volume <= 0) throw new Error(trans.t('modal.valueCheck.noValue'))
        return {
          issueCode: stock.issueCode,
          rate,
          volume,
          transactionType,
          isSwap,
        }
      })
      if (tab == 'live') {
        await httpPostOrder(
          pools.map(({ stock, rate, volume }) => ({
            issueCode: stock.issueCode,
            rate,
            volume,
            orderType: 'BORROW',
          }))
        )
        // alert(trans.t('modal.result.succeed'))
        setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.result.succeed') })
        reset()
        return
      }
      if (postPoolToAll !== true && selectedPoolList.length === 0) {
        throw new Error(trans.t('modal.valueCheck.noIds'))
      }
      if (postPoolToAll) {
        const payload: HttpPostPoolPayload = Object.assign({
          orderType: 'BORROW' as const,
          settlementType: tab == 'today' ? 'TODAY' : ('NEXT_DAY' as 'TODAY' | 'NEXT_DAY'),
          poolList,
          poolType: 'ALL',
        })
        await httpPostPool(payload)
        setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.result.succeed') })
        // alert(trans.t('modal.result.succeed'))
        reset()
        return
      }
      const payloadAdditional = getPayloadAdditional(selectedPoolList)
      const payload: HttpPostPoolPayload = Object.assign(
        {
          orderType: 'BORROW' as const,
          settlementType: tab == 'today' ? 'TODAY' : ('NEXT_DAY' as 'TODAY' | 'NEXT_DAY'),
          poolList,
          poolType: poolType as 'ALL' | 'SELECT' | 'ASSET' | 'SECURITY',
        },
        payloadAdditional
      )
      await httpPostPool(payload)
      setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.result.succeed') })
      reset()
    } catch (error) {
      console.error('error', error)
      setErrorModal({ ...errorModal, innerString: error.message, isOpen: true })
    } finally {
      setIsWaiting(false)
    }
  }

  const _postPool = () => {
    try {
      const poolList = pools.map(({ stock, rate, volume, transactionType, isSwap }) => {
        if (volume <= 0) throw new Error(trans.t('modal.valueCheck.noValue'))
        return {
          issueCode: stock.issueCode,
          rate,
          volume,
          transactionType,
          isSwap,
        }
      })
      if (poolList.length < 1) {
        throw Error(trans.t('modal.valueCheck.noInput'))
      }
      const innerString =
        localStorageAPI.getItem('lang') === 'ko'
          ? `${poolList.length}${trans.t('confirmCheck')}`
          : trans.t('confirmCheck') + ' ' + poolList.length + ' ' + 'lines.'
      setConfirmModal({
        ...confirmModal,
        isOpen: true,
        innerString: innerString,
        confirmFunction: postPoolCallback,
        closeFunction: () => {
          return
        },
      })
    } catch (error) {
      setErrorModal({ ...errorModal, innerString: error.message, isOpen: true })
    }
  }

  const postPool = async () => {
    try {
      // if (poolType == null) throw new Error('선택된 상대가 없습니다')
      const poolList = pools.map(({ stock, rate, volume, transactionType, isSwap }) => {
        if (volume <= 0) throw new Error(trans.t('modal.valueCheck.noValue'))
        return {
          issueCode: stock.issueCode,
          rate,
          volume,
          transactionType,
          isSwap,
        }
      })
      if (poolList.length < 1) {
        throw Error(trans.t('modal.valueCheck.noInput'))
      }
      if (window.confirm(`${poolList.length} 건을 등록합니다.`)) {
        if (tab === 'live') {
          await httpPostOrder(
            pools.map(({ stock, rate, volume }) => ({
              issueCode: stock.issueCode,
              rate,
              volume,
              orderType: 'BORROW',
            }))
          )
          alert(trans.t('modal.result.succeed'))
          reset()
          return
        }
        if (postPoolToAll !== true && selectedPoolList.length === 0) {
          throw new Error(trans.t('modal.valueCheck.noIds'))
        }
        if (postPoolToAll) {
          const payload: HttpPostPoolPayload = Object.assign({
            orderType: 'BORROW' as const,
            settlementType: tab == 'today' ? 'TODAY' : ('NEXT_DAY' as 'TODAY' | 'NEXT_DAY'),
            poolList,
            poolType: 'ALL',
          })
          await httpPostPool(payload)
          alert(trans.t('modal.result.succeed'))
          reset()
          return
        }
        const payloadAdditional = getPayloadAdditional(selectedPoolList)
        const payload: HttpPostPoolPayload = Object.assign(
          {
            orderType: 'BORROW' as const,
            settlementType: tab === 'today' ? 'TODAY' : ('NEXT_DAY' as 'TODAY' | 'NEXT_DAY'),
            poolList,
            poolType: poolType as 'ALL' | 'SELECT' | 'ASSET' | 'SECURITY',
          },
          payloadAdditional
        )
        await httpPostPool(payload)
        // }
        alert(trans.t('modal.result.succeed'))
        reset()
      }
      // window.location.reload()
    } catch (err) {
      if (err.message) {
        alert(err.message)
      } else {
        alert(RESULT.fail)
      }
    }
  }

  return (
    <div className={wrapperClass}>
      <WaitingToastModal isWaiting={isWaiting} />
      <article key={tab}>
        <Tab
          click={() => {
            setInputPools([])
          }}
          tabs={[
            {
              path: '/borrow/request/order/today',
              selected: tab === 'today',
              title: trans.t('settlementType.today'),
            },
            {
              path: '/borrow/request/order/next_day',
              selected: tab === 'next_day',
              title: trans.t('settlementType.nextDay'),
            },
            {
              path: '/borrow/request/order/live',
              selected: tab === 'live',
              title: trans.t('settlementType.live'),
            },
          ]}
        />
        <div className="button-wrap">
          <TradeButton color="red" onClick={() => reset()}>
            {trans.t('reset')}
          </TradeButton>
          <TradeButton
            disabled={inputPools.length === 0}
            color="blue"
            // className={`${pools.length < 1 ? 'disabled' : ''}`}
            onClick={() => _postPool()}
            // disabled={pools.length < 1}
          >
            {trans.t('submit') + '(F8)'}
          </TradeButton>
        </div>
        <React.Fragment key={uuid}>
          {tab !== 'live' && (
            <BorrowRequestOrderOption
              poolType={poolType}
              setPoolType={setPoolType}
              selectedPoolList={selectedPoolList}
              setSelectedPoolList={setSelectedPoolList}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
          )}
          <BorrowRequestOrderTable
            inputPools={inputPools}
            setInputPools={setInputPools}
            setSelectedPools={setPools}
            tab={tab}
          />
        </React.Fragment>
      </article>
    </div>
  )
}

export default BorrowRequestOrder
