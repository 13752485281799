import httpClient from './httpClient'

export const httpGetMyGroup = async (id: number) => {
  const res = await httpClient.get('/api/v1/groups/' + id)
  return res.data
}

export const httpDelMyGroupCompany = async (id: number, accountId: number) => {
  const res = await httpClient.delete('/api/v1/groups/' + id + '/' + accountId)
  return res.data
}

export const httpPostAddCompanyToGroup = async (id: number, param: { accountId: number }) => {
  const res = await httpClient.post('/api/v1/groups/' + id, param)
}

export const httpGetMyGroupList = async () => {
  const res = await httpClient.get('/api/v1/groups')
  return res.data
}

export const httpPostAddMyGroup = async () => {
  const res = await httpClient.post('/api/v1/groups')
}

export const httpDelMyGroup = async (id: number) => {
  const res = await httpClient.delete('/api/v1/groups/' + id)
}

export const httpPutMyGroupName = async (id: number, param: { name: string }) => {
  const res = await httpClient.put('/api/v1/groups/' + id, param)
}
