import { PropaneSharp } from '@mui/icons-material'
import { httpGetStock } from '@src/api/httpAPI/httpStockAPI'
import Checkbox from '@src/components/Checkbox'
import ContextButtons from '@src/components/ContextButtons'
import TextField from '@src/components/TextField'
import { CLASSES } from '@src/constants/Global'
import { COPY_PASTE, VALUECHECK } from '@src/constants/NotiText'
import useDebounce from '@src/hooks/useDebounce'
import { useGetRateSuffix } from '@src/hooks/useGetRateSuffix'
import useVolumeFormat from '@src/hooks/useVolumeFormat'
import { toastModalState } from '@src/stores/toastModalState'
import { formatNumber } from '@src/util/formatNumber'
import { FunctionComponent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { MemoizedTableRow as TableRow } from './LendPoolRegisterTableRow'

import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface Props {
  setSelectedPools: React.Dispatch<React.SetStateAction<PoolType[]>>
  inputPools: PoolType[]
  setInputPools: React.Dispatch<React.SetStateAction<PoolType[]>>
  tab: string
}

const LendPoolRegisterTable: FunctionComponent<Props> = (props) => {
  const [pools, setPools] = [props.inputPools, props.setInputPools]

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const [isCopyPasteButtonShow, setIsCopyPasteButtonShow] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])

  const [contextWindowEventEnabled, setContextWindowEventEnabled] = useState(true)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const [isPasting, setIsPasting] = useState(false)

  const trans = useTranslation()

  const contextWindowEvent = useCallback(
    (e) => {
      if (!e.ctrlKey) {
        return
      }
      if (e.key === 'c') {
        handleCopyButton()
      }

      if (e.key === 'v') {
        setIsPasting(true)
      }
    },
    [pools]
  )

  useEffect(() => {
    if (isPasting === true) {
      handlePasteButton()
    }
  }, [isPasting])

  const tableWrapperSuffix = useMemo(() => {
    return props.tab === 'live' ? CLASSES.orderLive : CLASSES.orderNormal
  }, [])

  useEffect(() => {
    if (contextWindowEventEnabled) {
      window.addEventListener('keydown', contextWindowEvent)
    }
    if (!contextWindowEventEnabled) {
      window.removeEventListener('keydown', contextWindowEvent)
    }
    return () => window.removeEventListener('keydown', contextWindowEvent)
  }, [contextWindowEvent, contextWindowEventEnabled])

  const handleCopyButton = useCallback(() => {
    if (!pools.length) {
      setToastModal({ ...toastModal, isOpen: true, innerString: '복사할 데이터가 없습니다.' })
      return
    }
    navigator.clipboard
      .writeText(
        pools
          .map(({ stock, volume, rate }) => {
            return Object.values({
              issueCode: stock.issueCode,
              issueName: stock.issueName,
              volume,
              rate,
            }).join('\t')
          })
          .join('\n')
      )
      .then(() => {
        setToastModal({ ...toastModal, isOpen: true, innerString: '복사되었습니다.' })
      })
      .catch(() => {
        setToastModal({ ...toastModal, isOpen: true, innerString: COPY_PASTE.copyFail })
      })
      .finally(() => {
        setIsCopyPasteButtonShow(false)
      })
  }, [navigator, pools])

  const handlePasteButton = useCallback(async () => {
    try {
      const clipboard = await navigator.clipboard.readText()
      const clipboardItems = clipboard.split('\n').filter((v) => v)
      if (!(clipboardItems instanceof Array)) throw new Error(COPY_PASTE.pasteFail)
      const issueCodes = []
      clipboardItems.forEach((clipboardItem) => {
        const items = clipboardItem.split('\t')
        const issueCode = items[0]
        issueCodes.push(issueCode)
      })
      let stocks = []
      if (issueCodes.length < 200) {
        const _stocks = await httpGetStock(issueCodes)
        stocks = _stocks
      } else {
        for (let i = 0; i < Math.ceil(issueCodes.length / 200); i++) {
          const _stocks = await httpGetStock(issueCodes.slice(i * 200, (i + 1) * 200))
          // stocks.concat(_stocks)
          stocks = [...stocks, ..._stocks]
        }
      }

      const newPools: Partial<PoolType>[] = await Promise.all(
        clipboardItems.map(async (clipboardItem, index) => {
          const items = clipboardItem.split('\t')
          if (items.length !== 4) throw new Error(COPY_PASTE.pasteFail)
          const [_issueCode, issueName, _volume, _rate] = items

          const volume = Number(_volume)
          const rate = parseFloat(Number(_rate).toFixed(2))

          if (!volume) throw new Error(trans.t('modal.valueCheck.invalidvalue'))
          if (String(volume).length > 8)
            throw new Error(trans.t('modal.valueCheck.upToEightCharacters'))
          if (Number.isNaN(rate)) throw new Error(trans.t('modal.valueCheck.invalidrate'))
          // if (rate >= 1000) throw new Error('요율은 정수 3자리까지 입력해주세요')

          // const [stock] = await httpGetStock([_issueCode])
          const stock = stocks[index]

          return {
            stock: {
              issueCode: stock.issueCode,
              issueName:
                localStorageAPI.getItem('lang') === 'en' ? stock.issueEnglishName : stock.issueName,
              previousClosingPrice: stock.previousClosingPrice,
              tradePrice: stock.tradePrice,
            },
            volume,
            rate,
          }
        })
      )

      setPools((prevPools) => [...prevPools, ...(newPools as PoolType[])])
      setSelectedIndex([...pools, ...newPools].map((_, index) => index))
    } catch (err) {
      const message = err?.message ? err?.message : COPY_PASTE.pasteFail
      setToastModal({ ...toastModal, isOpen: true, innerString: message })
      console.error(err)
    } finally {
      setIsCopyPasteButtonShow(false)
      setIsPasting(false)
    }
  }, [navigator, pools])

  const handleWindowClick = useCallback((e) => {
    setIsCopyPasteButtonShow(false)
  }, [])

  useEffect(() => {
    try {
      if (isCopyPasteButtonShow) {
        window.addEventListener('click', handleWindowClick)
      } else {
        window.removeEventListener('click', handleWindowClick)
      }
    } catch {
      //
    }
    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [isCopyPasteButtonShow])

  useEffect(() => {
    props.setSelectedPools(pools.filter((_, index) => selectedIndex.includes(index)))
  }, [pools, selectedIndex])

  return (
    <div
      className={'fixed-table' + ' ' + tableWrapperSuffix}
      onContextMenu={(e) => {
        e.preventDefault()
        setContextMenuPosition({ x: e.pageX, y: e.pageY })
        setIsCopyPasteButtonShow(true)
      }}
    >
      <table>
        <thead>
          <th>
            <Checkbox
              readOnly
              checked={selectedIndex.length !== 0 && selectedIndex.length == pools.length}
              onClick={() => {
                if (selectedIndex.length == pools.length) {
                  setSelectedIndex([])
                } else {
                  setSelectedIndex(pools.map((_, index) => index))
                }
              }}
            />
          </th>
          <th>{trans.t('issueCode')}</th>
          <th>{trans.t('issueName')}</th>
          <th>{trans.t('table.volume')}</th>
          <th>{trans.t('table.rate')}</th>
          <th>{trans.t('table.amount')}</th>
          <th></th>
        </thead>
        {!isPasting && (
          <tbody>
            {pools.map((pool, index) => (
              <TableRow
                key={`${pool.stock.issueCode}-${index}`}
                index={index}
                data={pool}
                setData={setPools}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                contextWindowEventEnabled={contextWindowEventEnabled}
                setContextWindowEventEnabled={setContextWindowEventEnabled}
                isPasting={isPasting}
              />
            ))}
            <TableRow
              key={pools.length}
              index={pools.length}
              data={{
                stock: {
                  issueCode: '',
                  issueName: '',
                  previousClosingPrice: 0,
                  tradePrice: 0,
                },
                volume: 0,
                rate: 0,
              }}
              setData={setPools}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              isNew
              isExample={pools.length == 0}
              contextWindowEventEnabled={contextWindowEventEnabled}
              setContextWindowEventEnabled={setContextWindowEventEnabled}
              isPasting={isPasting}
            />
            <div className="table-bottom"></div>
          </tbody>
        )}
      </table>
      {isCopyPasteButtonShow && (
        <ContextButtons
          handleClickPasteButton={() => setIsPasting(true)}
          handleClickCopyButton={handleCopyButton}
          position={contextMenuPosition}
        >
          {/* 붙여넣기 */}
        </ContextButtons>
      )}
    </div>
  )
}

export default LendPoolRegisterTable

export const MemoizedTable = memo(LendPoolRegisterTable)
