import httpClient from './httpClient'

export { httpGetMyGroupList, httpGetMyGroup } from './httpMyPageAPI'

export const httpGetAssetGroupList = async () => {
  const res = await httpClient.get('/api/v1/companies?companyType=ASSET')
  return res.data
}

export const httpGetSecurityGroupList = async () => {
  const res = await httpClient.get('/api/v1/accounts?companyType=SECURITY')
  return res.data
}

export const httpDelOrdersAll = async (orderType: OrderType, settlementType: SettlementType) => {
  const res = await httpClient.delete(
    `/api/v1/pools/all?orderType=${orderType}&settlementType=${settlementType}`
  )
  return res.status
}

export const httpDelLiveOrdersAll = async (orderType: OrderType) => {
  const res = await httpClient.delete(`/api/v1/orders/all?orderType=${orderType}`)
  return res.status
}
