import 'core-js'
import 'regenerator-runtime/runtime'
import 'react-hot-loader'

import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import App from './App'
import './public/main.css'
import './assets/fonts/font.css'

import '@src/lang/i18n'
// import './util/fcm'

const SentryDns =
  process.env.TARGET_ENV === 'prod'
    ? 'https://ff34ee8c4c054a4a809d2d377e75fb31@o373584.ingest.sentry.io/6618384'
    : 'https://4fd3d950225847d3baf0dede46a356d2@o373584.ingest.sentry.io/6522070'

Sentry.init({
  dsn: SentryDns,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

function createContainerElement(containerId = ''): HTMLElement {
  const oldContainerElement = document.getElementById(containerId)
  if (oldContainerElement) {
    document.body.removeChild(oldContainerElement)
  }

  const containerElement = document.createElement('div')
  containerElement.id = containerId
  document.body.appendChild(containerElement)

  return containerElement
}

function renderApp(containerId?: string) {
  const appContainer = createContainerElement(containerId)
  ReactDOM.render(
    <>
      <CssBaseline />
      <App />
    </>,
    appContainer
  )
}

renderApp('b2b')

console.warn(`b2b directional running ${process.env.TARGET_ENV} mode`)
console.warn(`build date : ${process.env.BUILD_DATE}`)
