import { atom } from 'recoil'

interface confirmFunctionParam {
  tab?: string
  responseType?: string
  id?: number
  accountId?: number
}

interface ConfirmModalState {
  isOpen: boolean
  innerString: string
  confirmFunction: (param: { responseType?: string }) => void
  closeFunction: () => void
  confirmFunctionParam?: confirmFunctionParam
}

export const confirmModalState = atom<ConfirmModalState>({
  key: 'confirmModalState',
  default: {
    isOpen: false,
    innerString: 'test test test',
    confirmFunction: () => {
      return
    },
    closeFunction: () => {
      return
    },
  },
})
