import { httpGetAccounts } from '@src/api/httpAPI/httpAccountAPI'
import { httpPutCustomOrdersRequest } from '@src/api/httpAPI/httpCustomOrdersAPI'
import Button from '@src/components/Button'
import Checkbox from '@src/components/Checkbox'
import ExcelButton from '@src/components/ExcelButton/ExcelButton'
import OrderTypeButtons from '@src/components/OrderTypeButtons'
import SearchIssue from '@src/components/SearchIssue/SearchIssue'
import SelectOption from '@src/components/SelectOption'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import useCustomOrder from '@src/hooks/useCustomOrder'
import ResultPopup from '@src/components/ResultPopup'
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'

import { DateTime } from 'luxon'
import { formatNumber } from '@src/util/formatNumber'
import LogoutButton from '@src/components/Button/LogoutButton'
import { useTranslation } from 'react-i18next'

const CustomOrdersChange = () => {
  const tableRef = useRef<HTMLTableElement>(null)

  const [orderType, setOrderType] = useState<OrderType>('BORROW')
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const [accounts, setAccounts] = useState<AccountType[]>([])
  const [settlement, setSettlement] = useState<SettlementType>('TODAY')
  const [selectedAccount, setSelectedAccount] = useState<AccountType>(null)
  const [resultPopupTitle, setResultPopupTitle] = useState<string>('')

  const [isOpenResultPopup, setIsOpenResultPopup] = useState<boolean>(false)
  const [resultCustomOrdersRequest, setResultCustomOrdersRequset] = useState<
    ResponseCustomPostRequestOrdersType[]
  >([])
  const [query, setQuery] = useState<string>('')
  const { customOrderList, setCustomOrderList, page, setPage, getCustomOrderList } =
    useCustomOrder()
  const trans = useTranslation()

  const requestCustomOrder = useCallback(
    async (isAccept: boolean) => {
      const selectedCustomOrders = customOrderList
        .filter((_, index) => selectedIndex.includes(index))
        .map(({ orderId, requestId }) => ({
          isAccept,
          orderId,
          requestId,
        }))
      try {
        const resultData = await httpPutCustomOrdersRequest(selectedCustomOrders)
        setResultCustomOrdersRequset(resultData)
        setIsOpenResultPopup(true)
        if (isAccept) {
          setResultPopupTitle('맞춤거래 요율변경 동의가 완료되었습니다.')
        } else {
          setResultPopupTitle('맞춤거래 요율변경 거부가 완료되었습니다.')
        }
      } catch {
        alert('요청에 실패하였습니다.')
      }
    },
    [selectedIndex, customOrderList]
  )

  const getAccounts = useCallback(async () => {
    const { data } = await httpGetAccounts()
    setAccounts(data)
  }, [])

  useEffect(() => {
    getAccounts()
  }, [])

  useEffect(() => {
    getCustomOrderList({
      page,
      query,
      orderType,
      type: 'UPDATE',
      partnerAccountId: selectedAccount?.accountId || '',
      settlement,
    })
  }, [page, query, orderType, selectedAccount, settlement])

  useEffect(() => {
    setPage(0)
    setCustomOrderList([])
    setSelectedIndex([])
    tableRef.current.scrollTo({ top: 0 })
  }, [orderType, query, selectedAccount, settlement])

  return (
    <>
      <section className={`custom-orders-agree ${orderType}`}>
        <article>
          <header>
            <OrderTypeButtons handleChangeOrderType={setOrderType} />
          </header>
          <div className="bigger-wrapper">
            <div className="action-buttons">
              <Button
                onClick={() => requestCustomOrder(false)}
                color={selectedIndex.length ? 'green' : 'grey'}
              >
                거부
              </Button>
              <Button
                color={selectedIndex.length ? 'green' : 'grey'}
                onClick={() => requestCustomOrder(true)}
              >
                승인
              </Button>
            </div>
            <div className="options">
              <SearchIssue searchIcon handleSearch={(query) => setQuery(query)} />
              <div className="select-options">
                <SelectOption
                  value={settlement}
                  onChange={(e) => {
                    setSettlement(e.target.value as SettlementType)
                  }}
                  label="결제일"
                  options={[
                    {
                      label: 'T',
                      value: 'TODAY',
                    },
                    {
                      label: 'T1',
                      value: 'NEXT_DAY',
                    },
                  ]}
                />
                <SelectOption
                  label="상대기관"
                  defaultValue=""
                  onChange={(e) => {
                    const account = accounts.find(
                      ({ accountId }) => accountId == Number(e.target.value)
                    )
                    setSelectedAccount(account ? account : null)
                  }}
                  options={[
                    { label: '선택해주세요', value: '', isHidden: true },
                    ...accounts.map(({ companyName, accountId }) => ({
                      label: `${companyName}`,
                      value: accountId,
                    })),
                  ]}
                />
              </div>
            </div>
            <div className="fixed-table" ref={tableRef}>
              <table>
                <thead>
                  <th>
                    <Checkbox
                      checked={selectedIndex.length == customOrderList.length}
                      onClick={() => {
                        if (selectedIndex.length == customOrderList.length) {
                          setSelectedIndex([])
                        } else {
                          setSelectedIndex(customOrderList.map((_, index) => index))
                        }
                      }}
                    />
                  </th>
                  <th>결제일</th>
                  <th>{trans.t('lendListTable.orderType')}</th>
                  <th>{trans.t('partnerAsset')}</th>
                  <th>{trans.t('issueCode')}</th>
                  <th>{trans.t('issueName')}</th>
                  <th>{trans.t('table.volume')}</th>
                  <th>{trans.t('borrowListTable.rate')}</th>
                  <th>{trans.t('updateRate')}</th>
                  <th>금액(원)</th>
                  <th></th>
                </thead>
                <tbody>
                  {customOrderList.map((customOrder, index) => (
                    <TableRow
                      key={index}
                      index={index}
                      customOrder={customOrder}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                    />
                  ))}
                  <div className="table-bottom"></div>
                </tbody>
              </table>
            </div>
          </div>
          <footer>
            <ExcelButton
              data={customOrderList.map((customOrder) => ({
                ...customOrder,
                issueCode: `=""${customOrder.stock.issueCode}""`,
                orderType: OrderTypeText[orderType],
              }))}
              headers={headers}
              fileName={`맞춤거래 변경동의 ${DateTime.local().toFormat('yyyyMMdd')}`}
            >
              엑셀다운로드
            </ExcelButton>
            <ul>
              <li>종목수 : {customOrderList.length}</li>
              <li>수량합계 : {customOrderList.reduce((acc, cur) => acc + cur.volume, 0)}</li>
              <li>
                금액합계 :{' '}
                {formatNumber(
                  customOrderList.reduce(
                    (acc, cur) => acc + cur.stock.previousClosingPrice * cur.volume * 1,
                    0
                  )
                )}
                원
              </li>
            </ul>
          </footer>
        </article>
      </section>
      {isOpenResultPopup && (
        <ResultPopup
          close={() => {
            setIsOpenResultPopup(false)
            setResultCustomOrdersRequset(null)
          }}
          title={resultPopupTitle}
        />
      )}
    </>
  )
}

interface TableRowType {
  index: number
  customOrder: CustomOrderRequestType
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
}

const TableRow: FunctionComponent<TableRowType> = ({
  customOrder,
  selectedIndex,
  setSelectedIndex,
  index,
}) => {
  return (
    <tr
      onClick={() => {
        const checked = selectedIndex.includes(index)
        if (!checked) {
          setSelectedIndex((selectedIndex) => [...selectedIndex, index])
        } else {
          setSelectedIndex((selectedIssueCodes) =>
            selectedIssueCodes.filter((_index) => _index !== index)
          )
        }
      }}
    >
      <td>
        <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
      </td>
      <td>{customOrder.settlementDate}</td>
      <td>{OrderTypeText[customOrder.orderType]}</td>
      <td className="left">
        {customOrder.partnerAccount &&
          `${customOrder.partnerAccount.companyName}(${customOrder.partnerAccount.companyCode})`}
      </td>
      <td>{customOrder.stock.issueCode}</td>
      <td className="left">{customOrder.stock.issueName}</td>
      <td className="right">{formatNumber(customOrder.volume)}</td>
      <td className="right">{customOrder.currentRate.toFixed(2)}</td>
      <td className="right">{customOrder.requestedRate.toFixed(2)}</td>
      <td className="right">
        {formatNumber(customOrder.stock.previousClosingPrice * customOrder.volume * 1)}
      </td>
      <td></td>
    </tr>
  )
}

const headers = [
  { label: '결제일', key: 'settlementDate' },
  { label: '종목코드', key: 'issueCode' },
  { label: '종목명', key: 'stock.issueName' },
  { label: '대차구분', key: 'orderType' },
  { label: '상대기관', key: 'partnerAccount?.companyName' },
  { label: '수량(주)', key: 'volume' },
  { label: '신청요율($)', key: 'currentRate' },
  { label: '변경요율(%)', key: 'requestedRate' },
]

export default CustomOrdersChange
