export const csvBorrowHeader = [
  '신청일자',
  '구분',
  '결제유형',
  '상태',
  '종목코드',
  '종목명',
  '신청수량(주)',
  '신청요율(%)',
  '확정대기 수량(주)',
  '확정수량(주)',
  '잔여수량(주)',
  '스왑',
  '기관명',
]

export const csvBorrowHeaderEn = [
  'Date',
  'B/L',
  'Value date',
  'Status',
  'Ticker',
  'Security',
  'Requested qty',
  'Bid rate',
  'Pending qty',
  'Confirmed qty',
  'Unfilled qty',
  'Swap',
  'Counterparty',
]

export const csvBorrowKey = [
  '@createdDate',
  '!orderType',
  '!settlementType',
  '!poolStatus',
  '0stock.issueCode',
  'stock.issueName',
  'volume',
  '#rate',
  'orderedVolume',
  'matchedVolume',
  'remainVolume',
  '?isSwap',
  'getCompanies',
]

export const csvBorrowKeyEn = [
  '@createdDate',
  '!orderType',
  '!settlementType',
  '!poolStatus',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'volume',
  '#rate',
  'orderedVolume',
  'matchedVolume',
  'remainVolume',
  '?isSwap',
  'getCompanies',
]

export const csvBorrowHeaderLive = [
  '신청일자',
  '구분',
  '결제유형',
  '상태',
  '종목코드',
  '종목명',
  '신청수량(주)',
  '신청요율(%)',
  '확정수량(주)',
  '잔여수량(주)',
]

export const csvBorrowHeaderLiveEn = [
  'Date',
  'B/L',
  'Value date',
  'Status',
  'Ticker',
  'Security',
  'Requested qty',
  'Bid rate',
  'Confirmed qty',
  'Remaining Qty',
]

export const csvBorrowKeyLive = [
  '@orderDateTime',
  '!orderType',
  '$T',
  '!poolStatus',
  '0stock.issueCode',
  'stock.issueName',
  'volume',
  '#rate',
  'CONFIRMED_VOLUME',
  'remainVolume',
]

export const csvBorrowKeyLiveEn = [
  '@orderDateTime',
  '!orderType',
  '$T',
  '!poolStatus',
  '0stock.issueCode',
  'stock.issueName',
  'volume',
  '#rate',
  'CONFIRMED_VOLUME',
  'remainVolume',
]

export const csvLendHeader = [
  '등록일자',
  '구분',
  '결제유형',
  '상태',
  '종목코드',
  '종목명',
  '등록수량(주)',
  '등록요율(%)',
  '확정대기 수량(주)',
  '확정수량(주)',
  '잔여수량(주)',
  '기관명',
]

export const csvLendHeaderEn = [
  'Date',
  'B/L',
  'Value date',
  'Status',
  'Ticker',
  'Security',
  'Quantity(shs)',
  'Rate(%)',
  'Requested qty',
  'Confirmed qty',
  'Remaining qty',
  'Counterparty',
]

export const csvLendKey = [
  '@createdDate',
  '!orderType',
  '!settlementType',
  '!poolStatus',
  '0stock.issueCode',
  'stock.issueName',
  'volume',
  '#rate',
  'orderedVolume',
  'matchedVolume',
  'remainVolume',
  'getCompanies',
]

export const csvLendKeyEn = [
  '@createdDate',
  '!orderType',
  '!settlementType',
  '!poolStatus',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'volume',
  '#rate',
  'orderedVolume',
  'matchedVolume',
  'remainVolume',
  'getCompanies',
]

export const csvLendHeaderLive = [
  '등록일자',
  '구분',
  '결제유형',
  '상태',
  '종목코드',
  '종목명',
  '등록수량(주)',
  '등록요율(%)',
  '확정수량(주)',
  '잔여수량(주)',
]

export const csvLendHeaderLiveEn = [
  'Date',
  'B/L',
  'Value date',
  'Status',
  'Ticker',
  'Security',
  'Quantity(shs)',
  'Rate(%)',
  'Confirmed qty',
  'Remaining Qty',
]

export const csvLendKeyLive = [
  '@orderDateTime',
  '!orderType',
  '$T',
  '!poolStatus',
  '0stock.issueCode',
  'stock.issueName',
  'volume',
  '#rate',
  'CONFIRMED_VOLUME',
  'remainVolume',
]

export const csvLendKeyLiveEn = [
  '@orderDateTime',
  '!orderType',
  '$T',
  '!poolStatus',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'volume',
  '#rate',
  'CONFIRMED_VOLUME',
  'remainVolume',
]

export const csvDict = {
  LEND: '대여',
  BORROW: '차입',
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  RECEIVED: '신청',
  MATCHED: '확정',
  CANCELED: '취소',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  PARTIALLY_MATCHED: '일부확정',
  NONE: '없음',
}

export const csvDictEn = {
  LEND: 'Lend',
  BORROW: 'Borrow',
  CUSTOM: 'Customized',
  DESIGNATED: 'Arranged',
  COMPETITIVE: 'Bid offer',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  RECEIVED: 'Requested',
  MATCHED: 'Confirmed',
  CANCELED: 'Caleled',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  PARTIALLY_MATCHED: 'Partially confirmed',
  NONE: 'No selection',
}

export const csvDictLive = {
  LEND: '대여',
  BORROW: '차입',
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  RECEIVED: '신청',
  MATCHED: '전부확정',
  PARTIALLY_MATCHED: '일부확정',
  CANCELED: '취소',
  NONE: '없음',
}

export const csvDictLiveEn = {
  LEND: 'Lend',
  BORROW: 'Borrow',
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  ORDER_CANCELED: '주문취소',
  MATCH_CANCELED: '매칭취소',
  RECEIVED: 'Requested',
  MATCHED: 'Confirmed',
  CANCELED: 'Caleled',
  PARTIALLY_MATCHED: '일부확정',
  NONE: '없음',
}
