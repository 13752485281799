import Checkbox from '@src/components/Checkbox'
import { BorrowPoolInput, BorrowPoolLiveInput } from '@src/components/Input/BorrowPoolInput'
import { infScrollPageState } from '@src/stores/infScrollPageState'
import { isGroupChecked } from '@src/util/borrow-pool-list'
import parseNumberWithComma from '@src/util/parseNumComma'
import { useRecoilState } from 'recoil'

import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'
import { useInfScrollAddPage } from '@src/hooks/useInfScrollPage'
import { useTranslation } from 'react-i18next'

interface LiveGroupRowProps {
  outputPool: LivePoolOutput
  selectedIds: number[]
  setSelectedIds: (value: React.SetStateAction<number[]>) => void
  isInfScrollObserver: boolean
}

export const LiveGroupRow = ({
  outputPool,
  selectedIds,
  setSelectedIds,
  isInfScrollObserver,
}: LiveGroupRowProps) => {
  const addPage = useInfScrollAddPage()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true) {
      addPage()
    }
  }, [inView])
  const checked = isGroupChecked(outputPool.idList, selectedIds)
  return (
    <tr
      ref={isInfScrollObserver ? ref : null}
      key={outputPool.issueCode}
      style={{ backgroundColor: '#e8f1ff' }}
      onClick={() => {
        if (checked) {
          const removed = selectedIds.filter((id) => !outputPool.idList.includes(id))
          setSelectedIds(removed)
        } else {
          const merged = [...new Set([...selectedIds, ...outputPool.idList])]
          setSelectedIds(merged)
        }
      }}
    >
      <td>
        <Checkbox
          readOnly
          checked={checked}
          // onClick={() => {
          //   if (checked) {
          //     const removed = selectedIds.filter((id) => !outputPool.idList.includes(id))
          //     setSelectedIds(removed)
          //   } else {
          //     const merged = [...new Set([...selectedIds, ...outputPool.idList])]
          //     setSelectedIds(merged)
          //   }
          // }}
        />
      </td>
      <td>대여</td>
      <td>{outputPool.issueCode}</td>
      <td>{outputPool.issueName}</td>
      <td>{parseNumberWithComma(outputPool.volume)}</td>
      <td>{outputPool.rate.toFixed(2)}</td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  )
}

interface LiveSingleRowProp {
  actionRecord: ActionRecord
  outputPool: LivePoolOutput
  selectedIds: number[]
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>
  isInfScrollObserver: boolean
}

export const LiveSingleRow = ({
  actionRecord,
  outputPool,
  selectedIds,
  setSelectedIds,
  isInfScrollObserver,
}: LiveSingleRowProp) => {
  // console.log('index', index)
  // console.log('poolLen', poolLen)
  const inputTmpKey = outputPool.id
  const checked = selectedIds.includes(outputPool.id)
  const addPage = useInfScrollAddPage()
  const [ref, inView] = useInView()
  const trans = useTranslation()
  useEffect(() => {
    if (inView === true) {
      addPage()
    }
  }, [inView])

  return (
    <tr
      ref={isInfScrollObserver ? ref : null}
      key={outputPool.id}
      className="table-bottom"
      onClick={() => {
        if (checked) {
          const removed = selectedIds.filter((id) => id !== outputPool.id)
          setSelectedIds(removed)
        } else {
          const merged = selectedIds.concat([outputPool.id])
          setSelectedIds(merged)
        }
      }}
    >
      <td>
        <Checkbox readOnly checked={checked} />
      </td>
      <td>{trans.t('lend')}</td>
      <td>{outputPool.issueCode}</td>
      <td>{outputPool.issueEnglishName}</td>
      <td className="right">{parseNumberWithComma(outputPool.volume)}</td>
      <td className="right">{outputPool.rate.toFixed(2)}</td>
      {/* <td>{outputPool.settlementType === 'TODAY' ? 'T ' : 'T1'}</td> */}
      <td>
        <BorrowPoolLiveInput
          outputPool={outputPool}
          actionRecord={actionRecord}
          inputTmpKey={outputPool.id}
        ></BorrowPoolLiveInput>
      </td>
      <td></td>
      {/* <div className="table-bottom"></div> */}
    </tr>
  )
}
