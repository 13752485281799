/** Convert a 2D array into a CSV string
 */

import { localStorageAPI } from '../api/storageAPI'
import { getBurnoutRate } from './getBurnoutRate'

const isPARTNER = (keyStr: string) => {
  if (keyStr === 'PARTNER') {
    return true
  }
  return false
}

const isMessageRead = (keyStr: string) => {
  if (keyStr === 'ISREAD') {
    return true
  }
  return false
}

const isMessageSender = (keyStr: string) => {
  if (keyStr === 'ISSENDER') {
    return true
  }
  return false
}

const isConfirmStat = (keyStr: string) => {
  if (keyStr === 'CONFIRM_STAT') {
    return true
  }
  return false
}

const isRespStat = (keyStr: string) => {
  if (keyStr === 'RESP_STAT') {
    return true
  }
  return false
}

const isConfirmedVolume = (keyStr: string) => {
  if (keyStr === 'CONFIRMED_VOLUME') {
    return true
  }
  return false
}

const inNullable = (keyStr: string) => {
  if (keyStr.substring(0, 1) === '-') {
    return true
  }
  return false
}

const isYYYYMMDD = (keyStr: string) => {
  if (keyStr.substring(0, 1) === '@') {
    return true
  }
  return false
}

const isNeedSplit = (keyStr: string) => {
  if (keyStr.indexOf('.') !== -1) {
    return true
  }
  return false
}

const isNeedDict = (keyStr: string) => {
  if (keyStr.substring(0, 1) === '!') {
    return true
  }
  return false
}

const isNeedBoolToYN = (keyStr: string) => {
  if (keyStr.substring(0, 1) === '?') {
    return true
  }
  return false
}

const isToFixed = (keyStr: string) => {
  if (keyStr.substring(0, 1) === '#') {
    return true
  }
  return false
}

const isFixed = (keyStr: string) => {
  if (keyStr.substring(0, 1) === '$') {
    return true
  }
  return false
}

const isPrefixZero = (keyStr: string) => {
  if (keyStr.substring(0, 1) === '0') {
    return true
  }
  return false
}

const isBurnoutRate = (keyStr: string) => {
  if (keyStr.substring(0, 7) === 'burnout') {
    return true
  }
  return false
}

const inCompanies = (keyStr: string) => {
  if (keyStr.substring(0, 12) === 'getCompanies') {
    return true
  }
  return false
}

const wrapString = (str: string) => {
  return '="' + str + '"'
}

const arrayToCsv = (data: Array<any[]>) => {
  // console.log('data', data)
  return data
    .map(
      (row: any[]) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(',') // comma-separated
    )
    .join('\r\n') // rows starting on new lines
}

export const getNowClock = () => {
  const pad2 = (n: number) => {
    return n < 10 ? '0' + n : n.toString()
  }
  const now = new Date()
  var yyyy = now.getFullYear().toString()
  var MM = pad2(now.getMonth() + 1)
  var dd = pad2(now.getDate())
  var hh = pad2(now.getHours())
  var mm = pad2(now.getMinutes())
  var ss = pad2(now.getSeconds())
  return yyyy + MM + dd + hh + mm + ss
}

/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
const downloadPoolAsCsv = (content: string, fileName) => {
  // Create a blob
  const fullFilename = fileName + '_' + getNowClock() + '.csv'
  let csvContent = 'data:text/csv;charset=euc-kr,'
  const ansi = '\uFEFF'
  const _content = ansi + content
  const uri = encodeURI(csvContent + _content)
  const pom = document.createElement('a')
  pom.setAttribute('download', fullFilename)
  pom.setAttribute('href', uri)
  pom.click()
}
// '"="' + '""'

interface Dict {
  [key: string]: string
}

const csvHeader = [
  '구분',
  '결제유형',
  '거래유형',
  '상태',
  '상대기관',
  '종목코드',
  '종목명',
  '희망수량(주)',
  '희망요율(%)',
  '잔여수량(주)',
  '스왑',
]

const csvKey = [
  '$대여',
  '$T',
  'TransactionTypeText',
  'RequestTypeText',
  'issueCode',
  'issueName',
  'requestedVolume',
  'requestedRate',
  'remainVolume',
  'isSwap',
]

const csvDict = {
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래요청',
  UPDATE: '변경요청',
  RECEIVED: '신청',
  MATCHED: '전부확정',
  PARTIALLY_MATCHED: '일부확정',
  CANCELED: '취소',
  TODAY: 'T',
  NEXT_DAY: 'T1',
}

const getResponseStatus = (
  requestStatus: RequestStatusType | 'CANCEL',
  requestType: RequestOptionType,
  lang?: string
) => {
  if (lang === 'en') {
    if (requestType === 'ACCEPT') {
      return requestStatus === 'RECEIVED'
        ? 'Trade requested'
        : requestStatus === 'ACCEPTED'
        ? 'Trade accepted'
        : requestStatus === 'REJECTED'
        ? 'Trade rejected'
        : requestStatus === 'CANCEL' && 'Trade Canceled'
    }
    if (requestType === 'UPDATE') {
      return requestStatus === 'RECEIVED'
        ? 'Update requested'
        : requestStatus === 'ACCEPTED'
        ? 'Update accepted'
        : requestStatus === 'REJECTED'
        ? 'Update rejected'
        : requestStatus === 'CANCEL' && 'Update canceled'
    }
    if (requestType === 'CANCEL') {
      return requestStatus === 'RECEIVED'
        ? 'Cancel requested'
        : requestStatus === 'ACCEPTED'
        ? 'Cancel accepted'
        : requestStatus === 'REJECTED'
        ? 'Cancel rejected'
        : requestStatus === 'CANCEL' && 'Cancel Canceled'
    }
  } else {
    if (requestType === 'ACCEPT') {
      return requestStatus === 'RECEIVED'
        ? '거래신청'
        : requestStatus === 'ACCEPTED'
        ? '거래동의'
        : requestStatus === 'REJECTED'
        ? '거래거부'
        : requestStatus === 'CANCEL' && '거래취소'
    }
    if (requestType === 'UPDATE') {
      return requestStatus === 'RECEIVED'
        ? '변경요청'
        : requestStatus === 'ACCEPTED'
        ? '변경동의'
        : requestStatus === 'REJECTED'
        ? '변경거부'
        : requestStatus === 'CANCEL' && '변경취소'
    }
    if (requestType === 'CANCEL') {
      return requestStatus === 'RECEIVED'
        ? '취소신청'
        : requestStatus === 'ACCEPTED'
        ? '취소동의'
        : requestStatus === 'REJECTED'
        ? '취소거부'
        : requestStatus === 'CANCEL' && '취소철회'
    }
  }
  return ''
}

const getIsCompaniesCell = (
  poolType: 'ALL' | 'SELECT' | 'ASSET' | 'SECURITY',
  selectedList: any[],
  lang?: string
) => {
  if (poolType === 'ALL') {
    if (lang === 'en') {
      return 'Open to All'
    } else {
      return '모든 사용자에게 공개'
    }
  }
  let prefix = ''
  if (poolType === 'ASSET') {
    if (lang === 'en') {
      prefix = 'Asset Managers(All),'
    } else {
      prefix = '운용사 전체 공개,'
    }
  }
  if (poolType === 'SECURITY') {
    if (lang === 'en') {
      prefix = 'Brokers(All),'
    } else {
      prefix = '운용사 전체 공개,'
    }
  }
  const getSelectedList = () => {
    let nameList = []
    if (lang === 'en') {
      selectedList.forEach((name) => {
        nameList.push(name.englishName)
      })
    } else {
      selectedList.forEach((name) => {
        nameList.push(name.name)
      })
    }
    return nameList as string[]
  }
  const listToStr = getSelectedList().join()
  return prefix + listToStr
}

const getConfirmStatCell = (confirmationStatus: ConfirmationStatusType, orderType: OrderType) => {
  if (confirmationStatus === 'COMPLETED') {
    if (orderType === 'BORROW') {
      return '수신완료'
    }
    if (orderType === 'LEND') {
      return '발송완료'
    }
  }
  if (confirmationStatus === 'ENABLE') {
    return '발송가능'
  }
  if (confirmationStatus === 'UNABLE') {
    return '상대방발송전'
  }
}

const getArrayForCsvWithDict = (
  array: any[],
  csvHeader: string[],
  key: string[],
  dict: Dict,
  lang?: string
) => {
  const result: any[] = []
  array.forEach((elem: any) => {
    const _result = []
    for (let i = 0; i < key.length; i++) {
      if (isFixed(key[i])) {
        _result.push(key[i].substring(1))
        continue
      }
      if (isYYYYMMDD(key[i])) {
        _result.push(elem[key[i].substring(1)].substring(0, 8))
        continue
      }
      if (isNeedSplit(key[i])) {
        if (isPrefixZero(key[i])) {
          _result.push(
            wrapString(elem[key[i].substring(1).split('.')[0]][key[i].substring(1).split('.')[1]])
          )
          continue
        } else {
          _result.push(elem[key[i].split('.')[0]][key[i].split('.')[1]])
          continue
        }
      }
      if (isPrefixZero(key[i])) {
        _result.push(wrapString(elem[key[i].substring(1)]))
        continue
      }
      if (isToFixed(key[i])) {
        // _result.push(elem[key[i].substring(1)])
        // continue
        if (elem[key[i].substring(1)] === null) {
          _result.push('')
        } else {
          _result.push(elem[key[i].substring(1)])
        }
        // else {
        //   _result.push(wrapString(elem[key[i].substring(1)].toFixed(2)))
        // }
        continue
      }
      if (isNeedDict(key[i])) {
        _result.push(dict[elem[key[i].substring(1)]])
        continue
      }
      if (isNeedBoolToYN(key[i])) {
        const yorN = elem[key[i].substring(1)] === true ? 'Y' : 'N'
        _result.push(yorN)
        continue
      }
      if (inNullable(key[i])) {
        if (elem[key[i].substring(1)] === null) {
          _result.push('')
        } else {
          _result.push(elem[key[i].substring(1)])
        }
        continue
      }
      if (isRespStat(key[i])) {
        const status = getResponseStatus(elem.requestStatus, elem.requestType, lang)
        _result.push(status)
        continue
      }
      if (isConfirmStat(key[i])) {
        const status = getConfirmStatCell(elem.confirmationStatus, elem.orderType)
        _result.push(status)
        continue
      }
      if (isPARTNER(key[i])) {
        if (lang === 'en') {
          const partner =
            elem.partnerAccount.companyEnglishName +
            '(' +
            elem.partnerAccount.companyCode +
            '-' +
            elem.partnerAccount.companyPropertyCode +
            '-' +
            elem.partnerAccount.companySLBCode +
            ')'
          _result.push(partner)
        } else {
          const partner =
            elem.partnerAccount.companyName +
            '(' +
            elem.partnerAccount.companyCode +
            '-' +
            elem.partnerAccount.companyPropertyCode +
            '-' +
            elem.partnerAccount.companySLBCode +
            ')'
          _result.push(partner)
        }
        continue
      }
      _result.push(elem[key[i]])
    }
    result.push(_result)
  })
  result.unshift(csvHeader)
  return result
}

const getMessageCsv = (array: any[], csvHeader: string[], key: string[], dict: Dict) => {
  const result: any[] = []
  array.forEach((elem: any) => {
    const _result = []
    for (let i = 0; i < key.length; i++) {
      if (isPrefixZero(key[i])) {
        _result.push(wrapString(elem[key[i].substring(1)]))
        continue
      }
      if (isNeedDict(key[i])) {
        _result.push(dict[elem[key[i].substring(1)]])
        continue
      }
      if (isMessageRead(key[i])) {
        _result.push(elem['isRead'] === true ? '읽음' : '읽지 않음')
        continue
      }
      if (isMessageSender(key[i])) {
        _result.push(elem['isSender'] === true ? '발신' : '수신')
        continue
      }
      if (isNeedSplit(key[i])) {
        if (isPrefixZero(key[i])) {
          _result.push(
            wrapString(elem[key[i].substring(1).split('.')[0]][key[i].substring(1).split('.')[1]])
          )
          continue
        } else {
          _result.push(elem[key[i].split('.')[0]][key[i].split('.')[1]])
          continue
        }
      }
      _result.push(elem[key[i]])
    }
    result.push(_result)
  })
  result.unshift(csvHeader)
  return result
}

const getArrayForCsvWithDictAndBurnout = (
  array: any[],
  csvHeader: string[],
  key: string[],
  dict: Dict,
  lang?: string
) => {
  const result: any[] = []
  array.forEach((elem: any) => {
    const _result = []
    for (let i = 0; i < key.length; i++) {
      if (isFixed(key[i])) {
        _result.push(key[i].substring(1))
        continue
      }
      if (isYYYYMMDD(key[i])) {
        _result.push(elem[key[i].substring(1)].substring(0, 8))
        continue
      }
      if (isNeedSplit(key[i])) {
        if (isPrefixZero(key[i])) {
          _result.push(
            wrapString(elem[key[i].substring(1).split('.')[0]][key[i].substring(1).split('.')[1]])
          )
          continue
        } else {
          _result.push(elem[key[i].split('.')[0]][key[i].split('.')[1]])
          continue
        }
      }
      if (isToFixed(key[i])) {
        _result.push(elem[key[i].substring(1)])
        continue
        if (elem[key[i].substring(1)] === null) {
          _result.push('')
        } else {
          _result.push(wrapString(elem[key[i].substring(1)].toFixed(2)))
        }
        continue
      }
      if (isNeedDict(key[i])) {
        _result.push(dict[elem[key[i].substring(1)]])
        continue
      }
      if (isNeedBoolToYN(key[i])) {
        const yorN = elem[key[i]] === true ? 'Y' : 'N'
        _result.push(yorN)
        continue
      }
      if (isBurnoutRate(key[i])) {
        const burnoutRate = getBurnoutRate(elem.volume, elem.remainVolume)
        _result.push(wrapString(burnoutRate))
        continue
      }
      if (inCompanies(key[i])) {
        const cellData = getIsCompaniesCell(elem.poolType, elem.selectedList, lang)
        _result.push(cellData)
        continue
      }
      if (isConfirmedVolume(key[i])) {
        const cellData = elem.volume - elem.remainVolume
        _result.push(cellData)
        continue
      }
      _result.push(elem[key[i]])
    }
    result.push(_result)
  })
  result.unshift(csvHeader)
  return result
}

const getArrayForCsv = (pool: PoolType[], csvHeader: string[], key: string[]) => {
  const array: any[] = []
  // console.log('pool', pool)
  pool.forEach((trade: PoolType) => {
    const _array = []
    for (let i = 0; i < key.length; i++) {
      if (isFixed(key[i])) {
        _array.push(key[i].substring(1))
        continue
      }
      if (isNeedSplit(key[i])) {
        if (isPrefixZero(key[i])) {
          _array.push(
            wrapString(trade[key[i].substring(1).split('.')[0]][key[i].substring(1).split('.')[1]])
          )
          continue
        } else {
          _array.push(trade[key[i].split('.')[0]][key[i].split('.')[1]])
          continue
        }
      }
      if (isPrefixZero(key[i])) {
        _array.push(wrapString(trade[key[i].substring(1)]))
        continue
      }
      if (isToFixed(key[i])) {
        _array.push(trade[key[i].substring(1)])
        continue
        if (trade[key[i].substring(1)] === null) {
          _array.push('')
        } else {
          _array.push(wrapString(trade[key[i].substring(1)].toFixed(2)))
        }
        continue
      }
      _array.push(trade[key[i]])
    }
    array.push(_array)
  })
  array.unshift(csvHeader)
  return array
}

export const downloadCsv = (
  dataArray: any[],
  header: string[],
  key: string[],
  fileName?: string
) => {
  const arrayForCsv = getArrayForCsv(dataArray, header, key)
  const csvStr = arrayToCsv(arrayForCsv)
  downloadPoolAsCsv(csvStr, fileName || 'temp')
}

export const downloadCsvWithDict = (
  array: any[],
  csvHeader: string[],
  key: string[],
  dict: Dict,
  fileName?: string,
  lang?: string
) => {
  const arrayForCsv = getArrayForCsvWithDict(array, csvHeader, key, dict, lang)
  const csvStr = arrayToCsv(arrayForCsv)
  downloadPoolAsCsv(csvStr, fileName || 'temp')
}

export const downloadCsvWithDictAndBurnout = (
  array: any[],
  csvHeader: string[],
  key: string[],
  dict: Dict,
  fileName?: string,
  lang?: string
) => {
  const arrayForCsv = getArrayForCsvWithDictAndBurnout(array, csvHeader, key, dict, lang)
  const csvStr = arrayToCsv(arrayForCsv)
  downloadPoolAsCsv(csvStr, fileName || 'temp')
}

export const downloadMessageCsv = (
  array: any[],
  csvHeader: string[],
  key: string[],
  dict: Dict,
  fileName?: string
) => {
  const arrayForCsv = getMessageCsv(array, csvHeader, key, dict)
  const csvStr = arrayToCsv(arrayForCsv)
  downloadPoolAsCsv(csvStr, fileName || 'temp')
}
