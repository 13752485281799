import { httpGetAccounts } from '@src/api/httpAPI/httpAccountAPI'
import { httpGetRequest } from '@src/api/httpAPI/httpRequestAPI'
import { httpPutDesignatedOrdersRequest } from '@src/api/httpAPI/httpDesignatedOrdersAPI'
import Button from '@src/components/Button'
import Checkbox from '@src/components/Checkbox'
import ExcelButton from '@src/components/ExcelButton/ExcelButton'
import SearchIssue from '@src/components/SearchIssue/SearchIssue'
import SelectOption from '@src/components/SelectOption'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import { useCallback, useEffect, useState } from 'react'

import { httpPutCustomOrdersRequest } from '@src/api/httpAPI/httpCustomOrdersAPI'
import { DateTime } from 'luxon'
import ResultPopup from '@src/components/ResultPopup'
import { formatNumber } from '@src/util/formatNumber'
import LogoutButton from '@src/components/Button/LogoutButton'
import { useTranslation } from 'react-i18next'

const SearchAgree = () => {
  const [orderType, setOrderType] = useState<OrderType | ''>('')
  const [query, setQuery] = useState<string>('')
  const [accounts, setAccounts] = useState<AccountType[]>([])
  const [selectedAccount, setSelectedAccount] = useState<AccountType>(null)
  const [requestData, setRequestData] = useState<RequestType[]>([])
  const [selectedRequestIds, setSelectedRequestIds] = useState<RequestType['requestId'][]>([])
  const [resultPopupTitle, setResultPopupTitle] = useState<string>('')
  const [isOpenResultPopup, setIsOpenResultPopup] = useState<boolean>(false)
  const [resultData, setResultData] = useState<
    ResponseDesignatedPutRequestOrdersType[] | ResponseCustomPutRequestOrdersType[]
  >([])
  const trans = useTranslation()

  const getAccounts = useCallback(async () => {
    const { data } = await httpGetAccounts()
    setAccounts(data)
  }, [])

  const getRequests = useCallback(
    async (query?: string) => {
      const requestData = await httpGetRequest({
        partnerAccountId: selectedAccount?.accountId || '',
        query: query || '',
        orderType,
        requestType: 'CANCEL',
        transactionType: '',
      })

      setRequestData(requestData)
    },
    [orderType, selectedAccount]
  )

  const putOrderRequest = useCallback(
    async (isAccept: boolean) => {
      const selectedRequestData = requestData
        .filter(({ requestId }) => selectedRequestIds.includes(requestId))
        .map(({ requestId, orderId, transactionType }) => ({
          requestId,
          orderId,
          isAccept,
          transactionType,
        }))

      const customRequestData = selectedRequestData
        .filter(({ transactionType }) => transactionType == 'CUSTOM')
        .map(({ transactionType, ...rest }) => rest)
      const designatedRequestData = selectedRequestData
        .filter(({ transactionType }) => transactionType == 'DESIGNATED')
        .map(({ transactionType, ...rest }) => rest)

      try {
        const result = await Promise.all([
          httpPutCustomOrdersRequest(customRequestData),
          httpPutDesignatedOrdersRequest(designatedRequestData),
        ])

        setResultData(result.flat())
        setResultPopupTitle(`취소 ${isAccept ? '동의' : '거부'}가 완료되었습니다.`)
        setIsOpenResultPopup(true)
      } catch {
        alert(`취소 ${isAccept ? '동의' : '거부'}에 실패하였습니다.`)
      }
    },
    [requestData, selectedRequestIds]
  )

  // 거부
  const putDesignatedOrderRequest = useCallback(async () => {
    const selectedRequestData = requestData
      .filter(({ requestId }) => selectedRequestIds.includes(requestId))
      .map(({ requestId, orderId }) => ({
        requestId,
        orderId,
        isAccept: true,
      }))
    try {
      const result = await httpPutDesignatedOrdersRequest(selectedRequestData)
      setResultData(result)
      setResultPopupTitle('취소 거부가 완료되었습니다.')
      setIsOpenResultPopup(true)
    } catch {
      alert('취소 거부에 실패하였습니다.')
    }
  }, [selectedRequestIds])

  // 동의
  const putCustomOrderRequest = useCallback(async () => {
    const selectedRequestData = requestData
      .filter(({ requestId }) => selectedRequestIds.includes(requestId))
      .map(({ requestId, orderId }) => ({
        requestId,
        orderId,
        isAccept: true,
      }))
    try {
      const result = await httpPutCustomOrdersRequest(selectedRequestData)
      setResultData(result)
      setResultPopupTitle('취소가 완료되었습니다.')
      setIsOpenResultPopup(true)
    } catch {
      alert('취소 완료에 실패하였습니다.')
    }
  }, [selectedRequestIds])

  useEffect(() => {
    getAccounts()
    getRequests()
  }, [])

  return (
    <>
      <section className={`search-agree ${orderType}`}>
        <article>
          <div className="options">
            <div className="select-options">
              <SelectOption
                label="대차구분"
                value={orderType}
                onChange={(e) => {
                  setOrderType(e.target.value as OrderType)
                }}
                options={[
                  {
                    label: '전체',
                    value: '',
                  },
                  {
                    label: '차입',
                    value: 'BORROW',
                  },
                  {
                    label: '대여',
                    value: 'LEND',
                  },
                ]}
              />
              <SelectOption
                label="상대기관"
                defaultValue=""
                onChange={(e) => {
                  const account = accounts.find(
                    ({ accountId }) => accountId == Number(e.target.value)
                  )
                  setSelectedAccount(account ? account : null)
                }}
                options={[
                  { label: '선택해주세요', value: '', isHidden: true },
                  ...accounts.map(({ companyName, accountId }) => ({
                    label: `${companyName}`,
                    value: accountId,
                  })),
                ]}
              />
            </div>
          </div>
          <div className="action-buttons">
            <Button color="grey" onClick={() => putOrderRequest(false)}>
              거부
            </Button>
            <Button color="green" onClick={() => putOrderRequest(true)}>
              동의
            </Button>
          </div>
          <SearchIssue
            searchIcon
            handleSearch={(query) => {
              getRequests(query)
            }}
          />
          <div className="fixed-table">
            <table>
              <thead>
                <th>
                  <Checkbox
                    checked={selectedRequestIds.length == requestData.length}
                    onClick={() => {
                      if (selectedRequestIds.length == requestData.length) {
                        setSelectedRequestIds([])
                      } else {
                        setSelectedRequestIds(requestData.map(({ requestId }) => requestId))
                      }
                    }}
                  />
                </th>
                <th>결제일</th>
                <th>{trans.t('lendListTable.orderType')}</th>
                <th>{trans.t('partnerAsset')}</th>
                <th>{trans.t('matchedId')}</th>
                <th>{trans.t('issueCode')}</th>
                <th>{trans.t('issueName')}</th>
                <th>{trans.t('table.volume')}</th>
                <th>{trans.t('table.rate')}</th>
                <th>요청시간</th>
                <th></th>
              </thead>
              <tbody>
                {requestData.map((request, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      const checked = selectedRequestIds.includes(request.requestId)
                      if (!checked) {
                        setSelectedRequestIds((selectedRequestIds) => [
                          ...selectedRequestIds,
                          request.requestId,
                        ])
                      } else {
                        setSelectedRequestIds((selectedRequestIds) =>
                          selectedRequestIds.filter((requestId) => requestId !== request.requestId)
                        )
                      }
                    }}
                  >
                    <td>
                      <Checkbox
                        checked={selectedRequestIds.includes(request.requestId)}
                        onClick={() => {}}
                      />
                    </td>
                    <td>{request.orderDateTime?.substring(0, 8)}</td>
                    <td>{OrderTypeText[request.orderType]}</td>
                    <td className="left">
                      {request.partnerAccount &&
                        `${request.partnerAccount.companyName}(${request.partnerAccount.companyCode})`}
                    </td>
                    <td className="right">{request.requestId}</td>
                    <td>{request.stock.issueCode}</td>
                    <td className="left">{request.stock.issueName}</td>
                    <td className="right">{formatNumber(request.volume)}</td>
                    <td className="right">{request.currentRate.toFixed(2)}</td>
                    <td>{request.requestDateTime?.substring(0, 8)}</td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <footer>
            <ExcelButton
              data={requestData.map((data) => ({
                ...data,
                issueCode: `=""${data.stock.issueCode}""`,
                orderType: OrderTypeText[orderType],
                orderDateTime: data.orderDateTime?.substring(0, 8),
                requestDateTime: data.requestDateTime?.substring(0, 8),
              }))}
              headers={headers}
              fileName={`확정취소 동의 ${DateTime.local().toFormat('yyyyMMdd')}`}
            >
              엑셀다운로드
            </ExcelButton>
            <ul>
              <li>종목수 : {requestData.length}</li>
              <li>수량합계 : {requestData.reduce((acc, cur) => acc + cur.volume, 0)}</li>
            </ul>
          </footer>
        </article>
      </section>
      {isOpenResultPopup && (
        <ResultPopup
          close={() => {
            setIsOpenResultPopup(false)
          }}
          title={resultPopupTitle}
          result={resultData}
        />
      )}
    </>
  )
}

const headers = [
  { label: '대차구분', key: 'orderType' },
  { label: '결제일', key: 'orderDateTime' },
  { label: '상대기관', key: 'partnerAccount?.companyName' },
  { label: '확정번호', key: 'requestId' },
  { label: '종목코드', key: 'issueCode' },
  { label: '종목명', key: 'stock.issueName' },
  { label: '수량(주)', key: 'volume' },
  { label: '요율(%)', key: 'requestedRate' },
  { label: '요청시간', key: 'requestDateTime' },
]

export default SearchAgree
