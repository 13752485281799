import { SelectedPool } from '@src/pages/LendPoolRegister/LendPoolRegister'

export const getPayloadAdditional = (selectedPoolList: SelectedPool[]) => {
  let poolType = 'ALL'
  if (selectedPoolList.length === 0) {
    return {
      poolType: poolType,
    }
  }
  let isOpenToAllAsset = false
  let isOpenToAllSecurity = false
  const companyCodeList = []
  const accountIdList = []
  selectedPoolList.forEach((pool) => {
    if (pool.companyCode === '-1') {
      isOpenToAllAsset = true
      return
    }
    if (pool.accountId === -1) {
      isOpenToAllSecurity = true
      return
    }
    if (pool.companyCode !== undefined) {
      companyCodeList.push(pool.companyCode)
      return
    }
    if (pool.accountId !== undefined) {
      accountIdList.push(pool.accountId)
      return
    }
  })
  if (isOpenToAllAsset) {
    poolType = 'ASSET'
    if (isOpenToAllSecurity) {
      poolType = 'ALL'
    }
  }
  if (!isOpenToAllAsset) {
    if (isOpenToAllSecurity) {
      poolType = 'SECURITY'
    } else {
      poolType = 'SELECT'
    }
  }
  const payloadAdditional = {
    poolType: poolType,
    companyCodeList: companyCodeList,
    accountIdList: accountIdList,
  }
  return payloadAdditional
}
