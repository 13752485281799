import {
  httpGetLivePool,
  httpGetPool,
  httpPostLivePool,
  httpPostPoolOrder,
} from '@src/api/httpAPI/httpPoolAPI'
import TextField from '@src/components/TextField'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import LendListLiveTable from './LendListLiveTable'
import LendListTable from './LendListTable'
import LogoutButton from '@src/components/Button/LogoutButton'
import ColorToggleButton from '@src/components/Button/ToggleButtonGroup'
import { SpanCursorPointer } from '@src/components/Span/SpanCursorPointer'
import OptionRateInput from '@src/components/Input/OptionRateInput'
import {
  getGroupByCompanyOutput,
  getGroupByIssueOutput,
  getLiveOutput,
  getPostLivePoolOrderPayLoad,
  getPostPoolOrderPayLoad,
} from '@src/util/borrow-pool-list'
import { useRecoilState, useRecoilValue } from 'recoil'
import { infScrollPageState } from '@src/stores/infScrollPageState'
import { isKeyTabOrEnter } from '@src/util/isKeyTapOrEnter'
import TradeButton from '@src/components/Button/TradeButton'
import { getLiveOrderText, getOrderText } from '@src/util/getOrdesText'
import { Button } from '@mui/material'

import { downloadCsv, downloadCsvWithDictAndBurnout } from '@src/util/getCsv'
import { TRADE_REQUEST } from '@src/constants/NotiText'
import { topNotiCountsState } from '@src/stores/topNotiCountsState'
import parseNumComma from '@src/util/parseNumComma'
import { OverAmount } from '@src/components/Modal/OverAmount'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'
import { Transgender } from '@mui/icons-material'
import { localStorageAPI } from '../../api/storageAPI'

type LendListPoolType = PoolType & {
  transactionType: TransactionType
  requestVolume?: number
  requestRate?: number
}

const searchQueryDefault = {
  orderType: 'BORROW' as const,
  settlementType: 'TODAY' as const,
  orderBy: 'STOCK' as const,
  query: '',
  companyName: '',
  page: 0,
  size: 60,
}

const LendList: FunctionComponent = () => {
  const [tab, setTab] = useState<'TODAY' | 'NEXT_DAY' | 'LIVE'>('TODAY')
  const [isLast, setIsLast] = useState(true)
  const [isFirstPage, setIsFirstPage] = useState(true)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [pool, setPool] = useState<PoolType[]>([])
  const [groupByIssue, setGroupByIssue] = useState([])
  const [groupByCompany, setGroupByCompany] = useState([])
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    orderType: 'BORROW',
    settlementType: 'TODAY',
    orderBy: 'STOCK',
    query: '',
    companyName: '',
    page: 0,
    size: 60,
  })
  const [livePool, setLivePool] = useState<LivePool[]>([])
  const [livePoolOutput, setLivePoolOutput] = useState<LivePoolOutput[]>([])
  const [actionRecord, setActionRecord] = useState<ActionRecords>({})
  const [infScrollPage, setInfScrollPage] = useRecoilState<number>(infScrollPageState)

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const topNotiCounts = useRecoilValue(topNotiCountsState)
  const forceRefresh = useRecoilValue(forceRefreshState)

  const trans = useTranslation()

  const errorMessage = useMemo(() => {
    const message = {
      noOrder: trans.t('modal.valueCheck.noOrder'),
      zeroValue: trans.t('modal.valueCheck.zeroValue'),
    }
    return message
  }, [trans])

  const issueTotal = useMemo(() => {
    const tabType = tab === 'TODAY' ? 'today' : tab === 'NEXT_DAY' ? 'nextDay' : 'competitive'
    const result = {
      issueCount: topNotiCounts.borrow[tabType].issueCount,
      totalValue: topNotiCounts.borrow[tabType].totalValue,
    }
    return result
  }, [topNotiCounts, tab])

  const postShortCutEvent = (e) => {
    if (e.key === 'F8') {
      if (selectedIds.length === 0) {
        return
      }
      tab !== 'LIVE' ? handleOrder() : handleLiveOrder()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', postShortCutEvent)
    return () => {
      window.removeEventListener('keydown', postShortCutEvent)
    }
  }, [selectedIds])

  const handleToggleBtnClick = useCallback(
    (value: string) => {
      setSelectedIds([])
      setActionRecord({})
      setSearchQuery({ ...searchQuery, orderBy: value as 'STOCK' | 'COMPANY' })
      setIsFirstPage(true)
    },
    [searchQuery]
  )

  const resetPool = useCallback(() => {
    setSearchQuery({ ...searchQuery, query: '', companyName: '', transactionType: '', isSwap: '' })
    setPool([])
    setGroupByCompany([])
    setGroupByIssue([])
    setIsLast(false)
    setIsFirstPage(true)
    setLivePool([])
    setLivePoolOutput([])
    setActionRecord({})
    setSelectedIds([])
    setInfScrollPage(0)
  }, [])

  const resetCondition = useCallback(() => {
    setActionRecord({})
    setSelectedIds([])
  }, [])

  useEffect(() => {
    if (isFirstPage) {
      tab !== 'LIVE' ? handleGetBorrowPool() : handleGetLiveBorrowPool()
      setIsFirstPage(false)
      return
    }
    if (infScrollPage > 0) {
      tab === 'LIVE' ? handleUpdateLiveBorrowPool() : handleUpdateBorrowPool()
    }
  }, [infScrollPage, isFirstPage])

  useEffect(() => {
    resetPool()
    setIsFirstPage(true)
  }, [tab, forceRefresh])

  const handleGetLiveBorrowPool = useCallback(async () => {
    const poolPostParam = {
      type: 'BORROW',
      query: searchQuery.query.replace(/ /g, ''),
      page: 0,
      size: 200,
    }
    const _pool = await httpGetLivePool(poolPostParam)
    const livePoolOutput = getLiveOutput(_pool)
    setLivePool(_pool)
    // setLivePool(_pool)
    setLivePoolOutput(livePoolOutput)
    setInfScrollPage(0)
    setIsFirstPage(false)
  }, [tab, searchQuery])

  const handleUpdateLiveBorrowPool = useCallback(async () => {
    if (isLast) {
      return
    }
    if (infScrollPage === 0) {
      return
    }
    const poolPostParam = {
      type: 'BORROW',
      query: searchQuery.query.replace(/ /g, ''),
      page: infScrollPage,
      size: 200,
    }
    const _pool = await httpGetLivePool(poolPostParam)
    if (_pool.length > 0) {
      const mergedPool = [...livePool, ..._pool]
      const livePoolOutput = getLiveOutput(mergedPool)
      setLivePool(mergedPool)
      setLivePoolOutput(livePoolOutput)
    } else {
      setIsLast(true)
    }
  }, [tab, searchQuery, isLast, livePool, infScrollPage])

  const handleGetBorrowPool = useCallback(async () => {
    const poolPostParam: PoolPostParam = Object.assign(searchQuery, {
      settlementType: tab,
      query: searchQuery.query.replace(/ /g, ''),
      companyName: searchQuery.companyName.replace(/ /g, ''),
      page: 0,
    })
    const _pool = await httpGetPool(poolPostParam)
    const groupByCompanyOutput = getGroupByCompanyOutput(_pool)
    const groupByIssueOutput = getGroupByIssueOutput(_pool)
    setPool(_pool)
    setGroupByCompany(groupByCompanyOutput)
    setGroupByIssue(groupByIssueOutput)
    setInfScrollPage(0)
    setIsLast(false)
    setIsFirstPage(false)
  }, [searchQuery])

  const handleUpdateBorrowPool = useCallback(async () => {
    if (isLast) {
      return
    }
    if (infScrollPage === 0) {
      return
    }
    const poolPostParam: PoolPostParam = Object.assign(searchQuery, {
      settlementType: tab,
      query: searchQuery.query.replace(/ /g, ''),
      companyName: searchQuery.companyName.replace(/ /g, ''),
      page: infScrollPage,
    })
    const _pool = await httpGetPool(poolPostParam)
    if (_pool.length > 0) {
      const mergedPool = [...pool, ..._pool]
      const groupByCompanyOutput = getGroupByCompanyOutput(mergedPool)
      const groupByIssueOutput = getGroupByIssueOutput(mergedPool)
      setGroupByCompany(groupByCompanyOutput)
      setGroupByIssue(groupByIssueOutput)
      setPool(mergedPool)
    } else {
      setIsLast(true)
    }
  }, [tab, searchQuery, isLast, pool, infScrollPage])

  const handleOrderCallback = async () => {
    const orderList = getPostPoolOrderPayLoad(
      localStorageAPI.getItem('lang'),
      selectedIds,
      actionRecord,
      pool,
      errorMessage
    )
    // const confirmMsg = '선택된 거래를 신청합니까?' + '\n' + '\n' + getOrderText(orderList)
    try {
      const resStatus = await httpPostPoolOrder(orderList)
      if (resStatus === 200) {
        setToastModal({
          ...toastModal,
          isOpen: true,
          innerString: trans.t('modal.tradeRequest.complete'),
        })
        // alert(TRADE_REQUEST.complete)
        resetPool()
      } else {
        setErrorModal({
          ...errorModal,
          isOpen: true,
          innerString: trans.t('modal.tradeRequest.error'),
        })
        // alert(TRADE_REQUEST.error)
      }
    } catch (error) {
      if (error) {
        console.error(error)
      }
      setErrorModal({
        ...errorModal,
        isOpen: true,
        innerString: trans.t('modal.tradeRequest.errorAndNeedCheck'),
      })
    }
  }

  const handleOrder = useCallback(async () => {
    try {
      const orderList = getPostPoolOrderPayLoad(
        localStorageAPI.getItem('lang'),
        selectedIds,
        actionRecord,
        pool,
        errorMessage
      )
      const confirmMsg =
        `${trans.t('orderConfirm')} \n\n` +
        getOrderText(orderList, localStorageAPI.getItem('lang')) +
        '\n' +
        '\n' +
        '\n'
      setConfirmModal({
        ...confirmModal,
        isOpen: true,
        innerString: confirmMsg,
        confirmFunction: handleOrderCallback,
        closeFunction: () => {
          return
        },
      })
    } catch (error) {
      console.error(error)
      setErrorModal({ ...errorModal, isOpen: true, innerString: error.message })
    }
  }, [selectedIds, actionRecord, pool, trans, errorMessage])

  const handleLiveOrderCallback = async () => {
    try {
      const orderList = getPostLivePoolOrderPayLoad(
        localStorageAPI.getItem('lang'),
        selectedIds,
        livePoolOutput,
        actionRecord,
        'LEND',
        errorMessage
      )
      const resStatus = await httpPostLivePool(orderList)
      if (resStatus === 200) {
        setToastModal({
          ...toastModal,
          isOpen: true,
          innerString: trans.t('modal.tradeRequest.complete'),
        })
        resetPool()
      } else {
        setErrorModal({
          ...errorModal,
          isOpen: true,
          innerString: trans.t('modal.tradeRequest.error'),
        })
        // alert(TRADE_REQUEST.error)
      }
    } catch (error) {
      if (error) {
        console.error(error)
      }
      setErrorModal({
        ...errorModal,
        isOpen: true,
        innerString: trans.t('modal.tradeRequest.errorAndNeedCheck'),
      })
    }
  }

  const handleLiveOrder = useCallback(async () => {
    try {
      const orderList = getPostLivePoolOrderPayLoad(
        localStorageAPI.getItem('lang'),
        selectedIds,
        livePoolOutput,
        actionRecord,
        'LEND',
        errorMessage
      )
      const confirmMsg =
        `${trans.t('orderConfirm')} \n\n` +
        getLiveOrderText(orderList, localStorageAPI.getItem('lang')) +
        '\n' +
        '\n' +
        '\n'
      setConfirmModal({
        ...confirmModal,
        isOpen: true,
        innerString: confirmMsg,
        confirmFunction: handleLiveOrderCallback,
        closeFunction: () => {
          return
        },
      })
    } catch (error) {
      // console.error(error)
      console.error(error.message)
      setErrorModal({ ...errorModal, isOpen: true, innerString: error.message })
    }
    // const orderList = getPostLivePoolOrderPayLoad(selectedIds, livePoolOutput, actionRecord, 'LEND')
    // const confirmMsg = '선택한 거래를 신청하시겠습니까?' + '\n' + '\n' + getLiveOrderText(orderList)
    // if (window.confirm(confirmMsg)) {
    //   try {
    //     const resStatus = await httpPostLivePool(orderList)
    //     if (resStatus === 200) {
    //       alert(alert(TRADE_REQUEST.complete))
    //       resetPool()
    //     } else {
    //       alert(TRADE_REQUEST.error)
    //     }
    //   } catch (e) {
    //     console.error(`err: ${e}`)
    //     alert(TRADE_REQUEST.error)
    //   }
    // }
  }, [selectedIds, livePool])

  const handleDownloadCsv = useCallback(async () => {
    const poolPostParam: PoolPostParam = {
      settlementType: tab,
      orderType: 'BORROW',
      orderBy: 'STOCK',
      // query: searchQuery.query.replace(/ /g, ''),
      // companyName: searchQuery.companyName.replace(/ /g, ''),
    }
    const _pool = await httpGetPool(poolPostParam)
    const transactionType = tab === 'TODAY' ? '$T' : '$T1'

    let csvInfo
    if (localStorageAPI.getItem('lang') === 'en') {
      csvInfo = {
        dict: {
          LEND: '대여',
          BORROW: '차입',
          CUSTOM: 'Customized',
          DESIGNATED: 'Arranged',
          COMPETITIVE: 'Bid offer',
          NONE: 'No selection',
          ACCEPT: '거래요청',
          UPDATE: '변경요청',
          CANCEL: '취소요청',
          TODAY: 'T',
          NEXT_DAY: 'T1',
          RECEIVED: '접수',
          MATCHED: '확정',
          CANCELED: '취소',
          ORDER_CANCELED: '주문취소',
          MATCH_CANCELED: '매칭취소',
        },
        header: [
          'B/L',
          'Value date',
          'Ticker',
          'Security',
          'Quantity(shs)',
          'Rate(%)',
          'Counterparty',
          'Settlement type',
          'Remaining Qty',
          'Swap',
        ],
        key: [
          '$Lend',
          transactionType,
          '0stock.issueCode',
          'stock.issueEnglishName',
          'volume',
          '#rate',
          'account.companyEnglishName',
          '!transactionType',
          'remainVolume',
          '?isSwap',
        ],
        fileName: 'Lend_BorrowRequest',
      }
    } else {
      csvInfo = {
        dict: {
          LEND: '대여',
          BORROW: '차입',
          CUSTOM: '맞춤',
          DESIGNATED: '지정',
          COMPETITIVE: '경쟁',
          ACCEPT: '거래요청',
          UPDATE: '변경요청',
          CANCEL: '취소요청',
          TODAY: 'T',
          NEXT_DAY: 'T1',
          RECEIVED: '접수',
          MATCHED: '확정',
          CANCELED: '취소',
          ORDER_CANCELED: '주문취소',
          MATCH_CANCELED: '매칭취소',
          NONE: '없음',
        },
        header: [
          '구분',
          '결제유형',
          '종목코드',
          '종목명',
          '수량(주)',
          '요율(%)',
          '기관명',
          '거래유형',
          '대여풀 잔여수량(주)',
          '스왑',
        ],
        key: [
          '$대여',
          transactionType,
          '0stock.issueCode',
          'stock.issueName',
          'volume',
          '#rate',
          'account.companyName',
          '!transactionType',
          'remainVolume',
          '?isSwap',
        ],
        fileName: '대여_차입수요리스트',
      }
    }

    downloadCsvWithDictAndBurnout(
      _pool,
      csvInfo.header,
      csvInfo.key,
      csvInfo.dict,
      csvInfo.fileName
    )
  }, [searchQuery, tab])

  const handleDownloadLiveCsv = useCallback(async () => {
    const poolPostParam = {
      type: 'BORROW',
      // query: searchQuery.query.replace(/ /g, ''),
    }
    const _pool = await httpGetLivePool(poolPostParam)
    let csvInfo
    if (localStorageAPI.getItem('lang') === 'en') {
      csvInfo = {
        header: ['B/L', 'Ticker', 'Security', 'Quantity(shs)', 'Rate(%)'],
        key: ['$Lend', '0issueCode', 'issueEnglishName', 'volume', '#rate'],
        fileName: 'Lending_BorrowRequest',
      }
    } else {
      csvInfo = {
        header: ['구분', '종목코드', '종목명', '수량', '요율'],
        key: ['$대여', '0issueCode', 'issueName', 'volume', '#rate'],
        fileName: '대여_차입수요리스트',
      }
    }
    downloadCsv(_pool, csvInfo.header, csvInfo.key, csvInfo.fileName)
  }, [searchQuery.query])

  const mainWrapperClassBase = 'lend-borrow-list LEND'
  const mainWrapperClass = useMemo(() => {
    const suffix = tab === 'LIVE' ? 'order-live' : 'order-normal'
    return mainWrapperClassBase + ' ' + suffix
  }, [tab])
  //  = tab === 'LIVE'? 'live-o'

  return (
    <div className={mainWrapperClass}>
      <OverAmount innerStringPrefix={'수요수량보다'} />
      <article key={tab}>
        <div className="tab">
          <ul>
            <li
              onClick={() => {
                setTab('TODAY')
              }}
              className={tab == 'TODAY' ? 'selected' : ''}
            >
              {trans.t('settlementType.today')}
            </li>
            <li
              onClick={() => {
                setTab('NEXT_DAY')
              }}
              className={tab == 'NEXT_DAY' ? 'selected' : ''}
            >
              {trans.t('settlementType.nextDay')}
            </li>
            <li
              onClick={() => {
                setTab('LIVE')
              }}
              className={tab == 'LIVE' ? 'selected' : ''}
            >
              {trans.t('settlementType.live')}
            </li>
          </ul>
        </div>

        <div className="button-wrap">
          <TradeButton
            disabled={selectedIds.length === 0}
            color="blue"
            onClick={() => {
              tab !== 'LIVE' ? handleOrder() : handleLiveOrder()
            }}
          >
            {trans.t('orderSubmit') + '(F8)'}
          </TradeButton>
          <div className="total">
            <ul>
              <li>
                {trans.t('issue')}: {issueTotal.issueCount}
              </li>
              <li>
                {trans.t('amount')}: {parseNumComma(issueTotal.totalValue)}
              </li>
            </ul>
          </div>
        </div>
        <div className="option">
          <div className="first-line">
            <h3>{trans.t('searchInList')}</h3>
            <SpanCursorPointer
              onClick={() => {
                setSearchQuery({ ...searchQueryDefault, orderBy: searchQuery.orderBy })
                setIsFirstPage(true)
              }}
            >
              <RefreshIcon></RefreshIcon>
              <p>&nbsp;{trans.t('refresh')}</p>
            </SpanCursorPointer>
          </div>
          {tab !== 'LIVE' && (
            <div className="second-line">
              <ColorToggleButton btnOnClick={handleToggleBtnClick}></ColorToggleButton>
            </div>
          )}
          <div className="second-line" style={{ marginTop: '30px' }}>
            <TextField
              label={trans.t('issue')}
              value={searchQuery.query}
              inputStyle={{ width: '240px' }}
              onChange={(e) => setSearchQuery({ ...searchQuery, query: e.target.value })}
              onKeyDown={(e: any) => {
                if (isKeyTabOrEnter(e)) {
                  resetCondition()
                  tab !== 'LIVE' ? handleGetBorrowPool() : handleGetLiveBorrowPool()
                }
              }}
            />
            {tab !== 'LIVE' && (
              <>
                <OptionRateInput
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleGetBorrowPool={handleGetBorrowPool}
                  resetCondition={resetCondition}
                ></OptionRateInput>
                <TextField
                  label={trans.t('company')}
                  value={searchQuery.companyName}
                  // style={{ marginLeft: '40px' }}
                  inputStyle={{ width: '240px' }}
                  onChange={(e) => setSearchQuery({ ...searchQuery, companyName: e.target.value })}
                  onKeyDown={(e: any) => {
                    if (isKeyTabOrEnter(e)) {
                      resetCondition()
                      handleGetBorrowPool()
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>

        {tab == 'LIVE' ? (
          <LendListLiveTable
            actionRecord={actionRecord}
            livePoolOutput={livePoolOutput}
            setActionRecord={setActionRecord}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            tab={tab}
          />
        ) : (
          <LendListTable
            actionRecord={actionRecord}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            groupByIssue={groupByIssue}
            groupByCompany={groupByCompany}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            setActionRecord={setActionRecord}
            setIsFirstPage={setIsFirstPage}
            pool={pool}
            tab={tab}
          />
        )}

        <Button
          sx={{ marginLeft: '30px' }}
          variant="outlined"
          onClick={() => {
            tab !== 'LIVE' ? handleDownloadCsv() : handleDownloadLiveCsv()
          }}
        >
          {trans.t('downloadAllList')}
        </Button>
      </article>
    </div>
  )
}

export default LendList
