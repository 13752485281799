import { Button } from '@mui/material'
import { httpGetAccounts } from '@src/api/httpAPI/httpAccountAPI'
import LogoutButton from '@src/components/Button/LogoutButton'
import TradeButton from '@src/components/Button/TradeButton'
import Popup from '@src/components/PopupState/PopupState'
import SelectOption from '@src/components/SelectOption'
import { downloadMessageCsv } from '@src/util/getCsv'
import { MessageTypeText, MessageTypeTextEn } from '@src/constants/MessageTypeText'
import { OrderTypeText, OrderTypeTextEn } from '@src/constants/OrderTypeText'
import useMessage from '@src/hooks/useMessage'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { csvDict, csvHeader, csvKeyNotSender, csvKeySender } from './csv'
import { CLASSES } from '@src/constants/Global'
import { useRecoilState, useRecoilValue } from 'recoil'
import { topNotiCountsState } from '@src/stores/topNotiCountsState'
import { topBlinkState } from '@src/stores/topBlinkState'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../../api/storageAPI'
const MessageList = () => {
  const navigate = useNavigate()

  const [orderType, setOrderType] = useState<OrderType | 'RECALL' | ''>('')
  const [messageType, setMessageType] = useState<MessageStatusType | ''>('')
  const [accounts, setAccounts] = useState<AccountType[]>([])
  const [partnerAccountId, setPartnerAccountId] = useState<number | ''>('')
  const [isSender, setIsSender] = useState<boolean>(false)
  const [isRead, setIsRead] = useState<boolean | ''>('')
  const [isLoading, setIsLoading] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const [topBlink, setTopBlink] = useRecoilState(topBlinkState)

  const topNotiCounts = useRecoilValue(topNotiCountsState)

  const forceRefresh = useRecoilValue(forceRefreshState)

  const tableDivRef = useRef<HTMLDivElement>()

  const { messageList, setMessageList, getMessageList, page, setPage } = useMessage()
  const trans = useTranslation()

  const getAccounts = useCallback(async () => {
    const { data } = await httpGetAccounts()
    setAccounts(data)
  }, [])

  useEffect(() => {
    if (isRefreshing && page === 0) {
      setIsRefreshing(false)
      return
    }
    setIsLoading(true)
    getMessageList({
      page,
      isRead,
      isSender,
      messageType,
      orderType,
      partnerAccountId,
    })
    setIsLoading(false)
  }, [isRead, isSender, messageType, orderType, partnerAccountId, page])

  useEffect(() => {
    if (isLoading === true) {
      return
    }
    getMessageList({
      page: 0,
      isRead,
      isSender,
      messageType,
      orderType,
      partnerAccountId,
    })
    tableDivRef.current.scrollTop = -200
    setPage(0)
    setIsRefreshing(true)
  }, [forceRefresh])

  // useEffect(() => {
  //   getMessageList({
  //     page,
  //     isRead,
  //     isSender,
  //     messageType,
  //     orderType,
  //     partnerAccountId,
  //   })
  // }, [page])

  useEffect(() => {
    getAccounts()
    setTopBlink({ ...topBlink, recallCount: false, urgentCount: false })
  }, [])

  const handleIsReadPopup = (popupMenuValue: string, queryKey: string, popupClose: () => void) => {
    setIsRead(popupMenuValue as any)
    setPage(0)
    popupClose()
  }

  const handleOrderTypePopup = (
    popupMenuValue: string,
    queryKey: string,
    popupClose: () => void
  ) => {
    setOrderType(popupMenuValue as OrderType)
    setPage(0)
    popupClose()
  }

  const handleMessageTypePopup = (
    popupMenuValue: string,
    queryKey: string,
    popupClose: () => void
  ) => {
    setMessageType(popupMenuValue as MessageStatusType)
    setPage(0)
    popupClose()
  }

  const hanldeAccountpopup = (popupMenuValue: string, queryKey: string, popupClose: () => void) => {
    setPartnerAccountId(Number(popupMenuValue) || '')
    setPage(0)
    popupClose()
  }

  const handleDownloadCsv = async () => {
    const csvKey = isSender ? csvKeySender : csvKeyNotSender
    downloadMessageCsv(messageList, csvHeader, csvKey, csvDict, '메시지함')
  }

  return (
    <div className={`message-list`}>
      {/* //  ${orderType}`}> */}
      <article>
        <div className="tab">
          <ul>
            <li
              onClick={() => {
                // if (isRefreshing) {
                setIsRefreshing(false)
                // return
                // }
                setIsSender(false)
                setPage(0)
                // setTab('TODAY')
              }}
              className={isSender === false ? 'selected' : ''}
            >
              {trans.t('messagePage.received')}
            </li>
            <li
              onClick={() => {
                // if (isRefreshing) {
                setIsRefreshing(false)
                // return
                // }
                setIsSender(true)
                setPage(0)
              }}
              className={isSender === true ? 'selected' : ''}
            >
              {trans.t('messagePage.sent')}
            </li>
          </ul>
        </div>
        <div className="button-wrap">
          {/* <Button
            color="grey"
            onClick={() => {
              getMessageList({
                page: 0,
                isSender,
                messageType,
                orderType,
                partnerAccountId,
              })
            }}
          >
            조회
          </Button> */}
          <TradeButton color="green" onClick={() => navigate('/message/send')}>
            {trans.t('messagePage.write')}
          </TradeButton>
        </div>
        <div className="mypage-not-read">
          <ul>
            <li>
              {trans.t('messagePage.unread')}: {topNotiCounts.messageCount}
            </li>
          </ul>
        </div>
        <div className="fixed-table message-list" ref={tableDivRef}>
          <table>
            <thead>
              <th>No</th>
              <th>
                <Popup
                  visibleText={trans.t('lendListTable.poolStatus')}
                  menuItems={[
                    {
                      text: trans.t('lendListTable.all'),
                      value: '',
                    },
                    {
                      text: trans.t('messagePage.read'),
                      value: 'true',
                    },
                    {
                      text: trans.t('messagePage.unread'),
                      value: 'false',
                    },
                  ]}
                  queryKey="isRead"
                  handlePopupMenuClick={handleIsReadPopup}
                />
              </th>
              <th className="th-change">
                <Popup
                  visibleText={trans.t('partnerAsset')}
                  menuItems={[
                    { text: trans.t('lendListTable.all'), value: '' },
                    ...accounts.map(({ companyName, companyEnglishName, accountId }) => {
                      const text =
                        localStorageAPI.getItem('lang') === 'en' ? companyEnglishName : companyName
                      return {
                        text: text,
                        value: accountId.toString(),
                      }
                    }),
                  ]}
                  // menuItems={[
                  //   {
                  //     text: trans.t('lendListTable.all'),
                  //     value: '',
                  //   },
                  //   {
                  //     text: trans.t('borrow'),
                  //     value: 'BORROW',
                  //   },
                  //   {
                  //     text: trans.t('lend'),
                  //     value: 'LEND',
                  //   },
                  // ]}
                  queryKey="accoutId"
                  handlePopupMenuClick={hanldeAccountpopup}
                />
              </th>
              <th className="th-change">
                <Popup
                  visibleText={trans.t('messagePage.borrowLend')}
                  menuItems={[
                    {
                      text: trans.t('lendListTable.all'),
                      value: '',
                    },
                    {
                      text: trans.t('borrow'),
                      value: 'BORROW',
                    },
                    {
                      text: trans.t('lend'),
                      value: 'LEND',
                    },
                  ]}
                  queryKey="orderType"
                  handlePopupMenuClick={handleOrderTypePopup}
                />
              </th>
              <th className="th-change">
                <Popup
                  visibleText={trans.t('messagePage.type')}
                  menuItems={[
                    {
                      text: trans.t('lendListTable.all'),
                      value: '',
                    },
                    {
                      text: trans.t('messagePage.urgent'),
                      value: 'URGENT',
                    },
                    {
                      text: trans.t('messagePage.recall'),
                      value: 'RECALL',
                    },
                    {
                      text: trans.t('messagePage.contact'),
                      value: 'CONTACT',
                    },
                  ]}
                  queryKey="messageTypeStatus"
                  handlePopupMenuClick={handleMessageTypePopup}
                />
              </th>
              {/* <th>{isSender ? '발신' : '수신'}</th> */}
              <th>{trans.t('messagePage.contact')}</th>
              <th>{trans.t('messagePage.time')}</th>
              <th></th>
            </thead>
            <tbody>
              {messageList.map((message, index) => {
                const date = message.dateTime?.substring(0, 8) || ''
                const time = message.dateTime?.substring(8, 12) || ''

                const partnerAccount = message.isSender ? message.to : message.from
                return (
                  <tr
                    key={index}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/message/detail/${message.id}`)}
                  >
                    <td>{message.id}</td>
                    <td className={CLASSES.tableCellFilterable}>
                      {message.isRead ? trans.t('messagePage.read') : trans.t('messagePage.unread')}
                    </td>
                    <td className={'left' + ' ' + CLASSES.tableCellFilterable}>
                      {partnerAccount &&
                        `${
                          localStorageAPI.getItem('lang') === 'en'
                            ? partnerAccount.companyEnglishName
                            : partnerAccount.companyName
                        }(${partnerAccount.companyCode}-${partnerAccount.companyPropertyCode}-${
                          partnerAccount.companySLBCode
                        })`}
                    </td>
                    <td className={CLASSES.tableCellFilterable}>
                      {localStorageAPI.getItem('lang') === 'en'
                        ? OrderTypeTextEn[message.orderType]
                        : OrderTypeText[message.orderType]}
                    </td>
                    <td className={CLASSES.tableCellFilterable}>
                      {localStorageAPI.getItem('lang') === 'en'
                        ? MessageTypeTextEn[message.messageType]
                        : MessageTypeText[message.messageType]}
                    </td>
                    {/* <td>{message.isSender ? '발신' : '수신'}</td> */}
                    <td>{date}</td>
                    <td>{time && time.substring(0, 2) + ':' + time.substring(2, 4)}</td>
                    <td></td>
                  </tr>
                )
              })}
              <div className="table-bottom"></div>
            </tbody>
          </table>
        </div>
        {/* <Button
          // onClick={() => (tab !== 'live' ? handleCsvDownload() : handleCsvDownloadLive())}
          onClick={() => handleDownloadCsv()}
          variant="outlined"
          sx={{ marginLeft: '30px', marginTop: '5px !important' }}
        >
          {trans.t('downloadAllList')}
        </Button> */}
        <footer></footer>
      </article>
    </div>
  )
}

export default MessageList
