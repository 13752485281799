import { httpPostOrder } from '@src/api/httpAPI/httpOrdersAPI'
import { httpPostPool } from '@src/api/httpAPI/httpPoolAPI'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import LendPoolRegisterOption from './LendPoolRegisterOption'
import { MemoizedTable as LendPoolRegisterTable } from './LendPoolRegisterTable'
import LogoutButton from '@src/components/Button/LogoutButton'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router'
import Tab from '@src/components/Tab'
import TradeButton from '@src/components/Button/TradeButton'
import { getPayloadAdditional } from '@src/util/getPayloadAdditional'
import { useRecoilState } from 'recoil'
import { postPoolToAllState } from '@src/stores/postPoolToAllState'
import styled from '@emotion/styled'
import { RESULT, VALUECHECK } from '@src/constants/NotiText'
import { CLASSES } from '@src/constants/Global'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { succeedModalState } from '@src/stores/succeedModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { WaitingToastModal } from '@src/components/Modal/WaitingToastModal'

import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '@src/api/storageAPI'
import { langState } from '../../stores/langState'

export interface SelectedPool {
  companyName: string
  accountId?: number
  companyCode?: string
}

export interface MyGroupPool {
  companyName: string
  accountId?: number
  companyCode?: string
}

export interface AssetPool {
  companyName: string
  companyCode: string
}

export interface SecurityPool {
  companyName: string
  accountId: number
}

export type SelectedGroup = 'MYGROUP' | 'ASSET' | 'SECURITY' | 'ALL'

interface InnerPoolList {
  issueCode: string
  rate: number
  volume: number
}

interface HttpPostPoolPayload {
  orderType: 'LEND' | 'BORROW'
  settlementType: 'TODAY' | 'NEXT_DAY'
  poolList: InnerPoolList[]
  poolType: 'ALL' | 'SELECT' | 'ASSET' | 'SECURITY'
  companyCodeList?: string[]
  accountIdList?: number[]
}

// 대여 - 등록
const LendPoolRegister: FunctionComponent = () => {
  const [pools, setPools] = useState<PoolType[]>([])
  const [poolType, setPoolType] = useState<PoolOptionType | null>(null)

  const [inputPools, setInputPools] = useState<PoolType[]>([])

  const [selectedGroup, setSelectedGroup] = useState<SelectedGroup[]>([])

  const [selectedPoolList, setSelectedPoolList] = useState<SelectedPool[]>([])

  const [postPoolToAll, setPostPoolToAll] = useRecoilState(postPoolToAllState)

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const [succeedModal, setSucceedModal] = useRecoilState(succeedModalState)
  const [lang, setLang] = useRecoilState(langState)

  const [isWaiting, setIsWaiting] = useState<boolean>(false)

  const trans = useTranslation()
  const settlementType = useTranslation('translation', { keyPrefix: 'settlementType' })

  const params = useParams()
  const tab = useMemo(() => params.tab, [params.tab])

  const baseClass = 'lend-pool-register LEND'
  const wrapperClass = useMemo(() => {
    if (tab === 'live') {
      return baseClass + ' ' + CLASSES.orderLive
    }
    return baseClass + ' ' + CLASSES.orderNormal
  }, [tab])

  const [uuid, setUuid] = useState<number>(Math.random())

  const postShortCutEvent = (e) => {
    if (e.key === 'F8') {
      if (inputPools.length === 0) {
        return
      }
      _postPool()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', postShortCutEvent)
    return () => {
      window.removeEventListener('keydown', postShortCutEvent)
    }
  }, [pools, selectedPoolList])

  useEffect(() => {
    return () => setPostPoolToAll(false)
  }, [])

  useEffect(() => {
    reset()
  }, [tab, lang])

  const reset = useCallback(() => {
    setPoolType(null)
    setSelectedPoolList([])
    setSelectedGroup([])
    setInputPools([])
    setPostPoolToAll(false)
  }, [])

  const postPoolCallback = async () => {
    try {
      if (inputPools.length > 200) {
        setIsWaiting(true)
      }
      const poolList = pools.map(({ stock, rate, volume }) => {
        if (volume <= 0) throw new Error(trans.t('modal.valueCheck.noValue'))
        return {
          issueCode: stock.issueCode,
          rate,
          volume,
        }
      })
      if (tab == 'live') {
        await httpPostOrder(
          pools.map(({ stock, rate, volume }) => ({
            issueCode: stock.issueCode,
            rate,
            volume,
            orderType: 'LEND',
          }))
        )
        // alert(trans.t('modal.result.succeed'))
        setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.result.succeed') })
        reset()
        return
      }
      if (postPoolToAll !== true && selectedPoolList.length === 0) {
        throw new Error(trans.t('modal.valueCheck.noIds'))
      }
      if (postPoolToAll) {
        const payload: HttpPostPoolPayload = Object.assign({
          orderType: 'LEND' as const,
          settlementType: tab == 'today' ? 'TODAY' : ('NEXT_DAY' as 'TODAY' | 'NEXT_DAY'),
          poolList,
          poolType: 'ALL',
        })
        await httpPostPool(payload)
        setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.result.succeed') })
        // alert(trans.t('modal.result.succeed'))
        reset()
        return
      }
      const payloadAdditional = getPayloadAdditional(selectedPoolList)
      const payload: HttpPostPoolPayload = Object.assign(
        {
          orderType: 'LEND' as const,
          settlementType: tab == 'today' ? 'TODAY' : ('NEXT_DAY' as 'TODAY' | 'NEXT_DAY'),
          poolList,
          poolType: poolType as 'ALL' | 'SELECT' | 'ASSET' | 'SECURITY',
        },
        payloadAdditional
      )
      await httpPostPool(payload)
      setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.result.succeed') })
      reset()
    } catch (error) {
      setErrorModal({ ...errorModal, innerString: error.message, isOpen: true })
      console.log('error', error)
    } finally {
      setIsWaiting(false)
    }
  }

  const _postPool = () => {
    try {
      const poolList = pools.map(({ stock, rate, volume }) => {
        if (volume <= 0) throw new Error(trans.t('modal.valueCheck.noValue'))
        return {
          issueCode: stock.issueCode,
          rate,
          volume,
        }
      })
      if (poolList.length < 1) {
        throw Error(trans.t('modal.valueCheck.noInput'))
      }
      const innerString =
        localStorageAPI.getItem('lang') === 'ko'
          ? `${poolList.length}${trans.t('confirmCheck')}`
          : trans.t('confirmCheck') + ' ' + poolList.length + ' ' + 'lines.'
      setConfirmModal({
        ...confirmModal,
        isOpen: true,
        innerString: innerString,
        confirmFunction: postPoolCallback,
        closeFunction: () => {
          return
        },
      })
    } catch (error) {
      setErrorModal({ ...errorModal, innerString: error.message, isOpen: true })
    }
  }

  const postPool = async () => {
    try {
      const poolList = pools.map(({ stock, rate, volume }) => {
        if (volume <= 0) throw new Error(trans.t('modal.valueCheck.noValue'))
        return {
          issueCode: stock.issueCode,
          rate,
          volume,
        }
      })
      if (poolList.length < 1) {
        throw Error(trans.t('modal.valueCheck.noInput'))
      }
      if (window.confirm(`${poolList.length} 건을 등록하시겠습니까?`)) {
        if (tab == 'live') {
          await httpPostOrder(
            pools.map(({ stock, rate, volume }) => ({
              issueCode: stock.issueCode,
              rate,
              volume,
              orderType: 'LEND',
            }))
          )
          alert(trans.t('modal.result.succeed'))
          reset()
          return
        }
        if (postPoolToAll !== true && selectedPoolList.length === 0) {
          throw new Error(trans.t('modal.valueCheck.noIds'))
        }
        if (postPoolToAll) {
          const payload: HttpPostPoolPayload = Object.assign({
            orderType: 'LEND' as const,
            settlementType: tab == 'today' ? 'TODAY' : ('NEXT_DAY' as 'TODAY' | 'NEXT_DAY'),
            poolList,
            poolType: 'ALL',
          })
          await httpPostPool(payload)
          alert(trans.t('modal.result.succeed'))
          reset()
          return
        }
        const payloadAdditional = getPayloadAdditional(selectedPoolList)
        const payload: HttpPostPoolPayload = Object.assign(
          {
            orderType: 'LEND' as const,
            settlementType: tab == 'today' ? 'TODAY' : ('NEXT_DAY' as 'TODAY' | 'NEXT_DAY'),
            poolList,
            poolType: poolType as 'ALL' | 'SELECT' | 'ASSET' | 'SECURITY',
          },
          payloadAdditional
        )
        await httpPostPool(payload)
        alert(trans.t('modal.result.succeed'))
        reset()
      }
    } catch (err) {
      if (err.message) {
        alert(err.message)
      } else {
        alert(RESULT.fail)
      }
    }
  }

  return (
    <div className={wrapperClass} key={tab}>
      <WaitingToastModal isWaiting={isWaiting} />
      <article>
        <Tab
          click={() => {
            setInputPools([])
          }}
          tabs={[
            {
              path: '/lend/pool/register/today',
              selected: tab === 'today',
              title: settlementType.t('today'),
            },
            {
              path: '/lend/pool/register/next_day',
              selected: tab === 'next_day',
              title: settlementType.t('nextDay'),
            },
            {
              path: '/lend/pool/register/live',
              selected: tab === 'live',
              title: settlementType.t('live'),
            },
          ]}
        />
        {/* <TradeButton
          sx={{
            fontWeight: 'bold',
            fontSize: '16px',
            height: '40px',
            width: '140px',
            background: '#fa4e60 !important',
            borderRadius: '2px',
          }}
        >
          테스트
        </TradeButton> */}
        <div className="button-wrap">
          <TradeButton color="red" onClick={() => reset()}>
            {trans.t('reset')}
          </TradeButton>
          <TradeButton
            color="blue"
            // className={`${pools.length < 1 ? 'disabled' : ''}`}
            onClick={() => _postPool()}
            disabled={inputPools.length === 0}
            // disabled={pools.length < 1}
          >
            {trans.t('submit') + '(F8)'}
          </TradeButton>
        </div>
        <>
          {tab !== 'live' && (
            <LendPoolRegisterOption
              poolType={poolType}
              setPoolType={setPoolType}
              selectedPoolList={selectedPoolList}
              setSelectedPoolList={setSelectedPoolList}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
          )}
          <LendPoolRegisterTable
            setSelectedPools={setPools}
            inputPools={inputPools}
            setInputPools={setInputPools}
            tab={tab}
          />
        </>
      </article>
    </div>
  )
}

export default LendPoolRegister
