import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { MenuItem, MenuList } from '@mui/material'

interface TabsObj {
  [key: string]: string
}

interface SetTabProps {
  selectedTab: string
  wrapClassName: string
  setTab: React.Dispatch<React.SetStateAction<SettlementType>>
  tabsObj: TabsObj
}

const SetTab = ({ selectedTab, wrapClassName, setTab, tabsObj }: SetTabProps) => {
  return (
    <div className={wrapClassName}>
      <ul>
        {Object.keys(tabsObj).map((tabKey, index) => (
          <li
            key={tabKey}
            onClick={() => setTab(tabKey as SettlementType)}
            className={selectedTab === tabKey ? 'selected' : ''}
          >
            {tabsObj[tabKey]}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SetTab
