import { CSSProperties, FunctionComponent } from 'react'

interface Props {
  style?: CSSProperties
  children: React.ReactNode
}
const Form: FunctionComponent<Props> = (props) => {
  return (
    <div className="form-wrapper" style={props.style}>
      <form>
        <fieldset>{props.children}</fieldset>
      </form>
    </div>
  )
}

export default Form
