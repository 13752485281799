import httpClient from './httpClient'

export const httpPostOrder = async (params: RequestPostOrdersType[]) =>
  await httpClient.post<ResponsePostOrdersType[]>('/api/v1/orders', [...params])

// TODO
// declare orderId Type number[]
export const httpDeletetOrder = async (orderId: any) => {
  await httpClient.delete(`/api/v1/orders?ids=${orderId.join()}`)
}
export const httpGetOrder = async (params: {
  page?: number
  size?: number
  orderType?: OrderType
  orderStatus?: OrderStatusType
  query?: string
  poolStatus?: PoolStatusType
}) => {
  const { data } = await httpClient.get<OrderDataType[]>('/api/v1/orders', {
    params: {
      ...params,
    },
  })

  return data
}

export const httpGetOrderStatus = async (params: {
  page?: number
  size?: number
  orderType?: OrderType
  orderStatus?: OrderStatusType
  query?: string
  poolStatus?: PoolStatusType
}) => {
  const { data } = await httpClient.get<OrderDataType[]>('/api/v1/orders/status', {
    params: {
      ...params,
    },
  })

  return data
}
