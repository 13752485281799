import { Button } from '@mui/material'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { SelectedGroup, SelectedPool } from './LendPoolRegister'
import { postPoolToAllState } from '@src/stores/postPoolToAllState'
import { useRecoilValue } from 'recoil'

import { useTranslation } from 'react-i18next'

interface ThirdLineProps {
  selectedGroup: SelectedGroup[]
  selectedPoolList: SelectedPool[]
  setSelectedPoolList: React.Dispatch<React.SetStateAction<SelectedPool[]>>
}

const ThirdLine = ({ selectedGroup, selectedPoolList, setSelectedPoolList }: ThirdLineProps) => {
  const postPoolToAll = useRecoilValue(postPoolToAllState)
  const [output, setOutput] = useState('')

  const trans = useTranslation()

  const isOpenToAll = useMemo(() => {
    if (selectedGroup.length === 0 && selectedPoolList.length === 0) {
      return true
    }
    return false
  }, [selectedGroup, selectedPoolList])

  useEffect(() => {
    if (selectedGroup === null) {
      setOutput('')
      return
    }
    if (isOpenToAll) {
      if (postPoolToAll !== true) {
        setOutput(trans.t('registerOption.none'))
        return
      }
      setOutput(trans.t('registerOption.lendingAll'))
      return
    }
    if (selectedGroup.includes('ASSET')) {
      if (selectedPoolList.length < 1) {
        setOutput(trans.t('registerOption.asset'))
      }
      return
      // }
      // if (selectedGroup === 'SECURITY') {
      //   if (selectedPoolList.length < 1) {
      //     setOutput('증권사 전체 공개')
      //   }
      //   return
      // }
      // if (selectedGroup === 'MYGROUP') {
      //   if (selectedPoolList.length < 1) {
      //     setOutput('내 그룹 선택하지 않음')
      //   }
      //   return
    }
  }, [selectedGroup, selectedPoolList, trans])

  const handleDelBtnAccountId = useCallback(
    (currentPool: SelectedPool) => {
      setSelectedPoolList(
        selectedPoolList.filter((pool) => pool.accountId !== currentPool.accountId)
      )
    },
    [selectedPoolList]
  )

  const handleDelBtnConmpanyCode = useCallback(
    (currentPool: SelectedPool) => {
      setSelectedPoolList(
        selectedPoolList.filter((pool) => pool.companyCode !== currentPool.companyCode)
      )
    },
    [selectedPoolList]
  )

  const handleDelBtn = (currentPool: SelectedPool) => {
    if (currentPool.accountId !== undefined) {
      setSelectedPoolList(
        selectedPoolList.filter((pool) => pool.accountId !== currentPool.accountId)
      )
      return
    }
    setSelectedPoolList(
      selectedPoolList.filter((pool) => pool.companyCode !== currentPool.companyCode)
    )
  }

  if (postPoolToAll) {
    return <>{trans.t('registerOption.lendingAll')}</>
  }
  if (isOpenToAll) {
    return <>{output}</>
  } else {
    return (
      <>
        {selectedPoolList.map((currentPool) => (
          <Fragment key={currentPool.accountId || currentPool.companyName}>
            <li className="li-company">
              {currentPool.companyName}
              <Button
                onClick={() => handleDelBtn(currentPool)}
                sx={{
                  backgroundColor: '#ffffff !important',
                  color: 'red !important',
                  minWidth: '20px',
                  width: '20px !important',
                  height: '20px !important',
                }}
              >
                x
              </Button>
            </li>
          </Fragment>
        ))}
      </>
    )
  }
}

export default ThirdLine
