import { atom } from 'recoil'

interface OverAmountModalState {
  isOpen: boolean
  maxVolume: number
}

export const overAmountModalState = atom<OverAmountModalState>({
  key: 'overAmountModalState',
  default: {
    isOpen: false,
    maxVolume: 0,
  },
})
