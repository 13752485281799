import Checkbox from '@src/components/Checkbox'
import TextField from '@src/components/TextField'
import { SAVE, VALUECHECK } from '@src/constants/NotiText'
import { useCallback, useEffect, useState } from 'react'
import TradeButton from '@src/components/Button/TradeButton'
import { useRecoilState } from 'recoil'
import { userState } from '@src/stores/userStore'
import { httpPutUser } from '@src/api/httpAPI/httpUserAPI'
import { useTranslation } from 'react-i18next'

const MyPage3 = () => {
  const [accountList, setAccountList] = useState<UserType['accountList']>([])
  const [account, setAccount] = useState<string>('')
  const [slbAccount, setSlbAccount] = useState<string>('')
  const [organization, setOrganization] = useState<string>('')
  const [memo, setMemo] = useState<string>('')
  const [user, setUser] = useRecoilState(userState)

  const [phoneList, setPhoneList] = useState<Map<number, string>>(new Map())
  const [confirmationEmailCount, setConfirmationEmailCount] = useState<number>(0)
  const [confirmationEmailList, setConfirmationEmailList] = useState<Map<number, string>>(new Map())

  const [name, setName] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const trans = useTranslation()

  const addAccount = useCallback(() => {
    if (account.length < 12) {
      alert(trans.t('modal.valueCheck.need12Characters'))
      return
    }
    setAccountList((accountList) => [
      ...accountList,
      {
        isSelected: false,
        companyName: '',
        ksdAccount: account,
        memo,
        organization,
        slbAccount: slbAccount,
      },
    ])
    setAccount('')
    setSlbAccount('')
    setOrganization('')
    setMemo('')
  }, [account, slbAccount, organization, memo])

  const saveUser = useCallback(async () => {
    try {
      const { data } = await httpPutUser({
        name,
        username,
        phoneList: Array.from(phoneList, ([_, phone]) => ({ phone })),
        accountList,
        confirmationEmailList: Array.from(confirmationEmailList, ([_, email]) => ({ email })),
      })

      setUser(data)
      alert(SAVE.succeed)
    } catch {
      alert(SAVE.fail)
    }
  }, [name, username, phoneList, confirmationEmailList, accountList])

  useEffect(() => {
    if (!user) return
    setName(user.name)
    setUsername(user.username)
    setPhoneList(
      user.phoneList.reduce((map, { phone }, index) => {
        map.set(index, phone)
        return map
      }, new Map())
    )
    setConfirmationEmailList(
      user.confirmationEmailList.reduce((map, { email }, index) => {
        map.set(index, email)
        return map
      }, new Map())
    )
    setConfirmationEmailCount(
      user.confirmationEmailList.length == 0 ? 1 : user.confirmationEmailList.length
    )

    setAccountList(user.accountList)
    // getUserGroup()
  }, [user])

  return (
    <div className="mypage mypage3">
      <article>
        <div className="button-wrap">
          {/*<TradeButton color="red" onClick={() => navigate(-1)}>*/}
          {/*  뒤로가기*/}
          {/*</TradeButton>*/}
          <TradeButton color="green" onClick={() => saveUser()}>
            저장
          </TradeButton>
        </div>
        <div className="ksd-account-info">
          <h3>KSD 등록 계좌 정보</h3>
          <div className="fixed-table registered-account">
            <table>
              <thead>
                <tr>
                  <th>주문</th>
                  <th>No.</th>
                  <th>계좌</th>
                  <th>SLB 계정 코드</th>
                  <th>사용부서</th>
                  <th>메모</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {accountList.map((account, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        setAccountList((accountList) => {
                          return accountList.map((account, _index) => {
                            if (index == _index) {
                              return {
                                ...account,
                                isSelected: true,
                              }
                            } else {
                              return {
                                ...account,
                                isSelected: false,
                              }
                            }
                          })
                        })
                      }}
                    >
                      <td>
                        <Checkbox readOnly checked={account.isSelected} onClick={() => {}} />
                      </td>
                      <td>{index + 1}</td>
                      <td>{account.ksdAccount}</td>
                      <td>{account.slbAccount}</td>
                      <td>{account.organization}</td>
                      <td>{account.memo}</td>
                      <td>
                        <span
                          className="del"
                          onClick={(e) => {
                            e.preventDefault()
                            setAccountList((accountList) =>
                              accountList.filter((_, i) => i !== index)
                            )
                          }}
                        >
                          삭제
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  )
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <TextField
                      type="text"
                      pattern="[0-9]*"
                      maxLength={12}
                      value={account}
                      onChange={(e) => {
                        if (e.target.validity) {
                          setAccount(e.target.value)
                        }
                      }}
                    />
                  </td>
                  <td>
                    <TextField
                      type="text"
                      pattern="[0-9]*"
                      value={slbAccount}
                      onChange={(e) => setSlbAccount(e.target.value)}
                    />
                  </td>
                  <td>
                    <TextField
                      type="text"
                      value={organization}
                      onChange={(e) => setOrganization(e.target.value)}
                    />
                  </td>
                  <td>
                    <TextField type="text" value={memo} onChange={(e) => setMemo(e.target.value)} />
                  </td>
                  <td>
                    <span className="add" onClick={() => addAccount()}>
                      추가
                    </span>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>* 주문으로 체크한 계좌로 대차거래 됩니다(다른 계좌로 주문하려는 경우 변경 후 사용)</p>
        </div>
      </article>
    </div>
  )
}

export default MyPage3
