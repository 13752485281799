import { httpGetLedger } from '@src/api/httpAPI/httpLedgerAPI'
import Button from '@src/components/Button'
import LogoutButton from '@src/components/Button/LogoutButton'
import Checkbox from '@src/components/Checkbox'
import ExcelButton from '@src/components/ExcelButton/ExcelButton'
import SearchIssue from '@src/components/SearchIssue/SearchIssue'
import SelectOption from '@src/components/SelectOption'
import TextField from '@src/components/TextField'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import { formatNumber } from '@src/util/formatNumber'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type StatusType =
  | 'ALL'
  | 'RECEIVED'
  | 'MATCHED'
  | 'TRANSFERRED'
  | 'CANCELED'
  | 'REJECTED'
  | 'REQUESTED'

const SearchBalance = () => {
  const [orderType, setOrderType] = useState<OrderType | ''>('')
  const [status, setStatus] = useState<StatusType>('ALL')
  const [dateType, setDateType] = useState<'ORDER_DATE' | 'SETTLEMENT_DATE'>('ORDER_DATE')
  const [after, setAfter] = useState<string>(DateTime.local().toFormat('yyyyMMdd'))
  const [before, setBefore] = useState<string>(DateTime.local().toFormat('yyyyMMdd'))
  const [ledgers, setLedgers] = useState<LedgerType[]>([])
  const [selectedLedgerId, setSelectedLedgerId] = useState<LedgerType['ledgerId'][]>([])

  const trans = useTranslation()

  const getLedgers = useCallback(
    async (query?: string) => {
      const ledgers = await httpGetLedger({
        orderType,
        orderStatus: '',
        query: query || '',
        after,
        before: after,
        // dateType,
      })
      setLedgers(ledgers)
    },
    [orderType, after, before, dateType]
  )

  useEffect(() => {
    getLedgers()
  }, [])

  return (
    <div className="BORROW">
      <header>잔고조회</header>
      <article>
        <div className="options">
          <div className="select-options">
            <SelectOption
              label="대차구분"
              value={orderType}
              onChange={(e) => {
                setOrderType(e.target.value as OrderType)
              }}
              options={[
                {
                  label: '전체',
                  value: 'ALL',
                },
                {
                  label: '차입',
                  value: 'BORROW',
                },
                {
                  label: '대여',
                  value: 'LEND',
                },
              ]}
            />
          </div>
          <div className="select-options">
            {
              <SelectOption
                value={dateType}
                onChange={(e) => {
                  setDateType(e.target.value as 'ORDER_DATE' | 'SETTLEMENT_DATE')
                }}
                options={[
                  {
                    label: '신청일',
                    value: 'ORDER_DATE',
                  },
                  {
                    label: '결제일',
                    value: 'SETTLEMENT_DATE',
                  },
                ]}
              />
            }
            <TextField value={after} onChange={(e) => setAfter(e.target.value)} />
            {/* <span>~</span>
            <TextField value={before} onChange={(e) => setBefore(e.target.value)} /> */}
            <Button color="green" onClick={() => getLedgers()}>
              조회
            </Button>
          </div>
        </div>
        <SearchIssue
          searchIcon
          handleSearch={(query) => {
            getLedgers(query)
          }}
        />
        <div className="fixed-table">
          <table>
            <thead>
              {/* <th>전체선택</th> */}
              <th>{trans.t('lendListTable.orderType')}</th>
              <th>신청일</th>
              <th>결제일</th>
              <th>{trans.t('matchedId')}</th>
              <th>예탁원 확정번호</th>
              <th>{trans.t('partnerAsset')}</th>
              <th>{trans.t('issueCode')}</th>
              <th>{trans.t('issueName')}</th>
              <th>{trans.t('table.volume')}</th>
              <th>{trans.t('table.rate')}</th>
              <th>잔고금액(원)</th>
              <th></th>
            </thead>
            <tbody>
              {ledgers.map((ledger, index) => (
                <tr key={index}>
                  <td>{OrderTypeText[ledger.orderType]}</td>
                  <td>{ledger.orderDateTime?.substring(0, 8)}</td>
                  <td>{ledger.matchedDateTime?.substring(0, 8)}</td>
                  <td className="right">{ledger.ledgerId}</td>
                  <td className="right">{ledger.matchKSDId}</td>
                  <td className="left">
                    {ledger.partnerAccount &&
                      `${ledger.partnerAccount.companyName}(${ledger.partnerAccount.companyCode})`}
                  </td>
                  <td>{ledger.stock.issueCode}</td>
                  <td className="left">{ledger.stock.issueName}</td>
                  <td className="right">{formatNumber(ledger.volume)}</td>
                  <td className="right">{ledger.rate.toFixed(2)}</td>
                  <td>{formatNumber(ledger.remainVolume * ledger.stock.previousClosingPrice)}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <footer>
          <ExcelButton
            data={ledgers.map((ledger) => ({
              ...ledger,
              issueCode: `=""${ledger.stock.issueCode}""`,
              orderType: OrderTypeText[orderType],
              orderDateTime: ledger.orderDateTime?.substring(0, 8),
              matchedDateTime: ledger.matchedDateTime?.substring(0, 8),
            }))}
            headers={headers}
            fileName={`잔고조회 ${DateTime.local().toFormat('yyyyMMdd')}`}
          >
            엑셀다운로드
          </ExcelButton>
          <ul>
            <li>종목수 : {ledgers.length} </li>
            <li>수량합계 : {ledgers.reduce((acc, cur) => acc + cur.volume, 0)}</li>
            <li>
              잔고금액합계 : {formatNumber(ledgers.reduce((acc, cur) => acc + cur.amount, 0))}원
            </li>
          </ul>
        </footer>
      </article>
    </div>
  )
}

const headers = [
  { label: '대차구분', key: 'orderType' },
  { label: '상태', key: 'orderStatus' },
  { label: '신청일', key: 'orderDateTime' },
  { label: '결제일', key: 'matchedDateTime' },
  { label: '확정번호', key: 'matchKsdId' },
  { label: '예탁원 확정번호', key: 'ledgerId' },
  { label: '상대기관', key: 'partnerAccount?.companyName' },
  { label: '종목코드', key: 'issueCode' },
  { label: '종목명', key: 'stock.issueName' },
  { label: '수량(주)', key: 'volume' },
  { label: '요율(%)', key: 'rate' },
  { label: '잔고금액(원)', key: 'amount' },
]

export default SearchBalance
