import httpClient from './httpClient'

export const httpPostDesignatedOrders = async (params: {
  orderList: {
    issueCode: string
    orderType: OrderType
    rate: number
    volume: number
  }[]
  partnerAccountId: number
  settlementType: SettlementType
}) => {
  const { data } = await httpClient.post<ResponseDesignatedPostOrdersType[]>(
    '/api/v1/designated-orders',
    { ...params }
  )
  return data
}

export const httpGetDesignatedOrdersRequest = async (params: {
  type: 'ACCEPT' | 'CANCEL' | 'CHECK' | 'POST' | 'REJECT' | 'UPDATE'
  orderType: OrderType
  status?: StatusType
  page: number
  size: number
  query?: string
  partnerAccountId: number | ''
  settlementType: SettlementType
}) => {
  const { data } = await httpClient.get<DesignatedOrderRequestType[]>(
    '/api/v1/designated-orders/requests',
    {
      params: {
        ...params,
      },
    }
  )
  return data
}

export const httpPostDesignatedOrdersRequest = async (
  params: {
    orderId: number
    rate: number
  }[]
) => {
  const { data } = await httpClient.post<ResponseDesignatedPostRequestOrdersType[]>(
    `/api/v1/designated-orders/requests`,
    params
  )
  return data
}

export const httpPutDesignatedOrdersRequest = async (
  params: {
    isAccept: boolean
    orderId: number
    requestId: number
  }[]
) => {
  const { data } = await httpClient.put<ResponseDesignatedPutRequestOrdersType[]>(
    `/api/v1/designated-orders/requests`,

    params
  )
  return data
}
