import React from 'react'

import { Button, Menu } from '@mui/material'
import { styled } from '@mui/system'
import MenuItem from '@mui/material/MenuItem'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

interface PopupMenuItem {
  text: string
  value: string
}

interface PopupProps {
  visibleText: string
  menuItems: PopupMenuItem[]
  queryKey?: string
  handlePopupMenuClick?: (popupMenuValue: string, queryKey: string, popupClose: () => void) => void
  pageType?: string
}

const MenuItemStatus = styled(MenuItem)({
  fontSize: '13px',
})

const Popup = ({
  visibleText,
  menuItems,
  queryKey,
  handlePopupMenuClick,
  pageType,
}: PopupProps) => {
  const popupBtnStyle = [
    {
      textTransform: 'none',
      fontWeight: 'bold',
      padding: '2px',
      backgroundColor: 'inherit',
      boxShadow: 'none !important',
      lineHeight: 'normal',
    },
    {
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        // pageType === 'borrow' ? '#b55b5b' : '#3c5c91',
      },
      '& >span': {
        marginTop: '20px !important',
      },
    },
  ]
  return (
    <PopupState variant="popover" popupId="basic-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="contained" sx={popupBtnStyle} {...bindTrigger(popupState)}>
            {visibleText}
            <KeyboardArrowDownIcon sx={{ width: '15px' }}></KeyboardArrowDownIcon>
          </Button>
          <Menu {...bindMenu(popupState)}>
            {menuItems.map((item) => {
              if (
                item.text === 'Y' ||
                item.text === 'N' ||
                item.text === 'T' ||
                item.text === 'T1'
              ) {
                return (
                  <MenuItemStatus
                    onClick={() => handlePopupMenuClick(item.value, queryKey, popupState.close)}
                  >
                    <span style={{ margin: 'auto' }}>{item.text}</span>
                  </MenuItemStatus>
                )
              }
              return (
                <MenuItemStatus
                  onClick={() => handlePopupMenuClick(item.value, queryKey, popupState.close)}
                >
                  <span>{item.text}</span>
                </MenuItemStatus>
              )
            })}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}

export default Popup
