import {
  httpDelRequestV2,
  httpGetRequestV2,
  httpPutRequestV2,
} from '@src/api/httpAPI/httpRequestAPI'
import SelectOption from '@src/components/SelectOption'
import TextField from '@src/components/TextField'
import useInfiniteScroll from '@src/hooks/useInfiniteScroll'
import { DateTime } from 'luxon'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import LendOrderResponseTable from './SearchTable'
import LogoutButton from '@src/components/Button/LogoutButton'
import { SpanCursorPointer } from '@src/components/Span/SpanCursorPointer'
import TradeButton from '@src/components/Button/TradeButton'
import { isKeyTabOrEnter } from '@src/util/isKeyTapOrEnter'
import { httpGetCalender } from '@src/api/httpAPI/httpCalender'
import {
  csvDict,
  csvDictEn,
  csvDictHistory,
  csvDictHistoryEn,
  csvHeader,
  csvHeaderEn,
  csvHeaderHistory,
  csvHeaderHistoryEn,
  csvHeaderNotRequester,
  csvHeaderResponse,
  csvHeaderResponseEn,
  csvKey,
  csvKeyEn,
  csvKeyHistory,
  csvKeyHistoryEn,
  csvKeyNotRequester,
  csvKeyResponse,
  csvKeyResponseEn,
} from './csv'
import { downloadCsvWithDict, getNowClock } from '@src/util/getCsv'
import { Button } from '@mui/material'
import {
  httpDelLedgerV2,
  httpGetLedgerDownload,
  httpGetLedgerV2,
} from '@src/api/httpAPI/httpLedgerAPI'
import { pageTypeBorrow, pageTypeLend } from '@src/constants/MatchPoolSearch'
import { REQUEST } from '@src/constants/NotiText'
import { useRecoilState, useRecoilValue } from 'recoil'
import { topBlinkState } from '@src/stores/topBlinkState'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { checkDateValid } from '@src/util/checkDateValid'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface QueryPayload {
  query?: string
  companyName?: string
  stockQuery?: string
  companyQuery?: string
  settlementType?: SettlementType | '' | string
  transactionType?: TransactionType | '' | string
  requestType?: RequestOptionType | '' | string
  isRequester?: boolean
  after?: string
  before?: string
  requestStatus?: string
  orderStatus?: string
}

interface MatchedPoolSearchProps {
  pageType: PageType
  isResponsePage: boolean
}

const MatchedPoolSearch: FunctionComponent<MatchedPoolSearchProps> = ({
  pageType,
  isResponsePage,
}: MatchedPoolSearchProps) => {
  const constants = pageType === 'LEND' ? pageTypeLend : pageTypeBorrow

  const [query, setQuery] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const [isLast, setIsLast] = useState(true)
  const [page, setPage] = useState(0)
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [settlementType, setSettlementType] = useState<SettlementType | ''>('')
  const [transactionType, setTransactionType] = useState<TransactionType | ''>('')
  const [requestType, setRequestType] = useState<RequestOptionType | ''>('')

  const [selectedRequestId, setSelectedRequestId] = useState<number[]>([])
  const [requests, setRequests] = useState<any[]>([])

  const [isRequester, setIsRequester] = useState<boolean>(true)
  const [after, setAfter] = useState<string>(sessionStorage.getItem('after'))
  const [before, setBefore] = useState<string>(sessionStorage.getItem('before'))

  const [selectedIndex, setSelectedIndex] = useState<number[]>([])

  const [topTab, setTopTab] = useState<'LEDGER' | 'RESPONSE' | 'HISTORY'>(
    isResponsePage ? 'RESPONSE' : 'LEDGER'
  )

  const [uuid, setUuid] = useState<number>(Math.random())
  const [topBlink, setTopBlink] = useRecoilState(topBlinkState)

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const forceRefresh = useRecoilValue(forceRefreshState)
  const trans = useTranslation()

  const searchResponsePayload = {
    settlementType: '',
    transactionType: '',
    isRequester: false,
    requestType: 'CANCEL',
    requestStatus: 'RECEIVED',
    after: after,
    before: before,
  }

  const [queryPayload, setQueryPayload] = useState<QueryPayload>(
    isResponsePage
      ? searchResponsePayload
      : {
          settlementType: '',
          transactionType: '',
          requestType: '',
          after: after,
          before: before,
        }
  )

  // useEffect(() => {}, [selectedRequestId])

  // {topTab === 'LEDGER' && (
  //   <TradeButton
  //     disabled={selectedRequestId.length === 0}
  //     onClick={() => delLedgers()}
  //     color="red"
  //   >
  //     취소(F8)
  //   </TradeButton>
  // )}
  // {topTab === 'HISTORY' && (
  //   <TradeButton
  //     disabled={selectedRequestId.length === 0}
  //     onClick={() => delLedgers()}
  //     color="red"
  //     sx={!isRequester && { visibility: 'hidden' }}
  //   >
  //     취소(F8)
  //   </TradeButton>
  // )}
  // {topTab === 'RESPONSE' && (
  //   <>
  //     <TradeButton
  //       disabled={selectedRequestId.length === 0}
  //       onClick={() => putRequests('REJECT')}
  //       color="red"
  //     >
  //       거부
  //     </TradeButton>
  //     <TradeButton
  //       disabled={selectedRequestId.length === 0}
  //       onClick={() => putRequests('ACCEPT')}
  //       color="blue"
  //     >
  //       동의(F8)
  //     </TradeButton>
  //   </>
  // )}

  const delShortCutEvent = (e) => {
    if (e.key === 'F8') {
      delLedgers()
    }
  }

  const putShortCutEvent = (e) => {
    if (e.key === 'F8') {
      putRequests('ACCEPT')
    }
  }

  const refresh = () => {
    isResponsePage
      ? setQueryPayload(searchResponsePayload)
      : setQueryPayload({
          settlementType: '',
          transactionType: '',
          requestType: '',
          // isRequester: isRequester,
          after: sessionStorage.getItem('after'),
          before: sessionStorage.getItem('before'),
        })
    resetField()
    setPage(0)
  }

  useEffect(() => {
    isLoading === false && refresh()
  }, [forceRefresh])

  useEffect(() => {
    if (topTab === 'RESPONSE') {
      window.addEventListener('keydown', putShortCutEvent)
      return () => {
        window.removeEventListener('keydown', putShortCutEvent)
      }
    } else {
      window.addEventListener('keydown', delShortCutEvent)
      return () => {
        window.removeEventListener('keydown', delShortCutEvent)
      }
    }
  }, [topTab, selectedRequestId])

  const getHttpApiUrl = (topTab: 'LEDGER' | 'RESPONSE' | 'HISTORY') => {
    if (topTab === 'LEDGER') {
      return httpGetLedgerV2
    }
    return httpGetRequestV2
  }

  const putRequestsAcceptCallback = async () => {
    try {
      const res = await httpPutRequestV2({
        responseList: requests
          .filter(({ requestId }) => selectedRequestId.includes(requestId))
          .map(({ orderId, requestedRate, requestedVolume, requestId }) => ({
            orderId,
            rate: requestedRate,
            volume: requestedVolume,
            requestId,
          })),
        responseType: 'ACCEPT',
      })
      setPage(0)
      setSelectedRequestId([])
      setSelectedIndex([])
      setRequests([])
      setQueryPayload({
        settlementType: '',
        transactionType: '',
        isRequester: false,
        requestType: 'CANCEL',
        requestStatus: 'RECEIVED',
        after: sessionStorage.getItem('after'),
        before: sessionStorage.getItem('before'),
      })
      resetField()
      if (pageType === 'BORROW') {
        const topBlinkBorrow = { ...topBlink.borrow, cancelRequestCount: false }
        setTopBlink({ ...topBlink, borrow: topBlinkBorrow })
      }
      if (pageType === 'LEND') {
        const topBlinkLend = { ...topBlink.lend, cancelRequestCount: false }
        setTopBlink({ ...topBlink, lend: topBlinkLend })
      }
      setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.request.succeed') })
    } catch (error) {
      console.error(error)
      setErrorModal({ ...errorModal, isOpen: true, innerString: trans.t('modal.request.invalid') })
    }
  }

  const putRequestsRejectCallback = async () => {
    try {
      const res = await httpPutRequestV2({
        responseList: requests
          .filter(({ requestId }) => selectedRequestId.includes(requestId))
          .map(({ orderId, requestedRate, requestedVolume, requestId }) => ({
            orderId,
            rate: requestedRate,
            volume: requestedVolume,
            requestId,
          })),
        responseType: 'REJECT',
      })
      setPage(0)
      setSelectedRequestId([])
      setSelectedIndex([])
      setRequests([])
      setQueryPayload({
        settlementType: '',
        transactionType: '',
        isRequester: false,
        requestType: 'CANCEL',
        requestStatus: 'RECEIVED',
        after: sessionStorage.getItem('after'),
        before: sessionStorage.getItem('before'),
      })
      resetField()
      if (pageType === 'BORROW') {
        const topBlinkBorrow = { ...topBlink.borrow, cancelRequestCount: false }
        setTopBlink({ ...topBlink, borrow: topBlinkBorrow })
      }
      if (pageType === 'LEND') {
        const topBlinkLend = { ...topBlink.lend, cancelRequestCount: false }
        setTopBlink({ ...topBlink, lend: topBlinkLend })
      }
      setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.request.succeed') })
    } catch (error) {
      console.error(error)
      setErrorModal({ ...errorModal, isOpen: true, innerString: trans.t('modal.request.invalid') })
    }
  }

  const putRequests = async (responseType: RequestOptionType) => {
    const acceptText =
      localStorageAPI.getItem('lang') === 'en' ? 'Submit to accept?' : '동의하시겠습니까?'
    const rejectText =
      localStorageAPI.getItem('lang') === 'en' ? 'Submit to reject?' : '거부하시겠습니까?'
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: responseType === 'ACCEPT' ? acceptText : rejectText,
      confirmFunction:
        responseType === 'ACCEPT' ? putRequestsAcceptCallback : putRequestsRejectCallback,
      closeFunction: () => {
        return
      },
      // if (window.confirm(trans.t('modal.request.confirm'))) {
      //   const res = await httpPutRequestV2({
      //     responseList: requests
      //       .filter(({ requestId }) => selectedRequestId.includes(requestId))
      //       .map(({ orderId, requestedRate, requestedVolume, requestId }) => ({
      //         orderId,
      //         rate: requestedRate,
      //         volume: requestedVolume,
      //         requestId,
      //       })),
      //     responseType,
      //   })
      //   setPage(0)
      //   setSelectedRequestId([])
      //   setSelectedIndex([])
      //   setRequests([])
      //   setQueryPayload({
      //     query: '',
      //     companyName: '',
      //     settlementType: '',
      //     transactionType: '',
      //     isRequester: false,
      //     requestType: 'CANCEL',
      //     requestStatus: 'RECEIVED',
      //     after: after,
      //     before: before,
      //   })
      // }
      // if (pageType === 'BORROW') {
      //   const topBlinkBorrow = { ...topBlink.borrow, cancelRequestCount: false }
      //   setTopBlink({ ...topBlink, borrow: topBlinkBorrow })
      // }
      // if (pageType === 'LEND') {
      //   const topBlinkLend = { ...topBlink.lend, cancelRequestCount: false }
      //   setTopBlink({ ...topBlink, lend: topBlinkLend })
      // }
    })
  }

  const delLedgersCallback = async () => {
    try {
      const res = await httpDelLedgerV2({ ids: selectedRequestId })
      setRequests([])
      setSelectedRequestId([])
      setSelectedIndex([])
      setPage(0)
      resetField()
      setQueryPayload({
        settlementType: '',
        transactionType: '',
        requestType: '',
        after: after,
        before: before,
      })
      setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.request.succeed') })
    } catch (error) {
      setErrorModal({ ...errorModal, isOpen: true, innerString: trans.t('modal.request.invalid') })
      console.error(error)
    }
  }

  const delLedgers = async () => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: trans.t('modal.request.confirm'),
      confirmFunction: delLedgersCallback,
      closeFunction: () => {
        return
      },
      // confirmFunctionParam: { responseType: responseType },
      // tab: tab,
    })
    // if (window.confirm(trans.t('modal.request.confirm'))) {
    //   try {
    //     const res = await httpDelLedgerV2({ ids: selectedRequestId })
    //     setRequests([])
    //     setSelectedRequestId([])
    //     setSelectedIndex([])
    //     setPage(0)
    //     resetField()
    //     setQueryPayload({
    //       query: '',
    //       companyName: '',
    //       settlementType: '',
    //       transactionType: '',
    //       requestType: '',
    //       after: after,
    //       before: before,
    //     })
    //   } catch (e) {
    //     alert(trans.t('modal.request.invalid'))
    //     console.error(e)
    //   }
    // }
  }

  const getRequests = useCallback(async () => {
    setIsLoading(true)
    try {
      const payload = Object.assign(
        {
          orderType: pageType as OrderType,
          page,
          settlementType: '',
          transactionType: '',
          requestType: '' as RequestStatusType,
        },
        queryPayload
      )
      const api = getHttpApiUrl(topTab)
      const requests = await api(payload)

      if (page == 0) {
        setRequests(requests)
      } else {
        setRequests((prevRequests) => [...prevRequests, ...requests])
      }

      setIsLast(requests.length < 1)
      if (page == 0 && !requests.length) {
        setIsEmpty(true)
      } else {
        setIsEmpty(false)
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }, [queryPayload, page])

  useEffect(() => {
    setSelectedRequestId([])
    setSelectedIndex([])
    getRequests()
  }, [queryPayload])

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  useEffect(() => {
    onInfiniteScrollInit(document.querySelector('.table-bottom'))
    if (pageType === 'BORROW') {
      // if (isResponsePage === true) {
      //   const topBlinkBorrow = { ...topBlink.borrow, cancelRequestCount: false }
      //   setTopBlink({ ...topBlink, borrow: topBlinkBorrow })
      // }
      if (isResponsePage === false) {
        const topBlinkBorrow = { ...topBlink.borrow, matchedCount: false }
        setTopBlink({ ...topBlink, borrow: topBlinkBorrow })
      }
    }
    if (pageType === 'LEND') {
      // if (isResponsePage === true) {
      //   const topBlinkLend = { ...topBlink.lend, cancelRequestCount: false }
      //   setTopBlink({ ...topBlink, lend: topBlinkLend })
      // }
      if (isResponsePage === false) {
        const topBlinkLend = { ...topBlink.lend, matchedCount: false }
        setTopBlink({ ...topBlink, lend: topBlinkLend })
      }
    }
  }, [])

  useEffect(() => {
    if (isLoading || isLast) onInfiniteScrollDisconnect()
    else onInfiniteScrollUpdate()
  }, [isLoading, isLast])

  const resetField = () => {
    setQuery('')
    setCompanyName('')
    setAfter(sessionStorage.getItem('after'))
    setBefore(sessionStorage.getItem('before'))
  }

  const getCsvDownloadPayload = (topTab: 'LEDGER' | 'RESPONSE' | 'HISTORY') => {
    if (topTab === 'LEDGER') {
      return Object.assign(
        {
          orderType: pageType as OrderType,
          // requestStatus: 'RECEIVED',
          settlementType: '',
          transactionType: '',
          requestType: '' as RequestStatusType,
          after: after,
          before: before,
        }
        // queryPayload
      )
    }
    if (topTab === 'RESPONSE') {
      return Object.assign(
        {
          orderType: pageType as OrderType,
          // requestStatus: 'RECEIVED',
          settlementType: '',
          transactionType: '',
          isRequester: false,
          requestStatus: 'RECEIVED',
          requestType: 'CANCEL' as RequestStatusType,
          after: after,
          before: before,
        }
        // queryPayload
      )
    }
    if (topTab === 'HISTORY') {
      return Object.assign(
        {
          orderType: pageType as OrderType,
          // requestStatus: 'RECEIVED',
          settlementType: '',
          transactionType: '',
          requestType: 'CANCEL' as RequestStatusType,
          after: after,
          before: before,
        }
        // queryPayload
      )
    }
  }

  const getCsvDownloadApiUrl = (topTab: 'LEDGER' | 'RESPONSE' | 'HISTORY') => {
    if (topTab === 'LEDGER') {
      return httpGetLedgerV2
    }
    return httpGetRequestV2
  }

  const handleCsvDownload = async () => {
    const payload = getCsvDownloadPayload(topTab)
    const api = getCsvDownloadApiUrl(topTab)
    const requests = await api(payload)

    let csvInfo

    if (localStorageAPI.getItem('lang') === 'en') {
      csvInfo = {
        header:
          topTab === 'LEDGER'
            ? csvHeaderEn
            : topTab === 'RESPONSE'
            ? csvHeaderResponseEn
            : csvHeaderHistoryEn,
        key:
          topTab === 'LEDGER'
            ? csvKeyEn
            : topTab === 'RESPONSE'
            ? csvKeyResponseEn
            : csvKeyHistoryEn,
        dict: topTab === 'HISTORY' ? csvDictHistoryEn : csvDictEn,
        fileName: !isResponsePage ? constants.csvFileNameEn : constants.responseCsvFileNameEn,
      }
    } else {
      csvInfo = {
        header:
          topTab === 'LEDGER'
            ? csvHeader
            : topTab === 'RESPONSE'
            ? csvHeaderResponse
            : csvHeaderHistory,
        key: topTab === 'LEDGER' ? csvKey : topTab === 'RESPONSE' ? csvKeyResponse : csvKeyHistory,
        dict: topTab === 'HISTORY' ? csvDictHistory : csvDict,
        fileName: !isResponsePage ? constants.csvFileName : constants.responseCsvFileName,
      }
    }

    downloadCsvWithDict(
      requests,
      csvInfo.header,
      csvInfo.key,
      csvInfo.dict,
      csvInfo.fileName,
      localStorageAPI.getItem('lang')
    )
  }

  return (
    <div className={constants.mainWrapper + ' ' + 'tap-' + topTab}>
      <article>
        <div className={constants.tabWapper}>
          <ul>
            {!isResponsePage && (
              <li
                onClick={() => {
                  setTopTab('LEDGER')
                  setPage(0)
                  // setIsRequester(true)
                  setQueryPayload({
                    settlementType: '',
                    transactionType: '',
                    requestType: '',
                    after: after,
                    before: before,
                    // isRequester: true,
                  })
                  resetField()
                }}
                className={topTab === 'LEDGER' ? 'selected' : ''}
              >
                {trans.t('listAndCancel')}
              </li>
            )}
            {/* {isResponsePage && (
              <li
                onClick={() => {
                  setTopTab('RESPONSE')
                  setPage(0)
                  setIsRequester(false)
                  setQueryPayload({
                    query: '',
                    companyName: '',
                    settlementType: '',
                    transactionType: '',
                    isRequester: false,
                    requestType: 'CANCEL',
                    requestStatus: 'RECEIVED',
                  })
                  resetField()
                }}
                className={topTab === 'RESPONSE' ? 'selected' : ''}
              >
                취소요청 응답
              </li>
            )} */}
            {!isResponsePage && (
              <li
                onClick={() => {
                  setTopTab('HISTORY')
                  setPage(0)
                  // setIsRequester(false)
                  setQueryPayload({
                    settlementType: '',
                    transactionType: '',
                    // isRequester: false,
                    requestType: 'CANCEL',
                    after: after,
                    before: before,
                  })
                  resetField()
                }}
                className={topTab === 'HISTORY' ? 'selected' : ''}
              >
                {trans.t('cancelHistoryTab')}
              </li>
            )}
          </ul>
        </div>
        <div className="button-wrap">
          {/* <button onClick={() => putRequests('ACCEPT')}>취소</button> */}
          {/* {isRequester && ( */}
          {topTab === 'LEDGER' && (
            <TradeButton
              disabled={selectedRequestId.length === 0}
              onClick={() => delLedgers()}
              color="red"
            >
              {trans.t('cancel') + '(F8)'}
            </TradeButton>
          )}
          {topTab === 'HISTORY' && (
            <TradeButton
              disabled={selectedRequestId.length === 0}
              onClick={() => delLedgers()}
              color="red"
              sx={{ visibility: 'hidden' }}
            >
              {trans.t('cancel') + '(F8)'}
            </TradeButton>
          )}
          {topTab === 'RESPONSE' && (
            <>
              <TradeButton
                disabled={selectedRequestId.length === 0}
                onClick={() => putRequests('REJECT')}
                color="red"
              >
                {trans.t('reject')}
              </TradeButton>
              <TradeButton
                disabled={selectedRequestId.length === 0}
                onClick={() => putRequests('ACCEPT')}
                color="blue"
              >
                {trans.t('accept') + '(F8)'}
              </TradeButton>
            </>
          )}
          {/* )} */}
        </div>
        <div className="option">
          <div className="first-line">
            <h3>{trans.t('searchInList')}</h3>
            <SpanCursorPointer onClick={refresh}>
              <RefreshIcon></RefreshIcon>
              <p>&nbsp;{trans.t('refresh')}</p>
            </SpanCursorPointer>
          </div>
          <div className="second-line">
            <TextField
              label={trans.t('issue')}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e: any) => {
                if (isKeyTabOrEnter(e)) {
                  if (topTab !== 'LEDGER') {
                    setQueryPayload({ ...queryPayload, stockQuery: query })
                  } else {
                    setQueryPayload({ ...queryPayload, query: query })
                  }
                  setPage(0)
                }
              }}
            />
            <TextField
              label={trans.t('company')}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              onKeyDown={(e: any) => {
                if (isKeyTabOrEnter(e)) {
                  if (topTab !== 'LEDGER') {
                    setQueryPayload({ ...queryPayload, companyQuery: companyName })
                  } else {
                    setQueryPayload({ ...queryPayload, companyName: companyName })
                  }
                  setPage(0)
                }
              }}
            />
            <TextField
              label={trans.t('applicationDay')}
              value={after}
              style={{ marginRight: '0' }}
              inputStyle={{ width: '110px', textAlign: 'center' }}
              onChange={(e) => {
                if (e.target.value.length > 8) return
                setAfter(e.target.value)
                if (e.target.value.length === 8) {
                  if (!checkDateValid(e.target.value)) {
                    setToastModal({
                      ...toastModal,
                      isOpen: true,
                      innerString: '신청일에 잘못된 값이 입력되었습니다.',
                    })
                    return
                  }
                  setQueryPayload({ ...queryPayload, after: e.target.value })
                  setPage(0)
                }
              }}
            />
            <span className="date-dash">~</span>
            <TextField
              value={before}
              style={{ marginRight: '0' }}
              inputStyle={{ width: '110px', textAlign: 'center' }}
              onChange={(e) => {
                if (e.target.value.length > 8) return
                setBefore(e.target.value)
                if (e.target.value.length === 8) {
                  if (!checkDateValid(e.target.value)) {
                    setToastModal({
                      ...toastModal,
                      isOpen: true,
                      innerString: '신청일에 잘못된 값이 입력되었습니다.',
                    })
                    return
                  }
                  setQueryPayload({ ...queryPayload, before: e.target.value })
                  setPage(0)
                }
              }}
            />
            <span style={{ fontSize: '14px', marginLeft: '5px' }}>
              {trans.t('applicationDateSearchDescription')}
            </span>
          </div>
        </div>

        <LendOrderResponseTable
          topTab={topTab}
          setSelectedRequestIds={setSelectedRequestId}
          requests={requests}
          setRequests={setRequests}
          queryPayload={queryPayload}
          setQueryPayload={setQueryPayload}
          setPage={setPage}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          pageType={pageType}
          constants={constants}
        />

        <Button
          sx={{ marginLeft: '30px' }}
          variant="outlined"
          onClick={() => {
            handleCsvDownload()
          }}
        >
          {trans.t('downloadAllList')}
        </Button>
        {pageType === 'BORROW' && isResponsePage !== true && (
          <Button
            color="success"
            sx={{ marginLeft: '10px' }}
            variant="outlined"
            onClick={async () => {
              const downloadData = await httpGetLedgerDownload({ orderType: 'BORROW' })
              if (downloadData) {
                const fileName = '예탁원차입신청_' + getNowClock() + '.xlsx'
                const url = window.URL.createObjectURL(new Blob([downloadData]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                link.setAttribute('id', 'tempLink')
                document.body.appendChild(link)
                link.click()
                const _link = document.querySelector('#tempLink')
                _link && _link.remove()
              }
            }}
          >
            {trans.t('ksdFormat')}
          </Button>
        )}
      </article>
    </div>
  )
}

export default MatchedPoolSearch
