import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { httpGetMyGroup } from '@src/api/httpAPI/httpMyPageAPI'
import { SelectedGroup, SelectedPool } from '@src/pages/LendPoolRegister/LendPoolRegister'
import { useCallback, useEffect, useMemo, useState } from 'react'
import reactSelect from 'react-select/async'

import { useTranslation } from 'react-i18next'

interface GroupAutocomplateProps {
  options: any[]
  selectedGroupId: string
  selectedPoolList: SelectedPool[]
  setSelectedPoolList: React.Dispatch<React.SetStateAction<SelectedPool[]>>
  securityAccountIdList: (string | number)[]
}

const GroupAutocomplateSecurity = ({
  options,
  selectedGroupId,
  selectedPoolList,
  setSelectedPoolList,
  securityAccountIdList,
}: GroupAutocomplateProps) => {
  const [autoCompleteInputText, setAutoCompleteInputText] = useState(null)
  const [selectedComboboxOption, setSelectedComboboxOption] = useState(null)
  const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>([])

  const trans = useTranslation()

  useEffect(() => {
    setAutoCompleteInputText(null)
  }, [])

  const hanldeMyGroupInputChange = useCallback(
    async (e: any, value, reason) => {
      if (e._reactName === 'onChange') {
        return
      }
      if (e._reactName === 'onBlur') {
        return
      }
      const res = await httpGetMyGroup(selectedComboboxOption.id)
      const list = res.accountList.map(({ accountId, companyName }) => {
        return {
          accountId: accountId,
          companyName: companyName,
        }
      })
      setSelectedPoolList(list)
      setAutoCompleteInputText(value)
    },
    [selectedComboboxOption]
  )

  const hanldeAssetGroupInputChange = useCallback(
    async (e: any, value, reason) => {
      if (e._reactName === 'onChange') {
        return
      }
      if (e._reactName === 'onBlur') {
        return
      }
      const isDup = (selectedPoolList: SelectedPool[], selectedComboboxOption) => {
        let bool = false
        selectedPoolList.forEach((pool) => {
          if (pool.companyCode === selectedComboboxOption.companyCode) {
            bool = true
            return
          }
        })
        return bool
      }
      if (isDup(selectedPoolList, selectedComboboxOption)) {
        return
      }
      setSelectedPoolList([
        ...selectedPoolList,
        {
          companyName: selectedComboboxOption.label,
          companyCode: selectedComboboxOption.companyCode,
        },
      ])
    },
    [selectedComboboxOption, selectedPoolList]
  )

  const hanldeSecurityGroupInputChange = useCallback(
    async (e: any, value, reason) => {
      if (!e) {
        return
      }
      if (e._reactName === 'onChange') {
        return
      }
      if (e._reactName === 'onBlur') {
        return
      }
      const selected = options.filter((option) => option.label === value)[0]
      const isDup = (selectedPoolList: SelectedPool[], selected) => {
        let bool = false
        selectedPoolList.forEach((pool) => {
          if (pool.accountId === selected.accountId) {
            bool = true
            return
          }
        })
        return bool
      }
      if (isDup(selectedPoolList, selected)) {
        return
      }
      if (selected.accountId === -1) {
        setSelectedPoolList([
          ...selectedPoolList.filter((pool) => pool.accountId === undefined),
          { companyName: selected.label, accountId: selected.accountId },
        ])
      } else {
        setSelectedPoolList([
          ...selectedPoolList.filter((pool) => pool.accountId !== -1),
          { companyName: selected.label, accountId: selected.accountId },
        ])
      }
    },
    [selectedComboboxOption]
  )

  const handleOnClick = useCallback(
    (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>,
      option: any,
      isAllOption: boolean,
      checked: boolean,
      selected: any
    ) => {
      e.preventDefault()
      if (isAllOption) {
        if (!checked) {
          setSelectedPoolList([
            ...selectedPoolList.filter((pool) => pool.accountId === undefined),
            { companyName: option.label, accountId: option.accountId },
          ])
          return
        }
      }
      if (!checked) {
        setSelectedPoolList([
          ...selectedPoolList.filter((pool) => pool.accountId !== -1),
          { companyName: option.label, accountId: option.accountId },
        ])
        return
      }
      setSelectedPoolList(selectedPoolList.filter((elem) => elem.accountId !== selected))
    },
    [selectedPoolList, securityAccountIdList]
  )

  return (
    <Autocomplete
      // autoSelect
      // autoHighlight
      // disabled={selectedGroupId !== selectedGroup}
      value={autoCompleteInputText}
      className="autocomplete-wrap"
      // sx={{
      //   width: '230px',
      //   button: {
      //     backgroundColor: '#ffffff !important;',
      //     height: '24px !important;',
      //     width: '24px !important;',
      //     padding: '0px !important;',
      //     marginTop: '2px !important',
      //   },
      // }}
      onInputChange={
        selectedGroupId === 'MYGROUP'
          ? hanldeMyGroupInputChange
          : selectedGroupId === 'ASSET'
          ? hanldeAssetGroupInputChange
          : hanldeSecurityGroupInputChange
      }
      onHighlightChange={(e, option) => {
        if (option === null) {
          return
        }
        setSelectedComboboxOption(option)
      }}
      onBlur={() => setAutoCompleteInputText(null)}
      forcePopupIcon={true}
      size="small"
      options={options}
      renderOption={(props, option) => {
        const isAllOption = option.accountId === -1
        const selected = option.accountId || option.companyCode
        const checked = securityAccountIdList.includes(selected)
        if (option.accountId === -1) {
          return (
            <li
              {...props}
              key={selected}
              className={'li-group'}
              onClick={(e) => handleOnClick(e, option, isAllOption, checked, selected)}
            >
              {checked && (
                <input
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleOnClick(e, option, isAllOption, checked, selected)
                  }}
                  readOnly
                  type="checkbox"
                  checked={true}
                ></input>
              )}
              {!checked && (
                <input
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleOnClick(e, option, isAllOption, checked, selected)
                  }}
                  readOnly
                  type="checkbox"
                  checked={false}
                ></input>
              )}
              {trans.t('all')}
            </li>
          )
        } else {
          return (
            <li
              {...props}
              key={selected}
              className={'li-group-list'}
              onClick={(e) => handleOnClick(e, option, isAllOption, checked, selected)}
            >
              {checked && (
                <input
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleOnClick(e, option, isAllOption, checked, selected)
                  }}
                  readOnly
                  type="checkbox"
                  checked={true}
                ></input>
              )}
              {!checked && (
                <input
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleOnClick(e, option, isAllOption, checked, selected)
                  }}
                  readOnly
                  type="checkbox"
                  checked={false}
                ></input>
              )}
              {option.label} {option.companyPropertyCode}-{option.companySLBCode}
            </li>
          )
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            onChange={(e) => setAutoCompleteInputText(e.target.value)}
            // onClick={() => {
            //   setSelectedGroup(selectedGroupId as SelectedGroup)
            // }}
            {...params}
            placeholder={trans.t('search')}
          />
        )
      }}
    ></Autocomplete>
  )
}

export default GroupAutocomplateSecurity
