export const TransactionTypeText = {
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  NONE: '없음',
}

export const TransactionTypeTextEn = {
  CUSTOM: 'Customized',
  DESIGNATED: 'Arranged',
  COMPETITIVE: 'Bid offer',
  NONE: 'No selection',
}
