import { httpGetNotification } from '@src/api/httpAPI/httpMessageAPI'
import { userState } from '@src/stores/userStore'
import { FunctionComponent, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router'
import { useMatch, useResolvedPath } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
// import Button from '../Button'
import Link from '@mui/material/Link'
import { MenuItem, Button, Menu } from '@mui/material'
import { forceRefreshState } from '@src/stores/forceRefreshState'
// import { topNotiCountsState } from '@src/stores/topNotiCountsState'
// import { httpGetTopNotiCounts } from '@src/api/httpAPI/httpNotiCountApi'

import { useTranslation } from 'react-i18next'

const SideBar: FunctionComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const user = useRecoilValue(userState)
  const [orderType, setOrderType] = useState<OrderType>('LEND')
  const trans = useTranslation('translation', { keyPrefix: 'sideBar' })
  // const [isMyPage, setIsMyPage] = useState(false)
  // const [isMessageBox, setIsMessageBox] = useState(false)

  // const [topNotiCounts, setTopNotiCounts] = useRecoilState(topNotiCountsState)

  // const getNotificationCount = useCallback(async () => {
  //   const notiCounts = await httpGetTopNotiCounts()
  //   setTopNotiCounts(notiCounts)
  // }, [])

  useLayoutEffect(() => {
    const orderType = location.pathname.split('/')[1]
    const second = location.pathname.split('/')[2]
    if (orderType == 'borrow') setOrderType('BORROW')
    if (orderType == 'lend') setOrderType('LEND')
    // if (orderType === 'mypage') {
    //   setIsMyPage(true)
    // } else {
    //   setIsMyPage(false)
    // }
    // if (orderType === 'message' && (second === 'list' || second === 'detail')) {
    //   setIsMessageBox(true)
    // } else {
    //   setIsMessageBox(false)
    // }
  }, [location])

  // useLayoutEffect(() => {
  //   getNotificationCount()
  // }, [])

  // const PersonIcon = ({ svgClass }: { svgClass: string }) => {
  //   return (
  //     <svg
  //       className={svgClass}
  //       width="20"
  //       height="20"
  //       viewBox="0 0 20 20"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M18.768 20c.34 0 .63-.12.87-.362.241-.24.362-.53.362-.87v-1.896c0-.365-.205-.662-.616-.89l-.616-.342-5.454-2.6c.43-.339.795-.746 1.095-1.222.3-.476.54-.97.723-1.486a8.51 8.51 0 0 0 .391-1.515 8.52 8.52 0 0 0 .117-1.31V4.38c0-.521-.166-1.042-.498-1.564a5.702 5.702 0 0 0-1.29-1.407 6.92 6.92 0 0 0-1.8-1.017A5.602 5.602 0 0 0 10.01 0c-.691 0-1.375.13-2.053.391-.678.26-1.28.6-1.808 1.017a5.535 5.535 0 0 0-1.281 1.407c-.326.522-.489 1.043-.489 1.564v3.128c0 .34.04.753.118 1.242.078.489.211.99.4 1.505.19.515.434 1.02.733 1.515.3.496.672.92 1.115 1.271l-5.494 2.6-.625.362c-.417.241-.626.531-.626.87v1.896c0 .34.12.63.362.87.24.241.537.362.89.362h17.516zM1.251 18.768v-1.27c0-.261.069-.44.206-.538a1.37 1.37 0 0 1 .322-.186l5.494-2.6c.195-.091.355-.225.479-.4.124-.177.205-.369.244-.578a1.241 1.241 0 0 0-.47-1.133c-.65-.522-1.13-1.258-1.436-2.21-.306-.951-.46-1.733-.46-2.346V4.38c0-.326.128-.671.382-1.036s.59-.704 1.007-1.017a5.86 5.86 0 0 1 1.407-.772 4.35 4.35 0 0 1 1.584-.303c.521 0 1.046.101 1.574.303.527.202.997.456 1.407.763.41.306.746.642 1.007 1.007.26.364.391.716.391 1.055v3.128c0 .6-.14 1.379-.42 2.337-.28.958-.76 1.697-1.437 2.219a1.241 1.241 0 0 0-.47 1.134 1.267 1.267 0 0 0 .723.997l5.455 2.58c.014 0 .101.043.264.127.163.085.245.297.245.636v1.212l-17.498.02z"
  //         // fill="#B9B9B9"
  //         fill-rule="nonzero"
  //       />
  //     </svg>
  //   )
  // }

  // const ArrowRightIcon = ({ svgClass }: { svgClass: string }) => {
  //   return (
  //     <svg
  //       className={svgClass}
  //       width="8"
  //       height="16"
  //       viewBox="0 0 8 16"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         className="fill-test"
  //         d="M.486 15.5a.51.51 0 0 1-.353-.148.514.514 0 0 1-.133-.355c0-.139.044-.257.133-.356l6.659-6.693-6.556-6.59a.486.486 0 0 1-.148-.355c0-.138.05-.256.148-.355A.481.481 0 0 1 .589.5C.727.5.845.55.943.648l6.91 6.945A.486.486 0 0 1 8 7.948c0 .138-.05.257-.147.356L.855 15.352a.482.482 0 0 1-.17.111.547.547 0 0 1-.199.037z"
  //         // fill="#B9B9B9"
  //         fill-rule="nonzero"
  //       />
  //     </svg>
  //   )
  // }

  // const MainIcon = ({ svgClass }: { svgClass: string }) => {
  //   return (
  //     <svg
  //       className={svgClass}
  //       width="21"
  //       height="17"
  //       viewBox="0 0 21 17"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M18.93 4.275V2.138L10.5 7.457l-8.43-5.32v2.138l8.43 5.268 8.43-5.268zm0-4.275c.572 0 1.06.212 1.464.636.404.424.606.925.606 1.502v12.724c0 .577-.202 1.078-.606 1.502A1.948 1.948 0 0 1 18.93 17H2.07c-.572 0-1.06-.212-1.464-.636A2.105 2.105 0 0 1 0 14.862V2.138C0 1.56.202 1.06.606.636A1.948 1.948 0 0 1 2.07 0h16.86z"
  //         // fill="#A8A8A8"
  //         fill-rule="nonzero"
  //       />
  //     </svg>
  //   )
  // }

  return (
    <div className="sidebar">
      <div className="logo">
        <img src={require('@src/assets/sidebar_logo.png')} alt="" />
      </div>
      {/* <div className={`user-info is-mypage-${isMyPage}`} onClick={() => navigate('/mypage')}>
        <PersonIcon svgClass={isMyPage ? 'sidebar-top-svg-selected' : 'sidebar-top-svg'} />
        <span className={isMyPage ? 'name sidebar-top-text-selected' : 'name sidebar-top-text'}>
          {user?.name || ''}
        </span>
        <ArrowRightIcon svgClass={isMyPage ? 'sidebar-top-svg-selected' : 'sidebar-top-svg'} />
      </div>
      <div
        className={`message is-message-${isMessageBox}`}
        onClick={() => navigate('/message/list')}
      >
        <MainIcon svgClass={isMessageBox ? 'sidebar-top-svg-selected' : 'sidebar-top-svg'} />
        <span className={isMessageBox ? 'text sidebar-top-text-selected' : 'text sidebar-top-text'}>
          MESSAGE
        </span>
        <span
          className={isMessageBox ? 'count sidebar-top-text-selected' : 'count sidebar-top-text'}
        >
          {topNotiCounts.messageCount}
        </span>
      </div> */}
      <div className="order-type">
        <Button
          onClick={() => navigate('/lend/pool/register')}
          className={orderType == 'LEND' ? 'selected' : ''}
        >
          {trans.t('lend.title')}
        </Button>
        <Button
          onClick={() => navigate('/borrow/list')}
          className={orderType == 'BORROW' ? 'selected' : ''}
        >
          {trans.t('borrow.title')}
        </Button>
      </div>
      <nav className={orderType}>
        <ul>
          {orderType == 'LEND' && (
            <>
              {/*<SideBarItem router="/lend/pool" path="/lend/pool/register/today" name="대여풀">*/}
              {/*  <ul className="sub">*/}
              <div
                style={{ height: '40px', lineHeight: '40px', color: 'white', paddingLeft: '30px', fontSize:'15px'}}
              >
                {trans.t('loan')}
              </div>
              {/* <div> */}
              <SideBarItem path="/lend/pool/register" name={trans.t('lend.poolReigister')} />
              <SideBarItem
                path="/lend/pool/register-list"
                name={trans.t('lend.poolRegisterList')}
              />
              <SideBarItem path="/lend/pool/matched" name={trans.t('lend.poolMatched')} />
              {/* </div> */}
              <hr />

              {/* 차입신청목록 */}
              <SideBarItem2 path="/lend/lend-list" name={trans.t('lend.list')} />
              <hr />
              {/*<SideBarItem router="/lend/order" path="/lend/order/response" name="거래협의">*/}
              {/*<ul className="sub">*/}
              <div
                style={{ height: '40px', lineHeight: '40px', color: 'white', paddingLeft: '30px', fontSize:'15px', fontFamily:'NotoSansKRRegular sans-serif' }}
              >
                {' '}
                {trans.t('pendingTrade')}
              </div>
              <SideBarItem path="/lend/order/response" name={trans.t('lend.orderResponse')} />
              <SideBarItem path="/lend/order/listcancel" name={trans.t('lend.orderListCancel')} />
              <SideBarItem path="/lend/order/list" name={trans.t('lend.orderList')} />
              <hr />
              {/*</ul>*/}
              {/*</SideBarItem>*/}
              <div
                style={{ height: '40px', lineHeight: '40px', color: 'white', paddingLeft: '30px' }}
              >
                {' '}
                {trans.t('matchedTrade')}
              </div>
              <SideBarItem
                path="/lend/search"
                name={trans.t('lend.search')}
                // count={notificationCount?.matchedCount}
              />
              <SideBarItem path="/lend/search-response" name={trans.t('lend.searchResponse')} />
            </>
          )}
          {orderType == 'BORROW' && (
            <>
              <SideBarItem2 path="/borrow/list" name={trans.t('borrow.list')} />
              <hr />
              {/*<SideBarItem router="/borrow/request" path="/borrow/request/order" name="차입신청">*/}
              {/*  <ul className="sub">*/}
              <div
                style={{ height: '40px', lineHeight: '40px', color: 'white', paddingLeft: '30px', fontSize:'15px', fontFamily:'NotoSansKRRegular sans-serif'  }}
              >
                {trans.t('locate')}
              </div>
              <SideBarItem path="/borrow/request/order" name={trans.t('borrow.requestOrder')} />
              <SideBarItem path="/borrow/request/list" name={trans.t('borrow.requestList')} />
              <hr />
              {/*  </ul>*/}
              {/*</SideBarItem>*/}
              {/*<SideBarItem router="/borrow/order" path="/borrow/order/response" name="거래협의">*/}
              {/*  <ul className="sub">*/}
              <div
                style={{ height: '40px', lineHeight: '40px', color: 'white', paddingLeft: '30px' }}
              >
                {trans.t('pendingTrade')}
              </div>
              <SideBarItem path="/borrow/order/response" name={trans.t('borrow.orderResponse')} />
              <SideBarItem
                path="/borrow/order/listcancel"
                name={trans.t('borrow.orderListCancel')}
              />
              <SideBarItem path="/borrow/order/list" name={trans.t('borrow.orderList')} />
              <hr />
              {/*  </ul>*/}
              {/*</SideBarItem>*/}

              <div
                style={{ height: '40px', lineHeight: '40px', color: 'white', paddingLeft: '30px' }}
              >
                {trans.t('matchedTrade')}
              </div>
              <SideBarItem
                path="/borrow/search"
                name={trans.t('borrow.search')}
                // count={notificationCount?.matchedCount}
              />
              <SideBarItem path="/borrow/search-response" name={trans.t('borrow.searchResponse')} />

              {/* <SideBarItem path="/borrow/balance" name="잔고조회" /> */}
            </>
          )}

          <hr
            className={
              location.pathname.split('/')[1] === 'borrow' ? 'sidebar-hr-borrow' : 'sidebar-hr-lend'
            }
          />
          {orderType == 'LEND' && <SideBarItem2 path="/lend/confirm" name={trans.t('confirm')} />}
          {orderType == 'BORROW' && (
            <SideBarItem2 path="/borrow/confirm" name={trans.t('confirm')} />
          )}
          {/* <SideBarItem path="/message/send" name="메시지 발송" /> */}
        </ul>
      </nav>
    </div>
  )
}

interface SideBarItemProps {
  path: string
  name: string
  router?: string
  count?: number
  children?: React.ReactNode
}

const SideBarItem: FunctionComponent<SideBarItemProps> = (props) => {
  const location = useLocation()
  const resolved = useResolvedPath(props.router || '')
  const match = useMatch({ path: resolved.pathname, end: false })

  const [forceRefresh, setForceRefresh] = useRecoilState(forceRefreshState)
  // const path = props.path ===
  const backgroundColor =
    location.pathname.split('/')[1] +
      location.pathname.split('/')[2] +
      location.pathname.split('/')[3] ===
    props.path.split('/')[1] + props.path.split('/')[2] + props.path.split('/')[3]
      ? '#24292E'
      : '#191919'

  return (
    <div className={`${match ? 'active' : ''} ${props.children ? '' : 'single'}`}>
      <NavLink to={props.path}>
        <MenuItem
          sx={[
            { paddingLeft: '60px', height: '40px', fontSize: '15px', fontFamily:'NotoSansKRRegular sans-serif'},
            () => ({
              '&:hover': {
                backgroundColor: '#24292E',
              },
              backgroundColor: backgroundColor,
            }),
          ]}
          onClick={() => {
            backgroundColor === '#24292E' && setForceRefresh(!forceRefresh)
          }}
        >
          {props.name}
          {
            props.children && (
              // (match ? (
              //   <img src={require('@src/assets/up.png')} alt="" />
              // ) : (
              <img src={require('@src/assets/down.png')} alt="" />
            )
            // ))}
          }
        </MenuItem>
        <span className={'new-count'}>{props.count}</span>
      </NavLink>
      {props.children}
    </div>
  )
}

const SideBarItem2: FunctionComponent<SideBarItemProps> = (props) => {
  const location = useLocation()
  const resolved = useResolvedPath(props.router || '')
  const match = useMatch({ path: resolved.pathname, end: false })

  const [forceRefresh, setForceRefresh] = useRecoilState(forceRefreshState)
  // const path = props.path ===
  const backgroundColor =
    location.pathname.split('/')[1] +
      location.pathname.split('/')[2] +
      location.pathname.split('/')[3] ===
    props.path.split('/')[1] + props.path.split('/')[2] + props.path.split('/')[3]
      ? '#24292E'
      : '#191919'

  return (
    <div className={`${match ? 'active' : ''} ${props.children ? '' : 'single'}`}>
      <NavLink to={props.path}>
        <MenuItem
          sx={[
            { paddingLeft: '30px', color:'#ffffff', height: '40px', fontSize: '15spx', fontFamily:'NotoSansKRRegular sans-serif' },
            () => ({
              '&:hover': {
                backgroundColor: '#24292E',
              },
              backgroundColor: backgroundColor,
            }),
          ]}
          onClick={() => {
            backgroundColor === '#24292E' && setForceRefresh(!forceRefresh)
          }}
        >
          {props.name}
          {
            props.children && (
              // (match ? (
              //   <img src={require('@src/assets/up.png')} alt="" />
              // ) : (
              <img src={require('@src/assets/down.png')} alt="" />
            )
            // ))}
          }
        </MenuItem>
        <span className={'new-count'}>{props.count}</span>
      </NavLink>
      {props.children}
    </div>
  )
}
export default SideBar
