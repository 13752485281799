import { useCallback } from 'react'

interface InputEvent {
  target: HTMLInputElement
}

export const useGetRateSuffix = () => {
  const getRateSuffix = useCallback(
    (e: InputEvent, setRate: React.Dispatch<React.SetStateAction<string>>) => {
      if (e.target.value === '') {
        return
      }
      if (e.target.value.indexOf('.') === -1) {
        setRate(e.target.value + '.00')
        return
      }
      const splited = e.target.value.split('.')
      if (splited[1].length === 0) {
        setRate(e.target.value + '00')
      }
      if (splited[1].length === 1) {
        setRate(e.target.value + '0')
      }
    },
    []
  )
  return getRateSuffix
}
