import {
  httpDelRequestV2,
  httpGetRequestV2,
  httpPutRequestV2,
} from '@src/api/httpAPI/httpRequestAPI'
import SelectOption from '@src/components/SelectOption'
import TextField from '@src/components/TextField'
import useInfiniteScroll from '@src/hooks/useInfiniteScroll'
import { DateTime } from 'luxon'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import LendOrderResponseTable from './LendConfirmTable'
import LogoutButton from '@src/components/Button/LogoutButton'
import { SpanCursorPointer } from '@src/components/Span/SpanCursorPointer'
import TradeButton from '@src/components/Button/TradeButton'
import { isKeyTabOrEnter } from '@src/util/isKeyTapOrEnter'
import { httpGetCalender } from '@src/api/httpAPI/httpCalender'
import {
  csvDict,
  csvDictEn,
  csvHeader,
  csvHeaderEn,
  csvHeaderNotRequester,
  csvKey,
  csvKeyEn,
  csvKeyNotRequester,
} from './csv'
import { downloadCsvWithDict } from '@src/util/getCsv'
import { Button } from '@mui/material'
import { httpDelLedgerV2, httpGetLedgerV2, httpPutLedgerV2 } from '@src/api/httpAPI/httpLedgerAPI'
// import { useParams } from 'react-router-dom'

import { useLocation } from 'react-router-dom'
import { REQUEST } from '@src/constants/NotiText'
import { useRecoilState, useRecoilValue } from 'recoil'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { forceRefreshState } from '@src/stores/forceRefreshState'

import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface QueryPayload {
  query?: string
  companyName?: string
  settlementType?: SettlementType | ''
  transactionType?: TransactionType | ''
  requestType?: RequestOptionType | ''
  isRequester?: boolean
  after?: string
  before?: string
  requestStatus?: string
  orderStatus?: string
}

const LendList: FunctionComponent = () => {
  let firtFetchFlag = true
  const [query, setQuery] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const [isLast, setIsLast] = useState(true)
  const [page, setPage] = useState(0)
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [settlementType, setSettlementType] = useState<SettlementType | ''>('')
  const [transactionType, setTransactionType] = useState<TransactionType | ''>('')
  const [requestType, setRequestType] = useState<RequestOptionType | ''>('')

  const [selectedRequestId, setSelectedRequestId] = useState<number[]>([])
  const [requests, setRequests] = useState<any[]>([])

  const [isRequester, setIsRequester] = useState<boolean>(true)
  const [after, setAfter] = useState<string>(DateTime.local().toFormat('yyyyMMdd'))
  const [before, setBefore] = useState<string>(DateTime.local().toFormat('yyyyMMdd'))

  const [selectedIndex, setSelectedIndex] = useState<number[]>([])

  const [topTab, setTopTab] = useState<'LEDGER' | 'RESPONSE' | 'HISTORY'>('LEDGER')

  const [defaultDate, setDefaultDate] = useState({
    previous: '',
    today: '',
    next: '',
  })

  const [companyNames, setCompanyNames] = useState<searchNameCell[]>([])
  const [issueNames, setIssueNames] = useState<searchNameCell[]>([])

  const [uuid, setUuid] = useState<number>(Math.random())

  const [queryPayload, setQueryPayload] = useState<QueryPayload>({
    query: '',
    companyName: '',
    settlementType: '',
    transactionType: '',
    requestType: '',
    orderStatus: 'MATCHED',
  })

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const forceRefresh = useRecoilValue(forceRefreshState)
  const trans = useTranslation()

  const location = useLocation()
  const currentOrderType = location.pathname.split('/')[1]
  const currentOrderTypeUpper = currentOrderType.toUpperCase()

  //   <TradeButton
  //   disabled={selectedRequestId.length === 0}
  //   onClick={() => putLedgers()}
  //   color="blue"
  // >
  //   재전송(F8)
  // </TradeButton>

  const postShortCutEvent = (e) => {
    if (e.key === 'F8') {
      if (selectedRequestId.length === 0) {
        return
      }
      putLedgers()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', postShortCutEvent)
    return () => {
      window.removeEventListener('keydown', postShortCutEvent)
    }
  }, [selectedRequestId])

  const refresh = () => {
    setQueryPayload({
      query: '',
      companyName: '',
      settlementType: '',
      transactionType: '',
      requestType: '',
      orderStatus: 'MATCHED',
      // isRequester: queryPayload.isRequester,
    })
    resetField()
    setPage(0)
  }

  useEffect(() => {
    isLoading === false && refresh()
  }, [forceRefresh])

  interface searchNameCell {
    text: string
    value: string
  }
  const getCompanyNames = (requests, lang): searchNameCell[] => {
    const nameSet = new Set<string>()
    requests.forEach((request) => {
      if (lang === 'en') {
        nameSet.add(request.partnerAccount.companyEnglishName)
      } else {
        nameSet.add(request.partnerAccount.companyName)
      }
    })
    const result = [
      {
        text: trans.t('lendListTable.all'),
        value: '',
      },
    ]
    nameSet.forEach((name) => {
      result.push({
        text: name,
        value: name,
      })
    })
    return result
  }

  const getIssueNames = (requests, lang): searchNameCell[] => {
    const nameSet = new Set<string>()
    requests.forEach((request) => {
      if (lang === 'en') {
        nameSet.add(request.stock.issueEnglishName)
      } else {
        nameSet.add(request.stock.issueName)
      }
    })
    // nameSet.add(request.stock.issueName)
    // })
    const result = [
      {
        text: trans.t('lendListTable.all'),
        value: '',
      },
    ]
    nameSet.forEach((name) => {
      result.push({
        text: name,
        value: name,
      })
    })
    return result
  }

  // useEffect(() => {}, [selectedRequestId])

  const getHttpApiUrl = (topTab: 'LEDGER' | 'RESPONSE' | 'HISTORY') => {
    if (topTab === 'LEDGER') {
      return httpGetLedgerV2
    }
    return httpGetRequestV2
  }

  const putRequests = useCallback(
    async (responseType: RequestOptionType) => {
      if (window.confirm(trans.t('modal.request.confirm'))) {
        const res = await httpPutRequestV2({
          responseList: requests
            .filter(({ requestId }) => selectedRequestId.includes(requestId))
            .map(({ orderId, requestedRate, requestedVolume, requestId }) => ({
              orderId,
              rate: requestedRate,
              volume: requestedVolume,
              requestId,
            })),
          responseType,
        })
        setSelectedRequestId([])
        setSelectedIndex([])
        setRequests([])
        setQueryPayload({
          query: '',
          companyName: '',
          settlementType: '',
          transactionType: '',
          isRequester: false,
          requestType: 'CANCEL',
          requestStatus: 'RECEIVED',
        })
        setPage(0)
      }
    },
    [requests, selectedRequestId]
  )

  const putLedgersCallback = async () => {
    try {
      const res = await httpPutLedgerV2({ ids: selectedRequestId })
      setRequests([])
      setSelectedRequestId([])
      setSelectedIndex([])
      setPage(0)
      resetField()
      setQueryPayload({
        query: '',
        companyName: '',
        settlementType: '',
        transactionType: '',
        requestType: '',
        orderStatus: 'MATCHED',
      })
      setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.request.succeed') })
    } catch (error) {
      setErrorModal({ ...errorModal, isOpen: true, innerString: trans.t('modal.request.invalid') })
      console.error(error)
    }
  }

  const putLedgers = async () => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: trans.t('resend'),
      confirmFunction: putLedgersCallback,
      closeFunction: () => {
        return
      },
      // tab: tab,
    })

    // if (window.confirm(trans.t('modal.request.confirm'))) {
    //   try {
    //     const res = await httpPutLedgerV2({ ids: selectedRequestId })
    //     setRequests([])
    //     setSelectedRequestId([])
    //     setSelectedIndex([])
    //     setPage(0)
    //     resetField()
    //     setQueryPayload({
    //       query: '',
    //       companyName: '',
    //       settlementType: '',
    //       transactionType: '',
    //       requestType: '',
    //       orderStatus: 'MATCHED',
    //     })
    //   } catch (e) {
    //     alert(trans.t('modal.request.invalid'))
    //     console.log(e)
    //   }
    // }
  }

  const fetchDefaultDate = async () => {
    const _defaultDate = await httpGetCalender()
    setAfter(_defaultDate.previous)
    setBefore(_defaultDate.today)
    setDefaultDate(_defaultDate)
  }

  useEffect(() => {
    fetchDefaultDate()
  }, [])

  const getRequests = useCallback(async () => {
    setIsLoading(true)
    try {
      const payload = Object.assign(
        {
          // orderType: 'LEND' as OrderType,
          query: '',
          companyName: '',
          page,
          settlementType: '',
          transactionType: '',
          requestType: '' as RequestStatusType,
          size: 20,
        },
        queryPayload
      )
      const api = getHttpApiUrl(topTab)
      const requests = await api(payload)

      if (page == 0) {
        setRequests(requests)
      } else {
        setRequests((prevRequests) => [...prevRequests, ...requests])
      }

      setIsLast(requests.length < 1)
      if (page == 0 && !requests.length) {
        setIsEmpty(true)
      } else {
        setIsEmpty(false)
      }
      if (!firtFetchFlag) {
        return
      }
      // console.log('getCompanyNames()', getCompanyNames())
      setCompanyNames(getCompanyNames(requests, localStorageAPI.getItem('lang')))
      setIssueNames(getIssueNames(requests, localStorageAPI.getItem('lang')))
      firtFetchFlag = false
    } catch {
      //
    }
    setIsLoading(false)
  }, [queryPayload, page])

  // const getRequests = useCallback(async () => {
  //   setIsLoading(true)
  //   try {
  //     const requests = await httpGetRequestV2({
  //       orderType: 'LEND',
  //       isRequester,
  //       query,
  //       companyName,
  //       page,
  //       settlementType,
  //       transactionType,
  //       requestType,
  //       size: 20,
  //     })

  //     if (page == 0) {
  //       setRequests(requests)
  //     } else {
  //       setRequests((prevRequests) => [...prevRequests, ...requests])
  //     }

  //     setIsLast(requests.length < 1)
  //     if (page == 0 && !requests.length) {
  //       setIsEmpty(true)
  //     } else {
  //       setIsEmpty(false)
  //     }
  //   } catch {
  //     //
  //   }

  //   setIsLoading(false)
  // }, [query, companyName, page, settlementType, transactionType, requestType, isRequester])

  useEffect(() => {
    getRequests()
  }, [queryPayload])

  // useEffect(() => {
  //   setQuery('')
  //   setCompanyName('')
  //   setPage(0)
  //   setSettlementType('TODAY')
  //   setTransactionType('DESIGNATED')
  //   setRequestType('ACCEPT')
  // }, [isRequester])

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  useEffect(() => {
    onInfiniteScrollInit(document.querySelector('.table-bottom'))
  }, [])

  useEffect(() => {
    if (isLoading || isLast) onInfiniteScrollDisconnect()
    else onInfiniteScrollUpdate()
  }, [isLoading, isLast])

  const resetField = () => {
    setQuery('')
    setCompanyName('')
    setAfter(defaultDate.previous)
    setBefore(defaultDate.today)
  }

  const handleCsvDownload = async () => {
    const payload = Object.assign(
      {
        orderType: '' as OrderType,
        requestStatus: '',
        query: '',
        companyName: '',
        settlementType: '',
        transactionType: '',
        requestType: '' as RequestStatusType,
      }
      // queryPayload
    )
    const requests = await httpGetLedgerV2(payload)
    const header = localStorageAPI.getItem('lang') === 'en' ? csvHeaderEn : csvHeader
    const key = localStorageAPI.getItem('lang') === 'en' ? csvKeyEn : csvKey
    const dict = localStorageAPI.getItem('lang') === 'en' ? csvDictEn : csvDict
    const fileName = localStorageAPI.getItem('lang') === 'en' ? 'Confirmation' : '컨퍼메이션'
    downloadCsvWithDict(requests, header, key, dict, fileName, localStorageAPI.getItem('lang'))
  }

  // const divClassName = currentOrderType + '-order-list' + ' ' + currentOrderTypeUpper
  const divClassName = currentOrderTypeUpper

  return (
    <div className={divClassName}>
      <article>
        <div className="tab"></div>
        <div className="button-wrap">
          <TradeButton
            disabled={selectedRequestId.length === 0}
            onClick={() => putLedgers()}
            color="blue"
          >
            {trans.t('reSubmit') + '(F8)'}
          </TradeButton>

          {/* )} */}
        </div>
        <div className="option">
          <div className="first-line">
            <h3>{trans.t('searchInList')}</h3>
            <SpanCursorPointer onClick={refresh}>
              <RefreshIcon></RefreshIcon>
              <p>&nbsp;{trans.t('refresh')}</p>
            </SpanCursorPointer>
          </div>
          <div className="second-line">
            <TextField
              label={trans.t('issue')}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e: any) => {
                if (isKeyTabOrEnter(e)) {
                  setQueryPayload({ ...queryPayload, query: query })
                  setPage(0)
                }
              }}
            />
            <TextField
              label={trans.t('company')}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              onKeyDown={(e: any) => {
                if (isKeyTabOrEnter(e)) {
                  setQueryPayload({ ...queryPayload, companyName: companyName })
                  setPage(0)
                }
              }}
            />
          </div>
        </div>

        <LendOrderResponseTable
          setSelectedRequestIds={setSelectedRequestId}
          requests={requests}
          setRequests={setRequests}
          queryPayload={queryPayload}
          setQueryPayload={setQueryPayload}
          setPage={setPage}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          companyNames={companyNames}
          issueNames={issueNames}
          currentOrderTypeUpper={currentOrderTypeUpper}
        />

        <Button
          sx={{ marginLeft: '30px' }}
          variant="outlined"
          onClick={() => {
            handleCsvDownload()
          }}
        >
          {trans.t('downloadAllList')}
        </Button>
      </article>
    </div>
  )
}

export default LendList
