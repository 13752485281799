import { CSSProperties, FunctionComponent } from 'react'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  style?: CSSProperties
  width?: number
  id?: string
  label?: string
  checked?: boolean
  position?: 'left' | 'right' | 'top' | 'bottom'
}

const Checkbox: FunctionComponent<Props> = ({ style, width, position, label, id, ...props }) => {
  const Label = () => {
    if (!label) return null
    return <label htmlFor={id}>{label}</label>
  }

  return (
    <div
      className={`checkbox ${position}`}
      style={style}
      // onClick={(e) => e.preventDefault()}
    >
      {(position == 'left' || position == 'top') && <Label />}
      <input
        type="checkbox"
        id={id}
        {...props}
        // key={JSON.stringify(props.checked)}
      />
      {(position == 'right' || position == 'bottom') && <Label />}
    </div>
  )
}

export default Checkbox

Checkbox.defaultProps = {
  position: 'left',
}
