import { RESULT } from '@src/constants/NotiText'
import httpClient from './httpClient'

export const httpGetPool = async (params: {
  settlementType: SettlementType
  poolType?: PoolOptionType
  companyCodeList?: string[]
  orderType: OrderType
  orderBy: 'COMPANY' | 'STOCK'
  companyName?: string
  minRate?: string
  maxRate?: string
  page?: number
  size?: number
  query?: string
}) => {
  const { data } = await httpClient.get<PoolType[]>('/api/v1/pools', {
    params,
  })

  return data.map((d) => ({
    ...d,
    ...d.stock,
  }))
}

export const httpGetLivePool = async (params: {
  type: string
  query?: string
  page?: number
  size?: number
}) => {
  const { data } = await httpClient.get<LivePool[]>('/api/v1/order-book', {
    params,
  })

  return data.map((d) => ({
    ...d,
  }))
}

export const httpGetPoolHistory = async (params: {
  orderType: OrderType | string
  settlementType: SettlementType | string
  query?: string
  poolStatus?: PoolStatusType | '' | string
  page?: number
  size?: number
  isSwap?: boolean | ''
}) => {
  const { data } = await httpClient.get<PoolHistoryType[]>('/api/v1/pools/history', {
    params,
  })

  return data
}

export const httpGetPoolStatus = async (params: {
  orderType: OrderType | string
  settlementType: SettlementType | string
  query?: string
  poolStatus?: PoolStatusType | '' | string
  page?: number
  size?: number
}) => {
  const { data } = await httpClient.get<PoolHistoryType[]>('/api/v1/pools/status', {
    params,
  })

  return data
}

export const httpPostPool = async (params: {
  orderType: OrderType
  poolList: {
    issueCode: string
    rate: number
    volume: number
  }[]
  poolType: PoolOptionType
  settlementType: SettlementType
  companyCodeList?: string[]
  accountIdList?: number[]
}) => {
  await httpClient.post('/api/v1/pools', {
    ...params,
  })
}

export const httpPostLivePool = async (
  param: {
    orderType: string
    issueCode: any
    volume: number
    rate: any
  }[]
) => {
  try {
    const res = await httpClient.post('/api/v1/orders', param)
    return res.status
  } catch (e) {
    throw Error(e)
  }
}

export const httpDeletePool = async (ids: number[]) => {
  await httpClient.delete('/api/v1/pools', {
    params: {
      ids,
    },
  })
}

export const httpPostPoolOrder = async (
  params: {
    poolId: number
    rate: number
    transactionType?: TransactionType
    volume: number
    isSwap: boolean
  }[]
) => {
  try {
    const res = await httpClient.post('/api/v1/pools/orders', params)
    return res.status
  } catch (e) {
    console.error('error:' + e)
    throw Error(RESULT.fail)
  }
}

export const httpGetPoolCsv = async (params: {
  settlementType: SettlementType
  poolType?: PoolOptionType
  companyCodeList?: string[]
  orderType: OrderType
  orderBy: 'COMPANY' | 'STOCK'
  companyName?: string
  page?: number
  size?: number
  query?: string
}) => {
  const { data } = await httpClient.get<PoolType[]>('/api/v1/pools', {
    params,
  })

  return data.map((d) => ({
    ...d,
    ...d.stock,
  }))
}

export const httpGetPoolStatis = async (params: PoolStatisPostParam) => {
  const { data } = await httpClient.get<PoolStatistics>('/api/v1/statistics', {
    params,
  })
  return data
}
