import httpClient from './httpClient'

interface defaultDate {
  previous: string
  today: string
  next: string
}

export const httpGetCalender = async (params?: { date: string }) => {
  const { data } = await httpClient.get<defaultDate>('/api/v1/calender', {
    params,
  })
  return data
}
