import { atom } from 'recoil'

interface ErrorModalState {
  isOpen: boolean
  innerString: string
  closeFunction: () => void
}

export const errorModalState = atom<ErrorModalState>({
  key: 'errorModalState',
  default: {
    isOpen: false,
    innerString: 'test test test',
    closeFunction: () => {
      return
    },
  },
})
