import { confirmModalState } from '@src/stores/confirmModalState'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useRecoilState } from 'recoil'
import { localStorageAPI } from '../../api/storageAPI'

export const ConfirmModal = () => {
  const location = useLocation()
  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const confirmRef = useRef<HTMLButtonElement>()
  const cancelRef = useRef<HTMLButtonElement>()
  const trans = useTranslation('translation', { keyPrefix: 'modal' })

  useEffect(() => {
    confirmRef.current.focus()
  }, [confirmModal])

  useEffect(() => {
    setConfirmModal({ ...confirmModal, isOpen: false })
  }, [location.pathname])

  const whiteList = ['ArrowRight', 'ArrowLeft']

  const focusChange = (e) => {
    if (!whiteList.includes(e.key)) {
      return
    }
    if (confirmModal.isOpen === false) {
      return
    }
    e.preventDefault()
    const active = document.activeElement
    if (active.id === 'modal-confirm') {
      cancelRef.current.focus()
      return
    }
    if (active.id === 'modal-cancel') {
      confirmRef.current.focus()
      return
    }
  }

  useEffect(() => {
    if (confirmModal.isOpen === true) {
      window.addEventListener('keydown', focusChange)
      return () => {
        window.removeEventListener('keydown', focusChange)
      }
    }
  }, [confirmModal])
  return (
    <div className={confirmModal.isOpen ? 'confirm-modal-wrap' : 'display-none'}>
      <div className={'confirm-modal-body'} style={{ whiteSpace: 'pre-line' }}>
        <span>{confirmModal.innerString}</span>
      </div>
      <div className={'confirm-modal-btn'}>
        <button
          id="modal-confirm"
          ref={confirmRef}
          onClick={() => {
            confirmModal.confirmFunction(confirmModal.confirmFunctionParam)
            setConfirmModal({ ...confirmModal, isOpen: false })
          }}
        >
          {localStorageAPI.getItem('lang') === 'en' ? 'Ok' : '예'}
        </button>
        <button
          id="modal-cancel"
          ref={cancelRef}
          onClick={() => setConfirmModal({ ...confirmModal, isOpen: false })}
        >
          {localStorageAPI.getItem('lang') === 'en' ? 'Cancel' : '아니오'}
        </button>
      </div>
    </div>
  )
}
