import httpClient from './httpClient'

export const httpGetLedger = async (params: {
  orderType: OrderType | ''
  orderStatus?: OrderStatusType | ''
  query: string
  settlementType?: SettlementType | string
  after?: string
  before?: string
  isConfirmed?: boolean | ''
  partnerAccountId?: number | ''
  dateType?: 'ORDER_DATE' | 'SETTLEMENT_DATE'
}) => {
  const { data } = await httpClient.get<LedgerType[]>('/api/v1/ledgers', {
    params,
  })

  return data
}

export const httpPutLedger = async (params: { ledgerId: number }[]) => {
  const { data } = await httpClient.put<ResponsePutLedgerType[]>('/api/v1/ledgers', params)

  return data
}

export const httpGetLedgerV2 = async (params: {
  orderType?: OrderType
  query?: string
  companyName?: string
  settlementType?: SettlementType | string
  transactionType?: TransactionType | string
  requestType?: RequestOptionType | string
  requestStatus?: RequestStatusType | string
  after?: string
  before?: string
  page?: number
  size?: number
}) => {
  const { data } = await httpClient.get('/api/v2/ledgers', {
    params,
  })
  return data
}

export const httpDelLedgerV2 = async (params: { ids: number[] }) => {
  const { data } = await httpClient.delete('/api/v2/ledgers', {
    params,
  })
  return data
}

export const httpPutLedgerV2 = async (params: { ids: number[] }) => {
  const payload = []
  params.ids.forEach((id) =>
    payload.push({
      ledgerId: id,
    })
  )
  const { data } = await httpClient.put('/api/v2/ledgers', payload)
  return data
}

export const httpGetLedgerDownload = async (params: { orderType: 'BORROW' }) => {
  const { data } = await httpClient('/api/v2/ledgers/download', { params, responseType: 'blob' })
  return data
}
