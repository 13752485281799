import { TransactionTypeText } from '@src/constants/TransactionTypeText'

const getSeparetedByIssueLive = (borrowPool: LivePool[]) => {
  const issuNames: string[] = []
  const sepratedByIssue: PoolSepratedByName = {}
  borrowPool.forEach((trade: any) => {
    if (!issuNames.includes(trade.issueName)) {
      issuNames.push(trade.issueName)
      sepratedByIssue[trade.issueName] = [JSON.parse(JSON.stringify(trade))]
    } else {
      sepratedByIssue[trade.issueName].push(JSON.parse(JSON.stringify(trade)))
    }
  })
  return sepratedByIssue
}

const getInfoByIssueLive = (sepratedByIssueLive: PoolSepratedByName) => {
  const infoByIssue: LivePools = {}
  const keys = Object.keys(sepratedByIssueLive)
  const values: Array<any[]> = Object.values(sepratedByIssueLive)
  for (let i = 0; i < keys.length; i++) {
    infoByIssue[keys[i]] = {
      issueCode: values[i][0].issueCode,
      tradeType: TransactionTypeText[values[i][0].orderType],
      tradePrice: values[i][0].tradePrice,
      issueName: values[i][0].issueName,
      issueEnglishName: values[i][0].issueEnglishName,
      settlementType: 'LIVE',
      volume: 0,
      rate: 0,
      interestTotal: 0,
      idList: [],
    }
    values[i].forEach((value, index) => {
      const valueId = value.issueCode.toString() + index.toString()
      value.id = valueId
      infoByIssue[keys[i]].volume += value.volume
      infoByIssue[keys[i]].interestTotal += value.volume * value.rate
      infoByIssue[keys[i]].idList.push(valueId)
    })
  }
  for (let i = 0; i < keys.length; i++) {
    infoByIssue[keys[i]].rate = infoByIssue[keys[i]].interestTotal / infoByIssue[keys[i]].volume
  }
  return infoByIssue
}

const getOutput = (separetedByIssueLive: any, infoByIssueLive: any) => {
  const output: Array<any> = []
  const len = Object.keys(infoByIssueLive).length
  for (let i = 0; i < len; i++) {
    output.push(Object.values(infoByIssueLive)[i])
    const _separetedByCompany: any = Object.values(separetedByIssueLive)[i]
    for (let j = 0; j < _separetedByCompany.length; j++) {
      const outPutLine = JSON.parse(JSON.stringify(Object.values(separetedByIssueLive)[i][j]))
      output.push(outPutLine)
    }
  }
  return output
}

export const getLiveOutput = (borrowPool: LivePool[]) => {
  const separetedByIssueLive = getSeparetedByIssueLive(borrowPool)
  const infoByIssueLive = getInfoByIssueLive(separetedByIssueLive)
  const output = getOutput(separetedByIssueLive, infoByIssueLive)
  return output
}

export const getIsInfScrollObserver = (pool: any, index: any) => {
  if (index === pool.length - 20) {
    return true
  }
  return false
}
