import { FunctionComponent, Suspense } from 'react'
import { hot } from 'react-hot-loader/root'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import Router from './Router'
const App: FunctionComponent = () => {
  // useEffect(() => {
  //   window.addEventListener('keydown', (e) => {
  //     console.log('e', e.key)
  //     if (e.defaultPrevented) {
  //       return
  //     }
  //     let handled = false
  //     if (e.key === 'F6') {
  //       handled = true
  //     }
  //     if (handled) {
  //       e.preventDefault()
  //     }
  //   })
  // }, [])
  return (
    <Suspense fallback={null}>
      <RecoilRoot>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </RecoilRoot>
    </Suspense>
  )
}

export default hot(App)
