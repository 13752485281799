import { CSSProperties, FunctionComponent, useCallback, useEffect, useRef } from 'react'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  issueCode?: string
  style?: CSSProperties
  inputStyle?: CSSProperties
  labelStyle?: CSSProperties
  position?: 'left' | 'right' | 'top' | 'bottom'
  id?: string
  name?: string
  label?: string
}

const TextField: FunctionComponent<Props> = ({
  issueCode,
  style,
  inputStyle,
  labelStyle,
  position,
  id,
  label,
  children,
  name,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>()
  const Label = useCallback(() => {
    if (!label) return null
    return <label htmlFor={id}>{label}</label>
  }, [label, id])

  // useEffect(() => {
  //   if (!issueCode) {
  //     return
  //   }
  //   if (issueCode.length === 6) {
  //     ref.current.focus()
  //   }
  // }, [issueCode, ref])

  return (
    <div
      className={`text-field ${position}`}
      style={style}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        e.persist()
      }}
    >
      {(position == 'left' || position == 'top') && <Label />}
      <input
        // ref={ref}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          e.persist()
        }}
        onFocus={(e) => e.target.select()}
        id={id}
        style={inputStyle}
        {...props}
      ></input>
      {children}
      {(position == 'right' || position == 'bottom') && <Label />}
    </div>
  )
}

export default TextField

TextField.defaultProps = {
  position: 'left',
  type: 'text',
}
