import { httpGetMessageList } from '@src/api/httpAPI/httpMessageAPI'
import { httpGetOrderBookList } from '@src/api/httpAPI/httpOrderBookAPI'
import { useCallback, useEffect, useState } from 'react'
import useInfiniteScroll from './useInfiniteScroll'

export default function useMessage() {
  const [isLoading, setIsLoading] = useState(false)
  const [isLast, setIsLast] = useState(true)
  const [page, setPage] = useState(0)
  const [messageList, setMessageList] = useState<MessageDetailType[]>([])

  const getMessageList = useCallback(
    async ({
      page,
      orderType,
      partnerAccountId,
      messageType,
      isSender,
      isRead,
    }: {
      page: number
      orderType: OrderType | ''
      partnerAccountId: number | ''
      messageType: MessageStatusType | ''
      isSender: boolean | ''
      isRead: boolean | ''
    }) => {
      setIsLoading(true)
      const messageList = await httpGetMessageList({
        partnerAccountId,
        orderType,
        messageType: messageType,
        isSender,
        isRead,
        page,
        size: 20,
      })

      if (page == 0) {
        setMessageList(messageList)
      } else {
        setMessageList((prevMessageList) => [...prevMessageList, ...messageList])
      }
      setIsLast(messageList.length < 1)

      setIsLoading(false)
    },
    []
  )

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  useEffect(() => {
    onInfiniteScrollInit(document.querySelector('.table-bottom'))
  }, [])

  useEffect(() => {
    if (isLoading || isLast) onInfiniteScrollDisconnect()
    else onInfiniteScrollUpdate()
  }, [isLoading, isLast])

  return {
    messageList,
    setMessageList,
    page,
    setPage,
    getMessageList,
  }
}
