import { localStorageAPI } from '@src/api/storageAPI'
import i18next from '@src/lang/i18n'
import { useSetRecoilState } from 'recoil'
import { langState } from '../stores/langState'

const useLang = () => {
  const setLangStore = useSetRecoilState(langState)

  const getLang = () => {
    const lang = localStorageAPI.getItem('lang') === 'en' ? 'en' : 'ko'
    return lang
  }

  const setLang = () => {
    switch (getLang()) {
      case 'en':
        i18next.changeLanguage('en')
        setLangStore('en')
        break
      case 'ko':
        i18next.changeLanguage('ko')
        localStorageAPI.setItem('lang', 'ko')
        setLangStore('ko')
        break
      default:
        i18next.changeLanguage('ko')
        localStorageAPI.setItem('lang', 'ko')
        setLangStore('ko')
    }
  }

  return setLang
}

export default useLang
