import { httpGetAccounts } from '@src/api/httpAPI/httpAccountAPI'
import {
  httpPostDesignatedOrdersRequest,
  httpPutDesignatedOrdersRequest,
} from '@src/api/httpAPI/httpDesignatedOrdersAPI'
import Button from '@src/components/Button'
import Checkbox from '@src/components/Checkbox'
import ExcelButton from '@src/components/ExcelButton/ExcelButton'
import OrderTypeButtons from '@src/components/OrderTypeButtons'
import SearchIssue from '@src/components/SearchIssue/SearchIssue'
import SelectOption from '@src/components/SelectOption'
import TextField from '@src/components/TextField'
import { OrderTypeText } from '@src/constants/OrderTypeText'
import useDebounce from '@src/hooks/useDebounce'
import useDesignatedOrder from '@src/hooks/useDesignatedOrder'
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'

import { DateTime } from 'luxon'
import ResultPopup from '@src/components/ResultPopup'
import { formatNumber } from '@src/util/formatNumber'
import LogoutButton from '@src/components/Button/LogoutButton'
import { useTranslation } from 'react-i18next'

const DesignatedOrdersChange = () => {
  const tableRef = useRef<HTMLTableElement>(null)

  const [orderType, setOrderType] = useState<OrderType>('BORROW')
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])
  const [accounts, setAccounts] = useState<AccountType[]>([])
  const [settlement, setSettlement] = useState<SettlementType>('TODAY')
  const [selectedAccount, setSelectedAccount] = useState<AccountType>(null)

  const [isOpenResultPopup, setIsOpenResultPopup] = useState<boolean>(false)
  const [resultDesignatedOrdersRequest, setResultDesignatedOrdersRequset] = useState<
    ResponseDesignatedPostRequestOrdersType[]
  >([])
  const [query, setQuery] = useState<string>('')
  const { designatedOrderList, setDesignatedOrderList, page, setPage, getDesignatedOrderList } =
    useDesignatedOrder()
  const [resultPopupTitle, setResultPopupTitle] = useState<string>('')
  const trans = useTranslation()

  const requestDesignatedOrder = useCallback(
    async (type: 'CHANGE' | 'AGREE') => {
      const selectedDesignatedOrders = designatedOrderList.filter((_, index) =>
        selectedIndex.includes(index)
      )

      try {
        let resultData = []
        if (type == 'CHANGE') {
          resultData = await httpPostDesignatedOrdersRequest(
            selectedDesignatedOrders.map(({ orderId, requestedRate, currentRate }) => {
              if (currentRate == requestedRate) throw new Error('변경요청 요율이 동일합니다')
              return {
                orderId,
                rate: requestedRate,
              }
            })
          )
          setResultPopupTitle('지정거래 변경요청 완료되었습니다.')
        }

        if (type == 'AGREE') {
          resultData = await httpPutDesignatedOrdersRequest(
            selectedDesignatedOrders.map(({ orderId, requestId, currentRate, requestedRate }) => {
              if (currentRate !== requestedRate) throw new Error('요율을 잘못 입력하였습니다.')
              return {
                orderId,
                requestId,
                isAccept: true,
              }
            })
          )
          setResultPopupTitle('지정거래 동의 완료되었습니다.')
        }
        setIsOpenResultPopup(true)
        setResultDesignatedOrdersRequset(resultData)
      } catch (err) {
        if (err.message) {
          alert(err.message)
        } else {
          alert('요청에 실패하였습니다.')
        }
      }
    },
    [selectedIndex, designatedOrderList]
  )

  const getAccounts = useCallback(async () => {
    const { data } = await httpGetAccounts()
    setAccounts(data)
  }, [])

  useEffect(() => {
    getAccounts()
  }, [])

  useEffect(() => {
    getDesignatedOrderList({
      page,
      query,
      orderType,
      type: 'ACCEPT',
      partnerAccountId: selectedAccount?.accountId || '',
      settlement,
    })
  }, [page, query, orderType, selectedAccount, settlement])

  useEffect(() => {
    setPage(0)
    setDesignatedOrderList([])
    setSelectedIndex([])
    tableRef.current.scrollTo({ top: 0 })
  }, [orderType, query, selectedAccount, settlement])

  return (
    <>
      <section className={`designated-orders-change ${orderType}`}>
        <article>
          <header>
            <OrderTypeButtons handleChangeOrderType={setOrderType} />
          </header>
          <div className="bigger-wrapper">
            <div className="action-buttons">
              <Button
                onClick={() => requestDesignatedOrder('CHANGE')}
                color={selectedIndex.length ? 'green' : 'grey'}
              >
                변경요청
              </Button>
              <Button
                color={selectedIndex.length ? 'green' : 'grey'}
                onClick={() => requestDesignatedOrder('AGREE')}
              >
                동의
              </Button>
            </div>
            <div className="options">
              <SearchIssue searchIcon handleSearch={(query) => setQuery(query)} />
              <div className="select-options">
                <SelectOption
                  value={settlement}
                  onChange={(e) => {
                    setSettlement(e.target.value as SettlementType)
                  }}
                  label="결제일"
                  options={[
                    {
                      label: 'T',
                      value: 'TODAY',
                    },
                    {
                      label: 'T1',
                      value: 'NEXT_DAY',
                    },
                  ]}
                />
                <SelectOption
                  label="상대기관"
                  defaultValue=""
                  onChange={(e) => {
                    const account = accounts.find(
                      ({ accountId }) => accountId == Number(e.target.value)
                    )
                    setSelectedAccount(account ? account : null)
                  }}
                  options={[
                    { label: '전체', value: '' },
                    ...accounts.map(({ companyName, accountId }) => ({
                      label: `${companyName}`,
                      value: accountId,
                    })),
                  ]}
                />
              </div>
            </div>
            <div className="fixed-table" ref={tableRef}>
              <table>
                <thead>
                  <th>
                    <Checkbox
                      checked={selectedIndex.length == designatedOrderList.length}
                      onClick={() => {
                        if (selectedIndex.length == designatedOrderList.length) {
                          setSelectedIndex([])
                        } else {
                          setSelectedIndex(designatedOrderList.map((_, index) => index))
                        }
                      }}
                    />
                  </th>
                  <th>결제일</th>
                  <th>{trans.t('lendListTable.orderType')}</th>
                  <th>{trans.t('partnerAsset')}</th>
                  <th>{trans.t('issueCode')}</th>
                  <th>{trans.t('issueName')}</th>
                  <th>{trans.t('table.volume')}</th>
                  <th>{trans.t('borrowListTable.rate')}</th>
                  <th>{trans.t('updateRate')}</th>
                  <th>필요담보(원)</th>
                  <th></th>
                </thead>
                <tbody>
                  {designatedOrderList.map((designatedOrder, index) => (
                    <TableRow
                      key={index}
                      index={index}
                      designatedOrder={designatedOrder}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                      setDesignatedOrderList={setDesignatedOrderList}
                    />
                  ))}
                  <div className="table-bottom"></div>
                </tbody>
              </table>
            </div>
          </div>

          <footer>
            <ExcelButton
              data={designatedOrderList.map((designatedOrder) => ({
                ...designatedOrder,
                issueCode: `=""${designatedOrder.stock.issueCode}""`,
                orderType: OrderTypeText[orderType],
              }))}
              headers={headers}
              fileName={`지정거래 동의 및 변경요청 ${DateTime.local().toFormat('yyyyMMdd')}`}
            >
              엑셀다운로드
            </ExcelButton>
            <ul>
              <li>종목수 : {designatedOrderList.length}</li>
              <li>수량합계 : {designatedOrderList.reduce((acc, cur) => acc + cur.volume, 0)}</li>
              <li>
                필요담보합계 :{' '}
                {formatNumber(
                  designatedOrderList.reduce(
                    (acc, cur) => acc + cur.stock.previousClosingPrice * cur.volume * 1,
                    0
                  )
                )}
                원
              </li>
            </ul>
          </footer>
        </article>
      </section>
      {isOpenResultPopup && (
        <ResultPopup
          close={() => {
            setIsOpenResultPopup(false)
            setResultDesignatedOrdersRequset(null)
          }}
          title={resultPopupTitle}
        />
      )}
    </>
  )
}

interface TableRowType {
  index: number
  designatedOrder: DesignatedOrderRequestType
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  setDesignatedOrderList: React.Dispatch<React.SetStateAction<DesignatedOrderRequestType[]>>
}

const TableRow: FunctionComponent<TableRowType> = ({
  designatedOrder,
  selectedIndex,
  setSelectedIndex,
  setDesignatedOrderList,
  index,
}) => {
  const [rate, setRate] = useState<number | string>(designatedOrder.currentRate.toFixed(2))
  const debouncedRate = useDebounce(rate, 1000)

  const changeTableInput = useCallback((index, field: keyof typeof designatedOrder, value: any) => {
    setDesignatedOrderList((orderBookList) => [
      ...orderBookList.slice(0, index),
      {
        ...orderBookList[index],
        [field]: value,
      },
      ...orderBookList.slice(index + 1),
    ])
  }, [])

  useEffect(() => {
    changeTableInput(index, 'requestedRate', parseFloat(Number(rate).toFixed(2)))
  }, [debouncedRate])

  return (
    <tr
      onClick={() => {
        const checked = selectedIndex.includes(index)
        if (!checked) {
          setSelectedIndex((selectedIndex) => [...selectedIndex, index])
        } else {
          setSelectedIndex((selectedIndex) => selectedIndex.filter((_index) => _index !== index))
        }
      }}
    >
      <td>
        <Checkbox checked={selectedIndex.includes(index)} onClick={() => {}} />
      </td>
      <td>{designatedOrder.settlementDate}</td>
      <td>{OrderTypeText[designatedOrder.orderType]}</td>
      <td className="left">
        {designatedOrder.partnerAccount &&
          `${designatedOrder.partnerAccount.companyName}(${designatedOrder.partnerAccount.companyCode})`}
      </td>
      <td>{designatedOrder.stock.issueCode}</td>
      <td className="left">{designatedOrder.stock.issueName}</td>
      <td className="right">{formatNumber(designatedOrder.volume)}</td>
      <td className="right">{designatedOrder.currentRate.toFixed(2)}</td>
      <td>
        <TextField
          type="text"
          value={rate}
          min={0}
          pattern="^\d*(\.\d{0,2})?$"
          onChange={(e) => {
            if (e.target.validity.valid && Number(e.target.value) < 1000) {
              setRate(e.target.value)
            }
          }}
        />
      </td>
      <td className="right">
        {formatNumber(designatedOrder.stock.previousClosingPrice * designatedOrder.volume * 1)}
      </td>
      <td></td>
    </tr>
  )
}

const headers = [
  { label: '결제일', key: 'settlementDate' },
  { label: '종목코드', key: 'issueCode' },
  { label: '종목명', key: 'stock.issueName' },
  { label: '대차구분', key: 'orderType' },
  { label: '상대기관', key: 'partnerAccount?.companyName' },
  { label: '수량(주)', key: 'volume' },
  { label: '신청요율($)', key: 'currentRate' },
  { label: '변경요율(%)', key: 'requestedRate' },
]

export default DesignatedOrdersChange
