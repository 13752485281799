import { useCallback } from 'react'

const useVolumeFormat = () => {
  const numberFormat = useCallback((_number: string, _sep = ',') => {
    if (_number === '0') {
      return _number
    }
    _number = typeof _number != 'undefined' && Number(_number) > 0 ? _number : ''
    _number = _number
      .replace(
        new RegExp('^(\\d{' + (_number.length % 3 ? _number.length % 3 : 0) + '})(\\d{3})', 'g'),
        '$1 $2'
      )
      .replace(/(\d{3})+?/gi, '$1 ')
      .trim()
    if (typeof _sep != 'undefined' && _sep != ' ') {
      _number = _number.replace(/\s/g, _sep)
    }
    return _number
  }, [])
  return numberFormat
}

export default useVolumeFormat
