import { SelectedGroup, SelectedPool } from '@src/pages/LendPoolRegister/LendPoolRegister'
import { CSSProperties, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  style?: CSSProperties
  width?: number
  id?: SelectedGroup
  label?: string
  checked?: boolean
  position?: 'left' | 'right' | 'top' | 'bottom'
  selectedGroup: SelectedGroup[]
  setSelectedGroup: (value: React.SetStateAction<SelectedGroup[]>) => void
  poolList: (string | number)[]
  selectedPoolList: SelectedPool[]
  setSelectedPoolList: React.Dispatch<React.SetStateAction<SelectedPool[]>>
}

const isAsset = (id: string) => {
  if (id === 'ASSET') {
    return true
  }
  return false
}

const GroupCheckbox: FunctionComponent<Props> = ({
  style,
  width,
  position,
  label,
  id,
  selectedGroup,
  setSelectedGroup,
  poolList,
  selectedPoolList,
  setSelectedPoolList,
  ...props
}) => {
  const trans = useTranslation()
  const checked = poolList.length !== 0
  const Label = () => {
    if (!label) return null
    return <label htmlFor={id}>{label}</label>
  }
  return (
    <div
      className={`checkbox ${position}`}
      style={style}
      onClick={() => {
        if (isAsset(id)) {
          if (checked) {
            const newSelected = selectedPoolList.filter((pool) => pool.companyCode === undefined)
            setSelectedPoolList(newSelected)
            return
          }
          const newSelected = [
            ...selectedPoolList,
            {
              companyName: trans.t('registerOption.companyAll'),
              companyCode: '-1',
            },
          ]
          setSelectedPoolList(newSelected)
          return
        }
        if (checked) {
          const newSelected = selectedPoolList.filter((pool) => pool.accountId === undefined)
          setSelectedPoolList(newSelected)
          return
        }
        const newSelected = [
          ...selectedPoolList,
          {
            companyName: trans.t('registerOption.securityAll'),
            accountId: -1,
          },
        ]
        setSelectedPoolList(newSelected)
        return
      }}
    >
      {(position == 'left' || position == 'top') && <Label />}
      <input
        readOnly
        type="checkbox"
        id={id}
        checked={checked}
        {...props}
        // key={JSON.stringify(props.checked)}
      />
      {(position == 'right' || position == 'bottom') && <Label />}
    </div>
  )
}

export default GroupCheckbox

GroupCheckbox.defaultProps = {
  position: 'left',
}
