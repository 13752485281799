import { httpGetCompanies } from '@src/api/httpAPI/httpCompanyAPI'
import {
  httpGetAssetGroupList,
  httpGetMyGroupList,
  httpGetSecurityGroupList,
} from '@src/api/httpAPI/httpLendPoolRegisterAPI'
import GroupAutocomplate from '@src/components/Autocomplete/GroupAutocomplete'
import GroupAutocomplateCompanyCode from '@src/components/Autocomplete/GroupAutocompleteCompanyCode'
import GroupAutocomplateSecurity from '@src/components/Autocomplete/GroupAutocompleteSecurity'
import GroupAllCheckbox from '@src/components/Checkbox/GroupAllCheckbox'
import GroupCheckbox from '@src/components/Checkbox/GroupCheckbox'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { SelectedGroup, SelectedPool } from './LendPoolRegister'
import ThirdLine from './LendPoolRegisterOptionThirdLine'

import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '@src/api/storageAPI'
import { useRecoilState } from 'recoil'
import { displayLangState } from '@src/stores/displayLangState'

interface Props {
  poolType: PoolOptionType
  setPoolType: React.Dispatch<React.SetStateAction<PoolOptionType>>
  selectedPoolList: SelectedPool[]
  setSelectedPoolList: React.Dispatch<React.SetStateAction<SelectedPool[]>>
  selectedGroup: SelectedGroup[]
  setSelectedGroup: React.Dispatch<React.SetStateAction<SelectedGroup[]>>
}

const LendPoolRegisterOption: FunctionComponent<Props> = (props) => {
  const [myGroupList, setMyGroupList] = useState<any[]>([])
  const [assetGroupList, setAssetGroupList] = useState<any[]>([])
  const [securityGroupList, setSecurityGroupList] = useState<any[]>([])

  const [selectedGroup, setSelectedGroup] = [props.selectedGroup, props.setSelectedGroup]
  const [displayLang, setDisplayLang] = useRecoilState(displayLangState)

  const trans = useTranslation()

  const [selectedPoolList, setSelectedPoolList] = [
    props.selectedPoolList,
    props.setSelectedPoolList,
  ]

  const assetCompanyCodeList = useMemo(() => {
    const list = []
    selectedPoolList.forEach(({ companyCode }) => {
      if (!companyCode) {
        return
      }
      list.push(companyCode)
    })
    return list
  }, [selectedPoolList, trans])

  const securityAccountIdList = useMemo(() => {
    const list = []
    selectedPoolList.forEach(({ accountId }) => {
      if (!accountId) {
        return
      }
      list.push(accountId)
    })
    return list
  }, [selectedPoolList, trans])

  const firstFetch = async () => {
    const [_myGroupList, _assetGroupList, _securityGroupList] = await Promise.all([
      httpGetMyGroupList(),
      httpGetAssetGroupList(),
      httpGetSecurityGroupList(),
    ])

    const myGroupParsed = _myGroupList.map(({ id, name }) => {
      return { label: name, id: id }
    })

    const assetGroupParsed = [{ label: trans.t('registerOption.companyAll'), companyCode: '-1' }]

    if (localStorageAPI.getItem('lang') === 'en') {
      _assetGroupList.forEach(({ companyCode, companyEnglishName }) => {
        assetGroupParsed.push({ label: companyEnglishName, companyCode: companyCode })
      })
    } else {
      _assetGroupList.forEach(({ companyCode, companyName }) => {
        assetGroupParsed.push({ label: companyName, companyCode: companyCode })
      })
    }

    const securityGroupParsed = [
      {
        label: trans.t('registerOption.securityAll'),
        accountId: -1,
        companyPropertyCode: '',
        companySLBCode: '',
      },
    ]

    if (localStorageAPI.getItem('lang') === 'en') {
      _securityGroupList.forEach(
        ({ accountId, companyEnglishName, companyPropertyCode, companySLBCode }) => {
          securityGroupParsed.push({
            label: companyEnglishName,
            accountId: accountId,
            companyPropertyCode,
            companySLBCode,
          })
        }
      )
    } else {
      _securityGroupList.forEach(
        ({ accountId, companyName, companyPropertyCode, companySLBCode }) => {
          securityGroupParsed.push({
            label: companyName,
            accountId: accountId,
            companyPropertyCode,
            companySLBCode,
          })
        }
      )
    }
    setMyGroupList(myGroupParsed)
    setAssetGroupList(assetGroupParsed)
    setSecurityGroupList(securityGroupParsed)
  }

  useEffect(() => {
    firstFetch()
  }, [displayLang])

  const getPoolType = (selectedGroup) => {
    if (selectedGroup.length === 0) {
      return 'ALL'
    }
    if (selectedGroup.includes('ASSET') && selectedGroup.includes('SECURITY')) {
      return 'ALL'
    }
  }

  // const handleCheckBoxClick = (selectedGroup: any) => {
  //   console.log('clicked')
  // }

  const CheckBoxIds = {
    ALL: 'ALL' as const,
    MYGROUP: 'MYGROUP' as const,
    ASSET: 'ASSET' as const,
    SECURITY: 'SECURITY' as const,
  }

  return (
    <div className="option">
      <div className="first-line">
        <GroupAllCheckbox
          id={CheckBoxIds.ALL}
          label={trans.t('registerOption.lendingAll')}
          position="right"
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          selectedPoolList={selectedPoolList}
          setSelectedPoolList={setSelectedPoolList}
        />
      </div>
      <div className="second-line">
        <div className="checkbox-wrap">
          <span>{trans.t('registerOption.myGroupLabel')}</span>
          {/* <GroupCheckbox
            id={CheckBoxIds.MYGROUP}
            label="내 그룹에게 공개"
            position="right"
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          /> */}
          <GroupAutocomplate
            options={myGroupList}
            selectedGroupId={CheckBoxIds.MYGROUP}
            selectedPoolList={selectedPoolList}
            setSelectedPoolList={setSelectedPoolList}
          ></GroupAutocomplate>
        </div>
        <div className="checkbox-wrap">
          <GroupCheckbox
            id={CheckBoxIds.ASSET}
            label={trans.t('registerOption.companyLabel')}
            position="right"
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            poolList={assetCompanyCodeList}
            selectedPoolList={selectedPoolList}
            setSelectedPoolList={setSelectedPoolList}
          />
          <GroupAutocomplateCompanyCode
            options={assetGroupList.filter((company) => company.companyCode !== '')}
            selectedGroupId={CheckBoxIds.ASSET}
            selectedPoolList={selectedPoolList}
            setSelectedPoolList={setSelectedPoolList}
            assetCompanyCodeList={assetCompanyCodeList}
          ></GroupAutocomplateCompanyCode>
        </div>
        <div className="checkbox-wrap">
          <GroupCheckbox
            id={CheckBoxIds.SECURITY}
            label={trans.t('registerOption.securityLabel')}
            position="right"
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            poolList={securityAccountIdList}
            selectedPoolList={selectedPoolList}
            setSelectedPoolList={setSelectedPoolList}
          />
          <GroupAutocomplateSecurity
            options={securityGroupList}
            selectedGroupId={CheckBoxIds.SECURITY}
            selectedPoolList={selectedPoolList}
            setSelectedPoolList={setSelectedPoolList}
            securityAccountIdList={securityAccountIdList}
          ></GroupAutocomplateSecurity>
        </div>
      </div>
      <div className="third-line">
        <ThirdLine
          selectedGroup={selectedGroup}
          selectedPoolList={selectedPoolList}
          setSelectedPoolList={setSelectedPoolList}
        ></ThirdLine>
        {/* <SelectedCompanyList
          companies={selectedAssetCompanies[0]?.companyCode == 'ALL' ? [] : selectedAssetCompanies}
          setCompanies={setSelectedAssetCompanies}
        />
        <SelectedCompanyList
          companies={
            selectedDSecurityCompanies[0]?.companyCode == 'ALL' ? [] : selectedDSecurityCompanies
          }
          setCompanies={setSelectedDSecurityCompanies}
        />
        <SelectedCompanyList
          companies={
            selectedOSecurityCompanies[0]?.companyCode == 'ALL' ? [] : selectedOSecurityCompanies
          }
          setCompanies={setSelectedOSecurityCompanies}
        /> */}
      </div>
    </div>
  )
}

export default LendPoolRegisterOption
