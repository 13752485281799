interface order {
  issueName?: string
  issueEnglishName?: string
  volume: number
  rate: number
  companyName?: string
  companyEnglishName?: string
}

export const getOrderText = (orderList: order[], lang?: string) => {
  const volumeSufix = lang === 'en' ? 'shs, ' : '주, '
  const rateSufix = lang === 'en' ? '% / counter party: ' : '% / 거래상대방: '
  const orders = orderList.map((order) => {
    const issueName = lang === 'en' ? order.issueEnglishName : order.issueName
    const companyName = lang === 'en' ? order.companyEnglishName : order.companyName
    return (
      issueName +
      ', ' +
      order.volume +
      volumeSufix +
      order.rate.toFixed(2) +
      rateSufix +
      companyName +
      ', ' +
      '\n'
    )
  })
  console.log('orders', orders)
  let text = ''
  orders.forEach((order) => {
    text += order
  })
  return text.substring(0, text.length - 3)
}

export const getLiveOrderText = (orderList: order[], lang?: string) => {
  const volumeSufix = lang === 'en' ? 'shs, ' : '주, '
  const orders = orderList.map((order) => {
    const issueName = lang === 'en' ? order.issueEnglishName : order.issueName
    return issueName + ', ' + order.volume + volumeSufix + order.rate.toFixed(2) + '%, ' + '\n'
  })
  let text = ''
  orders.forEach((order) => {
    text += order
  })
  return text.substring(0, text.length - 3)
}
