export const csvHeader = [
  '구분',
  '결제유형',
  '거래유형',
  '상태',
  '상대기관',
  '종목코드',
  '종목명',
  '희망수량(주)',
  '희망요율(%)',
  '대여풀 잔여수량(주)',
  '차입자 신청수량(주)',
  '차입자 신청요율(%)',
  '변경수량(주)',
  '변경요율(%)',
  '스왑',
]

export const csvHeaderEn = [
  'B/L',
  'Value date',
  'Settlement type',
  'Status',
  'Counterparty',
  'Ticker',
  'Security',
  'Uploaded qty',
  'Offer rate',
  'Remaining qty',
  'Requested qty',
  'Bid rate',
  'Qty update',
  'Rate update',
  'Swap',
]

export const csvKey = [
  '$대여',
  '!settlementType',
  '!transactionType',
  '!requestType',
  'partnerAccount.companyName',
  '0stock.issueCode',
  'stock.issueName',
  'poolVolume',
  '#poolRate',
  'remainVolume',
  'currentVolume',
  '#currentRate',
  '-requestedVolume',
  '-requestedRate',
  '?isSwap',
]

export const csvKeyEn = [
  '$대여',
  '!settlementType',
  '!transactionType',
  '!requestType',
  'partnerAccount.companyEnglishName',
  '0stock.issueCode',
  'stock.issueEnglishName',
  'poolVolume',
  '#poolRate',
  'remainVolume',
  'currentVolume',
  '#currentRate',
  '-requestedVolume',
  '-requestedRate',
  '?isSwap',
]

export const csvDict = {
  CUSTOM: '맞춤',
  DESIGNATED: '지정',
  COMPETITIVE: '경쟁',
  ACCEPT: '거래신청',
  UPDATE: '변경요청',
  CANCEL: '취소요청',
  TODAY: 'T',
  NEXT_DAY: 'T1',
  NONE: '없음',
}

export const csvDictEn = {
  CUSTOM: 'Customized',
  DESIGNATED: 'Arranged',
  COMPETITIVE: 'Bid offer',
  NONE: 'No selection',
  ACCEPT: 'Trade requested',
  UPDATE: 'Update requested',
  CANCEL: 'Cancel requested',
  TODAY: 'T',
  NEXT_DAY: 'T1',
}
