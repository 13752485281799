import { httpGetOrder } from '@src/api/httpAPI/httpOrdersAPI'
import Checkbox from '@src/components/Checkbox'
import getTableBodyTds from '@src/components/Table/getTableBodyTds'
import { OrderTypeText, OrderTypeTextEn } from '@src/constants/OrderTypeText'
import { PoolStatusText, PoolStatusTextEn } from '@src/constants/PoolStatusText'
import useInfiniteScroll from '@src/hooks/useInfiniteScroll'
import { forceFetchState } from '@src/stores/forceFetchState'
import { formatNumber } from '@src/util/formatNumber'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { localStorageAPI } from '../../api/storageAPI'
import Popup from '../PopupState/PopupState'

interface Props {
  setSelectedOrderIds: React.Dispatch<React.SetStateAction<number[]>>
  orderList: OrderDataType[]
  setOrderList: React.Dispatch<React.SetStateAction<OrderDataType[]>>
  pageType: PageType
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  query: string
  livePoolStatus: PoolStatusType
  setLivePoolStatus: React.Dispatch<React.SetStateAction<PoolStatusType>>
  constants: any
  tab: string
}

const LendPoolRegisterListLiveTable: FunctionComponent<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = [props.selectedIndex, props.setSelectedIndex]
  const [isLast, setIsLast] = useState(true)
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = [props.page, props.setPage]

  const [forceFetch, setForceFetch] = useRecoilState(forceFetchState)

  const transKeyPrefix = props.pageType === 'LEND' ? 'lendListTable' : 'borrowListTable'
  const trans = useTranslation('translation', { keyPrefix: transKeyPrefix })

  const getOrderList = useCallback(
    async (page?: number) => {
      try {
        const orderList = await httpGetOrder({
          page,
          size: 20,
          query: props.query,
          orderType: props.pageType,
          // orderStatus: 'RECEIVED',
          poolStatus: props.livePoolStatus,
        })
        if (page == 0) {
          props.setOrderList(orderList)
        } else {
          props.setOrderList((prevOrder) => [...prevOrder, ...orderList])
        }
        setIsLast(orderList.length < 1)
        if (page == 0 && !orderList.length) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      } catch (err) {
        //
      }
      setIsLoading(false)
    },
    [page, props.query, props.livePoolStatus]
  )

  useEffect(() => {
    getOrderList(page)
  }, [page, forceFetch])

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  useEffect(() => {
    onInfiniteScrollInit(document.querySelector('.table-bottom'))
  }, [])

  useEffect(() => {
    if (isLoading || isLast) onInfiniteScrollDisconnect()
    else onInfiniteScrollUpdate()
  }, [isLoading, isLast])

  useEffect(() => {
    props.setSelectedOrderIds(
      props.orderList
        .filter((_, index) => selectedIndex.includes(index))
        .map(({ orderId }) => orderId)
    )
  }, [selectedIndex])

  const getRowStatus = (volume: number, remainVolume: number) => {
    return volume === remainVolume
      ? '신청'
      : volume > remainVolume
      ? '일부확정'
      : remainVolume == 0 && '전부확정'
  }

  const handlePopupMenuClick = (
    popupMenuValue: string,
    queryKey: string,
    popupClose: () => void
  ) => {
    props.setLivePoolStatus(popupMenuValue as PoolStatusType)
    props.setPage(0)
    setForceFetch(!forceFetch)
    popupClose()
  }

  const getIsCancelable = (orderPoolStatus: PoolStatusType) => {
    if (orderPoolStatus === 'PARTIALLY_MATCHED' || orderPoolStatus === 'RECEIVED') {
      return true
    }
    return false
  }

  return (
    <div
      className={
        'fixed-table live' + ' ' + props.constants.pageType.toUpperCase() + ' ' + 'tab-' + props.tab
      }
    >
      <table>
        <thead>
          <th>
            <Checkbox
              checked={
                props.orderList.length !== 0 && selectedIndex.length === props.orderList.length
              }
              onClick={() => {
                if (selectedIndex.length === props.orderList.length) {
                  setSelectedIndex([])
                } else {
                  setSelectedIndex(props.orderList.map((_, index) => index))
                }
              }}
            />
          </th>
          <th>{trans.t('createdDate')}</th>
          <th>{trans.t('orderType')}</th>
          <th>{trans.t('settlementType')}</th>
          <th className={'lend-th-change'}>
            <Popup
              visibleText={trans.t('poolStatus')}
              menuItems={[
                {
                  text: trans.t('innerPoolStatus.all'),
                  value: '',
                },
                {
                  text: trans.t('innerPoolStatus.received'),
                  value: 'RECEIVED',
                },
                {
                  text: trans.t('innerPoolStatus.matched'),
                  value: 'MATCHED',
                },
                {
                  text: trans.t('innerPoolStatus.partiallyMatched'),
                  value: 'PARTIALLY_MATCHED',
                },
                {
                  text: trans.t('innerPoolStatus.canceled'),
                  value: 'CANCELED',
                },
              ]}
              queryKey="livePoolStatus"
              handlePopupMenuClick={handlePopupMenuClick}
              pageType={props.constants.pageType}
            ></Popup>
          </th>
          <th>{trans.t('issueCode')}</th>
          <th>{trans.t('issueName')}</th>
          <th>{props.pageType === 'LEND' ? trans.t('volume') : trans.t('liveOfferQty')}</th>
          <th>{props.pageType === 'LEND' ? trans.t('rate') : trans.t('liveOfferRate')}</th>
          <th>{trans.t('matchedVolume')}</th>
          <th>{props.pageType === 'LEND' ? trans.t('remainVolume') : trans.t('unfilledQty')}</th>
          <th></th>
        </thead>
        <tbody>
          {props.orderList.map((order, index) => (
            <tr
              onClick={() => {
                if (!getIsCancelable(order.poolStatus)) {
                  return
                }
                const checked = selectedIndex.includes(index)
                if (!checked) {
                  setSelectedIndex((selectedIndex) => [...selectedIndex, index])
                } else {
                  setSelectedIndex((selectedIndex) =>
                    selectedIndex.filter((_index) => _index !== index)
                  )
                }
              }}
            >
              {getTableBodyTds([
                [selectedIndex.includes(index), getIsCancelable(order.poolStatus)],
                order.orderDateTime.substring(0, 8),
                localStorageAPI.getItem('lang') === 'en'
                  ? OrderTypeTextEn[order.orderType]
                  : OrderTypeText[order.orderType],
                'T',
                localStorageAPI.getItem('lang') === 'en'
                  ? PoolStatusTextEn[order.poolStatus]
                  : PoolStatusText[order.poolStatus],
                order.stock.issueCode,
                localStorageAPI.getItem('lang') === 'en'
                  ? order.stock.issueEnglishName
                  : order.stock.issueName,
                formatNumber(order.volume),
                Number(order.rate).toFixed(2),
                formatNumber(order.volume - order.remainVolume),
                formatNumber(order.remainVolume),
                '',
              ])}
            </tr>
          ))}
          <div className="table-bottom"></div>
        </tbody>
      </table>
    </div>
  )
}

export default LendPoolRegisterListLiveTable
